import React, { useCallback, useEffect, useState, Fragment } from "react"
import { useParams } from "react-router-dom"
import _ from "lodash"
import CommentCard from "./CommentCard"
import { Pagination, Dropdown, Form, Icon } from "semantic-ui-react"
import DateTime from "@nateradebaugh/react-datetime"
import ReplyCard from "./ReplyCard"
import CommentApi from "../../libs/comment"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

const pageOptions = [
	{
		key: "10",
		text: "10",
		value: 10,
	},
	{
		key: "15",
		text: "15",
		value: 15,
	},
	{
		key: "25",
		text: "25",
		value: 25,
	},
	{
		key: "50",
		text: "50",
		value: 50,
	},
]
const statusOptions = [
	{
		key: "all",
		text: "All",
		value: "All",
	},
	{
		key: "reported",
		text: "Reported",
		value: "Reported",
	},
	{
		key: "deleted",
		text: "Deleted",
		value: "Deleted",
	},
]

export default function Topic(props) {
	const { id } = useParams()
	const admin = User.getCookieData()
	const [dataComments, setDataComments] = useState([])
	const [page, setPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(25)
	const [total, setTotal] = useState(0)
	const [formSearch, setFormSearch] = useState({
		status: "All",
	})
	const [condition, setCondition] = useState({ status: "All" })
	const [userTemp, setUserTemp] = useState({}) // fix when block user from Topic then ReplyCard is not change
	const [disabled, setDisabled] = useState(true)
	const [view, setView] = useState(true)

	const fetchData = useCallback(async () => {
		const response = await CommentApi.getComments({
			page: page,
			pageLimit: pageLimit,
			rootId: id,
			sort: { createDate: -1 },
			type: "comment",
			...condition,
		})
		if (response.status === 200) {
			await setDataComments(response.data.comments)
			setTotal(response.data.total)
		} else {
			console.log(response.data)
		}
	}, [page, pageLimit, condition])

	const handlePageChange = (e, pageInfo) => {
		setPage(pageInfo.activePage)
	}

	const handleSizePerPageList = (e, { value }) => {
		setPageLimit(value)
		setPage(1)
	}

	const handleChange = (value, name) => {
		setFormSearch((prev) => ({ ...prev, [name]: value }))
	}

	const handleClickSearch = async () => {
		setCondition({ ...formSearch })
		setDataComments([])
	}

	const handleClickClear = () => {
		setCondition({ status: "All" })
		setFormSearch({ status: "All" })
		setDataComments([])
		setPage(1)
	}

	const handleChangeDataUser = (user) => {
		const tempArr = dataComments.map((item) => {
			return user.id === item.user.id ? { ...item, user } : item
		})
		setDataComments([...tempArr])
		setUserTemp(user) // fix when block user from Topic then ReplyCard is not change
	}

	const permission = () => {
		setDisabled(!checkPermission(admin.permission, "action_comment_edit"))
		setView(checkPermission(admin.permission, "action_comment_view"))
	}

	useEffect(() => {
		const checked = checkPermission(admin.permission, "comment_view")
		if(checked){
			permission()
				
		fetchData()
		return () => {}
		}else{
			props.history.push('/')
		}
	
	}, [page, pageLimit, condition])

	return (
		<Fragment>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Topic {id}</h6>
				</div>
				{/* <div className="card-body"> */}
				<div className="card-body">
					{
						//#region search
						<Form>
							<Form.Group>
								<Form.Input
									value={formSearch.text || ""}
									onChange={(e) => handleChange(e.target.value, "text")}
									className="ui form small"
									label="ค้นหา Comment"
									placeholder="ค้นหา Comment"
								/>
								<Form.Dropdown
									value={formSearch.status}
									onChange={(e, { value }) => handleChange(value, "status")}
									className="ui form small"
									label="สถานะ Comment"
									placeholder="สถานะ Comment"
									selection
									options={statusOptions}
								/>
								<Form.Field>
									<label>ช่วงเวลา</label>
									<DateTime
										defaultValue={formSearch.startDate}
										onBlur={(value) => handleChange(value, "startDate")}
										dateFormat="DD/MM/YYYY"
										timeFormat={false}
										closeOnSelect
									/>
									<span className="mx-2">ถึง</span>
									<DateTime
										defaultValue={formSearch.endDate}
										onBlur={(value) => handleChange(value, "endDate")}
										dateFormat="DD/MM/YYYY"
										timeFormat={false}
										closeOnSelect
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Button onClick={handleClickSearch}>
									<Icon name="search" />
									ค้นหา
								</Form.Button>
								<Form.Button onClick={handleClickClear}>
									<Icon name="redo" />
									เคลียร์
								</Form.Button>
							</Form.Group>
						</Form>
						//#endregion
					}
				</div>
				{/* <hr /> */}
				<div className="card-body">
					{dataComments.map((item, index) => (
						<Fragment key={item._id}>
							<CommentCard data={item} onChangeDataUser={handleChangeDataUser} disabled={disabled} view={view} />
							{item.countReply > 0 && <ReplyCard data={item} userTemp={userTemp} onChangeDataUser={handleChangeDataUser} />}
						</Fragment>
					))}
				</div>
				<div className="card-footer">
					<Dropdown onChange={handleSizePerPageList} selection options={pageOptions} value={pageLimit} />
					<Pagination activePage={page} onPageChange={handlePageChange} totalPages={Math.ceil(total / pageLimit)} />
				</div>
			</div>
			{/* </div> */}
		</Fragment>
	)
}
