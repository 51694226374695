import React, { useState, useEffect, useRef, Fragment } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import "semantic-ui-css/semantic.min.css"
import { Pagination, Select } from "semantic-ui-react"
import "../../assets/css/paginationlist.css"
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"
import Spinner from "react-bootstrap/Spinner"
import ModalExport from "./ModalExport"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const MongkolWallDashboard = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")
	const [createdateGte, setCreatedateGte] = useState("")
	const [createdateLt, setCreatedateLt] = useState("")
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [partner, setPartner] = useState("")
	const [collection, setCollection] = useState("")
	const [paymentStatus, setPaymentStatus] = useState("all")
	const [zipurl, setZipurl] = useState("")
	const [searchAll, setSearchAll] = useState("")
	const [advanceSearch, setAdvanceSearch] = useState("")
	const [hasData, setHasData] = useState(false)
	const [isSearch, setIsSearch] = useState(false)
	const [loginDateGte, setLoginDateGte] = useState("")
	const [loginDateLt, setLoginDateLt] = useState("")
	const inputRef = useRef(null)
	const [listCollection, setListCollection] = useState([])
	const [projectList, setProjectList] = useState([])
	const [modalExport, setModalExport] = useState(false)

	const LinkInvoicePDF = (cell, row) => {
		if (row.invoice_pdf !== "-") {
			return <a href={row.invoice_pdf} target="_blank" rel="noopener noreferrer">View PDF</a>
		} else {
			return row.invoice_pdf
		}
	}
	

	useEffect(() => {
		const checked = checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "finance_edit", "admin"], "or")
		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/order/list?v=` + Math.random() * 1000, {
					withCredentials: true,
				})
				setTotalCount(data.totalCount)
				setallPage(data.pages)
				setData(data.data)
				setZipurl(`${process.env.REACT_APP_API_ROOT}/admin/download-zip`)

				// const {
				// 	data: { data: lst },
				// } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/dashboard/listAllActivities?v=` + Math.random() * 1000, { withCredentials: true })
				const project = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/project/list?v=` + Math.random() * 1000, {
					withCredentials: true,
				})
				console.log("listAllActivities", data)
				if (project.data) setProjectList(project.data.data)
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const carlendarcreatedateLt = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createdateGte)
		var compare = d1.getTime() < d2.getTime()
		if (compare) {
			setCreatedateLt(new Date())
		} else {
			setCreatedateLt(date)
		}
	}
	const carlendarcreatedateGte = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createdateLt)
		var compare = d1.getTime() > d2.getTime()
		if (compare) {
			setCreatedateGte(new Date())
		} else {
			setCreatedateGte(date)
		}
	}

	const formSearchQuery = () => {
		let searchQuery = ""
		if (searchAll.length > 0) {
			searchQuery += `&searchAll=${searchAll}`
		}
		if (createdateGte.length > 0 && createdateLt.length > 0 && createdateGte !== "Invalid date" && createdateLt !== "Invalid date") {
			searchQuery += `&create_dateSearchgte=${createdateGte}&create_dateSearchlt=${createdateLt}`
		}
		if (loginDateGte.length > 0 && loginDateLt.length > 0 && loginDateGte !== "Invalid date" && loginDateLt !== "Invalid date") {
			searchQuery += `&login_dateSearchgte=${loginDateGte}&login_dateSearchlt=${loginDateLt}`
		}
		if (partner.length > 0 && partner !== "all") {
			searchQuery += `&partner=${partner}`
		}
		if (paymentStatus.length > 0 && paymentStatus !== "all") {
			searchQuery += `&paymentStatus=${paymentStatus}`
		}
		if (collection.length > 0 && collection !== "all") {
			searchQuery += `&collection=${collection}`
		}

		if (advanceSearch) {
			searchQuery += `&advanceSearch=${advanceSearch}`
		}

		return searchQuery
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		if (isSearch !== true) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/order/list?page=${pageInfo.activePage}`, {
				withCredentials: true,
			})

			setData(data.data)
		} else {
			let searchQuery = formSearchQuery()
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/order/search?${searchQuery}&page=${pageInfo.activePage}`, {
				withCredentials: true,
			})

			setData(data.data)
		}
	}

	const getPaymentStatus = (cell) => {
		switch (cell) {
			case "1":
				return "ชำระเงินสำเร็จ"
			case "2":
				return "รอการชำระเงิน"
			case "3":
				return "หมดเวลาชำระเงิน"
			case "free":
				return "ฟรี"
			default:
				return "-"
		}
	}
	const getStatus = (cell) => {
		switch (cell) {
			case "0":
				return "-"
			case "2":
				return "ส่งทำ Wall แล้ว"
			case "3":
				return "ได้รับ Wall แล้ว"
			case "1":
				return "ส่งลูกค้าแล้ว"
			case "4":
				return "เกิดปัญหา"
			default:
				return "-"
		}
	}

	const getDateFormatter = (cell) => {
		if (!cell || cell === "-") {
			return "-"
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}
	const getDateFormatterAdd7 = (cell) => {
		if (!cell || cell === "-") {
			return "-"
		}
		return <div><Moment format="YYYY-MM-DD H:mm:ss">{cell}</Moment> +0700</div>
	}
	const getDateUrlLink = (cell) => {
		if (!cell || cell === "-") {
			return "-"
		}
		return (
			<a href={cell} target="_blank">
				{cell}
			</a>
		)
	}

	const searchButton = async () => {
		let searchQuery = formSearchQuery()
		setIsSearch(true)

		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/order/search?${searchQuery}`, { withCredentials: true })
		if (data.data !== false) {
			setTotalCount(data.totalCount)
			setallPage(data.pages)
			setActivePage(data.currentPage)
			setData(data.data)
		} else {
			setData([])
		}
	}

	const openModalExport = async () => {
		setModalExport(true)
	}
	const setFormCollection = (e) => {
		const value = e.target.value
		let list = projectList.find((item) => {
			return item.partner === value
		})
		if (value === "all") {
			list = []
			setCollection("all")
		}
		const collectionList = list.collection || []
		setListCollection(collectionList)
		setPartner(value)
	}

	const handleClick = () => {
		if (!createdateGte || !createdateLt) {
			alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
			return
		}

		const params = {
			startDate: createdateGte,
			endDate: createdateLt,
			partner: partner === "all" ? "" : partner,
			collection: collection === "all" ? "" : collection,
		}

		axios
			.get(`${process.env.REACT_APP_API_ROOT}/admin/wallmongkol/checkpdf`, { params: params, withCredentials: true })
			.then((response) => {
				if (response.data.message === "PDF files found") {
					const urlParams = new URLSearchParams(params).toString()
					const downloadUrl = `${process.env.REACT_APP_API_ROOT}/admin/download-zip?${urlParams}`
					window.location.href = downloadUrl
				} else {
					alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
				}
			})
			.catch((error) => {
				console.error(error)
				alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
			})
	}

	return (
		<>
			
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				<br />
				<div className="card-body">
					<br />

					<div className="row">
						<div className="col-8 col-md-6 offset-2 ">
							<div class="input-group md-form form-sm form-2 pl-0">
								<input
									class="form-control my-0 py-1 lime-border"
									type="text"
									placeholder="ค้นหา Transaction ID, ชื่อ, นามสกุล, เบอร์โทร, อีเมล , line id"
									aria-label="Search"
									value={searchAll}
									onChange={(e) => setSearchAll(e.target.value)}
								/>
							</div>
						</div>

						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => searchButton()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหาข้อมูล</span>
							</button>
							&nbsp;
						</p>

						<div class="container">
							<div class="card card-body" id="collapseExample">
								<div class="card card-body">
									<div>Create Date</div>
									<div className="col-10">
										<div className="row">
											<div>From</div>
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={createdateGte}
													onChange={(date) => {
														carlendarcreatedateGte(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div>To</div>
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={createdateLt}
													onChange={(date) => {
														const dateFormat = moment(date).set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss")
														carlendarcreatedateLt(dateFormat)
													}}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-4">Partner</div>
										<div className="col-4">Collection</div>
										{collection !== "ThairathXMootaeworld" && <div className="col-4">Payment status</div>}
									</div>
									<div className="row">
										<div className="col-4">
											<div className="row">
												<div class="col-12">
													<div class="input-group">
														<select class="custom-select" id="inputGroupSelect01" onChange={setFormCollection} disabled={projectList.length < 1}>
															<option value="all" selected>
																Choose
															</option>
															{projectList.map((item) => (
																<option value={item.partner} key={item.partner}>
																	{item.partner}
																</option>
															))}
															{/* <option value="horowall">Horo Wall</option>
															<option value="mootae_world">Mootae World</option> */}
														</select>
													</div>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div className="row">
												<div class="col-12">
													<div class="input-group">
														<select
															class="custom-select"
															id="inputGroupSelect01"
															onChange={(e) => {
																setCollection(e.target.value)
															}}
															disabled={listCollection.length < 1}
														>
															<option value="all" selected>
																Choose
															</option>
															{listCollection.map((item) => {
																return (
																	<option value={item.name} key={item.name}>
																		{item.title}
																	</option>
																)
															})}
														</select>
													</div>
												</div>
											</div>
										</div>
										{collection !== "ThairathXMootaeworld" && (
											<div className="col-4">
												<div className="row">
													<div class="col-12">
														<div class="input-group">
															<select
																class="custom-select"
																id="inputGroupSelect01"
																onChange={(e) => {
																	setPaymentStatus(e.target.value)
																}}
															>
																<option value="all" selected>
																	รายการทั้งหมด
																</option>
																<option value="1">ชำระเงินสำเร็จ</option>
																<option value="2">รอการชำระเงิน</option>
																<option value="3">หมดเวลาชำระเงิน</option>
															</select>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row" style={{ marginBottom: "8px" }}>
						<div className="col-10">
							<>
								{isLoading ? (
									<Fragment>
										<button className="btn btn-secondary" style={{ width: "120px", height: "41px" }}>
											<Spinner animation="border" role="status" variant="success">
												<span className="sr-only">Loading...</span>
											</Spinner>
										</button>
										{` waiting to export ..`}
									</Fragment>
								) : (
									<>
										<button className="btn btn-secondary" onClick={openModalExport} style={{ minWidth: "120px", height: "41px " }}>
											Export to CSV
										</button>
										&nbsp;
										{data.length !== 0 && (
											<button className="btn btn-secondary" onClick={handleClick} style={{ minWidth: "120px", height: "41px " }}>
												Download ใบกํากับภาษี
											</button>
										)}
									</>
								)}
							</>
						</div>
						<div class="col-2">จำนวนข้อมูล {totalCount}</div>
					</div>
					<BootstrapTable data={data} className="table table-striped">
						<TableHeaderColumn row="0" rowSpan="1" dataField="order_id" width="200" isKey>
							Transaction ID
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="partner_name" width="150">
							Partner Name
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" ref={inputRef} dataField="collection_name" width="150">
							Collection ที่ซื้อ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="firstname" width="150">
							ชื่อ (ภาษาไทย)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="lastname" width="150">
							นามสกุล (ภาษาไทย)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="englishname" width="150">
							ชื่อภาษาอังกฤษ (เฉพาะ Collection)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="type" width="150">
							องค์เทพที่ต้องการ (เฉพาะ Collection)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="gender" width="100">
							เพศ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="phone" width="150">
							เบอร์โทรศัพท์
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="birthdayDate" width="200">
							วัน/เดือน/ปีเกิด
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="birthdayTime" width="200">
							เวลาเกิด
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="birthday" width="100">
							วันเกิด
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="zodiac" width="100">
							ราศี
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="email" width="200">
							อีเมล
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="userWork" width="200">
							อาชีพ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="province" width="200">
							จังหวัด
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="province_other" width="200">
							จังหวัด (อื่น ๆ)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="topic" width="150">
							เรื่องที่ต้องการเสริม
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="addOn" width="200">
							บริการเสริมที่เลือก
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="congenital_disease" width="200">
							โรคประจำตัว
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="1" dataField="total" width="100">
							Amount
						</TableHeaderColumn>
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="fee" width="100">
								Fee
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="fee_vat" width="100">
								Fee Vat
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="net_value" width="100">
								Net
							</TableHeaderColumn>
						)}
						<TableHeaderColumn row="0" rowSpan="1" dataField="payment_status" width="150" dataFormat={getPaymentStatus}>
							สถานะการชำระเงิน
						</TableHeaderColumn>
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="status" width="150" dataFormat={getStatus}>
								สถานะการสั่งซื้อ
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="url_wallpaper" width="200" dataFormat={getDateUrlLink}>
								URL Wallpaper
							</TableHeaderColumn>
						)}
						<TableHeaderColumn row="0" rowSpan="1" dataField="paymentDate" width="200" dataFormat={getDateFormatterAdd7}>
							captured_at
						</TableHeaderColumn>
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="sendWallPaper" width="200" dataFormat={getDateFormatterAdd7}>
								วันที่ส่งวอลเปเปอร์
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="period" width="200">
								ระยะเวลาทั้งหมด
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="line_id" width="200">
								Line ID
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="1" dataField="invoice_pdf" width="200" dataFormat={LinkInvoicePDF}>
								PDF Invoice
							</TableHeaderColumn>
						)}
					</BootstrapTable>
					{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
				</div>
			</div>
			{modalExport && <ModalExport formSearchQuery={formSearchQuery} setModalExport={setModalExport} />}
		</>
	)
}
export default MongkolWallDashboard
