import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import iconUser from "../../assets/img/770826-200.png"
import { Button, Form, Col } from "react-bootstrap"
import { useFormik } from "formik"
import User from "../../libs/admin"
import Alert from "react-bootstrap/Alert"
import { checkPermission } from "../../helper/functions"

const Forms = () => {
	const admin = User.getCookieData()

	let { id } = useParams()

	const [profileState, setProfileState] = useState({})
	const [address1, setAddress] = useState({})
	const [memberId, setMemberId] = useState()
	const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)
	const [disabled, setDisabled] = useState(true)
	const [sensitive, setSensitive] = useState(true)
	const [editSensitive, setEditSensitive] = useState(true)
	const [disableBtn, setDisableBtn] = useState(true)

	const user = User.getCookieData()

	const permission = () => {
		//sensitive
		//birthday
		//address
		//mobile no
		//gender
		if(!checkPermission(admin.permission, ["member_sensitive_data_view"] )){
			setSensitive(false)
		}
		if (checkPermission(admin.permission, ["member_edit", "member_sensitive_data_edit"], "or")) {
			setEditSensitive(checkPermission(admin.permission, ["member_sensitive_data_edit"]) ? false : true)
			setDisableBtn(checkPermission(admin.permission, ["member_edit", "member_sensitive_data_edit"], "or") ? false : true)
			setDisabled(checkPermission(admin.permission, ["member_edit"]) ? false : true)
		} else {
			setDisabled(true)
			setDisableBtn(true)
		}
	}

	useEffect(() => {
		permission()
		async function fetchData() {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/form/${id}?v=` + Math.random() * 1000, { withCredentials: true })
			setProfileState(data.data)
			setAddress(data.data.address)
			setMemberId(data.data._id)
		}
		fetchData()
	}, [id, user.position])

	if (address1 !== undefined) {
		var adds = Object.keys(address1).map(function (s) {
			return address1[s]
		})
	}
	const address = adds === undefined ? "" : adds[0]
	const tamboon = adds === undefined ? "" : adds[1]
	const aumphur = adds === undefined ? "" : adds[2]
	const province = adds === undefined ? "" : adds[3]
	const zipcode = adds === undefined ? "" : adds[4]

	let arrDay = Array.apply(0, { length: 32 }).map(Number.call, Number)
	let arrMonth = [
		{
			key: "มกราคม",
			index: 1,
		},
		{
			key: "กุมภาพันธ์",
			index: 2,
		},
		{
			key: "มีนาคม",
			index: 3,
		},
		{
			key: "เมษายน",
			index: 4,
		},
		{
			key: "พฤษภาคม",
			index: 5,
		},
		{
			key: "มิถุนายน",
			idnex: 6,
		},
		{
			key: "กรกฎาคม",
			index: 7,
		},
		{
			key: "สิงหาคม",
			index: 8,
		},
		{
			key: "กันยายน",
			index: 9,
		},
		{
			key: "ตุลาคม",
			index: 10,
		},
		{
			key: "พฤศจิกายน",
			index: 11,
		},
		{
			key: "ธันวาคม",
			index: 12,
		},
	]

	var dateNow = new Date()
	var thisYear = dateNow.getFullYear() + 543
	const arrYear = Array.from(Array(150), (_, index) => parseInt(thisYear) - index)

	const onBirthday = new Date(profileState.birthday)

	const year = onBirthday.getFullYear() + 543
	const month = onBirthday.getMonth() + 1
	const day = onBirthday.getDate()

	const active = profileState.isActive === undefined ? "active" : profileState.isActive

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstname: profileState.firstname === undefined ? profileState.name : profileState.firstname,
			lastname: profileState.lastname,
			gender: profileState.gender,
			email: profileState.email,
			phone: profileState.phone,
			birthday: profileState.birthday,
			day: day,
			month: month,
			year: year,
			address: address,
			tamboon: tamboon,
			aumphur: aumphur,
			province: province,
			zipcode: zipcode,
			isActive: active,
		},
		onSubmit: async (values) => {
			const day = values.day
			const month = values.month
			const year = values.year - 543
			const includeBD = month + "/" + day + "/" + year + " 07:00:0:0"
			if (includeBD) {
				var birthday = new Date(includeBD)
			}
			const valuesSubmit = {
				id: memberId,
				address: {
					address: values.address,
					tamboon: values.tamboon,
					aumphur: values.aumphur,
					province: values.province,
					zipcode: values.zipcode,
				},

				firstname: values.firstname === undefined ? "" : values.firstname,
				lastname: values.lastname === undefined ? "" : values.lastname,
				name: values.firstname === undefined || values.lastname === undefined ? profileState.name : values.firstname + " " + values.lastname,
				display_name: values.firstname === undefined || values.lastname === undefined ? profileState.name : values.firstname + " " + values.lastname,
				birthday: birthday,
				gender: values.gender,
				phone: values.phone,
				email: values.email,
				isActive: values.isActive,
				adminUpdateBy: admin._id,
				adminUpdateDate: new Date(),
			}

			try {
				const data = await User.updateMembers(valuesSubmit, memberId)

				if (data.data === true) {
					setDataResult("บันทึกข้อมูลเรียบร้อยแล้ว")
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				} else {
					setDataResult("การบันทึกข้อมูลผิดพลาดกรุณาตรวจสอบข้อมูลอีกครั้ง หรือแจ้งแอดมิน")
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				}
			} catch (e) {
				console.log(`request failed: ${e}`)
			}
		},
	})

	const iconCheck = "<i class='fas fa-check-circle'></i>"
	const iconClose = "<i class='fas fa-times-circle'></i>"

	const google = profileState.google === undefined ? iconClose : iconCheck
	const huawei = profileState.huawei === undefined ? iconClose : iconCheck
	const facebook = profileState.facebook === undefined ? iconClose : iconCheck
	const hasPassword = profileState.hasPassword === false ? iconClose : iconCheck
	const imgProfile = profileState.profile_picture === undefined ? iconUser : profileState.profile_picture

	const forgetPassword = async () => {
		const result = await User.sendforgetPassword({ email: formik.values.email })
		if (result.status === 200) {
			setDataResult("ส่งอีเมลเรียบร้อย")
			setShow(true)
			setColor("success")
			setTimeout(() => {
				setShow(false)
			}, 3000)
		} else {
			setDataResult("ส่งอีเมลไม่สำเร็จ กรุณาตรวจสอบอีเมลว่ามีความความถูกต้องหรือไม่")
			setShow(true)
			setColor("danger")
			setTimeout(() => {
				setShow(false)
			}, 3000)
		}
	}

	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}

			<Form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-lg-6">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<div className="row">
									<h6 className="col-md-2 m-0 font-weight-bold text-primary">Info</h6>
									<div className="col-md-9 text-right">
										<Button type="submit" variant="success" className="btn btn-success btn-icon-split btn-sm mr-2" disabled={disableBtn ? "disabled" : ""}>
											<span className="icon text-white-50">
												<i className="fas fa-user-edit"></i>
											</span>
											<span className="text">บันทึกแก้ไขข้อมูล</span>
										</Button>
										<Button className="btn btn-info btn-icon-split btn-sm" disabled={disableBtn ? "disabled" : ""} onClick={forgetPassword}>
											<span className="icon text-white-50">
												<i className="fas fa-paper-plane"></i>
											</span>
											<span className="text">ส่งอีเมลเพื่อตั้งรหัสผ่านใหม่</span>
										</Button>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-12 text-center">
										<img src={imgProfile} width="150" className="circle imgProfile" alt="profile" />
										<h3>{profileState.name}</h3>
										<h6>Member_ID : {memberId}</h6>
									</div>
								</div>
								<hr />

								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>ชื่อจริง</Form.Label>

										<Form.Control
											type="text"
											id="firstname"
											name="firstname"
											value={formik.values.firstname || ""}
											placeholder="ชื่อจริง"
											onChange={formik.handleChange}
											disabled={disabled ? "disabled" : ""}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>นามสกุล</Form.Label>
										<Form.Control
											type="text"
											id="lastname"
											name="lastname"
											value={formik.values.lastname || ""}
											placeholder="นามสกุล"
											onChange={formik.handleChange}
											disabled={disabled ? "disabled" : ""}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											name="email"
											value={formik.values.email || ""}
											placeholder="email"
											onChange={formik.handleChange}
											disabled={disabled ? "disabled" : ""}
										/>
									</Form.Group>
									{sensitive && (
										<Form.Group as={Col}>
											<Form.Label>เบอร์โทรศัพท์</Form.Label>
											<Form.Control
												type="text"
												id="phone"
												name="phone"
												value={formik.values.phone || ""}
												placeholder="เบอร์โทรศัพท์"
												onChange={formik.handleChange}
												disabled={editSensitive ? "disabled" : ""}
											/>
										</Form.Group>
									)}
								</Form.Row>
								{sensitive && (
									<Form.Row>
										<Col xs={4}>
											<Form.Group>
												<Form.Label>เพศ</Form.Label>
												<Form.Control
													as="select"
													value={formik.values.gender || ""}
													name="gender"
													onChange={formik.handleChange}
													disabled={editSensitive ? "disabled" : ""}
												>
													<option value="">เพศ</option>
													<option value="male">ชาย</option>
													<option value="female">หญิง</option>
												</Form.Control>
											</Form.Group>
										</Col>

										<Form.Group as={Col}>
											<Form.Label>วันเกิด</Form.Label>
											<Form.Control
												as="select"
												value={formik.values.day || ""}
												name="day"
												onChange={formik.handleChange}
												disabled={editSensitive ? "disabled" : ""}
											>
												<option value="male">ไม่ระบุ</option>
												{arrDay.map((item, index) => (
													<option key={index} value={item + 1}>
														{item + 1}
													</option>
												))}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>เดือน</Form.Label>
											<Form.Control
												as="select"
												value={formik.values.month || ""}
												name="month"
												onChange={formik.handleChange}
												disabled={editSensitive ? "disabled" : ""}
											>
												<option value="male">ไม่ระบุ</option>
												{arrMonth.map((item, indexs) => (
													<option key={indexs} value={indexs + 1}>
														{item.key}
													</option>
												))}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>ปี</Form.Label>
											<Form.Control
												as="select"
												value={formik.values.year || ""}
												name="year"
												onChange={formik.handleChange}
												disabled={editSensitive ? "disabled" : ""}
											>
												<option value="male">ไม่ระบุ</option>
												{arrYear.map((item, indexs) => (
													<option key={indexs} value={item}>
														{item}
													</option>
												))}
											</Form.Control>
										</Form.Group>
									</Form.Row>
								)}

								{sensitive && (
									<Form.Group>
										<Form.Label>ที่อยู่</Form.Label>
										<Form.Control
											type="text"
											id="address"
											name="address"
											onChange={formik.handleChange}
											value={formik.values.address || ""}
											placeholder=""
											disabled={editSensitive ? "disabled" : ""}
										/>
									</Form.Group>
								)}

								{sensitive && (
									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>ตำบล</Form.Label>
											<Form.Control
												type="text"
												id="tamboon"
												name="tamboon"
												onChange={formik.handleChange}
												value={formik.values.tamboon || ""}
												placeholder=""
												disabled={editSensitive ? "disabled" : ""}
											/>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>อำเภอ</Form.Label>
											<Form.Control
												type="text"
												id="aumphur"
												name="aumphur"
												onChange={formik.handleChange}
												value={formik.values.aumphur || ""}
												placeholder=""
												disabled={editSensitive ? "disabled" : ""}
											/>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>จังหวัด</Form.Label>
											<Form.Control
												type="text"
												id="province"
												name="province"
												onChange={formik.handleChange}
												value={formik.values.province || ""}
												placeholder=""
												disabled={editSensitive ? "disabled" : ""}
											/>
										</Form.Group>
										<Form.Group as={Col}>
											<Form.Label>รหัสไปรษณีย์</Form.Label>
											<Form.Control
												type="text"
												id="zipcode"
												name="zipcode"
												onChange={formik.handleChange}
												value={formik.values.zipcode || ""}
												placeholder=""
												disabled={editSensitive ? "disabled" : ""}
											/>
										</Form.Group>
									</Form.Row>
								)}
								{sensitive && (<Form.Row>
									<Col xs={12}>
										<Form.Group>
											<Form.Label>สถานะ</Form.Label>
											<Form.Control
												as="select"
												value={formik.values.isActive || ""}
												name="isActive"
												onChange={formik.handleChange}
												disabled={editSensitive ? "disabled" : ""}
											>
												<option value="active">ปกติ</option>
												<option value="block">ถูกบล๊อค</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>)}
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">Login By</h6>
							</div>
							<div className="card-body">
								<table className="login_by">
									<tbody>
										<tr>
											<td>on web</td>
											<td>google</td>
											<td>facebook</td>
											<td>huawei</td>
										</tr>
										<tr>
											<td dangerouslySetInnerHTML={{ __html: hasPassword }} />
											<td dangerouslySetInnerHTML={{ __html: google }} />
											<td dangerouslySetInnerHTML={{ __html: facebook }} />
											<td dangerouslySetInnerHTML={{ __html: huawei }} />
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">Register By</h6>
							</div>
							<div className="card-body">{(profileState.register_client || {}).name}</div>
						</div>
					</div>
				</div>
			</Form>
		</>
	)
}
export default Forms
