import React from "react"
import DateTime from "@nateradebaugh/react-datetime"

export const DatePeriod = ({ label, startValue, endValue, onBlur, dateFormat = "YYYY-MM-DD", labelCol = "col-3 row-label", compCol = "col-8", disabled, require, id }) => {
	return (
		<div className="row ">
			<div className={labelCol}>
				{label}<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={compCol}>
				<DateTime
					style={{ borderColor: "#ccc", marginLeft: "10px" }}
					dateFormat={dateFormat}
					timeFormat={true}
					defaultValue={startValue}
					onBlur={(event) => onBlur(event, "startDate", id)}
					inputProps={{ disabled: disabled }}
				/>
				&nbsp; ถึง &nbsp;
				<DateTime
					style={{ borderColor: "#ccc" }}
					dateFormat={dateFormat}
					timeFormat={true}
					defaultValue={endValue}
					onBlur={(event) => onBlur(event, "endDate", id)}
					inputProps={{ disabled: disabled }}
				/>
			</div>
		</div>
	)
}
