import styled from "styled-components"

export const CardCampaignContainer = styled.div`
	--card-height: 260px;
	position: relative;
	/* border-top: 1px solid rgba(0, 0, 0, 0.12); */
	display: grid;
	gap: 1rem;
	margin-top: 1rem;
	overflow-x: auto;
`

export const CardCampaign = styled.div`
	height: var(--card-height);
	background-color: white;
	/* border: 1px solid rgba(0, 0, 0, 0.24); */
	border: 1px solid var(--info);
	padding: 0.5rem;
	border-radius: 0.5rem;
`

export const CardImageContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	gap: 0.5rem;
	height: calc(var(--card-height) - 4rem);
`

export const CardImageWrapper = styled.label`
	margin-top: 0.5rem;
	border: 2px dashed rgba(0, 0, 0, 0.12);
	padding: 0.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
	height: 100%;
	:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
	img {
		/* max-height: 96%; */
		width: 96%;
	}
`

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	input {
		min-width: 200px;
	}
	button {
		i {
			margin: auto;
		}
	}
`
