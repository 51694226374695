import React, { useState, useEffect, Fragment } from "react"
import axios from "axios"
import { StringIntFormatter, StringFloatFormatter, StringTimeFormatter } from '../../dashboard/Formatter'
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { fieldUserId, fieldDisplayName } from '../PersonsReport'

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const MemberTrafficTrPlus = (arrSelectedGroupby, startdate, enddate, widgetTitle, members) => {
    const [data, setData] = useState([])
    const hasMembers = members.length > 0
    
	useEffect(() => {
        async function fetchData() {
            arrSelectedGroupby = arrSelectedGroupby.map(value => value == "sectionTopic" ? "topicTrPlus" : value)
			let mainparameter = {
                'section': "", // Intentionally blank
                'followContent': "", // Intentionally blank
                'startdate': startdate,
                'enddate': enddate,
                'selectedgroupby': arrSelectedGroupby
            }

            if(hasMembers){
                mainparameter['arrMemberId'] = members.map(obj => obj[fieldUserId])
            }

            setData([])

            let apiUrl = "https://cms-member-report-3cor0z2p.an.gateway.dev/v1/member-traffic-plus?key=AIzaSyAgPNySb2caYYJMg-NS9dEb1uiEU6RUJg8"
			let reqObjTopContents = await axios.post(apiUrl, mainparameter,  {  headers: {'Content-Type': 'application/json'} })
            let lst = reqObjTopContents.data[0].list
            if(lst.length){
                setData(lst)
            } else {
                alert("Sorry, MemberTrafficTrPlus has no data following your conditions.")
            }
        }
        fetchData()
    }, [arrSelectedGroupby, startdate, enddate, widgetTitle, members])

    const getDisplayName = id => {
        if(hasMembers){
            let arr = members.filter(obj => obj[fieldUserId] === id)
            if(arr.length > 0){
                return arr[0][fieldDisplayName]
            }
        }
        return ""
    }

    return (
        <Fragment>
            <h4 className="font-weight-bold">{widgetTitle}</h4>
            <div style={{textAlign: "center"}}>
                <BootstrapTable data={data} className="table table-striped" keyField="ts" 
                    exportCSV pagination>
                    <TableHeaderColumn dataField="gender" width="100" hidden={!arrSelectedGroupby.includes("gender")}>
                        Gender
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="agerange" width="100" hidden={!arrSelectedGroupby.includes("agerange")}>
                        Age range
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:deviceCategory" width="100" hidden={!arrSelectedGroupby.includes("device")}>
                        Device
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:region" width="140" hidden={!arrSelectedGroupby.includes("location")}>
                        Location
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:sourceMedium" width="140" hidden={!arrSelectedGroupby.includes("sourceMedium")}>
                        Referrer
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:dimension1" hidden={!hasMembers} width="150" dataFormat={getDisplayName}>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:pagePathLevel2" width="140" hidden={!arrSelectedGroupby.includes("sectionTopic")}>
                        Topic
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:users" hidden={hasMembers} width="140" dataAlign="right" dataFormat={StringIntFormatter}>
                        Users
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:pageviews" width="140" dataAlign="right" dataFormat={StringIntFormatter}>
                        Page View
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:avgTimeOnPage" width="140" dataAlign="right" dataFormat={StringTimeFormatter}>
                        Avg Time on Page
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:bounceRate" width="140" dataAlign="right" dataFormat={StringFloatFormatter}>
                        Bounce rate (%)
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Fragment>
    )

}
export default MemberTrafficTrPlus