import React from "react"

export const Input = ({
	label,
	id,
	name,
	value,
	onChange,
	maxLength,
	labelCol = "col-3 row-label",
	compCol = "col-4",
	require,
	spaceCol = "col-0",
	disabled,
	onBlur,
	defaultValue,
	rows = 1,
	validate,
}) => {
	return (
		<div className="row">
			<div className={spaceCol} />
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={spaceCol} />
			<div className={compCol}>
				<textarea
					id={id}
					name={name}
					className={"form-control" + (validate ? " is-invalid" : "")}
					rows={rows}
					style={{ resize: "none" }}
					maxLength={maxLength}
					value={value}
					onChange={onChange}
					disabled={disabled}
					onBlur={onBlur}
					defaultValue={defaultValue}
				/>
				{validate && <div style={{ color: "red" }}>{validate}</div>}
			</div>
		</div>
	)
}

export const Textarea = ({
	label,
	id,
	name,
	value,
	onChange,
	maxLength,
	labelCol = "col-3 row-label",
	compCol = "col-4",
	disabled,
	require,
	multi,
	datas,
	onClick,
	onDelete,
	rows = 3,
	defaultValue,
	validate,
}) => {
	return multi ? (
		<div className="row">
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>
			</div>

			<div className={compCol}>
				{datas.map((list) => (
					<div key={list._id}>
						<br />
						<textarea
							id={list._id}
							name={name}
							index={list._id}
							className={"form-control" + (validate ? " is-invalid" : "")}
							rows={rows}
							maxLength={maxLength}
							value={list.text}
							onChange={onChange}
							disabled={disabled}
						/>
						<br />
						<div className="row" style={{ justifyContent: "flex-end", marginRight: 0, marginLeft: 0 }}>
							<button onClick={() => onDelete(list._id)}>ลบข้อมูล</button>
						</div>
					</div>
				))}
				<br />
				<button onClick={() => onClick()}>Add rule</button>
			</div>
		</div>
	) : (
		<div className="row">
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={compCol}>
				<textarea
					defaultValue={defaultValue}
					id={id}
					name={name}
					className={"form-control" + (validate ? " is-invalid" : "")}
					rows={rows}
					maxLength={maxLength}
					value={value}
					onChange={onChange}
					disabled={disabled}
				/>
				{validate && <div style={{ color: "red" }}>{validate}</div>}
			</div>
		</div>
	)
}

export const InputSlagName = ({
	label,
	id,
	name,
	value,
	onChange,
	maxLength,
	labelCol = "col-3 row-label",
	compCol = "col-4",
	require,
	spaceCol = "col-0",
	disabled,
	domain,
	validate,
}) => {
	return (
		<div className="row">
			<div className={spaceCol} />
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={spaceCol} />
			<div className={compCol}>
				<div style={{ display: "flex" }}>
					<div className="form-slagname">{domain}</div>
					<textarea
						id={id}
						name={name}
						className={"form-control" + (validate ? " is-invalid" : "")}
						rows="1"
						style={{ resize: "none" }}
						maxLength={maxLength}
						value={value}
						onChange={onChange}
						disabled={disabled}
					/>
				</div>
				{validate && <div style={{ color: "red" }}>{validate}</div>}
			</div>
		</div>
	)
}
