import React, { useState } from "react"
import { ContainerStyled, SectionStyled } from "../../styled"
import { FormEditor, FormInput, FormTextarea } from "../../widgets/Form"
import { Button } from "react-bootstrap"
import useNewsVote from "./context"
import _get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import useAlert from "../../useAlert"
const Rule = () => {
	const { data, handleChange, handleChangeStep, stepNow, handleNewTab } = useNewsVote()
	const handleClickNewSection = () => {
		handleChange({
			name: "rule.sections",
			value: [
				..._get(data, "rule.sections", []),
				{
					key: uuidv4(),
					title: "",
					description: "",
				},
			],
		})
	}
	const handleClickDeleteSection = (index) => {
		handleChange({ name: "rule.sections", value: _get(data, "rule.sections").filter((item, _index) => _index !== index) })
		onClose()
	}
	const { onOpen, onClose, renderAlert } = useAlert({
		title: "ต้องการลบ Section นี้หรือไม่",
		onConfirm: handleClickDeleteSection,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})
	return (
		<div>
			<ContainerStyled className="mb-5">
				<div style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
					<Button variant="outline-dark non-preview" onClick={handleNewTab}>
						เพิ่ม Tab
					</Button>
				</div>
				<SectionStyled className="mb-4">
					<FormInput label={"ชื่อของ Tab"} name="rule.tabName" defaultValue={_get(data, "rule.tabName")} onChange={handleChange} required />
				</SectionStyled>
				<SectionStyled className="mb-4">
					<h1>การแสดงผลหน้าแรกส่วนบน</h1>
					<FormInput name="rule.title" defaultValue={_get(data, "rule.title")} onChange={handleChange} label={"ชื่อหัวข้อ"} required />
					<FormTextarea
						name="rule.description"
						defaultValue={_get(data, "rule.description")}
						onChange={handleChange}
						label={"รายละเอียด (ไม่ควรเกิน 2 บรรทัด)"}
						required
					/>
				</SectionStyled>
				<h1>การแสดงผลของ Tab นี้</h1>
				{_get(data, "rule.sections", []).map((section, index) => (
					<SectionStyled key={section.key} className="mb-2">
						<div
							style={{
								display: "flex",
								alignItems: "start",
							}}
						>
							<h1 className="flex-grow-1">Section {index + 1}</h1>
							{_get(data, "rule.sections", []).length > 1 && (
								<Button variant="outline-danger non-preview" onClick={() => onOpen(index)}>
									ลบ Section นี้
								</Button>
							)}
						</div>
						<FormInput
							defaultValue={_get(data, `rule.sections[${index}].title`, "")}
							key={`${index}-title`}
							name={`rule.sections[${index}].title`}
							onChange={handleChange}
							label={"ชื่อหัวข้อ"}
							required
						/>
						<FormEditor
							defaultValue={_get(data, `rule.sections[${index}].description`, "")}
							key={`${index}-description`}
							name={`rule.sections[${index}].description`}
							onChange={handleChange}
							label={"รายละเอียด"}
							required
						/>
					</SectionStyled>
				))}
				<Button variant="outline-dark w-100 mb-4 non-preview" onClick={handleClickNewSection}>
					เพิ่ม Section
				</Button>
				<Button className="float-left mt-2 non-preview" variant="outline-primary" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" variant="primary" onClick={() => handleChangeStep(stepNow + 1)}>
					ถัดไป
				</Button>
			</ContainerStyled>
			{renderAlert()}
		</div>
	)
}

export default Rule
