import React from "react"

export default function Footer() {
	return (
		<footer className="sticky-footer bg-white">
			<div className="container my-auto">
				<div className="copyright text-center my-auto">
					<p>
						Copyright &copy; 2023 TREND VG3 CO., LTD.
						<br />
						ALL RIGHTS RESERVED.
					</p>
				</div>
			</div>
		</footer>
	)
}
