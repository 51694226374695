import React, { useState, useEffect } from "react"
import "moment/locale/th"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"
import { getUser, deleteUser, activedUser, genCodeUser } from "./service"
import ModalConFirm from "./ModalConfirm"

const MemberDashboard = (props) => {
	const user = User.getCookieData()

	const [email, setEmail] = useState("")
	const [showEmail, setShowEmail] = useState()
	const [dataShow, setDataShow] = useState("")
	const [userData, setUserData] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [checkRegis, setCheckRegis] = useState(false)
	const [load, setLoad] = useState(false)
	useEffect(() => {
		const checked = checkPermission(user.permission, ["member_manage_edit"], "or")
		if (checked) {
		} else {
			props.history.push("/")
		}
	}, [])

	const fetchData = async () => {
		const { data } = await getUser(email)
		if (data.status === 200) {
			const _json = JSON.stringify(data.data, null, 4)
			const _obj = JSON.parse(_json)
			setShowEmail(email)
			setDataShow(_json)
			setUserData(_obj)
			if (_obj.register_client) {
				setCheckRegis(true)
			}
		} else {
			setDataShow("")
			alert("not found")
		}
	}

	const onDelete = async () => {
		const { data } = await deleteUser(email)
		if (data.status === 200) {
			setLoad(false)
			setDataShow("")
		}
		colseModal()
	}
	const onActived = async () => {
		const { data } = await activedUser(email)
		if (data.status === 200) {
			alert("success")
			fetchData()
		}
	}

	const openModal = () => {
		setLoad(true)
		setShowModal(true)
	}
	const colseModal = () => {
		setShowModal(false)
		setLoad(false)
	}
	const onSite = async () => {
		if (!userData.register_client) {
			alert("no register site")
		} else {
			const { data } = await genCodeUser(email, userData.register_client.name)
			if (data.status == 200) window.open(data.data)
			else {
				alert("error")
			}
		}
	}
	return (
		<>
			<div className="card shadow mb-4" id="_container">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				<br />
				<div className="card-body">
					<br />

					<div className="row">
						<div className="col-8 col-md-6 offset-2 ">
							<div class="input-group md-form form-sm form-2 pl-0">
								<input
									class="form-control my-0 py-1 lime-border"
									type="text"
									placeholder="email"
									aria-label="Search"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => fetchData()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหา</span>
							</button>
							&nbsp;
						</p>
					</div>
				</div>

				{dataShow && (
					<div className="card shadow mb-4" style={{ margin: "10px" }}>
						<div className="card-header py-3">
							<button style={{ marginRight: "5px" }} disabled={load} className="btn btn-success btn-icon-split" onClick={() => openModal()}>
								<span className="text">ลบ</span>
							</button>
							<button
								className="btn btn-success btn-icon-split"
								style={{
									marginRight: "5px",
									backgroundColor: userData.isActivated ? "red" : "#1cc88a",
									borderColor: userData.isActivated ? "red" : "#1cc88a",
								}}
								disabled={load}
								onClick={() => onActived()}
							>
								<span className="text">Actived</span>
							</button>
							{checkRegis && (
								<button style={{ marginRight: "5px" }} disabled={load} className="btn btn-success btn-icon-split" onClick={() => onSite()}>
									<span className="text">Site Actived</span>
								</button>
							)}
						</div>
						<div style={{ padding: "10px" }}>
							<h6 className="m-0 font-weight-bold text-primary">{showEmail}</h6>
							<pre id="output">{dataShow}</pre>
						</div>
					</div>
				)}
				{showModal && (
					<ModalConFirm showModal={showModal} colseModal={colseModal} setLoad={setLoad} onDelete={onDelete}>
						<p>Please enter 'confirm' to proceed with deletion:</p>
						<input type="text" value={"inputValue"} />
					</ModalConFirm>
				)}
			</div>
		</>
	)
}
export default MemberDashboard
