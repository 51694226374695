import React, { useState, createContext, useEffect } from "react"
import { useParams } from "react-router"
import { v4 as uuidv4 } from "uuid"
import { stepQuiz, stepQuizId, stepScore, stepScoreId, stepVote, stepVoteId } from "../../../helper/const"
import { formatGenderToThai, formatInterestToThai } from "../../../helper/functions"
import { campaignController } from "../Services"
import User from "../../../libs/admin"
import { checkPermission } from "../../../helper/functions"
import moment from "moment"

export const StoreContext = createContext({})

export const StoreContextProvider = ({ props, children }) => {
	const user = User.getCookieData()
	const { id } = useParams()
	const [tabId, setTabId] = useState("")
	const [campSetup, setCampSetup] = useState({
		type: "Vote",
		name: "",
		description: "",
		detail: "",
		startDate: moment(),
		endDate: moment(),
		publish: undefined,
		rules: [
			{
				_id: uuidv4(),
				text: "",
			},
		],
		joinScore: 0,
		answerCorrect: 0,
		bannerImage: "",
		backgroundImage: "",
		sponsorImage: [],
		position: undefined,
		timeout: 0,
		slagName: "",
		adsAward: "",
		noteAward: "",
		link: "",
		note_rule: "",
		announce_status: false,
		shareImage: "",
		prizeImage: "",
		require_member: {
			name: true,
			phone: true,
			address: true,
		},
		premiumType: "membership",
		// displayPosition: "last_article"
	})

	const [loading, setLoading] = useState(true)

	const [target, setTarget] = useState({
		memberType: undefined,
		gender: [],
		age: undefined,
		province: [],
		interest: undefined,
		activity: undefined,
		topic: undefined,
		dataType: undefined,
		startRecency: undefined,
		endRecency: undefined,
	})

	const questionId = uuidv4()

	const [detail, setDetail] = useState([
		{
			qId: questionId,
			questionName: "",
			questionImage: {},
			clip: "",
			show: 0,
			choices: [
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
				},
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
				},
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
				},
			],
		},
	])

	const [quiz, setQuiz] = useState([
		{
			qId: questionId,
			questionName: "",
			logoImage: "",
			answerType: "single",
			answerCorrect: [],
			score: 0,
			choices: [
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "คำตอบที่ 1",
					choiceImage: "",
					clipeUrl: "",
				},
			],
		},
	])

	const [score, setScore] = useState([
		{
			qId: questionId,
			questionName: "",
			logoImage: "",
			startDate: moment(),
			endDate: moment(),
			teams: [
				{
					chId: uuidv4(),
					parentId: questionId,
					teamName1: "",
					flagImage1: "",
					correctScore1: "",
					teamName2: "",
					flagImage2: "",
					correctScore2: "",
				},
			],
		},
	])

	const [award, setAward] = useState([
		{
			awardId: uuidv4(),
			title: "",
			imgSrc: "",
			indexAward: 1,
			calculate: 1,
			members: [
				{
					memId: uuidv4(),
					name: "",
				},
			],
		},
	])

	const [itemDel, setItemDel] = useState([])
	const [awardDel, setAwardDel] = useState([])
	const [ruleDel, setRuleDel] = useState([])

	const [disabled, setDisabled] = useState(true)

	const permission = () => {
		setDisabled(!checkPermission(user.permission, "campaign_edit"))
	}

	useEffect(() => {
		const checked = checkPermission(user.permission, "campaign_view")
		if (checked) {
			permission()
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [id])

	const fetchData = async () => {
		if (id) {
			const response = await campaignController().getCampaignById(id)
			if (response && response.status === 200) {
				const {
					data: {
						data: { campSetup, target, detail, quiz, score, award },
					},
				} = response

				const newInterest = ((target || {}).interest || []).map((list) => {
					return {
						label: formatInterestToThai(list),
						value: list,
					}
				})

				const newGender = ((target || {}).gender || []).map((list) => {
					return {
						label: formatGenderToThai(list),
						value: list,
					}
				})

				const newProvince = ((target || {}).province || []).map((list) => {
					return {
						label: list,
						value: list,
					}
				})

				const newTarget = {
					...target,
					gender: newGender,
					province: newProvince,
					interest: newInterest,
				}

				setCampSetup(campSetup)
				setTarget(newTarget)
				setDetail(detail)
				setQuiz(quiz)
				setScore(score)
				setAward(award)

				switch (campSetup.type) {
					case "Vote":
						setTabId(detail[0]?.qId)
						break
					case "Quiz":
						setTabId(quiz[0]?.qId)
					case "Score":
						setTabId(score[0]?.qId)
						break
					default:
						break
				}
			}
		} else {
			switch (campSetup.type) {
				case "Vote":
					setTabId(detail[0].qId)
					break
				case "Quiz":
					setTabId(quiz[0].qId)
				case "Score":
					setTabId(score[0].qId)
					break
				default:
					break
			}
		}
		setLoading(false)
	}

	const rendereStep = (id, type) => {
		switch (type) {
			case "Vote":
				return id ? stepVoteId : stepVote

			case "Quiz":
				return id ? stepQuizId : stepQuiz
			case "Score":
				return id ? stepScoreId : stepScore

			default:
				break
		}
	}

	const store = {
		campSetup: [campSetup, setCampSetup],
		targetSec: [target, setTarget],
		detail: [detail, setDetail],
		quiz: [quiz, setQuiz],
		score: [score, setScore],
		itemDelete: [itemDel, setItemDel],
		awardDelete: [awardDel, setAwardDel],
		ruleDelete: [ruleDel, setRuleDel],
		id: id,
		tab: [tabId, setTabId],
		disabled: [disabled, setDisabled],
		award: [award, setAward],
		loading: [loading, setLoading],
		rendereStep: rendereStep,
	}

	return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
