import React, { useState, useEffect, Fragment } from "react"
import axios from "axios"
import { Form } from "react-bootstrap"
import { GATRPlusLinkFormatter, StringTimeFormatter, StringIntFormatter, StringFloatFormatter } from './Formatter'
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { fieldUserId, fieldDisplayName } from '../report/PersonsReport'

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const TopTrPlus = (startdate, enddate, arrSelectedGroupby, members) => {
    const [sectionChoices, setSectionChoices] = useState({})
    const [section, setSection] = useState("")
    const [followContentChoices, setFollowContentChoices] = useState([])
    const [followContent, setFollowContent] = useState("")
    const [topTrPlus, setTopTrPlus] = useState([])

    const hasMembers = members.length > 0
    
	useEffect(() => {
        async function fetchData() {
            const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/dashboard/`
            let reqObjSectionChoices = await axios.get(mainurl + `sectionChoicesTrPlus?v=` + Math.random() * 1000,  { withCredentials: true })
            setSectionChoices(reqObjSectionChoices.data.data)

            let reqObjFollowContentChoices = await axios.get(mainurl + `followContentChoices?v=` + Math.random() * 1000,  { withCredentials: true })
            setFollowContentChoices(reqObjFollowContentChoices.data.data)

            let mainparameter = {
                'startdate': startdate,
                'enddate': enddate,
                'section': section,
                'followContent': followContent,
                'selectedgroupby': arrSelectedGroupby.map(value => value == "sectionTopic" ? "topicTrPlus" : value)
            }
            if(hasMembers){
                mainparameter['arrMemberId'] = members.map(obj => obj[fieldUserId])
            }

            setTopTrPlus([]) // Use to reset topTrPlus because of delay from API call
            let apiUrl = "https://cms-member-report-3cor0z2p.an.gateway.dev/v1/top-content-plus-bymember?key=AIzaSyAgPNySb2caYYJMg-NS9dEb1uiEU6RUJg8"
			let reqObjTopTrPlus = await axios.post(apiUrl, mainparameter,  {  headers: {'Content-Type': 'application/json'} })
            let lst = reqObjTopTrPlus.data[0].list
            if(lst.length){
                setTopTrPlus(lst)
            } else {
                alert("Sorry, TopTrPlus has no data following your conditions.")
            }
        }
        fetchData()
    }, [startdate, enddate, section, followContent, arrSelectedGroupby, members])

    const Selector = <div className="mb-2">
        <Form.Label className="mr-2 font-weight-bold">Topic </Form.Label>
        <select value={section} onChange={e => setSection(e.target.value)} className="mr-2">
            {Object.keys(sectionChoices).map(sec => (<option key={sec} value={sec}>{sectionChoices[sec]}</option>))}
        </select>
        {false && <><Form.Label className="mr-2 font-weight-bold">Follow topic </Form.Label>
        <select value={followContent} onChange={e => setFollowContent(e.target.value)} className="mr-2">
            {Object.keys(followContentChoices).map(choice => (<option key={choice} value={choice}>{followContentChoices[choice]}</option>))}
        </select></>}
    </div>

    const getDisplayName = id => {
        if(hasMembers){
            let arr = members.filter(obj => obj[fieldUserId] === id)
            if(arr.length > 0){
                return arr[0][fieldDisplayName]
            }
        }
        return ""
    }

    return (
        <Fragment>
            <h4 className="font-weight-bold">Top Thairath Plus Articles</h4>
            <div style={{textAlign: "center"}}>
                {Selector}
                <BootstrapTable data={topTrPlus} className="table table-striped" keyField="ga:pagePath" 
                    exportCSV pagination>
                    <TableHeaderColumn dataField="gender" width="100" hidden={!arrSelectedGroupby.includes("gender")}>
                        Gender
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="agerange" width="100" hidden={!arrSelectedGroupby.includes("agerange")}>
                        Age range
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:deviceCategory" width="100" hidden={!arrSelectedGroupby.includes("device")}>
                        Device
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:region" width="140" hidden={!arrSelectedGroupby.includes("location")}>
                        Location
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="ga:dimension1" hidden={!hasMembers} width="150" dataFormat={getDisplayName}>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:pageTitle" dataFormat={GATRPlusLinkFormatter}>
                        Title
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" hidden={hasMembers} dataField="ga:users" width="140" dataAlign="right" dataFormat={StringIntFormatter}>
                        Users
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:pageviews" width="140" dataAlign="right" dataFormat={StringIntFormatter}>
                        Page View
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:avgTimeOnPage" width="140" dataAlign="right" dataFormat={StringTimeFormatter}>
                        Avg Time on Page
                    </TableHeaderColumn>
                    <TableHeaderColumn row="0" dataField="ga:bounceRate" width="140" dataAlign="right" dataFormat={StringFloatFormatter}>
                        Bounce rate (%)
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Fragment>
    )

}
export default TopTrPlus