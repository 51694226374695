import React, { useState, useEffect } from "react"
import { FormCheckbox, FormUpload } from "../../widgets/Form"
import {
	SectionStyled,
	ContainerStyled,
	ImageUploadContainer,
	ImageFlag,
	FileInput,
	Select,
	GridContainer,
	GameButton,
	FormStyle,
} from "../../styled"
import { Button, Row, Col } from "react-bootstrap"
import _get from "lodash/get"

import useEuroFootball from "./context"
import * as moment from "moment"
import "moment/locale/th"
import DateTime from "@nateradebaugh/react-datetime"
import { v4 as uuidv4 } from "uuid"
import countries from "./data/countries.json"

import ModalConfirmDelete from "./modalConfirmDelete"

const EuroFootballPlaygame = () => {
	const {
		euroSetup,
		playSetup,
		setPlaySetup,
		onSubmit,
		stepNow,
		handleChangeStep,
		onUploadImage,
		currentSelectGame,
		setCurrentSelectGame,
	} = useEuroFootball()
	const [dataEuro, setEuroFootball] = useState(playSetup)
	const [isModal, setIsModal] = useState(false)
	const [modalFunction, setModalFunction] = useState(null)

	useEffect(() => {
		setEuroFootball(playSetup)
	}, [playSetup])

	// const imageDefault = "https://i.pinimg.com/originals/da/ef/e4/daefe4a61442bcbd656e3f13adfae32e.jpg"
	const imageDefault = "https://img.icons8.com/?size=512&id=53386&format=png"

	const addItems = (id) => {
		const new_data = {
			chId: uuidv4(),
			parentId: id,
			teamName1: "",
			flagImage1: "",
			correctScore1: "",
			teamName2: "",
			flagImage2: "",
			correctScore2: "",
		}

		const new_result = dataEuro.map((item) => {
			if (item.qId === id) {
				return {
					...item,
					teams: [...item.teams, new_data],
				}
			} else {
				return item
			}
		})

		setEuroFootball(new_result)
		setPlaySetup(new_result)
	}

	const deleteData = (id) => {
		console.log(id, "delete")
		const new_result = dataEuro.map((item) => {
			if (item.qId !== id) {
				return item
			} else {
				item.isDelete = true
				return item
			}
		})

		setEuroFootball(new_result)
		setPlaySetup(new_result)
	}

	const deleteDataItem = (qid, chId) => {
		const newDetail = dataEuro.map((list) => {
			return {
				...list,
				teams: list.teams.filter((item) => {
					if (item.parentId == qid && item.chId != chId) {
						return item
					} else if (item.parentId !== qid && item.chId !== chId) {
						return item
					}
				}),
			}
		})

		setEuroFootball(newDetail)
		setPlaySetup(newDetail)
	}

	const addDate = () => {
		const questionId = uuidv4()
		const newQuestion = {
			qId: questionId,
			startDate: moment(),
			endDate: moment().add("days", 1),
			teams: [
				{
					chId: uuidv4(),
					parentId: questionId,
					group: "",
					teamName1: "",
					flagImage1: "",
					correctScore1: "",
					teamName2: "",
					flagImage2: "",
					correctScore2: "",
				},
			],
		}

		setEuroFootball([...dataEuro, newQuestion])
		setPlaySetup([...dataEuro, newQuestion])
	}

	useEffect(() => {
		if (dataEuro && dataEuro.length > 0) {
			setCurrentSelectGame(dataEuro.length - 1)
		}
	}, [])

	const uploadFlag = async (event, name, qId, chId, teamName, teamValue) => {
		// const data = await onUploadImage(event)
		const data = countries[event.target.value]
		console.log(teamName, teamValue)
		if (data) {
			// const { data: { response } } = data
			const response = data.image
			const newDetail = dataEuro.map((list) => {
				return {
					...list,
					teams: list.teams.map((item) => {
						if (item.parentId === qId && item.chId === chId) {
							return {
								...item,
								[name]: response,
								[teamName]: teamValue,
							}
						} else return item
					}),
				}
			})

			console.log("newDetail =< ", newDetail)

			setEuroFootball(newDetail)
			setPlaySetup(newDetail)
		}
	}

	const handleChangeDate = (event, qid, name) => {
		console.log(qid, name)
		const newDataName = dataEuro.map((list) => {
			if (list._id === qid || list.qId === qid) {
				return {
					...list,
					[`${name}`]: moment(event),
				}
			} else return list
		})
		setEuroFootball(newDataName)
		setPlaySetup(newDataName)
	}

	const handleChangeItem = (event, qid, chid, type) => {
		const { name, value } = event.target
		const newDataName = dataEuro.map((list) => {
			return {
				...list,
				teams:
					list.qId === qid
						? list.teams.map((item) => {
								if (item.parentId === qid && item.chId === chid) {
									return {
										...item,
										[`${name}`]: type === "number" ? value.replace(/[^0-9.]/g, "") : value,
									}
								} else {
									return item
								}
						  })
						: [...list.teams],
			}
		})

		setEuroFootball(newDataName)
		setPlaySetup(newDataName)
	}

	return (
		<>
			<ContainerStyled>
				<GridContainer>
					{(dataEuro || []).map((ele, index) => {
						if (!ele.isDelete)
							return (
								<>
									{index === currentSelectGame ? (
										<SectionStyled key={ele.qId} className="my-4">
											<Row>
												<Col>
													<h1>
														ประจำวันที่ {moment(ele.startDate || undefined).format("DD-MM-YYYY") || ""} (จำนวน {ele.teams.length} คู่)
													</h1>
													<Button
														className="float-right mt-6 non-preview"
														onClick={() => {
															setIsModal(true)
															const storeFunction = () => deleteData(ele.qId)
															setModalFunction(() => storeFunction)
														}}
														variant="outline-danger"
														style={{ position: "absolute", top: 0, right: 10 }}
													>
														X
													</Button>
												</Col>
											</Row>
											<div className="row">
												<FormStyle>
													<label>เริ่มทายผล</label>
													<DateTime
														style={{ borderColor: "#ccc", marginLeft: "10px" }}
														id="startDate"
														dateFormat={"DD-MM-YYYY"}
														timeFormat={true}
														defaultValue={moment(ele.startDate || undefined).format("YYYY-MM-DD HH:mm:ss")}
														onBlur={(event) => {
															handleChangeDate(event, ele._id || ele.qId, "startDate")
														}}
													/>
												</FormStyle>
												-
												<FormStyle>
													<label>หมดเวลาทายผล</label>
													<DateTime
														style={{ borderColor: "#ccc", marginLeft: "10px" }}
														id="endDate"
														dateFormat={"DD-MM-YYYY"}
														timeFormat={true}
														defaultValue={moment(ele.endDate || undefined).format("YYYY-MM-DD HH:mm:ss")}
														onBlur={(event) => {
															handleChangeDate(event, ele._id || ele.qId, "endDate")
														}}
													/>
												</FormStyle>
											</div>
											<hr />
											<div>
												{(ele.teams || []).map((item, index) => {
													return (
														<div key={item.chId} style={{ display: "flow-root" }} className="mb-4">
															<Row>
																<Col>
																	<h1>ลำดับที่ {index + 1}</h1>
																</Col>
																<Col>
																	<Button
																		className="float-right mt-6 non-preview"
																		onClick={() => {
																			setIsModal(true)
																			const storeFunction = () => deleteDataItem(ele.qId, item.chId)
																			setModalFunction(() => storeFunction)
																		}}
																		variant="outline-danger"
																	>
																		X
																	</Button>
																</Col>
															</Row>

															<FormStyle className="w-25 ml-0 mx-auto">
																<label htmlFor="group">ชื่อกลุ่ม-รอบ</label>
																<input name="group" defaultValue={item.group} onChange={(event) => handleChangeItem(event, item.parentId, item.chId, "text")} />
															</FormStyle>

															<Row>
																<div className="mt-2 w-50 flex-column align-items-center justify-content-center ml-0" style={{ display: "flex" }}>
																	<ImageUploadContainer>
																		{/* <ImageFlag alt='flag1' src={item.flagImage1.url || imageDefault} roundedCircle /> */}
																		{/* <FileInput type='file' onChange={e => uploadFlag(e, 'flagImage1', ele.qId, item.chId)} /> */}
																		<ImageFlag alt="flag1" src={item.flagImage1 || imageDefault} />
																		<Select
																			onChange={(event) =>
																				uploadFlag(event, "flagImage1", item.parentId, item.chId, "teamName1", event.target.selectedOptions[0].dataset.name)
																			}
																		>
																			<option value="">เลือกประเทศ</option>
																			{countries &&
																				Object.entries(countries).map(([key, value], index) => {
																					return (
																						<option key={key} value={key} data-name={value.name}>
																							{value.name}
																						</option>
																					)
																				})}
																		</Select>
																	</ImageUploadContainer>
																	<FormStyle className="ml-0 w-75">
																		<label htmlFor="teamName1">ชื่อทีมที่ 1</label>
																		<input name="teamName1" value={item.teamName1} onChange={(event) => handleChangeItem(event, item.parentId, item.chId, "text")} />
																	</FormStyle>
																	<FormStyle className="ml-0 w-75">
																		<label htmlFor="correctScore1">ผลประตู</label>
																		<input
																			name="correctScore1"
																			defaultValue={item.correctScore1}
																			onChange={(event) => handleChangeItem(event, item.parentId, item.chId, "number")}
																		/>
																	</FormStyle>
																</div>
																<div className="mt-2 w-50 flex-column align-items-center justify-content-center ml-0" style={{ display: "flex" }}>
																	<ImageUploadContainer>
																		{/* <ImageFlag alt="flag2" src={item.flagImage2.url || imageDefault} roundedCircle />
											<FileInput type="file" onChange={(e) => uploadFlag(e, "flagImage2", ele.qId, item.chId)} /> */}
																		<ImageFlag alt="flag2" src={item.flagImage2 || imageDefault} />
																		<Select
																			onChange={(event) =>
																				uploadFlag(event, "flagImage2", item.parentId, item.chId, "teamName2", event.target.selectedOptions[0].dataset.name)
																			}
																		>
																			<option value="">เลือกประเทศ</option>
																			{countries &&
																				Object.entries(countries).map(([key, value], index) => {
																					return (
																						<option key={key} value={key} data-name={value.name}>
																							{value.name}
																						</option>
																					)
																				})}
																		</Select>
																	</ImageUploadContainer>
																	<FormStyle className="ml-0 w-75">
																		<label htmlFor="teamName2">ชื่อทีมที่ 2</label>
																		<input name="teamName2" value={item.teamName2} onChange={(event) => handleChangeItem(event, item.parentId, item.chId, "text")} />
																	</FormStyle>
																	<FormStyle className="ml-0 w-75">
																		<label htmlFor="correctScore2">ผลประตู</label>
																		<input
																			name="correctScore2"
																			defaultValue={item.correctScore2}
																			onChange={(event) => handleChangeItem(event, item.parentId, item.chId, "number")}
																		/>
																	</FormStyle>
																</div>
															</Row>
														</div>
													)
												})}
											</div>

											<div style={{ display: "flow-root", paddingTop: "20px" }}>
												<Button style={{ width: "100%" }} onClick={() => addItems(ele.qId)} variant="outline-secondary">
													เพิ่มทีมแข่งขัน
												</Button>
											</div>
										</SectionStyled>
									) : (
										<GameButton onClick={() => setCurrentSelectGame(index)}>
											ประจำวันที่ {moment(ele.startDate).format("DD-MM-YYYY hh:mm A")} ถึง {moment(ele.endDate).format("DD-MM-YYYY hh:mm A")} (จำนวน{" "}
											{(ele.teams || []).length} คู่)
											<i className="fas fa-angle-right" style={{ background: "#fff", padding: "8px 12px", color: "#000", borderRadius: "4px" }}></i>
										</GameButton>
									)}
								</>
							)
					})}
				</GridContainer>
				<Button style={{ width: "100%" }} onClick={() => addDate()} variant="secondary">
					เพิ่ม
				</Button>
				<br />
				<Button className="float-left mt-2 non-preview" onClick={() => handleChangeStep(stepNow - 1, dataEuro, "play")} variant="warning">
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" onClick={() => handleChangeStep(stepNow + 1, dataEuro, "play")} variant="primary">
					ถัดไป
				</Button>
			</ContainerStyled>
			<ModalConfirmDelete
				showModal={isModal}
				closeModal={() => setIsModal(false)}
				onDelete={() => {
					setIsModal(false)
					modalFunction()
				}}
			/>
		</>
	)
}

export default EuroFootballPlaygame
