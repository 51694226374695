import { interest, gender } from "../const"

export const formatInterestToThai = (data) => {
    for (const key of interest) {
           if(key.value === data){
               return key.label
           }
    }

}

export const formatGenderToThai = (data) => {
    for (const key of gender) {
           if(key.value === data){
               return key.label
           }
    }

}

