export const checkPermission = (myPermission = [], permission, type) => {
	let checked = false
	let arrCheck = []
	if (typeof permission === "string") {
		checked = myPermission.includes(permission)
	} else if (Array.isArray(permission)) {
		permission.forEach((per) => {
			arrCheck.push(myPermission.includes(per))

			switch (type) {
				case 'or':
                    checked = arrCheck.includes(true)
					break
				case 'and':
                    let result = arrCheck.includes(false)
                    checked = !result
					break
				default:
                    checked = myPermission.includes(per)
					break
			}
		})
	} else {
		checked = false
	}

	return checked
}
