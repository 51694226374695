import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import "semantic-ui-css/semantic.min.css"
import { Pagination } from "semantic-ui-react"
import "../../assets/css/paginationlist.css"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import "moment/locale/th"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"
import Alert from "react-bootstrap/Alert"
var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")
	const [createDateGte, setCreateDateGte] = useState("")
	const [createDateLt, setCreateDateLt] = useState("")
	const [birthdayGte, setBirthdayGte] = useState("")
	const [birthdayLt, setBirthdayLt] = useState("")

	const [hasPassword, setHasPassword] = useState("")
	const [facebook, setFacebook] = useState("")
	const [google, setGoogle] = useState("")
	const [huawei, setHuawei] = useState("")
	const [isActivate, setIsActivate] = useState("")
	const [orderBy, setOrderBy] = useState("")
	const [register, setRegister] = useState("")

	const [gender, setGender] = useState("")
	const [isSearch, setIsSearch] = useState(false)
	const [loginDateGte, setLoginDateGte] = useState("")
	const [loginDateLt, setLoginDateLt] = useState("")


	const [email , setStateEmail] = useState("")

	const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)
	const [searchAll, setSearchAll] = useState("")


	useEffect(() => {
		const checked = checkPermission(user.permission, ["user_ads_management_view", 'user_ads_management_edit'], 'or')
		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/sale/list?v=` + Math.random() * 1000, { withCredentials: true })
				setallPage(data.pages)
				setData(data.data)
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	
	const pagination = async (e, pageInfo) => {
		let searchQuery = ""
		if (searchAll.length > 0) {
			searchQuery += `&searchAll=${searchAll}`
		}
		
		setActivePage(pageInfo.activePage)
		if (isSearch !== true) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/sale/list?page=${pageInfo.activePage}`, { withCredentials: true })
			console.log(data.pages, "1")

			setData(data.data)
		} else {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/sale/search?${searchQuery}&page=${pageInfo.activePage}`, {
				withCredentials: true,
			})
			console.log(data.pages, "2")

			setData(data.data)
		}
	}


	const AddButton = async () => {
			if(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)){
				const obj = {
					email: email,
					active : true
				}
				try {

				const data = await User.sendSaleUser(obj)
				if (data.status === 200) {
					setDataResult(data.message)
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
					setTimeout(() => {
						window.location.reload();
					}, 4000)
				
				} else {
					setDataResult(data.message)
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				}

				}catch(e){
					console.log(`request failed: ${e}`)
				}
			}else{
				alert("คุณกรอกอีเมลล์ไม่ถูกต้อง ตัวอย่าง : xxxx@mail.com")
			}
	}

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const activeFormat = (cell,row)=> {
		return <input class="form-check-input" type="checkbox" id="active" defaultChecked={cell} onChange={(e) => setActive(e.target.checked , row._id)} />
	}

	const searchButton = async () => {
		let searchQuery = ""
		if (searchAll.length > 0) {
			searchQuery += `&searchAll=${searchAll}`
		}	

		setIsSearch(true)
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/sale/search?${searchQuery}`, { withCredentials: true })
		if (data.data !== false) {
			setallPage(data.pages)
			setActivePage(data.currentPage)
			setData(data.data)
		} else {
			setData([])
		}
	}

	const setActive = async (data , id) => {

		const obj = {
			_id: id,
			active : data
		}

		try{

			const data = await User.updateSaleAdsActive(obj)
				if (data.status === 200) {
					setDataResult(data.message)
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
					
				} else {
					setDataResult(data.message)
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				}

		}catch(e){
			console.log(e)
		}
	}


	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">เพิ่มข้อมูล User</h6>
				</div>
				<br />
				<div className="card-body">
					<br />

					<div className="row">
						<div className="col-8 col-md-6 offset-2 ">
							<div class="input-group md-form form-sm form-2 pl-0">
								<input
									class="form-control my-0 py-1 lime-border"
									type="text"
									placeholder=" กรุณากรอก email ที่ต้องกาาค้นหา"
									aria-label="Search"
									value={searchAll}
									onChange={(e) => setSearchAll(e.target.value)}
								/>
							</div>
						</div>

						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => searchButton()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหาข้อมูล</span>
							</button>
						</p>
					</div>
					
					<br />

					{checkPermission(user.permission, "user_ads_management_edit") && (
						<div className="row">
						<div className="col-8 col-md-6 offset-2 ">
							<div class="input-group md-form form-sm form-2 pl-0">
								<input
									class="form-control my-0 py-1 lime-border"
									type="email"
									placeholder="กรุณากรอก Email ที่ต้องการเพิ่ม"
									aria-label="Search"
									value={email}
									onChange={e => setStateEmail(e.target.value)}
								/>
							</div>
						</div>

						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => AddButton()}>
								<span className="icon text-white-50">
									<i className="fas fa-plus"></i>
								</span>
								<span className="text">เพิ่มข้อมูล</span>
							</button>
						</p>
					</div>
					)}
					
					<br />

					<BootstrapTable data={data} className="table table-striped">
						<TableHeaderColumn row="1"  dataField="email" width="100" isKey>
							email
						</TableHeaderColumn>
						<TableHeaderColumn row="1"  dataField="create_date" width="100" dataFormat={dateFormatter}>
							วันที่เพิ่มข้อมูล
						</TableHeaderColumn>
						<TableHeaderColumn row="1"  dataField="active" width="100" dataFormat={activeFormat} dataAlign="center">
							Active
						</TableHeaderColumn>
					</BootstrapTable>
					{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
				</div>
			</div>
		</>
	)
}
export default List
