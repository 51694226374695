import React, { Fragment, useContext } from "react"
import { Navigation } from "../../../../utils"
import { StoreContext } from "../../Context/store"
import { Button, Nav, Badge } from "react-bootstrap"
import { Input, UploadInput, TableScore, DatePeriod } from "../../Widgets"
import { v4 as uuidv4 } from "uuid"
import { campaignController } from "../../Services"
import moment from "moment"
import { useHistory } from "react-router"

const Score = ({ pageStep = 3, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup],
		targetSec: [target],
		score: [score, setScore],
		award: [award],
		awardDelete: [awardDel],
		rendereStep: rendereStep,
		tab: [tabId, setTabId],
		itemDelete: [itemDel, setItemDel],
		disabled: [disabled],
		id: id,
	} = useContext(StoreContext)
	const history = useHistory()

	const deleteQuestion = (qid) => {
		let arrDelete = []
		const newQuestion = score.filter((list) => {
			if (list.qId !== qid) {
				return list
			} else {
				arrDelete.push(list.qId)
			}
		})

		setItemDel([...arrDelete])
		setScore([...newQuestion])
	}

	const onChangeQuestion = (event, qid) => {
		const { name, value } = event.target
		onChangeInQuestion(name, value, qid)
	}

	const onChangeInQuestion = (name, value, qid) => {
		const newData = score.map((list) => {
			if (list.qId === qid) {
				return {
					...list,
					[`${name}`]: value,
				}
			} else {
				return list
			}
		})
		setScore([...newData])
	}

	const onUpload = (event, qid, chid) => {
		const { name, files } = event.target
		let { type, size } = files[0]
		if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
			const err = {
				qid: qid,
				chid: chid,
				message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
			}
		} else {
			switch (name) {
				case "logoImage":
					onChangeInQuestion(name, files[0], qid)
					break
				case "flagImage1":
					onChangeInChoice(name, files[0], type, qid, chid)
					break
				case "flagImage2":
					onChangeInChoice(name, files[0], type, qid, chid)
					break
				default:
					break
			}
		}
	}

	const setUpload = (name, qid, chid, index) => {
		switch (name) {
			case "logoImage":
				const findLogo = score.find((list) => {
					return list.qId === qid && list[`${name}`]
				})

				const { logoImage } = findLogo
				if (logoImage) {
					sendToUpload(logoImage, name, qid, chid)
				}
				break

			case "flagImage1":
				const findChoiceImg1 = score.map((list) => {
					return (
						list.qId === qid &&
						list.teams.find((item) => {
							return item.parentId === qid && item.chId === chid && item
						})
					)
				})
				const { flagImage1 } = findChoiceImg1[index]

				if (flagImage1) {
					sendToUpload(flagImage1, name, qid, chid)
				}
				break

			case "flagImage2":
				const findChoiceImg2 = score.map((list) => {
					return (
						list.qId === qid &&
						list.teams.find((item) => {
							return item.parentId === qid && item.chId === chid && item
						})
					)
				})
				const { flagImage2 } = findChoiceImg2[index]

				if (flagImage2) {
					sendToUpload(flagImage2, name, qid, chid)
				}
				break
		}
	}

	const sendToUpload = async (file, name, qid, chid) => {
		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, campSetup.type)
		if (response && response.status === 200) {
			let data = response.data.response
			data = {
				...data,
				name: file.name,
			}
			if (chid) {
				onChangeInChoice(name, data, name, qid, chid)
			} else {
				onChangeInQuestion(name, data, qid)
			}
		}
	}

	const deleteChoice = (qid, chid) => {
		const newDetail = score.map((list) => {
			return {
				...list,
				teams: list.teams.filter((item) => {
					if (item.parentId === qid && item.chId !== chid) {
						return item
					} else if (item.parentId !== qid && item.chId !== chid) {
						return item
					}
				}),
			}
		})

		setScore([...newDetail])
	}

	const onChangeChoice = (event, qid, chid, type) => {
		const { name, value } = event.target
		onChangeInChoice(name, value, type, qid, chid)
	}

	const onChangeInChoice = (name, value, type, qid, chid) => {
		const newDataName = score.map((list) => {
			return {
				...list,
				teams:
					list.qId === qid
						? list.teams.map((item) => {
								if (item.parentId === qid && item.chId === chid) {
									return {
										...item,
										[`${name}`]: type === "number" ? value.replace(/[^0-9.]/g, "") : value,
									}
								} else {
									return item
								}
						  })
						: [...list.teams],
			}
		})
		setScore([...newDataName])
	}

	const addQuestion = () => {
		const questionId = uuidv4()
		const newQuestion = {
			qId: questionId,
			questionName: "",
			logoImage: "",
			teams: [
				{
					chId: uuidv4(),
					parentId: questionId,
					teamName1: "",
					flagImage1: "",
					correctScore1: "",
					teamName2: "",
					flagImage2: "",
					correctScore2: "",
				},
			],
		}

		setScore([...score, newQuestion])
	}

	const addChoice = (parentId) => {
		const newTeam = {
			chId: uuidv4(),
			parentId: parentId,
			teamName1: "",
			flagImage1: "",
			correctScore1: "",
			teamName2: "",
			flagImage2: "",
			correctScore2: "",
		}

		score.map((list) => {
			if (list.qId === parentId) {
				return list.teams.push(newTeam)
			} else {
				return list.teams
			}
		})

		setScore([...score])
	}

	const onBlurData = (event, name, qid) => {
		console.log(event, name)
		console.log(score)
		let newScore = []
		switch (name) {
			case "startDate":
				newScore = score.map((list) => {
					if (list.qId === qid) {
						return {
							...list,
							startDate: moment(event),
						}
					} else {
						return list
					}
				})
				setScore(newScore)
				break

			case "endDate":
				newScore = score.map((list) => {
					if (list.qId === qid) {
						return {
							...list,
							endDate: moment(event),
						}
					} else {
						return list
					}
				})
				setScore(newScore)
				break
		}
	}

	const onDeleteQuestionImage = async (_data) => {
		const data = { ..._data }
		let tempScore = [...score]
		const scoreIndex = tempScore.findIndex((f) => f.qId === data.qId)
		tempScore[scoreIndex].logoImage = null
		setScore([...tempScore])
		if (data?.logoImage?._id) {
			const res = await campaignController().deleteFile(data.logoImage._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	const onDeleteTeamImage = async (_data, flagName) => {
		const data = { ..._data }
		let tempScore = [...score]
		const scoreIndex = tempScore.findIndex((f) => f.qId === data.parentId)
		const teamIndex = tempScore[scoreIndex].teams.findIndex((f) => f._id === data._id)
		let tempTeam = tempScore[scoreIndex].teams[teamIndex]
		tempTeam[flagName] = null
		setScore([...tempScore])
		if (data[flagName] && data[flagName]._id) {
			const res = await campaignController().deleteFile(data[flagName]._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	const onSubmit = async () => {
		let data = {}

		data = {
			campSetup,
			target,
			detail: score,
			award,
			itemDel,
			awardDel,
		}

		if (id) {
			const resposne = await campaignController().updateCampaign(data, id)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		} else {
			const resposne = await campaignController().createCampaign(data)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		}
	}
	return (
		<Fragment>
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Score</h5>
				</div>
				<div className="card-body">
					<div className="row">
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							<Button className="btn btn-success " onClick={() => addQuestion()} disabled={disabled ? "disabled" : ""}>
								Add Question
							</Button>
							<Button className="btn btn-success offset-1" onClick={() => onSubmit()} disabled={disabled ? "disabled" : ""}>
								Submit
							</Button>
						</h6>
					</div>

					<Nav variant="pills" activeKey={tabId}>
						{score.map((list, index) => (
							<Nav.Item key={list.qId}>
								<Nav.Link eventKey={list.qId} onSelect={(e) => setTabId(e)}>
									{` Question ${index + 1}`}
								</Nav.Link>
								{tabId === list.qId && score.length > 1 && (
									<Badge
										style={{ fontSize: "16px", position: "relative", borderRadius: "100%", bottom: "46px", left: "79px", textTransform: "capitalize" }}
										variant="danger"
										onClick={() => deleteQuestion(list.qId)}
									>
										x
									</Badge>
								)}
							</Nav.Item>
						))}
					</Nav>
					<hr />
					{score.map(
						(list, order) =>
							tabId === list.qId && (
								<Fragment key={list.qId}>
									<div className="row">
										<div className="col-3">
											<h6 className="m-0 font-weight-bold text-primary">
												{`Question No.${order + 1}`}
												<a style={{ color: "red" }}> *</a>
											</h6>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-6">
											<Input
												label="Title"
												id="qname"
												name="questionName"
												value={list.questionName}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
											/>
											<br />
											<UploadInput
												label="Sponsor’s Logo"
												id="qImage"
												name="logoImage"
												placeholder="Browse..."
												value={(list.logoImage || {}).url ? list.logoImage.name : "Browse..."}
												list={(list.logoImage || {}).url ? list.logoImage.name : {}}
												onChange={(event) => onUpload(event, list.qId)}
												onClick={() => setUpload("logoImage", list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
												onDelete={(index) => onDeleteQuestionImage(list, index)}
												remark="Dimension: 120x120px (16:9)"
											/>
											<br />
											<DatePeriod
												id={list.qId}
												label="เวลาการแข่งขัน"
												dateFormat="YYYY-MM-DD"
												startValue={moment(list.startDate).format("YYYY-MM-DD HH:mm:ss")}
												endValue={moment(list.endDate).format("YYYY-MM-DD HH:mm:ss")}
												onBlur={onBlurData}
												disabled={disabled ? "disabled" : ""}
												require
											/>
										</div>
									</div>
									<br />
									{list.teams.map((item, index) => (
										<Fragment key={item.chId}>
											<div className="row">
												<div className="col-3">
													<h6 className="m-0 font-weight-bold text-primary">
														{`Match No. ${index + 1}`}
														<a style={{ color: "red" }}> *</a>
													</h6>
												</div>
												{list.teams.length > 1 && (
													<div className="col-9 row justify-content-end">
														<Button className="btn btn-danger" onClick={() => deleteChoice(list.qId, item.chId)} disabled={disabled ? "disabled" : ""}>
															Delete
														</Button>
													</div>
												)}
											</div>
											<br />
											<div className="row">
												<div className="col-6">
													<Input
														label="Team1"
														id="tname1"
														name="teamName1"
														value={item.teamName1}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId)}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
													/>
													<br />
													<UploadInput
														label="Logo team"
														name="flagImage1"
														id="tlogo1"
														placeholder="Browse..."
														value={(item.flagImage1 || {}).url ? item.flagImage1.name : "Browse..."}
														list={(item.flagImage1 || {}).url ? item.flagImage1.name : {}}
														onChange={(event) => onUpload(event, list.qId, item.chId)}
														onClick={() => setUpload("flagImage1", list.qId, item.chId, order)}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
														onDelete={() => onDeleteTeamImage(item, "flagImage1")}
														remark="Dimension: (รอ dimension)"
													/>
													<br />
													<Input
														label="result"
														id="tscore1"
														name="correctScore1"
														value={item.correctScore1}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId, "number")}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
													/>
												</div>
												<div className="col-6">
													<Input
														label="Team2"
														id="tname2"
														name="teamName2"
														value={item.teamName2}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId)}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
													/>
													<br />
													<UploadInput
														label="Logo team"
														name="flagImage2"
														id="tlogo2"
														placeholder="Browse..."
														value={(item.flagImage2 || {}).url ? item.flagImage2.name : "Browse..."}
														list={(item.flagImage2 || {}).url ? item.flagImage2.name : {}}
														onChange={(event) => onUpload(event, list.qId, item.chId)}
														onClick={() => setUpload("flagImage2", list.qId, item.chId, order)}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
														onDelete={() => onDeleteTeamImage(item, "flagImage2")}
														remark="Dimension: (รอ dimension)"
													/>
													<br />
													<Input
														label="result"
														id="tscore2"
														name="correctScore2"
														value={item.correctScore2}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId, "number")}
														compCol="col-8"
														disabled={disabled ? "disabled" : ""}
													/>
												</div>
											</div>
											<br />
										</Fragment>
									))}
									<div className="row">
										<h6 className="col-md-6 text-left">
											<Button className="btn btn-success" onClick={() => addChoice(list.qId)} disabled={disabled ? "disabled" : ""}>
												Add Choice
											</Button>
										</h6>
									</div>
									<hr />
								</Fragment>
							)
					)}
					<TableScore data={score} />
				</div>
			</div>
		</Fragment>
	)
}

export default Score
