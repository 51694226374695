import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import { ContainerStyled, SectionStyled } from "../../styled"
import useNewsVote from "./context"
import Prize from "./Prize"
import Question from "./Question"
import Rule from "./Rule"
import Setting from "./Setting"
import TabNew from "./TabNew"

const Submit = () => {
	const { data, handleChangeStep, stepNow, handleSave } = useNewsVote()

	return (
		<ContainerStyled preview>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "start",
				}}
			>
				<h1>ยืนยัน</h1>
				<Button variant={"success"} onClick={handleSave}>
					บันทึกแคมเปญ
				</Button>
			</div>
			<Setting preview />
			<Question preview />
			<Prize preview />
			<Rule preview />
			{data && data.tabs?.map((tab, index) => <TabNew preview index={index} />)}
			<div
				style={{
					display: "flex",
					justifyContent: "end",
					alignItems: "start",
				}}
			>
				<Button className="mr-2" variant="outline-dark" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button variant={"success"} onClick={handleSave}>
					บันทึกแคมเปญ
				</Button>
			</div>
		</ContainerStyled>
	)
}

export default Submit
