import React, { useEffect, useState } from "react"
import { CampaignSetup, TargetSection, Question, Submit, MemberJoin, Award, Announce } from "./Pages"
import { StoreContextProvider } from "./Context/store"
import { validateForm } from './Widgets/validate'

const StepForm = (props) => {
	const id = props.match.params?.id
	const [currentStep, setCurrentStep] = useState(0)
	const [error, setError] = useState('')

	const handleStep = async (step, data, schema) => {
		const result  = await validateForm(data, schema)
		if(result){
			setError(result)
		}else{
			setCurrentStep(step)
		}
	}

	const form1 = (step) => {
		switch (parseInt(step)) {
			case 1:
				return <CampaignSetup pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 2:
				return <TargetSection pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
			case 3:
				return <Question pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 4:
				return <Award pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 5:
				return <Announce pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
			case 6:
				return <Submit pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
		}
	}

	const form2 = (step) => {
		switch (parseInt(step)) {
			case 1:
				return <MemberJoin pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
			case 2:
				return <CampaignSetup pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 3:
				return <TargetSection pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
			case 4:
				return <Question pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 5:
				return <Award pageStep={currentStep} onChangeStep={handleStep} pageId={id} error={error} />
			case 6:
				return <Announce pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
			case 7:
				return <Submit pageStep={currentStep} onChangeStep={handleStep} pageId={id} />
		}
	}

	useEffect(() => {
		setCurrentStep(currentStep + 1)
	}, [])

	return (
		<div>
			<StoreContextProvider props={props}>{id ? form2(currentStep) : form1(currentStep)}</StoreContextProvider>
		</div>
	)
}

export default StepForm
