import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { Button, Form, Col, Table, Card } from "react-bootstrap"
import { useFormik } from "formik"
import Alert from "react-bootstrap/Alert"
import User from "../../libs/admin"
import Permission from "./Permission"
import { json } from "body-parser"
import { checkPermission } from "../../helper/functions"
import { type } from "../../helper/const"

const rules = [
	{
		name: "Dashboard",
		group: [
			{
				name: "All Dashboard",
				key: "dashboard",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Report",
		group: [
			{
				name: "Group",
				key: "report_group",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Persons",
				key: "report_persons",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Journey",
				key: "report_journey",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Members",
		group: [
			{
				name: "List/Info/edit/create",
				key: "member",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Sensitive Info (birthday, mobile no., address, gender etc.)",
				key: "member_sensitive_data",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Member Manage",
				key: "member_manage",
				type: {
					view: true,
					edit: true,
				}
			}
		],
	},
	{
		name: "User Management (admin)",
		group: [
			{
				name: "List/Info/edit/create",
				key: "user_management",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Permission section",
				key: "permsission_user_management",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Notification",
		group: [
			{
				name: "List/Info/edit/create",
				key: "notification",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Comment",
		group: [
			{
				name: "List/Info/edit/create",
				key: "comment",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Action (mark spam, delete, hide)",
				key: "action_comment",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Campaign",
		group: [
			{
				name: "Activity Vote",
				key: "activity_vote",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Vote In Article",
				key: "vote_in_article",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "News Vote",
				key: "news_vote",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Campaign Page Setting",
				key: "campaign_page_setting",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Eurofootball",
				key: "euro_football",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Sale",
		group: [
			{
				name: "Sale Ads User",
				key: "user_ads_management",
				type: {
					view: true,
					edit: true,
				},
			},
		],
	},
	{
		name: "Subscription",
		group: [
			{
				name: "Package",
				key: "subscription_package",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Member",
				key: "subscription_member",
				type: {
					view: true,
					edit: true,
				},
			},
			{
				name: "Transaction",
				key: "subscription_transaction",
				type: {
					view: true,
					edit: false,
				},
			},
		],
	},
	{
		name: "Mongkolwall",
		group: [
			{
				name: "Business Development",
				key: "business_development",
				type: {
					edit: true,
				},
			},
			{
				name: "Customer Service",
				key: "customer_service",
				type: {
					edit: true,
				},
			},
			{
				name: "Finance",
				key: "finance",
				type: {
					edit: true,
				},
			},
		],
	},
]

const Forms = (props) => {
	let { id } = useParams()
	const admin = User.getCookieData()

	const [profileState, setProfileState] = useState({})
	const [userId, setUserId] = useState(id)
	const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)

	//permission

	const [roles, setRoles] = useState("0")
	const [permission, setPermission] = useState([])

	const [permissionData, setPermissionData] = useState([])
	const [disableBtn, setDisableBtn] = useState(true)
	const [disablePer, setDisablePer] = useState(true)
	const [disableInfo, setDisableInfo] = useState(true)
	const [perView] = useState(checkPermission(admin.permission, "permsission_user_management_view"))

	const myPermission = () => {
		if (checkPermission(admin.permission, ["user_management_edit", "permsission_user_management_edit"], "or")) {
			setDisableInfo(checkPermission(admin.permission, ["user_management_edit"]) ? false : true)
			setDisablePer(checkPermission(admin.permission, ["permsission_user_management_edit"]) ? false : true)
			setDisableBtn(checkPermission(admin.permission, ["user_management_edit", "permsission_user_management_edit"], "or") ? false : true)
		}

	}

	const fetchData = async () => {
		try {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/user/form/${id}?v=${Math.random() * 1000}`, { withCredentials: true });
			const { _id, roles, permission } = data.data;
			setProfileState(data.data);
			setUserId(_id);
			setRoles(roles);
			setPermission(permission);
			setPermissionData(permission);
			// setPermissionBox(permission)
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const checked = checkPermission(admin.permission, "user_management_view")
		myPermission()

		if (checked) {
			if (id !== 'new') {
				fetchData();
			}
		} else {
			props.history.push('/')
		}
	}, [id])

	const setPermissionBox = (permission_name) => {
		if (permissionData.length > 0) {
			const even = (element) => element === permission_name
			return permissionData.some(even)
		}
	}
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstname: id !== 'new' ? profileState.firstname : "",
			lastname: id !== 'new' ? profileState.lastname : "",
			username: id !== 'new' ? profileState.username : "",
			isActive: id !== 'new' ? profileState.isActive : "",
			position: id !== 'new' ? profileState.position : "",
			roles: id !== 'new' ? profileState.role : "",
			permission: id !== 'new' ? profileState.permission : "",
			email: id !== 'new' ? profileState.email : ""
		},
		onSubmit: async (values) => {
			if (roles === "0") {
				alert("กรุณาเลือก Roles")
			} else {
				const valuesSubmit = {
					firstname: values.firstname,
					lastname: values.lastname,
					username: values.username,
					isActive: values.isActive,
					position: values.position,
					adminId: admin._id,
					roles: roles,
					permission: permission,
					email: values.email
				}

				try {
					const data = await User.sendupdateUser(valuesSubmit, userId)
					if (data.status === 200) {
						setDataResult(data.message)
						setShow(true)
						setColor("success")
						setTimeout(() => {
							setShow(false)
						}, 3000)
						console.log(data.message)
					} else {
						setDataResult(data.message)
						setShow(true)
						setColor("danger")
						setTimeout(() => {
							setShow(false)
						}, 3000)
						console.log(data.message)
					}
				} catch (e) {
					console.log(`request failed: ${e}`)
				}
			}
		},
	})

	const resetPassword = async () => {
		const result = await User.sendresetPassword(userId)
		if (result.status === 200) {
			setDataResult(result.message)
			setShow(true)
			setColor("success")
			setTimeout(() => {
				setShow(false)
			}, 3000)
			console.log(result.message)
		} else {
			setDataResult(result.message)
			setShow(true)
			setColor("danger")
			setTimeout(() => {
				setShow(false)
			}, 3000)
			console.log(result.message)
		}
	}

	const setStatePermission = (e) => {
		const { name, checked } = e.target
		const arr = permissionData

		if (checked) {
			if (!arr.includes(name)) {
				arr.push(name)
			}
			setPermission(arr)
		} else {
			if (arr.includes(name)) {
				const index = arr.indexOf(name)
				if (index > -1) {
					arr.splice(index, 1)
				}
				setPermission(arr)
			}
		}
	}

	const handleChangeRoles = (e) => {
		const { value } = e.target
		setRoles(value)

		switch (value) {
			case "0":
				setPermission([])
				setPermissionData([""])
				break;
			case "Super Admin":
				setPermission(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"user_management_view", "user_management_edit",
					"permsission_user_management_view", "permsission_user_management_edit",
					"notification_view", "notification_edit",
					"comment_view", "comment_edit",
					"action_comment_view", "action_comment_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"vote_in_article_view", "vote_in_article_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
					"business_development_edit",
					"customer_service_edit",
					"finance_edit",
					"report_journey_view", "report_journey_edit",
					"report_persons_view", "report_persons_edit",
					"report_group_view", "report_group_edit",
					"member_manage_view", "member_manage_edit",
					"subscription_member_view", "subscription_member_edit",
					"subscription_package_view", "subscription_package_edit",
					"subscription_transaction_view",
					"user_ads_management_view", "user_ads_management_edit", "admin"])

				setPermissionData(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"user_management_view", "user_management_edit",
					"permsission_user_management_view", "permsission_user_management_edit",
					"notification_view", "notification_edit",
					"comment_view", "comment_edit",
					"action_comment_view", "action_comment_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"vote_in_article_view", "vote_in_article_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
					"business_development_edit",
					"customer_service_edit",
					"finance_edit",
					"report_journey_view", "report_journey_edit",
					"report_persons_view", "report_persons_edit",
					"report_group_view", "report_group_edit",
					"member_manage_view", "member_manage_edit",
					"subscription_member_view", "subscription_member_edit",
					"subscription_package_view", "subscription_package_edit",
					"subscription_transaction_view",
					"user_ads_management_view", "user_ads_management_edit"])
				break;
			case "Admin":
				setPermission(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"comment_view", "comment_edit",
					"action_comment_view", "action_comment_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"vote_in_article_view", "vote_in_article_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
					"business_development_edit",
					"customer_service_edit",
					"finance_edit",
					"report_journey_view", "report_journey_edit",
					"report_persons_view", "report_persons_edit",
					"report_group_view", "report_group_edit",
					"member_manage_view", "member_manage_edit",
					"subscription_member_view", "subscription_member_edit",
					"subscription_package_view", "subscription_package_edit",
					"subscription_transaction_view",
					"user_ads_management_view", "user_ads_management_edit", "admin"])

				setPermissionData(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"comment_view", "comment_edit",
					"action_comment_view", "action_comment_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"vote_in_article_view", "vote_in_article_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
					"business_development_edit",
					"customer_service_edit",
					"finance_edit",
					"report_journey_view", "report_journey_edit",
					"report_persons_view", "report_persons_edit",
					"report_group_view", "report_group_edit",
					"member_manage_view", "member_manage_edit",
					"subscription_member_view", "subscription_member_edit",
					"subscription_package_view", "subscription_package_edit",
					"subscription_transaction_view",
					"user_ads_management_view", "user_ads_management_edit"])
				break;
			case "Marketing":
				setPermission(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
				])

				setPermissionData(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"campaign_view", "campaign_edit",
					"news_vote_view", "news_vote_edit",
					"activity_vote_view", "activity_vote_edit",
					"campaign_page_setting_view", "campaign_page_setting_edit",
					"euro_football_view", "euro_football_edit",
				])
				break;
			case "Editorial":
				setPermission([
					"dashboard_view", "dashboard_edit",
					"campaign_view", "campaign_edit",
					"vote_in_article_view", "vote_in_article_edit",
				])

				setPermissionData([
					"dashboard_view", "dashboard_edit",
					"campaign_view", "campaign_edit",
					"vote_in_article_view", "vote_in_article_edit",
				])
				break;
			case "Product":
				setPermission(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"campaign_view", "campaign_edit"])

				setPermissionData(["dashboard_view", "dashboard_edit",
					"member_view", "member_edit",
					"member_sensitive_data_view", "member_sensitive_data_edit",
					"notification_view", "notification_edit",
					"campaign_view", "campaign_edit"])
				break;
			case "Business Development":
				setPermission(["business_development_edit",])
				setPermissionData(["business_development_edit",])
				break;
			case "Customer Service":
				setPermission(["customer_service_edit",])
				setPermissionData(["customer_service_edit",])
				break;
			case "Finance":
				setPermission(["finance_edit",])
				setPermissionData(["finance_edit",])
				break;
			case "Custom":
				setPermission([])
				setPermissionData([""])
				break;

		}


	}
	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}
			<Form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-12">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<div className="row">
									<h6 className="col-md-6 m-0 font-weight-bold text-primary">Info</h6>
									<div className="col-md-6 text-right">
										<Button type="submit" variant="success" className="btn btn-success btn-icon-split btn-sm mr-2" disabled={disableBtn ? 'disabled' : ''}>
											<span className="icon text-white-50">
												<i className="fas fa-user-edit"></i>
											</span>
											<span className="text">บันทึกแก้ไขข้อมูล</span>
										</Button>
										<Button className="btn btn-info btn-icon-split btn-sm" onClick={resetPassword} disabled={disableBtn ? 'disabled' : ''}>
											<span className="icon text-white-50">
												<i className="fas fa-unlock-alt"></i>
											</span>
											<span className="text">รีเซ็ตรหัสผ่าน</span>
										</Button>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-12 text-center">
										<h3>{id !== "new" ? profileState.display_name : ""}</h3>
									</div>
								</div>
								<hr />

								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>ชื่อจริง</Form.Label>
										<Form.Control
											type="text"
											id="firstname"
											name="firstname"
											value={formik.values.firstname || ""}
											placeholder="ชื่อจริง"
											onChange={formik.handleChange}
											disabled={disableInfo ? 'disabled' : ''}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>นามสกุล</Form.Label>
										<Form.Control
											type="text"
											id="lastname"
											name="lastname"
											value={formik.values.lastname || ""}
											placeholder="นามสกุล"
											onChange={formik.handleChange}
											disabled={disableInfo ? 'disabled' : ''}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>username</Form.Label>
											<Form.Control
												type="username"
												id="username"
												name="username"
												value={formik.values.username || ""}
												placeholder="username"
												onChange={formik.handleChange}
												disabled={disableInfo ? 'disabled' : ''}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>email</Form.Label>
											<Form.Control
												type="email"
												id="email"
												name="email"
												value={formik.values.email || ""}
												placeholder="email"
												onChange={formik.handleChange}
												disabled={disableInfo ? 'disabled' : ''}
											/>
										</Form.Group>
									</Col>
								</Form.Row>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>สถานะ</Form.Label>
											<Form.Control as="select" value={formik.values.isActive || ""} name="isActive" onChange={formik.handleChange} disabled={disableInfo ? 'disabled' : ''}>
												<option value="">เลือกสถานะการใช้งาน</option>
												<option value={true}>ใช้งาน</option>
												<option value={false}>ปิดการใช้งาน</option>
											</Form.Control>
										</Form.Group>
									</Col>

									<Col>
										<Form.Group>
											<Form.Label>ตำแหน่ง</Form.Label>
											<Form.Control as="select" value={formik.values.position || ""} name="position" onChange={formik.handleChange} disabled={disableInfo ? 'disabled' : ''}>
												<option value="">เลือกตำแหน่ง</option>
												<option value="operation">Operation</option>
												<option value="admin">Admin</option>
												<option value="marketing">Marketing</option>
												<option value="analyst">Analyst</option>
												<option value="guest">Guest</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>
								<Col>**** รหัสผ่านตั้งต้นคือ trendvg3 </Col>
							</div>
						</div>
					</div>
				</div>
			</Form>

			{perView && <Card>
				<Card.Header>Permissions</Card.Header>
				<Card.Body>
					<Form.Control as="select" onChange={handleChangeRoles} value={roles} disabled={disablePer ? 'disabled' : ''} >
						<option value="0">-- Select --</option>
						<option value="Super Admin">Super Admin</option>
						<option value="Admin">Admin</option>
						<option value="Marketing">Marketing</option>
						<option value="Editorial">Editorial</option>
						<option value="Product">Product</option>
						<option value="Custom">Custom</option>
						<option value="Business Development">Business Development</option>
						<option value="Customer Service">Customer Service</option>
						<option value="Finance">Finance</option>
					</Form.Control>

					<Table striped bordered hover style={{ marginTop: "20px" }}>
						<tbody>
							{rules.map((rule) => (
								<>
									<tr>
										<th>{rule.name}</th>
										<th>View</th>
										<th>Edit</th>
									</tr>
									<tr></tr>
									{rule.group.map((sub) => (
										<tr >
											<td>{sub.name}</td>
											<td>
												{sub.type.view && (
													<Form.Check
														id={`${sub.key}_view`}
														key={`${sub.key}_view`}
														name={`${sub.key}_view`}
														onClick={setStatePermission}
														defaultChecked={setPermissionBox(`${sub.key}_view`)}
														disabled={disablePer ? 'disabled' : ''}
													/>
												)}
											</td>
											<td>
												{sub.type.edit && (
													<Form.Check
														id={`${sub.key}_edit`}
														key={`${sub.key}_edit`}
														name={`${sub.key}_edit`}
														onClick={setStatePermission}
														defaultChecked={setPermissionBox(`${sub.key}_edit`)}
														disabled={disablePer ? 'disabled' : ''}
													/>
												)}
											</td>
										</tr>
									))}
								</>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>}

			{/* <Permission userId={userId}/> */}
		</>
	)
}
export default Forms
