import _axios from "axios"
const axios = _axios.create({
	baseURL: process.env.REACT_APP_API_ROOT,
	withCredentials: true,
})

export const getNewsVotes = async (body) => {
	const response = await axios.post(`/campaign-new/news-vote/cms/list`, body)
	return response
}

export const getNewsVoteById = async (id) => {
	const response = await axios.get(`/campaign-new/news-vote/cms/${id}`)
	return response
}

export const saveNewsVote = async (body) => {
	const response = await axios.post(`/campaign-new/news-vote/cms`, body)
	return response
}

export const editNewsVote = async (id, body) => {
	const response = await axios.put(`/campaign-new/news-vote/cms/${id}`, body)
	return response
}

export const getReportNewsVote = async (id) => {
	const response = await axios.get(`/campaign-new/news-vote/cms/${id}/report`)
	return response
}
export const getReportMemberNewsVote = async (id, page, limit) => {
	const response = await axios.get(`/campaign-new/news-vote/cms/${id}/report-member`, {
		params: {
			page,
			limit,
		},
	})
	return response
}

export const changeIsActive = async (id, isActive) => {
	const response = await axios.get(`/campaign-new/news-vote/cms/${id}/change-is-active`, {
		params: {
			isActive,
		},
	})
	return response
}
export const changePrizeIsActive = async (id, isActive) => {
	const response = await axios.get(`/campaign-new/news-vote/cms/${id}/change-prize-is-active`, {
		params: {
			isActive,
		},
	})
	return response
}
