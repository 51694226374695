import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import { checkPermission } from "../../../helper/functions"
import User from "../../../libs/admin"
import { Pagination } from "semantic-ui-react"


var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const [data, setData] = useState()
	const inputRef = useRef(null)

	
	const [packageName , setPackageName] = useState([])
	const [payment , setPayment] = useState([])
	const [status , setStatus] = useState([])

	const [packageSearch , setPackageSearch] = useState("")
	const [paymentSearch , setPaymentSearch] = useState("")
	const [statusSearch , setStatusSearch] = useState("")
	
	const [activePage, setActivePage] = useState(1)
	
	const [allPage, setallPage] = useState("")

	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "subscription_transaction_view")
		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/list?v=` + Math.random() * 1000, { withCredentials: true })
				setallPage(data.pages)
                restrucObj(data)
                

				const package_detail =  await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/package/list?v=` + Math.random() * 1000, { withCredentials: true })
				generatePackageToSelect(package_detail.data.data)
			}
			fetchData()
		}else{
			props.history.push('/')
		}
	}, [])

	const restrucObj = (data_format, pagedata = 1) => {
		const newArr = []
		data_format.data.map((items, key) => {
			const newObj = {
				_id: items._id,
				count: key + (1 + 25 * (pagedata - 1)),
				customer_name : items.customer_name,
				package_name : items.package_name,
				payment_method : items.payment_method,
				ts : items.ts,
				action : items.action,
				type : items.type,
				status : items.status
			}

			newArr.push(newObj)
		})
		setData(newArr)
	}

    const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/list?v=` + Math.random() * 1000 + `&page=${pageInfo.activePage}`,
			{ withCredentials: true }
		)
		restrucObj(data, pageInfo.activePage)
	}

	const generatePackageToSelect = package_detail => {
		const package_name = []
		const package_payment = ["creditcard","mobilebanking","promptpay","truemoney"]
		const status = ["สำเร็จ" , "กำลังดำเนินการ" , "คืนเงิน" , "ยกเลิก" ]
		package_detail.map((items) => {
			package_name.push(items.name)
		})

		setPackageName(package_name)
		setPayment(package_payment)
		setStatus(status)
	}

	const formatName =  (cell, row, enumObject, index) => {
        return <Link to={"/subscription/transaction/view/" + row._id} >{cell}</Link>
	}

    const formatStartDate = (cell, row, enumObject, index) => {
		return  <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const formatStatus = (cell, row, enumObject, index) => {
		if(cell === "successful"){
			return "สำเร็จ"
		}else if(cell === "pending"){
			return  "กำลังดำเนินการ"
		}else if(cell === "deleted"){
            return  "ยกเลิก"
        }else if(cell === "reversed"){
            return  "คืนเงิน"
        }
	}

    const formatAction =  (cell, row, enumObject, index) => {
		if(cell === "create"){
			return "สร้าง"
		}else if(cell === "complete"){
			return  "เสร็จสิ้น"
		}else if(cell === "reverse"){
            return  "การขอคืนเงิน"
        }else {
            return  "อื่นๆ"
        }
	}
	
	const formatCharge = (cell, row, enumObject, index) => {
		if(cell === "charge"){
			return "การชำระเงิน"
		}else if(cell === "schedule"){
			return  "การสร้างกำหนดการ"
		}else{
            return  "อื่นๆ"
        }
		
	}

	const onSearch = async () => {

		
		let searchQuery = ""
		if (packageSearch.length > 0) {
			searchQuery += `&package=${packageSearch}`
		}
		if (paymentSearch.length > 0){
			searchQuery += `&payment=${paymentSearch}`
		}
		if (statusSearch.length > 0){
			searchQuery += `&status=${statusSearch}`
		}
		
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/search?${searchQuery}`, { withCredentials: true })
		if (data.data !== false) {
			setallPage(data.pages)
			setActivePage(data.currentPage)
			restrucObj(data)
		} else {
			setData([])
		}

	}

	
	
	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายละเอียดธุรกรรม</h6>
				</div>
				
				<div className="card-body">
					<br/>

					<div className="row">
						<div className="col-4" />
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={packageSearch || "Package Name"} onChange={e => setPackageSearch(e.target.value)}>
									<option value="">Package Name ...</option>
								{packageName && packageName.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={paymentSearch || "Payment"} onChange={e => setPaymentSearch(e.target.value)}>
									<option value="">Payment ...</option>
								{payment && payment.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={statusSearch || "success"} onChange={e => setStatusSearch(e.target.value)}>
									<option value="">Status ...</option>
								{status && status.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => onSearch()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหาข้อมูล</span>
							</button>
						</p>
					</div>

					
					
					<br/>
                    
                    {data !== undefined &&  <div className="col-12">
                        <BootstrapTable data={data} className="table table-striped"> 
							<TableHeaderColumn ref={inputRef} dataAlign="center" dataField="count" width="50">
								ลำดับ
							</TableHeaderColumn>
							<TableHeaderColumn  dataField="customer_name" width="150" dataFormat={formatName} isKey>
								ชื่อ-นามสกุล
							</TableHeaderColumn>
							<TableHeaderColumn dataField="package_name" width="100" >
								แพ็คเกจ
							</TableHeaderColumn>
                            <TableHeaderColumn dataField="payment_method" width="100" >
								ประเภทการชำระเงิน
							</TableHeaderColumn>
							<TableHeaderColumn dataField="ts" width="100" dataFormat={formatStartDate} >
								วันที่ทำรายการ
							</TableHeaderColumn>
                            <TableHeaderColumn dataField="action" width="100"  dataFormat={formatAction}>
								รูปแบบ
							</TableHeaderColumn>
                            <TableHeaderColumn dataField="type" width="100"  dataFormat={formatCharge}>
								ประเภทการทำรายการ
							</TableHeaderColumn>
                            <TableHeaderColumn dataField="status" width="100" dataFormat={formatStatus} >
								สถานะ
							</TableHeaderColumn>
							
						</BootstrapTable>
						{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
					</div>}
					
				</div>
			</div>
		</>
	)
}
export default List
