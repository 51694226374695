import moment from "moment"
import React, { useEffect, useState } from "react"
import { GridAutoStyled } from "../../styled"
import CardReportCoupon from "../../widgets/CardReportCoupon"
import _get from "lodash/get"
import { Form } from "react-bootstrap"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { v4 as uuid } from "uuid"
import { Dimmer, Loader, Pagination, Segment } from "semantic-ui-react"
import useNewsVote from "./context"
import { changeIsActive, getReportNewsVote, getReportMemberNewsVote } from "./service"
import { useParams } from "react-router-dom"
import _isEmpty from "lodash/isEmpty"
const Report = () => {
	const { data } = useNewsVote()
	const [loadingStat, setLoadingStat] = useState(true)
	const [loadingMembers, setLoadingMembers] = useState(true)
	const params = useParams()
	const [stat, setStat] = useState([])
	const [reportMembers, setReportMembers] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [showToggle, setShowToggle] = useState(false)
	const limit = 20

	const fetchReport = async () => {
		if (params.id) {
			const response = await getReportNewsVote(params.id)
			if (response.status === 200 && response.data.status === 200) {
				setStat(response.data.data)
			}
		}
		setLoadingStat(false)
	}

	const fetchReportMember = async (_page = page) => {
		if (params.id) {
			const response = await getReportMemberNewsVote(params.id, _page, limit)
			if (response.status === 200 && response.data.status === 200) {
				setReportMembers(response.data.data.data)
				setTotal(response.data.data.total)
			}
		}
		setLoadingMembers(false)
	}
	useEffect(() => {
		fetchReport()
		fetchReportMember()
		if (moment(_get(data, "setting.endDate")).isBefore(moment())) {
			setShowToggle(true)
		}
		return () => {}
	}, [])

	const formatDate = (date) => {
		return date ? moment(date).format("DD/MM/YYYY HH:mm") : "??"
	}

	const handlePageChange = (e, data) => {
		setPage(data.activePage)
		fetchReportMember(data.activePage)
	}

	const calPercent = (input = 0, all) => {
		if (all === 0 || input === 0) return 0
		return ((input / all) * 100).toFixed(2)
	}

	const formatConversion = (cell, row, enumObject, index) => {
		return (
			<p>
				<span className="text-primary mr-2">{calPercent(cell.countMemberVoted, cell.countMemberAll)}%</span>(
				<span className="text-primary">{cell.countMemberVoted}</span> members joined from <span className="text-primary">{cell.countMemberAll}</span>{" "}
				members)
			</p>
		)
	}

	const formatTask = (cell, row, enumObject, index) => {
		return (
			<ul className="table-task ">
				{cell?.map((item, index) => (
					<li key={index}>
						<label>
							{/* {console.log(`LOG: data.questions ---> `,data.questions)} */}
							{item.questionTitle}
							<span>
								( ทั้งหมด {item.count} คน {calPercent(item?.count, row.report.countVotedAll)}% )
							</span>
							<span className="guest">( ไม่เป็นสมาชิก {item.countGuest} คน )</span>
						</label>
						<ul className="table-task ">
							{item.choices.map((choice) => (
								<li key={choice._id}>
									- {choice.choiceTitle}
									<span>
										( ทั้งหมด {choice?.count} คน {calPercent(choice?.count, item?.count)}% )
									</span>
									<span className="guest">( ไม่เป็นสมาชิก {choice?.countGuest} คน )</span>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		)
	}

	const handleToggle = async (e) => {
		await changeIsActive(data._id, !e.target.checked)
	}
	return (
		<div>
			<div className="mb-4 d-flex align-items-end justify-content-between ">
				<div>
					<div className="text-md font-weight-bold ">ระยะเวลากิจกรรม*</div>
					<div>
						{formatDate(_get(data, "setting.startDate"))} - {formatDate(_get(data, "setting.endDate"))}
					</div>
				</div>
				{showToggle && (
					<div className={`d-flex align-items-center`}>
						<div className="mr-2 font-weight-bold text-md ">{`ซ่อนแคมเปญบนหน้าเว็บไซต์`}</div>
						<Form.Check type="switch" id="open-switch" defaultChecked={!_get(data, "isActive")} onChange={handleToggle} />
					</div>
				)}
			</div>
			<div style={{ position: "relative" }}>
				{loadingStat ? (
					<Loader active size="medium" />
				) : (
					<>
						<BootstrapTable data={_isEmpty(stat) ? [] : [stat]} className="table table-striped mb-4">
							<TableHeaderColumn
								dataField="report"
								dataFormat={formatConversion}
								tdStyle={{
									wordWrap: "break-word",
									whiteSpace: "normal",
								}}
								isKey
							>
								Conversion
							</TableHeaderColumn>
							<TableHeaderColumn
								tdStyle={{
									wordWrap: "break-word",
									whiteSpace: "normal",
								}}
								dataField="questions"
								dataFormat={formatTask}
							>
								Task Completion
							</TableHeaderColumn>
						</BootstrapTable>
					</>
				)}
			</div>
			{/* {href={`${process.env.REACT_APP_API_ROOT}/admin/campaign/export-csv/${id}`}} */}
			<a href={`${process.env.REACT_APP_API_ROOT}/campaign-new/news-vote/cms/${params.id}/export-csv`} className="btn btn-success mb-2" target="__blank">
				ExportCSV
			</a>
			<div style={{ position: "relative" }}>
				{loadingMembers ? (
					<Loader active size="medium" />
				) : (
					<>
						<BootstrapTable data={reportMembers} className="table table-striped">
							<TableHeaderColumn dataField="userId" width="50" dataAlign="center" dataFormat={(_, __, ___, index) => (page - 1) * limit + index + 1} isKey>
								ลำดับ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="userId" width="200">
								User Id
							</TableHeaderColumn>
							{/* <TableHeaderColumn dataField="coupon" dataFormat={(cell) => cell.code} width="200">
								รหัสคูปอง
							</TableHeaderColumn> */}
							<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.display_name} width="200">
								ชื่อ-นามสกุล
							</TableHeaderColumn>
							<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.gender} width="120">
								เพศ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.phone} width="120">
								เบอร์โทร
							</TableHeaderColumn>
							<TableHeaderColumn dataField="createdAt" width="150" dataFormat={(cell) => formatDate(cell)}>
								วันที่เล่น
							</TableHeaderColumn>
						</BootstrapTable>
						<Pagination activePage={page} onPageChange={handlePageChange} totalPages={Math.ceil(total / limit)} />
					</>
				)}
			</div>
		</div>
	)
}

// const mockData = [
// 	{
// 		userId: uuid(),
// 		user: {
// 			display_name: "นายสมชาย สมชาย",
// 			gender: "ชาย",
// 			phone: "0881232345",
// 		},
// 		coupon: {
// 			code: "ABC107",
// 		},
// 		createdAt: new Date(),
// 	},
// ]
export default Report
