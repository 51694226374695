import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getDashboardEuroFootball, getExportEuroFootball } from "./service/index"
import "../../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import "semantic-ui-css/semantic.min.css"
import { Pagination } from "semantic-ui-react"
import "../../../../assets/css/paginationlist.css"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import Spinner from "react-bootstrap/Spinner"
import { Link } from "react-router-dom"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const EuroFootballDashboard = () => {
	const { id } = useParams()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [allPage, setAllPage] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(false)

	const [limit, setLimit] = useState(100)

	useEffect(() => {
		const fetch = async () => {
			const { data } = await getDashboardEuroFootball(id, { limit })
			setData(data.data.data)
			setTotalCount(data.data.count)
			setAllPage(data.data.pages)
			setActivePage(data.data.current_page)
		}
		fetch()
	}, [id])

	const pagination = async (e, pageInfo) => {
		const { data } = await getDashboardEuroFootball(id, { current_page: pageInfo.activePage, limit })
		setData(data.data.data)
		setTotalCount(data.data.count)
		setAllPage(data.data.pages)
		setActivePage(data.data.current_page)
	}

	const countColumn = (cell, row, _, rowIndex) => {
		return (activePage - 1) * limit + rowIndex + 1
	}
	const getUserFirstName = (cell, row, _, rowIndex) => {
		if (cell && cell[0]) return cell[0].firstname || "-"
		return "-"
	}
	const getUserLastName = (cell, row, _, rowIndex) => {
		if (cell && cell[0]) return cell[0].lastname || "-"
		return "-"
	}
	const getUserAddress = (cell, row, _, rowIndex) => {
		let result = ""
		if (cell && cell[0] && cell[0].address) {
			const address = cell[0].address
			if (address.address) result += `${address.address} `
			if (address.tamboon) result += `ตำบล${address.tamboon} `
			if (address.aumphur) result += `อำเภอ${address.aumphur} `
			if (address.province) result += `จังหวัด${address.province} `
			if (address.zipcode) result += `${address.zipcode} `
		}
		return result || "-"
	}
	const getUserPhone = (cell, row, _, rowIndex) => {
		if (cell && cell[0]) return cell[0].phone || "-"
		return "-"
	}
	const getUserScore = (cell, row, _, rowIndex) => {
		// if (cell && cell[0]) return Number(cell[0].total).toLocaleString() || "0"
		if (cell) return Number(cell).toLocaleString()
		return "0"
	}

	return (
		<main
			style={{
				backgroundColor: "#fff",
				padding: "2rem",
			}}
		>
			<div className="row">
				<Link to={"/campaign-new/euro-football"} className="btn btn-primary" style={{ width: "2.5rem", height: "2.5rem" }}>
					<i class="fas fa-chevron-left"></i>
				</Link>
			</div>
			<br />
			<div className="row flex justify-content-between align-items-center">
				<div className="total-player">
					<p className="bold text-lg">
						จำนวนผู้เล่นทั้งหมด <span>{(totalCount && Number(totalCount).toLocaleString()) || 0}</span> คน
					</p>
				</div>
				<div className="export-csv">
					<a href={getExportEuroFootball(id)} target="__blank" className="btn btn-success">
						<p>Export CSV</p>
					</a>
				</div>
			</div>
			<br />
			<div className="row">
				<BootstrapTable data={data} className="table table-striped">
					<TableHeaderColumn row="0" dataField="_id" width="7" isKey dataFormat={countColumn}>
						อันดับ
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataField="user" width="15" tdStyle={{ textWrap: "wrap" }} dataFormat={getUserFirstName}>
						ชื่อ
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataField="user" width="15" tdStyle={{ textWrap: "wrap" }} dataFormat={getUserLastName}>
						นามสกุล
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataField="user" width="45" tdStyle={{ textWrap: "wrap" }} dataFormat={getUserAddress}>
						ที่อยู่
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataField="user" width="10" tdStyle={{ textWrap: "wrap" }} dataFormat={getUserPhone}>
						เบอร์โทรศัพท์
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataField="total" width="8" tdStyle={{ textWrap: "wrap" }} dataFormat={getUserScore}>
						คะแนน
					</TableHeaderColumn>
				</BootstrapTable>
				{data && data.length !== 0 && (
					<Pagination className="ml-auto" activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />
				)}
			</div>
		</main>
	)
}

export default EuroFootballDashboard
