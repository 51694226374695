import React, { useEffect } from "react"
import Forms from "./Form"
import Activity from "./Activity"
import User from "../../libs/admin"
import Subscription from "./Subscription"
import { checkPermission } from "../../helper/functions"

const Edit = (props) => {
	const user = User.getCookieData()
	useEffect(() => {
		const checked = checkPermission(user.permission, ["member_view", "member_edit"], 'or')

		if(!checked){
			props.history.push('/')
		}
	},[])
	return (
		<>
			<h1>ข้อมูลสมาชิกรายบุคคล</h1>
			<ul className="nav nav-tabs">
				<li>
					<a data-toggle="tab" href="#info">
						ข้อมูลสมาชิก
					</a>
				</li>
				<li>
					<a data-toggle="tab" href="#activity">
						ข้อมูลกิจกรรมของสมาชิก
					</a>
				</li>
				<li>
					<a data-toggle="tab" href="#subscription">
						ข้อมูล subscription
					</a>
				</li>
			</ul>

			<div className="tab-content">
				<div id="info" className="tab-pane fade in active show">
					<Forms />
				</div>
				<div id="activity" className="tab-pane fade">
					<Activity />
				</div>
				<div id="subscription" className="tab-pane fade">
					<Subscription />
				</div>
			</div>
		</>
	)
}
export default Edit
