import React, { Fragment } from "react"
import { SC_RecruitFromBrowse } from "./styles"
import { Button } from "react-bootstrap"

export const Upload = ({ label, name, key, value, onChange, onClick, disabled, validate }) => {
	return (
		<Fragment>
			<div className="row">
				<div className="col-10">
					<SC_RecruitFromBrowse disabled={disabled} error={validate}>
						<input name={name} type="file" key={key} className={"form-control" + (validate ? " is-invalid" : "")} onChange={onChange} />
						<label htmlFor={name}>{value ? value : label}</label>
						<span>เลือก</span>
					</SC_RecruitFromBrowse>
				</div>
				<div className="col-2" style={{ padding: 0 }}>
					<Button className={`btn btn-success ${disabled ? "btn-disabled" : ""} `} onClick={onClick}>
						Upload
					</Button>
				</div>
			</div>
		</Fragment>
	)
}
