import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { HStackStyled, SwiftButtom } from "../../styled"
import CustomTable from "../../widgets/CustomTable"
import { FormInput, FormSelect } from "../../widgets/Form"
import { getEurofootballAll, deleteEurofootballCampaign, calculateScoreEuroFootball } from "./service"
import DateTime from "@nateradebaugh/react-datetime"
import moment from "moment"

const EuroFootball = () => {
	const [data, setData] = useState([])
	const [formSearch, setFormSearch] = useState({})
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [sort, setSort] = useState({ _id: -1 })
	const [isLoading, setIsLoading] = useState(true)
	const fetch = async (options) => {
		const condition = { page, limit, sort }
		if (options) {
			condition["search"] = {}
			Object.entries(options).forEach(([key, value], index) => {
				if (key.includes("Date")) {
					condition["search"][key] = moment(value).unix()
				} else {
					condition["search"][key] = value
				}
			})
		}
		const {
			data: { data },
		} = await getEurofootballAll(condition)
		setData(data)
		setTotal(data.length)
		setIsLoading(false)
	}

	const handleChange = ({ name, value }) => {
		setFormSearch((prev) => ({ ...prev, [name]: value }))
	}

	const deleteCampaign = async (id) => {
		if (window.confirm(`ต้องการลบแคมเปญนี้ใช่หรือไม่? (${id})`) === true) {
			const { data } = await deleteEurofootballCampaign(id)
			console.log(data)
			if (data.status === 200) {
				fetch()
			} else {
				alert("ไม่สามารถลบข้อมูลได้")
			}
		}
	}

	useEffect(() => {
		fetch()
		return () => {}
	}, [page, limit, sort])

	const search = async () => {
		fetch(formSearch)
	}

	const columns = [
		{ label: "ลำดับ", field: "_id", sort: true, render: (col, row, index) => index + 1 },
		{
			label: "ชื่อแคมเปญ",
			sort: true,
			field: "name",
			render: (col, row) => <Link to={`/campaign-new/euro-football/manage/${row._id}`}>{row.name}</Link>,
		},
		{
			label: "สถานะ",
			field: "isActive",
			sort: true,
			render: (col, row) => <SwiftButtom active={row.isActive}></SwiftButtom>,
		},
		{ label: "วันที่เริ่ม", sort: true, field: "startDate", type: "datetime" },
		{ label: "วันที่สิ้นสุด", sort: true, field: "endDate", type: "datetime" },
		{
			label: "ลิ้งก์",
			field: "setting.url",
			render: (col, row) => (
				<a href={row.link} target="_blank">
					{row.link}
				</a>
			),
		},
		{
			label: "คำนวณ",
			field: "",
			render: (col, row) => (
				<button
					className="btn btn-success btn-sm"
					onClick={() => {
						setIsLoading(true)
						calculateScoreEuroFootball(row._id).then((_data) => {
							setIsLoading(false)
							if (!_data.data.data) {
								alert("ใส่ข้อมูลคะแนนที่ถูกต้องไม่ครบ")
							}
						})
					}}
				>
					คำนวณ
				</button>
			),
		},
		{
			label: "dashboard",
			field: "",
			render: (col, row) => (
				<Link className="btn btn-primary btn-sm" to={`/campaign-new/euro-football/dashboard/${row._id}`}>
					dashboard
				</Link>
			),
		},
		{
			label: "delete",
			field: "",
			render: (col, row) => (
				<button className="btn btn-danger btn-sm" onClick={() => deleteCampaign(row._id)}>
					X
				</button>
			),
		},
	]

	return (
		<div>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายการตารางกิจกรรม Euro Football</h6>
				</div>
				<div className="card-body ">
					<form
						onSubmit={(e) => {
							e.preventDefault()
							search()
						}}
					>
						<HStackStyled>
							<FormInput name={"name"} label={"ชื่อแคมเปญ"} onChange={handleChange} />
							<FormSelect
								name={"isActive"}
								label={"สถานะ"}
								placeholder="ทั้งหมด"
								options={[
									{ key: "active", value: true, text: "Active" },
									{ key: "inactive", value: false, text: "Inactive" },
								]}
								// defaultValue={"all"}
								onChange={handleChange}
							/>
							<FormInput
								label={"วันที่เริ่มต้น"}
								required
								name="startDate"
								renderInput={(props) => (
									<div className="non-editable d-flex align-items-center">
										<DateTime
											dateFormat={"DD-MM-YYYY"}
											timeFormat={true}
											className="w-100"
											defaultValue={formSearch["startDate"]}
											onBlur={(value) => handleChange({ name: props.name, value })}
										/>
									</div>
								)}
							/>
							<FormInput
								label={"วันสิ้นสุด"}
								required
								name="endDate"
								renderInput={(props) => (
									<div className="non-editable d-flex align-items-center">
										<DateTime
											dateFormat={"DD-MM-YYYY"}
											timeFormat={true}
											defaultValue={formSearch["endDate"]}
											className="w-100"
											onBlur={(value) => handleChange({ name: props.name, value })}
										/>
									</div>
								)}
							/>
							<div>
								<Button variant="primary" type="submit">
									ค้นหา
								</Button>
							</div>
						</HStackStyled>
					</form>
					<div className="d-flex justify-content-between mb-2">
						<div>
							จำนวน
							<span className="mx-2 font-weight-bold" style={{ color: "black" }}>
								{total}
							</span>
							รายการ
						</div>
						<div>
							<Link to="/campaign-new/euro-football/manage/" className="custom-rigth btn btn-success btn-sm">
								<span className="text">สร้างแคมเปญ</span>
							</Link>
							{/* <a
								href={`${process.env.REACT_APP_API_ROOT}/campaign-new/news-vote/cms/list/export-csv`}
								className="btn btn-success btn-sm ml-2"
								target="__blank"
							>
								Export to CSV
							</a> */}
						</div>
					</div>
					<CustomTable
						data={data}
						total={total}
						page={page}
						limit={limit}
						sort={{ _id: -1 }}
						onSort={setSort}
						onChangePage={setPage}
						onChangeLimit={setLimit}
						columns={columns}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	)
}
export default EuroFootball
