import isEmpty from "lodash/isEmpty"
import React, { useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { Select } from "semantic-ui-react"
import { CheckboxStyled, RadioStyled } from "../styled"
import Editor from "./Editor"
import styled from "styled-components"

export const FormInput = ({ name, label, errorMessage, required, onChange, renderInput, defaultValue, trim, ...props }) => {
	const handleChange = (e) => {
		if (onChange) {
			let _value = e.target.value
			if (trim) {
				_value = _value.trim()
			}
			onChange({
				name,
				value: _value,
			})
		}
	}
	const inputProps = {
		onChange: handleChange,
		name: name,
		className: `form-control ${errorMessage && "is-invalid"}`,
		defaultValue,
		id: name,
		...props,
	}
	return (
		<div className="form-input">
			<label htmlFor={name} className="label font-weight-bold">
				{label}
				{required && <span className="ml-1">*</span>}
			</label>
			{renderInput ? renderInput(inputProps) : <input {...inputProps} />}
			{errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
		</div>
	)
}

export const FormTextarea = ({ name, label, errorMessage, required, onChange, renderInput, defaultValue, remark, ...props }) => {
	const handleChange = (e) => {
		if (onChange) {
			onChange({
				name,
				value: e.target.value,
			})
		}
	}
	const inputProps = {
		onChange: handleChange,
		name: name,
		className: `form-control ${errorMessage && "is-invalid"}`,
		defaultValue,
		id: name,
		...props,
	}
	return (
		<div className="form-input w-100">
			<div className="d-flex">
				<label htmlFor={name} className="label font-weight-bold flex-grow-1">
					{label}
					{required && <span className="ml-1">*</span>}
				</label>
				{remark && <span style={{ fontSize: "80%" }}>{remark}</span>}
			</div>
			{renderInput ? renderInput(inputProps) : <textarea {...inputProps} />}
			{errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
		</div>
	)
}

export const FormCheckbox = ({ name, items = [], label, errorMessage, required, onChange, defaultValues = [] }) => {
	const [form, setForm] = useState(defaultValues?.reduce((prev, current) => ({ ...prev, [current]: true }), {}) || {})
	const handleChange = (e) => {
		const temp = { ...form, [e.target.name]: e.target.checked }
		setForm(temp)
		if (onChange) {
			const _value = Object.entries(temp)
				.filter(([key, value]) => {
					return !!key && !!value
				})
				.map(([key]) => key)
			onChange({
				name,
				value: _value,
			})
		}
	}
	return (
		<div className="form-input">
			<label className="label font-weight-bold">
				{label}
				{required && <span className="ml-1">*</span>}
			</label>
			{items.map((item, index) => (
				<div key={item.value}>
					<CheckboxStyled
						onChange={handleChange}
						type="checkbox"
						defaultChecked={defaultValues.includes(item.value)}
						name={item.value}
						value={item.value}
					/>
					<label htmlFor={item.value}>{item.label}</label>
				</div>
			))}
			{errorMessage && (
				<div
					style={{
						color: "#e74a3b",
						fontSize: "80%",
					}}
				>
					{errorMessage}
				</div>
			)}
		</div>
	)
}

export const FormRadio = ({ name, items = [], label, errorMessage, required, onChange, defaultValue }) => {
	const handleChange = (e) => {
		if (onChange) {
			onChange({
				name,
				value: e.target.value,
			})
		}
	}
	return (
		<div className="form-input">
			<label className="label font-weight-bold">
				{label}
				{required && <span className="ml-1">*</span>}
			</label>
			<div
				style={{
					display: "flex",
				}}
			>
				{items.map((item, index) => (
					<Form.Check
						key={item.value}
						onChange={handleChange}
						type="radio"
						label={item.label}
						checked={defaultValue === item.value}
						name={name}
						value={item.value}
						className={`mr-2`}
					/>
				))}
			</div>

			{errorMessage && (
				<div
					style={{
						color: "#e74a3b",
						fontSize: "80%",
					}}
				>
					{errorMessage}
				</div>
			)}
		</div>
	)
}

export const FormUpload = ({ name, label, errorMessage, required, remark, multiple, onUpload, defaultValue, isCanRemove, onRemove }) => {
	const [files, setFiles] = useState([])
	const ref = useRef()
	const handleChange = (e) => {
		const arr = []
		const files = e.target.files
		for (let index = 0; index < files.length; index++) {
			arr.push(files[index])
		}
		setFiles(arr)
	}
	const handleRemove = (file, index) => {
		if (!isCanRemove) return
		if (typeof onRemove !== "function") return
		if (Array.isArray(defaultValue)) {
			return onRemove({ name, value: defaultValue.filter((_, _index) => index !== _index) })
		}
		return onRemove({ name, value: {} })
	}
	const renderDefaultValue = () => {
		if (isEmpty(defaultValue)) return
		if (Array.isArray(defaultValue)) {
			return (
				<div className="mt-1 img-wrapper">
					{defaultValue &&
						defaultValue.map((file, index) => (
							<div
								key={file.name + index}
								className="text-primary d-flex align-items-center"
								style={{
									gap: "0.5rem",
									cursor: "pointer",
								}}
							>
								<a href={file.url} target="_blank">
									<span className="img-name">{`${index + 1}. ${file.name}`}</span>
									<img className="img-preview" key={index} src={file.url} />
								</a>
								{isCanRemove && <i onClick={() => handleRemove(file, index)} className="close icon red non-preview"></i>}
							</div>
						))}
				</div>
			)
		}
		if (defaultValue?.name) {
			return (
				<div className="mt-1">
					<div
						className="text-primary img-wrapper d-flex align-items-center"
						style={{
							gap: "0.5rem",
							cursor: "pointer",
						}}
					>
						<a href={defaultValue.url} target="_blank">
							<span className="img-name">{`${1}. ${defaultValue.name}`}</span>
							<img className="img-preview" src={defaultValue.url} />
						</a>
						{isCanRemove && <i onClick={handleRemove} className="close icon red non-preview"></i>}
					</div>
				</div>
			)
		}
	}
	const handleClickUpload = () => {
		if (onUpload) {
			const payload = {
				name,
				value: multiple ? files : files[0],
				oldValue: defaultValue,
			}
			onUpload(payload)
			setFiles([])
		}
	}
	return (
		<div className="form-input">
			<div style={{ display: "flex" }}>
				<label className="label font-weight-bold flex-grow-1">
					{label}
					{required && <span className="ml-1 ">*</span>}
				</label>
				{remark && <span style={{ fontSize: "80%", paddingRight: "15%" }}>{remark}</span>}
			</div>
			<div style={{ display: "flex" }}>
				<div className="mr-2 w-100" style={{ display: "flex" }}>
					<div
						className="form-control non-preview"
						style={{
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
							overflowX: "hidden",
							overflowY: "auto",
						}}
						title={files?.map((file, index) => `${index + 1}. ${file.name}`).join("\n")}
					>
						{files?.map((file, index) => (
							<div key={file + index}>{`${index + 1}. ${file.name}`}</div>
						))}
					</div>
					<Button
						style={{
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						}}
						variant="dark"
						onClick={() => ref.current.click()}
						className="non-preview"
					>
						เลือก
					</Button>
				</div>
				<Button onClick={handleClickUpload} className="non-preview" disabled={files?.length === 0} variant={files?.length > 0 ? "success" : "dark"}>
					อัพโหลด
				</Button>
			</div>
			{errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
			{renderDefaultValue()}
			<input onChange={handleChange} type="file" ref={ref} multiple={multiple} name={name} style={{ display: "none" }} />
		</div>
	)
}

export const FormEditor = ({ name, label, errorMessage, required, onChange, defaultValue, placeholder }) => {
	const handleChange = (text) => {
		if (onChange) {
			onChange({
				name,
				value: text,
			})
		}
	}
	return (
		<div className="form-input">
			<div style={{ display: "flex" }} className={`mb-1`}>
				<label className="label font-weight-bold ">{label}</label>
				{required && <span className="ml-1">*</span>}
			</div>
			<Editor placeholderText={placeholder} onChange={handleChange} defaultValue={defaultValue} />
			{errorMessage && (
				<div
					style={{
						color: "#e74a3b",
						// fontSize: "80%",
					}}
				>
					{errorMessage}
				</div>
			)}
		</div>
	)
}

const SelectStyled = styled(Select)`
	min-width: 20px !important;
	min-height: 0 !important ;
	padding: 0.58571429em 2.1em 0.58571429em 1em !important;
	height: calc(1.5em + 0.75rem + 2px) !important;
	border-radius: 0.35rem !important;
	.dropdown.icon {
		padding: 0.81666667em !important;
	}
`
export const FormSelect = ({ name, label, errorMessage, required, onChange, defaultValue, options, placeholder }) => {
	const handleChange = (e, data) => {
		if (onChange) {
			onChange({
				name,
				value: data.value,
			})
		}
	}
	return (
		<div className="form-input">
			<div style={{ display: "flex" }}>
				<label className="label font-weight-bold ">{label}</label>
				{required && <span className="ml-1">*</span>}
			</div>
			<SelectStyled clearable defaultValue={defaultValue} placeholder={placeholder} onChange={handleChange} options={options} />
			{errorMessage && (
				<div
					style={{
						color: "#e74a3b",
						// fontSize: "80%",
					}}
				>
					{errorMessage}
				</div>
			)}
		</div>
	)
}
