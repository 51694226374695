import React from "react"
import { Selectd, SelectNumber } from "../../../utils"
import Select from "react-select"

export const SelectInput = ({
	label,
	id,
	name,
	value,
	data,
	onChange,
	require,
	multiple,
	closeMenuOnSelect = false,
	title='Select...',
	labelCol = "col-3  row-label",
	compCol = "col-2",
	disabled,
	validate
}) => {
	return (
		<div className="row">
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={compCol}>
				{multiple ? (
					<Select  id={id} name={name} value={value} onChange={onChange} options={data} closeMenuOnSelect={closeMenuOnSelect} isDisabled={disabled} isMulti  />
				) : (
					<Selectd id={id} name={name} value={value} onChange={onChange} data={data} title={title} disabled={disabled} validate={validate} />
				)}
			</div>
		</div>
	)
}

export const SelectScore = ({
	label,
	id,
	name,
	value,
	data,
	onChange,
	require,
	unit,
	labelCol = "col-3 row-label",
	compCol = "col-2",
	unitCol = "col-2",
	disabled
}) => {
	return (
		<div className="row">
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={compCol}>
				<SelectNumber id={id} name={name} value={value} onChange={onChange} data={data} disabled={disabled} />
			</div>
			<div className={unitCol}>{unit}</div>
		</div>
	)
}

export const SelectAge = ({ label, id, name1, name2, value1, value2, data, onChange, require, labelCol = "col-3 row-label", compCol = "col-2" }) => {
	return (
		<div className="row">
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={compCol}>
				<SelectNumber id={id} name={name1} value={value1} onChange={onChange} data={data} type="age" start />
			</div>
			<div className={compCol}>
				<SelectNumber id={id} name={name2} value={value2} onChange={onChange} data={data} type="age" />
			</div>
		</div>
	)
}
