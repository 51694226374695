import DateTime from "@nateradebaugh/react-datetime"
import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import { SectionStyled, ContainerStyled } from "../../styled"
import Editor from "../../widgets/Editor"
import { FormInput, FormCheckbox, FormUpload } from "../../widgets/Form"
import useActivityVote from "./context"
import _get from "lodash/get"
import useUpload from "../../useUpload"
const Setting = () => {
	const { data, handleChange, handleChangeStep, stepNow } = useActivityVote()
	const { onUpload } = useUpload()
	// const urlCampaign = `${process.env.REACT_APP_LINK_CAMPAIGN}/`
	const getUrlCampaign = (slugName) => {
		return `${process.env.REACT_APP_LINK_CAMPAIGN}/activity-vote/${slugName}/home`
	}
	return (
		<ContainerStyled>
			<SectionStyled className="mb-4">
				<h1>ตั้งค่า Campaign</h1>

				<FormInput
					onChange={handleChange}
					name={"setting.name"}
					label={"ชื่อแคมเปญ"}
					required
					defaultValue={_get(data, "setting.name")}
					// errorMessage="กรุณากรอก"
				/>

				<FormInput
					label={"ชื่อ URL ของแคมเปญ"}
					required
					defaultValue={_get(data, "setting.slugName")}
					onChange={({ name, value }) => {
						handleChange([
							{ name: "setting.slugName", value },
							{ name: "setting.url", value: getUrlCampaign(value) },
						])
					}}
					renderInput={(inputProps) => (
						<div style={{ display: "flex" }}>
							<input
								style={{ width: "70%" }}
								className={`form-control mr-2 font-weight-bold`}
								disabled
								value={`${process.env.REACT_APP_LINK_CAMPAIGN}/activity-vote/`}
							/>
							<input style={{ width: "30%" }} {...inputProps} />
						</div>
					)}
				/>

				<FormInput
					onChange={handleChange}
					name={"setting.description"}
					defaultValue={_get(data, "setting.description")}
					label={"รายละเอียดแคมเปญ (แสดงใน search engine)"}
					required
				/>

				<FormInput
					label={"ระยะเวลากิจกรรม"}
					required
					renderInput={() => (
						<div className="non-editable" style={{ display: "flex", alignItems: "center" }}>
							<DateTime
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={_get(data, "setting.startDate")}
								onBlur={(value) => handleChange({ name: "setting.startDate", value })}
								inputProps={{ readOnly: true }}
							/>
							<span className="mx-2 font-weight-bold">-</span>
							<DateTime
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={_get(data, "setting.endDate")}
								onBlur={(value) => handleChange({ name: "setting.endDate", value })}
								inputProps={{ readOnly: true }}
							/>
						</div>
					)}
				/>

				<FormCheckbox
					name={"setting.allows"}
					items={[
						{ label: "Guest", value: "guest" },
						{ label: "Membership", value: "membership" },
					]}
					defaultValues={_get(data, "setting.allows", [])}
					label="ประเภทผู้เล่นที่สามารถเข้าร่วมได้"
					required
					// errorMessage={"กรุณาเลือก"}
					onChange={handleChange}
				/>

				<div className="form-input">
					<div style={{ display: "flex" }} className={`mb-1`}>
						<label className="label font-weight-bold flex-grow-1">{`Term & Condition (แสดงผลก่อนเล่นเกม)`}</label>
						<Form.Check
							type="switch"
							id="custom-switch"
							defaultChecked={_get(data, "setting.termCondition.has")}
							onChange={(e) => handleChange({ name: "setting.termCondition.has", value: !_get(data, "setting.termCondition.has") })}
						/>
					</div>
					{_get(data, "setting.termCondition.has") && (
						<Editor
							placeholderText="Term & Condition (แสดงผลก่อนเล่นเกม)"
							defaultValue={_get(data, "setting.termCondition.description")}
							onChange={(text) =>
								handleChange({
									name: "setting.termCondition.description",
									value: text,
								})
							}
						/>
					)}
				</div>

				<Divider className="my-4" />

				<h1>อัพโหลดไฟล์</h1>
				<FormUpload
					label={"Banner แคมเปญ"}
					remark="ขนาด 1128x865 px (16:9)"
					required
					name={"setting.bannerImg"}
					defaultValue={_get(data, "setting.bannerImg", {})}
					onUpload={onUpload(handleChange)}
				/>
				<FormUpload
					label={"Banner แคมเปญตอนแชร์"}
					remark="ขนาด 1200x875 px (16:9)"
					required
					name={"setting.shareImg"}
					defaultValue={_get(data, "setting.shareImg", {})}
					onUpload={onUpload(handleChange)}
				/>
				<FormUpload
					label={"ภาพพื้นหลังแคมเปญ"}
					remark="ขนาด 1440x789 px (16:9)"
					required
					name={"setting.bgImg"}
					defaultValue={_get(data, "setting.bgImg", {})}
					onUpload={onUpload(handleChange)}
				/>
				<FormUpload
					label={"โลโก้สปอนเซอร์"}
					remark="ขนาดไม่เกิน 120x120 px (1:1)"
					required
					name={"setting.logoImgs"}
					multiple
					defaultValue={_get(data, "setting.logoImgs", [])}
					onUpload={onUpload(handleChange)}
				/>
			</SectionStyled>
			<Button className="float-right mt-2 non-preview" onClick={() => handleChangeStep(stepNow + 1)} variant="primary">
				ถัดไป
			</Button>
		</ContainerStyled>
	)
}

export default Setting
