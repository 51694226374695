import React, { Fragment, useContext } from "react"
import { StoreContext } from "../../Context/store"
import VoteSetup from "./vote"
import QuizSetup from "./quiz"
import ScoreSetup from "./score"

const CampaignSetup = ({ pageStep = 1, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup],
		loading: [loading],
	} = useContext(StoreContext)

	const renderPage = () => {
		switch (campSetup.type) {
			case "Quiz":
				return <QuizSetup pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} error={error} />
			case "Score":
				return <ScoreSetup pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId}  />
			case "Vote":
				return <VoteSetup pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} error={error} />

			default:
				break
		}
	}

	return (
		<Fragment>
			{!loading && renderPage()}
		</Fragment>
	)
}

export default CampaignSetup
