import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import Moment from "react-moment"
import { checkPermission } from "../../../helper/functions"
import User from "../../../libs/admin"

import { Pagination } from "semantic-ui-react"

const ReactBsTable = require("react-bootstrap-table")
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn

const SECTION = ["homepage", "sport"]
const TYPE = ["panorama", "panorama2", "panorama3", "panorama4", "landing"]
const POSITION = [2, 3, 4, 5, 6]
const DETAIL_TYPE = ["ads"]
const DETAIL_LAYOUT = ["image-center"]
const STATUS = {
	10: "แสดงผล",
	9: "รอแสดงผล",
	"-1": "ไม่แสดงผล",
}

const URL_ADD = "/sale/ads-setting/form"
const URL_EDIT = "/sale/ads-setting/edit"

const List = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [totalPage, setTotalPage] = useState(1)

	const size = 25

	useEffect(() => {
		const checked = checkPermission(user.permission, ["user_ads_management_view", "user_ads_management_edit"], "or")
		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/ads-setting/list?v=${Math.random() * 1000}${size && "&size=" + size}`, {
					withCredentials: true,
				})
				console.log(data)
				setTotalPage(data.pages)
				setData(mappingData(data.data))
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/ads-setting/list?page=${pageInfo.activePage}${size && "&size=" + size}`, {
			withCredentials: true,
		})
		console.log(data.pages, "1")
		setData(mappingData(data.data))
	}

	const mappingData = (docs) => {
		if (!docs) return

		const data = docs.map((doc) => {
			const { info } = doc
			delete doc.info
			return {
				...doc,
				info_position: info?.position,
				info_detail_type: info?.detail?.type,
				info_detail_layout: info?.detail?.layout,
				info_detail_adunit: info?.detail?.adunit,
				info_detail_bg: info?.detail?.bg,
				info_detail_bg_btn: info?.detail?.bg_btn,
				info_detail_btn_text: info?.detail?.btn_text,
			}
		})
		return data
	}

	const dateFormatter = (cell) => {
		if (!cell) return ""
		return <Moment format="DD/MM/YYYY">{cell}</Moment>
	}

	const dateTimeFormatter = (cell) => {
		if (!cell) return ""
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const statusFormatter = (cell) => {
		if (!cell) return ""
		return STATUS[cell]
	}

	const updateAdsSetting = (cell) => {
		return (
			<Link to={`${URL_EDIT}/${cell}`} className="btn btn-success btn-circle btn-sm text-center">
				<i class="fas fa-edit"></i>
			</Link>
		)
	}

	const sectionFilter = SECTION.reduce((acc, item) => ((acc[item] = item), acc), {})
	const typeFilter = TYPE.reduce((acc, item) => ((acc[item] = item), acc), {})

	const statusFilter = { ...STATUS }

	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3">
				<h6 className="m-0 font-weight-bold text-primary">การตั้งค่า Ads</h6>
			</div>
			<div className="card-body">
				<div className="row">
					<h6 className="col-12 text-right">
						<Link to={URL_ADD} className="btn btn-success">
							เพิ่มการตั้งค่า Ads
						</Link>
					</h6>
				</div>
				<br /> {/* //*line break */}
				<BootstrapTable data={data} className="table table-striped">
					<TableHeaderColumn row="1" dataField="_id" width="40" dataFormat={updateAdsSetting} dataAlign="center" isKey>
						id
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataField="title" width="200" filter={{ type: "TextFilter" }}>
						title
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataField="section" width="50" filter={{ type: "SelectFilter", options: sectionFilter }}>
						section
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataField="type" width="80" filter={{ type: "SelectFilter", options: typeFilter }}>
						type
					</TableHeaderColumn>

					<TableHeaderColumn row="1" dataField="start" width="100" dataFormat={dateTimeFormatter} filter={{ type: "DateFilter" }}>
						วันที่เริ่ม
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataField="end" width="100" dataFormat={dateTimeFormatter} filter={{ type: "DateFilter" }}>
						วันที่สิ้นสุด
					</TableHeaderColumn>

					<TableHeaderColumn row="1" dataField="last_update" width="100" dataFormat={dateTimeFormatter} filter={{ type: "DateFilter" }}>
						วันที่แก้ไขข้อมูล
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataField="status"
						width="70"
						dataAlign="center"
						dataFormat={statusFormatter}
						filter={{ type: "SelectFilter", options: statusFilter }}
					>
						สถานะ
					</TableHeaderColumn>
				</BootstrapTable>
				{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={totalPage} ellipsisItem={null} />}
			</div>
		</div>
	)
}

export default List
