import React, { useState } from "react"
import { Button, Form, Col } from "react-bootstrap"
import { useFormik } from "formik"
import User from "../../libs/admin"
import Alert from "react-bootstrap/Alert"

const Passwords = () => {
	const user = User.getCookieData()
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)
	const [dataResult, setDataResult] = useState(null)

	const userId = user._id
	const formik = useFormik({
		enableReinitialize: true,

		initialValues: {
			password: "",
			oldpassword: "",
		},
		onSubmit: async (values) => {
		
			const valuesSubmit = {
				password: values.password,
				oldpassword: values.oldpassword,
			}

			try {
				const data = await User.sendupadtePassword(valuesSubmit, userId)
				if (data.status === 200) {
					setDataResult(data.message)
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
					console.log(data.message)
				} else {
					setDataResult(data.message)
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
					console.log(data.message)
				}
			} catch (e) {
				console.log(`request failed: ${e}`)
			}
		},
	})

	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}
			<Form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-lg-6">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<div className="row">
									<h6 className="col-md-6 m-0 font-weight-bold text-primary">Info</h6>
									<div className="col-md-6 text-right">
										<Button type="submit" variant="success" className="btn btn-success btn-icon-split btn-sm mr-2">
											<span className="icon text-white-50">
												<i className="fas fa-user-edit"></i>
											</span>
											<span className="text">บันทึกรหัสผ่าน</span>
										</Button>
									</div>
								</div>
							</div>
							<div className="card-body">
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>รหัสผ่านเดิม</Form.Label>
										<Form.Control
											type="password"
											id="oldpassword"
											name="oldpassword"
											value={formik.values.oldpassword || ""}
											placeholder="รหัสผ่านเดิม"
											onChange={formik.handleChange}
										/>
										
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>ตั้งรหัสผ่านใหม่</Form.Label>
										<Form.Control
											type="password"
											id="password"
											name="password"
											value={formik.values.password || ""}
											placeholder="รหัสผ่านใหม่"
											onChange={formik.handleChange}
										/>
									</Form.Group>
								</Form.Row>
							</div>
						</div>
					</div>
				</div>
			</Form>
		</>
	)
}
export default Passwords
