import React from "react"

const CardReportCoupon = ({ label, value = 0 }) => {
	return (
		<div
			style={{ minHeight: "80px", backgroundColor: "#dfdfdf", borderRadius: "0.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<div style={{ textAlign: "center", color: "black" }}>
				<label>{label}</label>
				<h4
					style={{
						fontWeight: "bold",
					}}
				>
					{value}
				</h4>
			</div>
		</div>
	)
}

export default React.memo(CardReportCoupon)
