import React, { Fragment, useContext, useState } from "react"
import { Navigation } from "../../../../utils"
import { StoreContext } from "../../Context/store"
import { Button, Nav, Badge } from "react-bootstrap"
import { Input, UploadInput, TableDetail, Textarea, SelectInput } from "../../Widgets"
import { v4 as uuidv4 } from "uuid"
import { campaignController } from "../../Services"
import { useHistory } from "react-router"
import { showDetail } from "../../../../helper/const"

const Detail = ({ pageStep = 1, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup],
		targetSec: [target],
		detail: [detail, setDetail],
		award: [award],
		awardDelete: [awardDel],
		itemDelete: [itemDel, setItemDel],
		rendereStep: rendereStep,
		tab: [tabId, setTabId],
		disabled: [disabled],
		ruleDelete: [ruleDel],
		id: id,
	} = useContext(StoreContext)

	const [myFile, setMyFile] = useState(undefined)
	const [chImage, setImageChice] = useState([])

	const history = useHistory()

	const onChangeChoice = (event, qid, chid) => {
		const { name, value } = event.target

		switch (name) {
			case "choiceName":
				onChangeInChoice(name, value, qid, chid)
				break

			case "clipeUrl":
				onChangeInChoice(name, value, qid, chid)
				break

			default:
				break
		}
	}

	const onChangeQuestion = (event, qid) => {
		const { name, value, type } = event.target
		onChangeInQuestion(name, value, qid, type)
	}

	const setUpload = (name, qid, chid, index) => {
		switch (name) {
			case "questionImage":
				if (myFile) {
					sendToUpload(myFile, name, qid, chid)
				}
				break

			case "choiceImage":
				const findChoiceImg = chImage.find((list) => {
					return list.index === index
				})
				const fileImage = (findChoiceImg || "").file
				if (fileImage) {
					sendToUpload(fileImage, name, qid, chid, index)
				}
				break
		}
	}

	const sendToUpload = async (file, name, qid, chid, index) => {
		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, campSetup.type)
		if (response && response.status === 200) {
			let data = response.data.response
			data = {
				...data,
				name: file.name,
			}
			if (chid) {
				onChangeInChoice(name, data, qid, chid)
				const newChImage = chImage.map((list) => {
					if (list.index === index) {
						return {}
					}
					return list
				})

				setImageChice(newChImage)
			} else {
				onChangeInQuestion(name, data, qid)
				setMyFile("")
			}
		}
	}

	const onUpload = (event, qid, chid, order) => {
		const { name, files } = event.target
		let { type, size } = files[0]
		if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
			const err = {
				qid: qid,
				chid: chid,
				message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
			}
		} else {
			switch (name) {
				case "questionImage":
					setMyFile(files[0])
					break
				case "choiceImage":
					let arrFileImage = []
					arrFileImage.push({ file: files[0], index: order })
					setImageChice([...chImage, ...arrFileImage])
					break
				default:
					break
			}
		}
	}

	const onChangeInQuestion = (name, value, qid, type) => {
		const newData = detail.map((list) => {
			if (list.qId === qid) {
				return {
					...list,
					[`${name}`]: type === "select-one" ? parseInt(value) : value,
				}
			} else {
				return list
			}
		})
		setDetail([...newData])
	}

	const onChangeInChoice = (name, value, qid, chid, type) => {
		const newDataName = detail.map((list) => {
			return {
				...list,
				choices:
					list.qId === qid
						? list.choices.map((item) => {
								if (item.parentId === qid && item.chId === chid) {
									return {
										...item,
										[`${name}`]: type === "select-one" ? parseInt(value) : value,
									}
								} else {
									return item
								}
						  })
						: [...list.choices],
			}
		})
		setDetail([...newDataName])
	}

	const addQuestion = () => {
		const questionId = uuidv4()
		const newQuestion = {
			qId: questionId,
			questionName: "",
			questionImage: "",
			clipUrl: "",
			choices: [
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
					clipeUrl: "",
				},
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
					clipeUrl: "",
				},
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "",
					choiceImage: "",
					clipeUrl: "",
				},
			],
		}

		setDetail([...detail, newQuestion])
	}

	const addChoice = (parentId) => {
		const newChoice = {
			chId: uuidv4(),
			parentId: parentId,
			choiceName: "",
			choiceImage: "",
			clipeUrl: "",
		}

		detail.map((list) => {
			if (list.qId === parentId) {
				return list.choices.push(newChoice)
			} else {
				return list.choices
			}
		})

		setDetail([...detail])
	}

	const deleteQuestion = (qid) => {
		let arrDelete = []
		const newDetail = detail.filter((list) => {
			if (list.qId !== qid) {
				return list
			} else {
				arrDelete.push(list.qId)
			}
		})

		setItemDel([...arrDelete])
		setDetail([...newDetail])
	}

	const deleteChoice = (qid, chid) => {
		const newDetail = detail.map((list) => {
			return {
				...list,
				choices: list.choices.filter((item) => {
					if (item.parentId === qid && item.chId !== chid) {
						return item
					} else if (item.parentId !== qid && item.chId !== chid) {
						return item
					}
				}),
			}
		})

		setDetail([...newDetail])
	}

	const onDeleteQuestionImage = async (_data) => {
		const data = { ..._data }
		let tempDetail = [...detail]
		const detailIndex = tempDetail.findIndex((f) => f.qId === data.qId)
		tempDetail[detailIndex].questionImage = null
		setDetail([...tempDetail])
		if (data?.questionImage?._id) {
			const res = await campaignController().deleteFile(data.questionImage._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	const onDeleteChoiceImage = async (_data) => {
		const data = { ..._data }
		let tempDetail = [...detail]
		const detailIndex = tempDetail.findIndex((f) => f.qId === data.parentId)
		const choiceIndex = tempDetail[detailIndex].choices.findIndex((f) => f._id === data._id)
		let tempChoice = tempDetail[detailIndex].choices[choiceIndex]
		tempChoice.choiceImage = null
		setDetail([...tempDetail])
		if (data?.choiceImage?._id) {
			const res = await campaignController().deleteFile(data.choiceImage._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}
	const onSubmit = async () => {
		let data = {}

		data = {
			campSetup,
			target,
			detail: detail,
			award,
			itemDel,
			awardDel,
			ruleDel,
		}

		if (id) {
			const resposne = await campaignController().updateCampaign(data, id)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		} else {
			const resposne = await campaignController().createCampaign(data)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		}
	}

	return (
		<Fragment>
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} data={detail} schema="camp-detail" />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Detail</h5>
				</div>

				<div className="card-body">
					<div className="row">
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							<Button className="btn btn-success" onClick={() => addQuestion()} disabled={disabled ? "disabled" : ""}>
								Add Question
							</Button>
							<Button className="btn btn-success offset-1" onClick={() => onSubmit()} disabled={disabled ? "disabled" : ""}>
								Submit
							</Button>
						</h6>
					</div>

					<Nav variant="pills" activeKey={tabId}>
						{detail.map((list, index) => (
							<Nav.Item key={list.qId}>
								<Nav.Link eventKey={list.qId} onSelect={(e) => setTabId(e)}>
									{` Question ${index + 1}`}
								</Nav.Link>
								{tabId === list.qId && detail.length > 1 && (
									<Badge
										style={{ fontSize: "16px", position: "relative", borderRadius: "100%", bottom: "46px", left: "79px", textTransform: "capitalize" }}
										variant="danger"
										onClick={() => deleteQuestion(list.qId)}
									>
										x
									</Badge>
								)}
							</Nav.Item>
						))}
					</Nav>
					<hr />

					{detail.map(
						(list, order) =>
							tabId === list.qId && (
								<Fragment key={list.qId}>
									<div className="row">
										<div className="col-3">
											<h6 className="m-0 font-weight-bold text-primary">
												{`Question No.${order + 1}`}
												<a style={{ color: "red" }}> *</a>
											</h6>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-6">
											<Input
												label="Title"
												id="qname"
												name="questionName"
												value={list.questionName}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
												require
												validate={error[`[${order}].questionName`]}
											/>

											<br />
											<SelectInput
												label="Showing"
												id="showwing"
												name="show"
												value={list.show}
												data={showDetail}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												disabled={disabled ? "disabled" : ""}
												compCol="col-8"
											/>
											<br />
											{typeof list?.show !== "undefined" && (
												<>
													{list.show === showDetail.find((f) => f.label === "image")?.value ? (
														<UploadInput
															label="Image"
															id="qImage"
															name="questionImage"
															placeholder="Browse..."
															value={myFile ? myFile.name : "Browse..."}
															onChange={(event) => onUpload(event, list.qId)}
															onClick={() => setUpload("questionImage", list.qId)}
															compCol="col-8"
															disabled={disabled ? "disabled" : ""}
															list={list.questionImage?.url ? list.questionImage.name : ""}
															onDelete={(index) => onDeleteQuestionImage(list, index)}
															remark="Dimension: 600x338px (16:9)"
															require
															validate={error[`[${order}].questionImage`]}
														/>
													) : (
														<Textarea
															label="Clip Url"
															id="qClip"
															name="clip"
															value={list.clip}
															onChange={(event) => onChangeQuestion(event, list.qId)}
															disabled={disabled ? "disabled" : ""}
															require
															compCol="col-8"
															validate={error[`[${order}].clip`]}
														/>
													)}
												</>
											)}
										</div>
									</div>
									<br />
									{list.choices.map((item, index) => (
										<Fragment key={item.chId}>
											<div className="row">
												<div style={{ padding: "10px" }} />
												<div className="col-3">
													<h6 className="m-0 font-weight-bold text-primary">
														{`Choice No. ${index + 1}`}
														<a style={{ color: "red" }}> *</a>
													</h6>
												</div>
												{list.choices.length > 3 && (
													<div className="col-3 row justify-content-end">
														<Button className="btn btn-danger" onClick={() => deleteChoice(list.qId, item.chId)} disabled={disabled ? "disabled" : ""}>
															Delete
														</Button>
													</div>
												)}
											</div>
											<br />
											<div className="row">
												<div style={{ padding: "10px" }} />
												<div className="col-6">
													<Input
														label="Title"
														id="cname"
														name="choiceName"
														value={item.choiceName}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId)}
														compCol="col-9"
														disabled={disabled ? "disabled" : ""}
														require
														validate={error[`[${order}].choices[${index}].choiceName`]}
													/>
													<br />
													<UploadInput
														label="Image"
														name="choiceImage"
														id="cImage"
														key={item.chId}
														placeholder={"Browse..."}
														value={
															chImage.find((list) => {
																return list.index === index
															})?.file?.name
														}
														list={item.choiceImage?.url ? item.choiceImage.name : ""}
														onChange={(event) => onUpload(event, list.qId, item.chId, index)}
														onClick={() => setUpload("choiceImage", list.qId, item.chId, index)}
														compCol="col-9"
														disabled={disabled ? "disabled" : ""}
														onDelete={(index) => onDeleteChoiceImage(item, index)}
														remark="Dimension: 600x338px (16:9)"
														require
														validate={error[`[${order}].choices[${index}].choiceImage`]}
													/>
													<br />
												</div>
											</div>
										</Fragment>
									))}
									<div className="row">
										<h6 className="col-md-6 text-left">
											<Button className="btn btn-success" onClick={() => addChoice(list.qId)} disabled={disabled ? "disabled" : ""}>
												Add Choice
											</Button>
										</h6>
									</div>
									<hr />
								</Fragment>
							)
					)}
					{/* <button onClick={() => console.log(detail)}>Submit</button> */}
					<TableDetail data={detail} />
				</div>
			</div>
		</Fragment>
	)
}

export default Detail
