import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import axios from "axios"
import moment from "moment"
import { Button, Form, Col } from "react-bootstrap"
import Alert from "react-bootstrap/Alert"
import { useFormik } from "formik"
import { checkPermission } from "../../../helper/functions"
import User from "../../../libs/admin"

const SECTION = ["homepage"]
const TYPE = ["panorama", "panorama2", "panorama3", "panorama4", "landing"]
const POSITION = [2, 3, 4, 5, 6]

const STATUS = {
	10: "แสดงผล",
	9: "รอแสดงผล",
	"-1": "ไม่แสดงผล",
}
const URL_LIST = "/sale/ads-setting"

const Forms = (props) => {
	const { id } = useParams()
	const user = User.getCookieData()

	const [dataUpdate, setDataUpdate] = useState(null)
	const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)

	useEffect(() => {
		const checked = checkPermission(user.permission, ["user_ads_management_view", "user_ads_management_edit"], "or")
		if (!checked) props.history.push("/")
		if (id) fetchData(id)

		async function fetchData(id) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/ads-setting/list/${id}?v=` + Math.random() * 1000, {
				withCredentials: true,
			})
			if (data.status === 200 && data.data) {
				setDataUpdate(data.data)
			}
		}
	}, [])

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: dataUpdate?.title || "",
			section: dataUpdate?.section || "",
			type: dataUpdate?.type || "",

			detail_bg: dataUpdate?.info.detail.bg || "",
			detail_cap: dataUpdate?.info.detail.detail_cap || 30,

			status: dataUpdate?.status || 9,
			start: dataUpdate ? moment(dataUpdate.start).format("YYYY-MM-DDTHH:mm") : moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
			end: dataUpdate ? moment(dataUpdate.end).format("YYYY-MM-DDTHH:mm") : moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
		},
		onSubmit: async (values) => {
			const { title, section, type, detail_bg, detail_cap, status, start, end } = values
			const valuesSubmit = {
				title,
				section,
				type,
				info: {
					detail: {
						bg: detail_bg,
						cap: detail_cap,
					},
				},
				status,
				start: moment(start).tz("Asia/Bangkok"),
				end: moment(end).tz("Asia/Bangkok"),
			}

			try {
				const data = dataUpdate
					? await User.updateAdsSetting({ _id: dataUpdate._id, data: valuesSubmit })
					: await User.addAdsSetting({ data: valuesSubmit })

				if (data.status === 200) {
					setDataResult(data.message)
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				} else {
					setDataResult(data.message)
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				}
			} catch (e) {
				console.error(`request failed: ${e}`)
			}
			// }
		},
	})

	return (
		<>
			{show === true && (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			)}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">{!dataUpdate ? "สร้างการตั้งค่า Ads" : `แก้ไขข้อมูล id: ${id}`}</h6>
				</div>
				<div className="card-body">
					<div className="row">
						<h6 className="col-12 text-right">
							<Link to={URL_LIST} className="btn btn-success">
								กลับ
							</Link>
						</h6>
					</div>
					<br /> {/* //*line break */}
					{checkPermission(user.permission, "user_ads_management_edit") && (
						<>
							<Form onSubmit={formik.handleSubmit}>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>ชื่อ Campaign &#42;</Form.Label>
											<Form.Control
												type="text"
												placeholder="กรุณากรอกชื่อ Campaign"
												value={formik.values.title}
												name="title"
												onChange={formik.handleChange}
												required
											></Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>section &#42;</Form.Label>
											<Form.Control as="select" value={formik.values.section} name="section" onChange={formik.handleChange} required>
												<option value="">เลือก section</option>
												{SECTION &&
													SECTION.map((_el, _index) => {
														return (
															<option key={_el} value={_el}>
																{_el}
															</option>
														)
													})}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>type &#42;</Form.Label>
											<Form.Control as="select" value={formik.values.type} name="type" onChange={formik.handleChange} required>
												<option value="">เลือก type</option>
												{TYPE &&
													TYPE.map((_el, _index) => {
														return (
															<option key={_el} value={_el}>
																{_el}
															</option>
														)
													})}
											</Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>
								{formik.values.type === "landing" && (
									<Form.Row>
										<Col>
											<Form.Group>
												<Form.Label>สีพื้นหลัง &#42;</Form.Label>
												<Form.Control
													type="text"
													placeholder="กรุณากรอกสีพื้นหลัง"
													value={formik.values.detail_bg}
													name="detail_bg"
													onChange={formik.handleChange}
													required
												></Form.Control>
											</Form.Group>
										</Col>
										<Col>
											<Form.Label>cap &#42;</Form.Label>
											<Form.Control
												type="text"
												placeholder="กรุณากรอกความถี่"
												value={formik.values.detail_cap}
												name="detail_cap"
												onChange={formik.handleChange}
												required
											></Form.Control>
										</Col>
									</Form.Row>
								)}
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>วันที่เริ่ม</Form.Label>
											<Form.Control type="datetime-local" value={formik.values.start} name="start" onChange={formik.handleChange}></Form.Control>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>วันที่สิ้นสุด</Form.Label>
											<Form.Control type="datetime-local" value={formik.values.end} name="end" onChange={formik.handleChange}></Form.Control>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>สถานะ</Form.Label>
											<Form.Control as="select" value={formik.values.status} name="status" onChange={formik.handleChange}>
												{STATUS &&
													Object.entries(STATUS).map((_el, _index) => {
														return (
															<option key={_el[0]} value={_el[0]}>
																{_el[1]}
															</option>
														)
													})}
											</Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>
								<br /> {/* //*line break */}
								<div className="row">
									<div className="col-12 text-right">
										<Button type="submit" variant="success" className="btn btn-success btn-icon-split btn-sm">
											<span className="icon text-white-50">
												<i className="fas fa-save"></i>
											</span>
											<span className="text">{!dataUpdate ? "สร้าง Ads" : "บันทึกข้อมูล Ads"}</span>
										</Button>
									</div>
								</div>
							</Form>
						</>
					)}
				</div>
			</div>
		</>
	)
}

export default Forms
