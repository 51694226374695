import DateTime from "@nateradebaugh/react-datetime"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import { SectionStyled, ContainerStyled } from "../../styled"
import { FormInput, FormCheckbox, FormUpload } from "../../widgets/Form"
import useVoteInArticle from "./context"
import _get from "lodash/get"
import useUpload from "../../useUpload"
const Setting = () => {
	const { data, handleChange, handleChangeStep, stepNow } = useVoteInArticle()
	const { onUpload } = useUpload()
	return (
		<ContainerStyled>
			<SectionStyled className="mb-4">
				<h1>ตั้งค่า Campaign</h1>

				<FormInput
					onChange={handleChange}
					name={"setting.name"}
					label={"ชื่อแคมเปญ"}
					required
					defaultValue={_get(data, "setting.name")}
					// errorMessage="กรุณากรอก"
				/>

				<FormInput
					label={"ระยะเวลากิจกรรม"}
					required
					renderInput={() => (
						<div className="non-editable d-flex align-items-center">
							<DateTime
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={_get(data, "setting.startDate")}
								onBlur={(value) => handleChange({ name: "setting.startDate", value })}
								id="setting.startDate"
								inputProps={{ readOnly: true }}
							/>
							<span className="mx-2 font-weight-bold">-</span>
							<DateTime
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={_get(data, "setting.endDate")}
								onBlur={(value) => handleChange({ name: "setting.endDate", value })}
								id="setting.endDate"
								inputProps={{ readOnly: true }}
							/>
						</div>
					)}
				/>

				<FormCheckbox
					name={"setting.allows"}
					items={[
						{ label: "Guest", value: "guest" },
						{ label: "Membership", value: "membership" },
					]}
					defaultValues={_get(data, "setting.allows", [])}
					label="ประเภทผู้เล่นที่สามารถเข้าร่วมได้"
					required
					// errorMessage={"กรุณาเลือก"}
					onChange={handleChange}
				/>
				<Divider className="my-4" />
				<h1>อัพโหลดไฟล์</h1>
				<FormUpload
					label={"Banner แคมเปญตอนแชร์"}
					remark="ขนาด 1200x875 px (16:9)"
					required
					name={"setting.shareImg"}
					defaultValue={_get(data, "setting.shareImg", {})}
					onUpload={onUpload(handleChange)}
				/>
			</SectionStyled>
			<Button className="float-right mt-2 non-preview" onClick={() => handleChangeStep(stepNow + 1)} variant="primary">
				ถัดไป
			</Button>
		</ContainerStyled>
	)
}

export default Setting
