import React, { useState, useEffect, useRef, Fragment } from "react"

import { Container, Row, Col, Form, Table } from "react-bootstrap"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import axios from "axios"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

/** Datepicker use */
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"

import ReactDOM from "react-dom"
import Graph from "react-graph-vis"
import Spinner from "react-bootstrap/Spinner"
// import mock_data from "./data/dropoffPercent.json"
/** Number Slider */
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import _ from "lodash"
const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const Journey = (props) => {
	const user = User.getCookieData()
	const [startdate, setStartdate] = useState(moment("2021-06-01").format("YYYY-MM-DD"))
	const [enddate, setEnddate] = useState(moment("2021-06-30").format("YYYY-MM-DD"))
	const minDays = 1
	const [maxDays, setMaxDays] = useState(31)

	const arrChoices = ["gender", "agerange", "device"]
	const [dictChoices, setDictChoices] = useState({})
	const [dictValue, setDictValue] = useState({})

	const [data, setData] = useState({ nodes: [], edges: [], dropoffPercent: undefined })
	const [dataUserReturn, setDataUserReturn] = useState({ nodes: [], edges: [], dropoffPercent: undefined })
	const [loading, setLoading] = useState(false)
	const [loadingReturn, setLoadingReturn] = useState(false)

	const [alertMessages, setAlertMessages] = useState(false)
	const [dropoffPercent, setDropoffPercent] = useState({
		max_step: undefined,
		data: undefined,
	})
	const getDayDiffIncludeStartDate = () => moment(enddate).diff(moment(startdate), "days") + 1
	const marks = () => {
		let arrRange = Array.from({ length: maxDays }, (_, i) => 1 + i)
		let objMarks = {}

		arrRange.map((i) => (objMarks[i] = i))
		return objMarks
	}

	useEffect(() => {
		const check = checkPermission(user.permission, "report_journey_edit")
		async function fetchData() {
			// Get dict choices data which will be used in MemberTopSection / TopContent / SpecialContent
			const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/report/`
			const mainparameter = {
				source: "thairath",
				selectedgroupby: arrChoices,
			}
			let { data: resChoices } = await axios.post(mainurl + "memberIdByCondition", mainparameter, {
				withCredentials: true,
			})
			setDictChoices(resChoices.data)
			console.log(alertMessages)
		}
		if (!check) {
			props.history.push("/")
		}
		fetchData()
	}, [startdate, enddate, data])

	const getDatePicker = (name, defaultvalue) => {
		return (
			<DateTime
				dateFormat="YYYY-MM-DD"
				timeFormat={false}
				defaultValue={defaultvalue}
				onChange={(date) => {
					date = moment(date).format("YYYY-MM-DD")
					name === "startdate" && setStartdate(date)
					name === "enddate" && setEnddate(date)
					setMaxDays(getDayDiffIncludeStartDate())
					console.log(name + " " + date)
				}}
			/>
		)
	}

	const resetSelector = () => {
		//setData(originalData)
		let dictTemp = { ...dictValue }
		Object.keys(dictTemp).map((choice) => {
			dictTemp[choice] = ""
			return true
		})
		setDictValue(dictTemp)
	}

	const changeDictValue = (choice, newvalue) => {
		let dictTemp = { ...dictValue }
		dictTemp[choice] = newvalue
		console.log(dictTemp)
		setDictValue(dictTemp)
	}

	const getDataSnaplogic = async () => {
		setLoading(true)
		setLoadingReturn(true)
		const params = {
			startDate: startdate,
			endDate: enddate,
			gender: dictValue.gender ? dictValue.gender : "",
			agerange: dictValue.agerange ? dictValue.agerange : "",
			device: dictValue.device ? dictValue.device : "",
			visitingdays: [minDays, maxDays],
		}
		//const params = { startDate: "2021-01-01", endDate: "2021-01-31", gender: "male", agerange: "13 - 17", device: "Mobile", visitingdays: [1, 31] }
		let objHeader = {  headers: {'Content-Type': 'application/json'} }
		let { data } = await axios.post("https://cms-member-report-3cor0z2p.an.gateway.dev/v1/cms-user-journey-behavior?key=AIzaSyAgPNySb2caYYJMg-NS9dEb1uiEU6RUJg8", params, objHeader)
		data['nodes'] = makeUniqueList(data['nodes'], 'id')
		let { data: dataUserReturn } = await axios.post("https://cms-member-report-3cor0z2p.an.gateway.dev/v1/cms-user-return?key=AIzaSyAgPNySb2caYYJMg-NS9dEb1uiEU6RUJg8", params, objHeader)
		dataUserReturn['nodes'] = makeUniqueList(dataUserReturn['nodes'], 'id')

		if (data) {
			setDropoffPercent({
				data: _.groupBy(data?.dropoffPercent, "referrer") || {},
				max_step: _.maxBy(data?.dropoffPercent, "step")?.step || 1,
			})
			setData(data)
		} else {
			setAlertMessages(true)
		}
		setLoading(false)

		if (dataUserReturn) {
			/** */
			setDataUserReturn(dataUserReturn)
		} else {
			setAlertMessages(true)
		}
		setLoadingReturn(false)
	}

	const makeUniqueList = (data, idkey) => [
		...new Map(data.map((item) => [item[idkey], item])).values(),
	]

	const options = {
		layout: {
			hierarchical: {
				direction: "LR",
				sortMethod: "directed", // Directed adheres to the to and from data of the edges
				levelSeparation: 300,
				shakeTowards: "roots", // all the roots be lined up at the top and their child nodes as close to their roots as possible
			},
		},
		edges: {
			arrows: "to",
		},
		nodes: {
			shape: "dot",
		},
		width: '100%',
		height: "700px"
	}
	return (
		<>
			{/** Add CSS to enable hover label */}
			<link rel="stylesheet" type="text/css" href="https://unpkg.com/vis-network/styles/vis-network.min.css" />
			<Container>
				<Row className="mb-4">
					<Col className="text-center">
						<span className="mr-4">
							<Form.Label className="mr-2 font-weight-bold">Start date</Form.Label>
							{getDatePicker("startdate", startdate)}
						</span>
						<span>
							<Form.Label className="mr-2 font-weight-bold">End date</Form.Label>
							{getDatePicker("enddate", enddate)}
						</span>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col className="text-center">
						<button className="mr-4" onClick={(e) => resetSelector()}>
							Reset
						</button>
						{arrChoices.map((choice) => (
							<span className="mr-2" key={"span_" + choice}>
								<Form.Label className="mr-2 font-weight-bold">{choice} : </Form.Label>
								{dictChoices && dictChoices[choice] && (
									<select onChange={(e) => changeDictValue(choice, e.target.value)}>
										{dictChoices[choice].map((item) => (
											<option key={choice + "_" + item} value={item == null ? "" : item}>
												{item}
											</option>
										))}
									</select>
								)}
							</span>
						))}
					</Col>
				</Row>
				<Row className="mb-4">
					<Col xs={2}>
						<Form.Label className="mr-2 font-weight-bold">Visiting days : </Form.Label>
					</Col>
					<Col>
						<Range min={minDays} max={maxDays} defaultValue={[minDays, maxDays]} marks={marks()} />
					</Col>
				</Row>
				<Row className="mb-4">
					<Col className="text-center">
						<button className="mr-4" onClick={(e) => getDataSnaplogic()}>
							Submit
						</button>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col className="text-center">
						{loading ? 
						<Spinner animation="border" role="status" variant="success">
							<span className="sr-only">Loading...</span>
						</Spinner> : 
						<Tabs defaultActiveKey="journey" id="journey-result">
							<Tab eventKey="journey" title="Member Journey Pattern" tabClassName="h3">
								{alertMessages ? <p>ไม่พบข้อมูล</p> : 
								<div style={{ border: "1px dashed #d7d8db" }} className="rounded">
									<Graph graph={data} options={options} getNetwork={network => {
										console.log(network)
										network.moveTo({
											position: {x:0, y:-1500}, // Move the center view of graph to the north so that we could see all graph easily
											scale: 0.45
										})
									 } } />
								</div>}
							</Tab>
							<Tab eventKey="return" title="Member Return Pattern" tabClassName="h3">
								{loadingReturn ? <Spinner animation="border" role="status" variant="success">
									<span className="sr-only">Loading...</span>
								</Spinner> : 
								(alertMessages ? <p>ไม่พบข้อมูล</p> :
									<div style={{ border: "1px dashed #d7d8db" }} className="rounded">
										<Graph graph={dataUserReturn} options={options} getNetwork={network => {
											network.moveTo({
												position: {x:-1000, y:-800}, // Move the center view of graph to the northeast so that we could see all graph easily
												scale: 0.5
											})
										 } } />
									</div>)}
							</Tab>
							<Tab eventKey="dropoff" title="Drop-off % Rate" tabClassName="h3">
								{dropoffPercent?.data && (
									<Table striped bordered hover size="sm">
										<thead>
											<tr>
												<th>After page no.</th>
												{_.range(dropoffPercent.max_step - 1).map((step) => (
													<th key={"afterPageNo" + (step+1)}>{step + 1}</th>
												))}
											</tr>
										</thead>
										<tbody>
											{Object.keys(dropoffPercent.data).map((key) => (
												<tr key={"dropoffLv" + key}>
													<td>{key}</td>
													{dropoffPercent.data[key].map((item) => (
														<Fragment key={"fragmentDropoffLv" + key + "Step" + item.step}>
															<td key={"dropoffLv" + key + "Step" + item.step}>{item.dropoffPercent + " %"}</td>
														</Fragment>
													))}
												</tr>
											))}
										</tbody>
									</Table>
								)}
							</Tab>
						</Tabs>}
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Journey
