import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import { ContainerStyled, SectionStyled } from "../../styled"
import useVoteInArticle from "./context"
import Question from "./Question"
import Setting from "./Setting"

const Submit = () => {
	const { data, handleChangeStep, stepNow, handleSave } = useVoteInArticle()

	return (
		<ContainerStyled preview>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "start",
				}}
			>
				<h1>ยืนยัน</h1>
				<Button variant={"success"} onClick={handleSave}>
					บันทึกแคมเปญ
				</Button>
			</div>
			<Setting preview />
			<Question preview />
			<div
				style={{
					display: "flex",
					justifyContent: "end",
					alignItems: "start",
				}}
			>
				<Button className="mr-2" variant="outline-dark" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button variant={"success"} onClick={handleSave}>
					บันทึกแคมเปญ
				</Button>
			</div>
		</ContainerStyled>
	)
}

export default Submit
