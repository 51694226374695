import React, { Fragment, useState } from "react"
import { Card, Col, Image, Row, Badge } from "react-bootstrap"
import moment from "moment"
import BlockUserModal from "./BlockUserModal"
import { useHistory } from "react-router-dom"
import ReportPanel from "./ReportPanel"
import CommentApi from "../../libs/comment"
import AdminApi from "../../libs/admin"
export default ({ data, onChangeDataUser, disabled, view }) => {
	const stickers = require("./sticker.json")
	const history = useHistory()
	const [comment, setComment] = useState(data)

	const handleChangeStatus = async () => {
		const newStatus = comment.hide === -1 ? 10 : -1
		const res = await CommentApi.deleteComment({
			id: comment._id,
			hide: newStatus,
			type: comment.type,
			parentId: comment.parentId,
			contentId: comment.rootId,
		})
		if (res.status === 200) {
			setComment((prev) => ({ ...res.data, ...prev.user })) // use old user data
		} else {
			console.log("error CommentCard", res)
		}
	}
	const handleBlock = async (status) => {
		const res = await AdminApi.updateStatusBlockUser({
			id: comment.user.id,
			status,
		})
		if (res.status === 200) {
			onChangeDataUser(res.data)
		} else {
			console.log("error handleBlock", res)
		}
	}
	return (
		<Card style={{ marginBottom: "10px" }}>
			<Card.Body>
				<Row>
					<Col style={{ minWidth: "55px", maxWidth: "55px" }}>
						<Image src={`${process.env.REACT_APP_API_ROOT}/members/profile/${comment.user.id}`} width="40" height="40" roundedCircle />
					</Col>
					<Col>
						<div>
							{comment.user.name} ({comment.user.email}){" "}
							{!!data.user.isBlock && ( // use data only
								<Badge pill variant="danger">
									Blocked
								</Badge>
							)}
						</div>
						<div>
							<Badge pill variant="secondary">
								{comment.type}
							</Badge>{" "}
							<Badge pill variant="primary">
								like : {comment.like}
							</Badge>{" "}
							<Badge pill variant="success">
								reply : {comment.countReply}
							</Badge>{" "}
							<Badge pill variant="danger">
								report : {comment.countReport}
							</Badge>{" "}
						</div>
						<div>สถานะ : {comment.hide === 10 ? <span style={{ color: "#1cc88a" }}>แสดง</span> : <span style={{ color: "#e74a3b" }}>ไม่แสดง</span>}</div>
					</Col>
				</Row>
				<hr />
				<blockquote className="blockquote mb-0">
					{comment.isSticker ? <img src={`${stickers.path}${comment.text}.${stickers.type}`} alt="sticker"/> : <Fragment>{comment.text}</Fragment>}
				</blockquote>
				{comment.countReport > 0 && (
					<>
						<hr />
						<ReportPanel data={comment} disabled={disabled} view={view} />
					</>
				)}
			</Card.Body>
			<Card.Footer>
				<Row>
					<Col>
						{/* use data only */}
						<BlockUserModal data={data} onBlock={handleBlock} disabled={disabled} view={view} />
						{comment.hide === 10 ? (
							view && <Card.Link
								href="#"
								onClick={(e) => {
									e.preventDefault()
									handleChangeStatus()
									// onHidePost(data)
								}}
								className={ disabled ? 'text-link-disabled' : ''}
							>
								ลบข้อความ
							</Card.Link>
						) : (
							<Card.Link
								href="#"
								onClick={(e) => {
									e.preventDefault()
									handleChangeStatus()
									// onUnHidePost(data)
								}}
							>
								แสดงข้อความ
							</Card.Link>
						)}
						<Card.Link
							href={`/#/comment/topic/${comment.rootId}`}
						>
							ดูบทสนทนา
						</Card.Link>
						{/* {console.log(`${process.env.REACT_APP_WEB_TR_ONLINE}${comment.path}/${comment.rootId}`)} */}
						<Card.Link>
							<a
								href={`${process.env.REACT_APP_WEB_TR_ONLINE}${comment.path}/${comment.rootId}`}
								target="_blank"
								style={{
									textDecoration: "none",
								}}
							>
								ไปหน้าเว็บ
							</a>
						</Card.Link>
					</Col>
					<Col style={{ textAlign: "right" }}>
						<small>
							<strong>{moment(comment.createDate).format("DD/MM/YYYY HH:mm:ss")}</strong> ( edit: {moment(comment.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}{" "}
							)
						</small>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	)
}
