import axios from "axios"
import React, { useEffect, useState } from "react"
import { Card, Col, Image, Row, Badge } from "react-bootstrap"
import moment from "moment"
import BlockUserModal from "./BlockUserModal"
import ReportPanel from "./ReportPanel"
import AdminApi from "../../libs/admin"
import CommentApi from "../../libs/comment"

export default function ReplyCard({ data, onChangeDataUser, userTemp }) {
	const [dataReply, setDataReply] = useState([])
	const [page, setPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(5)
	const [total, setTotal] = useState(0)

	const fetchData = async () => {
		const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/comment/cms/`, {
			params: {
				page: page,
				pageLimit: pageLimit,
				parentId: data._id,
				sort: { createDate: -1 },
			},
		})
		console.log("ReplyCard", response.data.data)
		if (response.data.status === 200) {
			await setDataReply(response.data.data.comments)
			setTotal(response.data.data.total)
		} else {
			// console.log(response.data.data)
		}
	}

	const handleBlock = async (status, user) => {
		const res = await AdminApi.updateStatusBlockUser({
			id: user.id,
			status,
		})
		if (res.status === 200) {
			onChangeDataUser(res.data) // change user comment
			await fetchData()
		} else {
			console.log("error handleBlock", res)
		}
	}

	const handleChangeStatus = async (reply) => {
		const newStatus = reply.hide === -1 ? 10 : -1
		const res = await CommentApi.deleteComment({
			id: reply._id,
			hide: newStatus,
			type: reply.type,
			parentId: reply.parentId,
			contentId: reply.rootId,
		})
		if (res.status === 200) {
			const tempArr = dataReply.map((item) => {
				return res.data._id === item._id ? { ...res.data, user: reply.user } : item
			})
			setDataReply([...tempArr])
		} else {
			console.log("error CommentCard", res)
		}
	}

	const getUserChange = (reply) => {
		// fix when block user from Topic then ReplyCard is not change
		return userTemp.id === reply.user.id ? userTemp : reply.user
	}

	useEffect(() => {
		fetchData()
		return () => {
			setDataReply([])
		}
	}, [page, pageLimit])

	return (
		<>
			{dataReply.map((reply) => (
				<Card key={reply._id} style={{ marginBottom: "10px", marginLeft: "50px" }}>
					<Card.Body>
						<div style={{ width: "55px", height: "55px", float: "left" }}>
							<Image src={`${process.env.REACT_APP_API_ROOT}/members/profile/${reply.user.id}`} width="40" height="40" roundedCircle />
						</div>
						<div>
							{reply.user.name} ({reply.user.email}){" "}
							{getUserChange(reply).isBlock && (
								<Badge pill variant="danger">
									Blocked
								</Badge>
							)}
						</div>
						<div>
							<Badge pill variant="secondary">
								{reply.type}
							</Badge>{" "}
							<Badge pill variant="primary">
								like : {reply.like}
							</Badge>{" "}
							<Badge pill variant="success">
								reply : {reply.countReply}
							</Badge>{" "}
							<Badge pill variant="danger">
								report : {reply.countReport}
							</Badge>{" "}
						</div>
						<div>สถานะ : {reply.hide === 10 ? <span style={{ color: "#1cc88a" }}>แสดง</span> : <span style={{ color: "#e74a3b" }}>ไม่แสดง</span>}</div>
						<hr />
						<blockquote className="blockquote mb-0">{reply.text}</blockquote>
						{reply.countReport > 0 && (
							<ReportPanel>
								<hr />
								<ReportPanel data={reply} />
							</ReportPanel>
						)}
					</Card.Body>
					<Card.Footer>
						<Row>
							<Col>
								<BlockUserModal
									data={{ ...reply, user: getUserChange(reply) }}
									onBlock={(status) => {
										handleBlock(status, reply.user)
									}}
								/>
								{reply.hide === 10 ? (
									<Card.Link
										href="#"
										onClick={(e) => {
											e.preventDefault()
											handleChangeStatus(reply)
										}}
									>
										ลบข้อความ
									</Card.Link>
								) : (
									<Card.Link
										href="#"
										onClick={(e) => {
											e.preventDefault()
											handleChangeStatus(reply)
										}}
									>
										แสดงข้อความ
									</Card.Link>
								)}
								<Card.Link href={`${process.env.REACT_APP_WEB_TR_ONLINE}/${reply.path}/${reply.rootId}`} target="_blank">
									ไปหน้าเว็บ
								</Card.Link>
							</Col>
							<Col style={{ textAlign: "right" }}>
								<small>
									<strong>{moment(reply.createDate).format("DD/MM/YYYY HH:mm:ss")}</strong> ( edit: {moment(reply.lastUpdate).format("DD/MM/YYYY HH:mm:ss")} )
								</small>
							</Col>
						</Row>
					</Card.Footer>
				</Card>
			))}
		</>
	)
}
