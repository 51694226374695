import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import User from "../../../libs/admin"
import { checkPermission } from "../../../helper/functions"

const Forms = (props) => {
	let { id } = useParams()
	const admin = User.getCookieData()
    const [transaction_data , setTransactionData] = useState([]) 
    
	
	useEffect(() => {
		const checked = checkPermission(admin.permission, "subscription_package_edit")

		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/form/${id}?v=` + Math.random() * 1000, { withCredentials: true })
                setTransactionData(data.data[0])
            }
			fetchData()
		} else {
			props.history.push('/')
		}
	}, [id])

   
	return (
		<>
			
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Subscription Transaction : {id}</h6>
				</div>

                
                
                <div className="card-body">
                    <br />
                    <div className="row">
						<div className="col-2">ชื่อ Package :</div>
						<div className="col-6">
							{transaction_data.package_name} PLAN
						</div>
						<div className="col-4" />
					</div>

                    <br />

                    <div className="row">
						<div className="col-2">ชื่อผู้ใช้ :</div>
						<div className="col-6">
                        {transaction_data.customer_name}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">ช่องทางการชำระเงิน :</div>
						<div className="col-6">
                        {transaction_data.payment_method}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">รูปแบบการทำรายการ :</div>
						<div className="col-6">
                        {transaction_data.action}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">ประเภทการทำรายการ :</div>
						<div className="col-6">
                        {transaction_data.type}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">สถานะ :</div>
						<div className="col-6">
                        {transaction_data.status}
						</div>
						<div className="col-4" />
					</div>


                    {/* truemoney data */}
                    {transaction_data.payment_method === "truemoney" ? 
                        <>
                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงิน :</div>
						<div className="col-6">
                        {transaction_data.data.data.amount / 100} {transaction_data.data.data.currency}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงินสุทธิ :</div>
						<div className="col-1">
                        {transaction_data.data.data.net / 100} บาท
						</div>
                        <div className="col-2">ค่าธรรมเนียม :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee / 100} บาท
						</div>
                        <div className="col-2">ค่าภาษี :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee_vat / 100} บาท
						</div>
                    </div>

                    <br/>

                    <div className="row">
						<div className="col-2">วันที่ทำรายการ :</div>
						<div className="col-6">
                        <Moment format="DD/MM/YYYY H:mm">{transaction_data.data.created_at}</Moment>
						</div>
						<div className="col-4" />
					</div>

                    <br/>
                        </>
                    : ""}

                    {/* creditcard data */}
                    {transaction_data.payment_method === "creditcard" ? 
                        <>
                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงิน :</div>
						<div className="col-6">
                        {transaction_data.data.data.amount / 100} {transaction_data.data.data.currency}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงินสุทธิ :</div>
						<div className="col-1">
                        {transaction_data.data.data.net / 100} บาท
						</div>
                        <div className="col-2">ค่าธรรมเนียม :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee / 100} บาท
						</div>
                        <div className="col-2">ค่าภาษี :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee_vat / 100} บาท
						</div>
                    </div>

                    <br/>

                    <div className="row">
						<div className="col-2">วันที่ทำรายการ :</div>
						<div className="col-4">
                        <Moment format="DD/MM/YYYY H:mm">{transaction_data.data.created_at}</Moment>
						</div>
						<div className="col-2">วันที่ชำระเงิน :</div>
						<div className="col-4">
                        <Moment format="DD/MM/YYYY H:mm">{transaction_data.data.data.paid_at}</Moment>
						</div>
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">ประเภทบัตรเครดิต :</div>
						<div className="col-2">
                            {transaction_data.data.data.card.brand   }
						</div>
						<div className="col-2">เลขลงท้ายบัตรเครดิต :</div>
						<div className="col-2">
                        {transaction_data.data.data.card.last_digits}
						</div>
                        <div className="col-2">ชื่อผู้ถือบัตร :</div>
						<div className="col-2">
                        {transaction_data.data.data.card.name}
						</div>
					</div>

                    

                    <br/>
                        </>
                    : ""}

                    {/* mobile banking data */}
                    {transaction_data.payment_method === "mobile_banking_scb" ? 
                        <>
                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงิน :</div>
						<div className="col-6">
                        {transaction_data.data.data.amount / 100} {transaction_data.data.data.currency}
						</div>
						<div className="col-4" />
					</div>

                    <br/>

                    <div className="row">
						<div className="col-2">จำนวนเงินสุทธิ :</div>
						<div className="col-1">
                        {transaction_data.data.data.net / 100} บาท
						</div>
                        <div className="col-2">ค่าธรรมเนียม :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee / 100} บาท
						</div>
                        <div className="col-2">ค่าภาษี :</div>
						<div className="col-1">
                        {transaction_data.data.data.fee_vat / 100} บาท
						</div>
                    </div>

                    <br/>

                    <div className="row">
						<div className="col-2">วันที่ทำรายการ :</div>
						<div className="col-6">
                        <Moment format="DD/MM/YYYY H:mm">{transaction_data.data.created_at}</Moment>
						</div>
						<div className="col-4" />
					</div>

                    <br/>
                        </>
                    : ""}

                </div>

                

				
			</div>

			
		</>
	)
}
export default Forms
