import React from "react"

export const Active = ({
	label,
	id,
	name,
	value,
	onChange,
	maxLength,
	labelCol = "col-3 row-label",
	compCol = "col-4",
	require,
	spaceCol = "col-0",
	disabled,
    onClick
}) => {
	return (
		<div className="row">
			<div className={spaceCol} />
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={spaceCol} />
			<div className={compCol}>
				<input
					type="button"
					style={{ background: value ? "#17B19E" : "#FD5329", color: "#FFFFFF" }}
					value={value ? 'on' : 'off'}
					id="onoff"
					onClick={onClick}
				/>
			</div>
		</div>
	)
}
