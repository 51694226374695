import React, { useState } from "react"
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/css/froala_style.css"
import "froala-editor/js/plugins.pkgd.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/image_manager.min.js"
import "froala-editor/js/plugins/paragraph_style.min.js"
import FroalaEditorComponent from "react-froala-wysiwyg"

export default function Editor({ placeholderText = "รายละเอียด", onChange, defaultValue }) {
	const [text, setText] = useState(defaultValue)
	const handleModelChange = (model) => {
		setText(model)
		if (onChange) {
			onChange(model)
		}
	}

	return (
		<div className="non-editable">
			<FroalaEditorComponent
				tag="textarea"
				onModelChange={handleModelChange}
				model={text}
				config={{
					key: process.env.REACT_APP_FROALA_KEY,
					pastePlain: true,
					placeholderText: placeholderText,
					charCounterCount: false,
					attribution: false,
					linkAlwaysBlank: true,
					heightMin: 180,
					heightMax: 200,
					useClasses: true,
					toolbarSticky: false,
					pluginsEnabled: [
						"align",
						"codeBeautifier",
						"codeView",
						"colors",
						"fontSize",
						"fullscreen",
						"inlineStyle",
						"link",
						"lists",
						"paragraphFormat",
						"paragraphStyle",
						"table",
						"url",
						"insertHR",
					],

					toolbarButtons: {
						moreText: {
							buttonsVisible: 4,
							buttons: [
								"bold",
								"italic",
								"underline",
								"fontSize",
								// more
								"textColor",
								"backgroundColor",
								"strikeThrough",
								"subscript",
								"superscript",
								// 'fontFamily',
								"inlineClass",
								"inlineStyle",
								"clearFormatting",
							],
						},
						moreParagraph: {
							buttonsVisible: 4,
							buttons: [
								"paragraphStyle",
								"paragraphFormat",
								"formatUL",
								"insertTable",
								"alignLeft",
								"alignCenter",
								"alignRight",
								"formatOL",
								"formatOLSimple",
								"alignJustify",
								"lineHeight",
								"outdent",
								"indent",
								// 'quote',
								"insertHR",
							],
						},
						moreRich: {
							buttonsVisible: 4,
							buttons: ["insertLink"],
						},
						moreMisc: {
							buttonsVisible: 4,
							buttons: [
								"fullscreen",
								"clearFormatting",
								"undo",
								"redo",
								"html",
								// more
								// 'print',
								// 'getPDF',
								"spellChecker",
								// 'selectAll',
								"help",
							],
							align: "right",
						},
					},
					htmlRemoveTags: [""],
				}}
			/>
		</div>
	)
}
