import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { Button, Form, Col, Table, Card } from "react-bootstrap"
import { useFormik } from "formik"
import Alert from "react-bootstrap/Alert"
import User from "../../../libs/admin"
import { json } from "body-parser"
import { checkPermission } from "../../../helper/functions"

const Forms = (props) => {
	let { id } = useParams()
	const admin = User.getCookieData()

    const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)

    const [disableSaveBtn , setStateDisableBtnSave] = useState(true)

    const [packageName , setStatePackageName] = useState("")
    const [packageDescription , setStatePackageDescription] = useState("") 
    const [packageDays , setStatePackageDays] = useState("")
    const [packageTrial , setStatePackageTrial] = useState("")
    const [packagePrice , setStatePackagePrice] = useState("")
    const [packageActive , setStatePackageActive] = useState("On")
	const [packageType , setStatePackageType] = useState(null)

    
	
	const myPermission = () => {
        setStateDisableBtnSave(checkPermission(admin.permission, "subscription_package_edit"))
    }

	useEffect(() => {
		const checked = checkPermission(admin.permission, "subscription_package_edit")

		if (checked) {
			myPermission()
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/package/form/${id}?v=` + Math.random() * 1000, { withCredentials: true })
                setStatePackageName(data.data.name)
                setStatePackageDescription(data.data.description)
                setStatePackageDays(data.data.day)
                setStatePackageTrial(data.data.freeTrial)
                setStatePackagePrice(data.data.price)
                setStatePackageActive(data.data.active === false ? "Off" : "On")
				setStatePackageType(data.data.type)
            }
			fetchData()
		} else {
			props.history.push('/')
		}
	}, [id])

    const onoff = value => {
         if(value == "Off"){
             setStatePackageActive("On")
         }else{
             setStatePackageActive("Off")
         }
    }

    const savePackage = async () => {

        let active
		let package_type

        if(packageActive === "On"){
            active = true
        }else if (packageActive === "Off"){
            active = false
        }
		 
		
		if(!packageType){
			package_type = "day"
		}else {
			package_type = packageType
		}



        const obj = {
            package_name : packageName ,
            package_description : packageDescription,
            package_days : packageDays,
			package_type : package_type,
            package_trial : packageTrial ,
            package_price : packagePrice,
            package_active  : active
        }

        const data = await User.sendupdatePackage(obj, id)
        if (data.status === 200) {
            setDataResult(data.message)
            setShow(true)
            setColor("success")
            setTimeout(() => {
                setShow(false)
            }, 3000)
        } else {
            setDataResult(data.message)
            setShow(true)
            setColor("danger")
            setTimeout(() => {
                setShow(false)
            }, 3000)
        }

    }

	

	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Subscription Package</h6>
				</div>

				<div className="card-body">
					
                    <br />

					{/* Package Name */}
					<div className="row">
						<div className="col-2">ชื่อ Package :</div>
						<div className="col-6">
							<input
								className="form-control"
								id="TaPackageName"
								style={{ resize: "none" }}
								maxLength="300"
								onChange={(e) => setStatePackageName(e.target.value)}
								value={packageName}
							></input>
						</div>
						<div className="col-4" />
					</div>
					{/* Package Name */}

					<br />

                    {/* Package Description */}
					<div className="row">
						<div className="col-2">รายละเอียด Package :</div>
						<div className="col-6">
							<input
								className="form-control"
								id="TaPackageDescription"
								style={{ resize: "none" }}
								maxLength="300"
								onChange={(e) => setStatePackageDescription(e.target.value)}
								value={packageDescription}
							></input>
						</div>
						<div className="col-4" />
					</div>
					{/* Package Description*/}

					<br />

                    {/* Package Day(s) */}
					<div className="row">
						<div className="col-2">จำนวนวัน :</div>
						<div className="col-6">
							<input
								className="form-control"
								id="TaPackageDays"
								style={{ resize: "none" }}
								maxLength="300"
								onChange={(e) => setStatePackageDays(e.target.value)}
								value={packageDays}
							></input>
						</div>
						<div className="col-4" />
					</div>
					{/* Package Day(s) */}

					

					<br />

					{/* Package Type */}
					<div className="row">
						<div className="col-2">ประเภท :</div>
						<div className="col-6">
							<select className="form-control" value={packageType || "day"} onChange={(e) => setStatePackageType(e.target.value)} id="SStatus" >
								<option value="day">day</option>
								<option value="month">month</option>
							</select>
						</div>
						<div className="col-4" />
					</div>
					{/* Package Type */}

					

					<br />

                    {/* Package Free trial Day(s) */}
					<div className="row">
						<div className="col-2">จำนวนวันทดลอง :</div>
						<div className="col-6">
							<input
								className="form-control"
								id="TaPackageFreeTrialDays"
								style={{ resize: "none" }}
								onChange={(e) => setStatePackageTrial(e.target.value)}
								value={packageTrial}
							></input>
						</div>
						<div className="col-2">วัน</div>
						<div className="col-4" />
					</div>
					{/* Package Free trial Day(s) */}

                    <br />

                    {/* Package price */}
					<div className="row">
						<div className="col-2">ราคา :</div>
						<div className="col-6">
							<input
								className="form-control"
								id="TaPackagePrice"
								style={{ resize: "none" }}
								onChange={(e) => setStatePackagePrice(e.target.value)}
								value={packagePrice}
							></input>
						</div>
						<div className="col-4" />
					</div>
					{/* Package price */}

                    <br />

                    {/* Package active */}
					<div className="row">
						<div className="col-2">active :</div>
						<div className="col-6">
                            <input type="button" 
                                style={{background : (packageActive === "On" ? "#17B19E" : "#FD5329" ) , color : "#FFFFFF"}} 
                                value={packageActive} 
                                id="onoff" 
                                onClick={(e) => onoff(e.target.value)}></input>
						</div>
						<div className="col-4" />
					</div>
					{/* Package active */}

                    <br />


					{/* Submit */}
                    {disableSaveBtn ? 
                    <div className="row">
						<div className="col-2" />
						<div className="col-6">
							<button className="btn btn-success btn-icon-split" onClick={() => savePackage()}  >
								<span className="icon text-white-50">
									<i className="fas fa-check"></i>
								</span>
								<span className="text">Save</span>
							</button>
						</div>
					</div> :""}

					
					{/* Submit */}
				</div>
			</div>

			
		</>
	)
}
export default Forms
