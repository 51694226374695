export const stepVoteId = [
    {
        id: 1,
        index: 1,
        name: 'Member'
    },
    {
        id: 2,
        index: 2,
        name: 'Setup'
        
    },
    {
        id: 3,
        index: 3,
        name: 'Target'
    },
    {
        id: 4,
        index: 4,
        name: 'Detail'
    },
    {
        id: 5,
        index: 5,
        name: 'Award',
        position: 1
    },
    {
        id: 6,
        index: 6,
        name: 'Announcement',
        position: 1
    },
    {
        id: 7,
        index: 7,
        name: 'Submit'
    }
]

export const stepQuizId = [
    {
        id: 1,
        index: 1,
        name: 'Member'
    },
    {
        id: 2,
        index: 2,
        name: 'Setup'
        
    },
    {
        id: 3,
        index: 3,
        name: 'Target'
    },
    {
        id: 4,
        index: 4,
        name: 'Detail'
    },
    {
        id: 5,
        index: 5,
        name: 'Award',
        position: 1
    },
    {
        id: 6,
        index: 6,
        name: 'Announcement',
        position: 1
    },
    {
        id: 7,
        index: 7,
        name: 'Submit'
    }
]

export const stepScoreId = [
    {
        id: 1,
        index: 1,
        name: 'Member'
    },
    {
        id: 2,
        index: 2,
        name: 'Setup'
        
    },
    {
        id: 3,
        index: 3,
        name: 'Target'
    },
    {
        id: 4,
        index: 4,
        name: 'Detail'
    },
    {
        id: 5,
        index: 5,
        name: 'Award',
        position: 1
    },
    {
        id: 6,
        index: 6,
        name: 'Announcement',
        position: 1
    },
    {
        id: 7,
        index: 7,
        name: 'Submit'
    }
]