import _axios from "axios"
const axios = _axios.create({
	baseURL: process.env.REACT_APP_API_ROOT,
	withCredentials: true,
})

export const getUser = async (email = "") => {
	const response = await axios.post(`/admin/member/get`, { email: email })
	return response
}

export const deleteUser = async (email = "") => {
	const response = await axios.post(`/admin/member/delete`, { email: email })
	return response
}

export const activedUser = async (email = "") => {
	const response = await axios.post(`/admin/member/actived`, { email: email })
	return response
}

export const genCodeUser = async (email = "", from) => {
	const response = await axios.post(`/admin/member/gencode`, { email, from })
	return response
}
