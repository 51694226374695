import React, { useState, useEffect, Fragment } from "react"
import axios from "axios"
import { NumberFormatter } from '../../dashboard/Formatter'
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const MemberCount = (arrSelectedGroupby, startdate, enddate) => {
    const [data, setData] = useState([])
    
	useEffect(() => {
        async function fetchData() {
            const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/report/`
			const mainparameter = {
                'selectedgroupby': arrSelectedGroupby,
                'startdate': startdate,
                'enddate': enddate
            }

            setData([])

            let { data: reqObj } = await axios.post(mainurl + 'memberCounts', mainparameter, { withCredentials: true })
            console.log(reqObj.data.data)
            setData(reqObj.data.data)
        }
        fetchData()
    }, [arrSelectedGroupby, startdate, enddate])

    return (
        <Fragment>
            <h4 className="font-weight-bold">Number of Members</h4>
            <div style={{textAlign: "center"}}>
                <BootstrapTable data={data} className="table table-striped" keyField="ts" 
                    options={{noDataText : "Please wait for data..."}} exportCSV pagination>
                    <TableHeaderColumn dataField="gender" width="100" hidden={!arrSelectedGroupby.includes("gender")}>
                        Gender
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="agerange" width="100" hidden={!arrSelectedGroupby.includes("agerange")}>
                        Age range
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="counttotal" width="100" dataAlign="right" dataFormat={NumberFormatter}>
                        #Total members
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="countactive" width="100" dataAlign="right" dataFormat={NumberFormatter}>
                        #Active members
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="countnew" width="100" dataAlign="right" dataFormat={NumberFormatter}>
                        #New members
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Fragment>
    )

}
export default MemberCount