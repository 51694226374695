import _axios from "axios"
const axios = _axios.create({
	baseURL: process.env.REACT_APP_API_ROOT,
	withCredentials: true,
})

export const getEurofootballAll = async (options) => {
	const { page, limit, sort, search } = options
	let url = "/campaign-new/euro-football/cms/all?"
	if (page) url += `page=${page}&`
	if (limit) url += `limit=${limit}&`
	if (sort) url += `sort=${JSON.stringify(sort)}&`
	if (search) {
		Object.entries(search).forEach(([key, value], index) => {
			if (value) {
				url += `${key}=${value}&`
			}
		})
	}

	const response = await axios.get(url)
	return response
}

export const getEurofootballById = async (id) => {
	const response = await axios.get(`/campaign-new/euro-football/cms/${id}`)
	return response
}

export const addEurofootballCampaign = async (body) => {
	return await axios.post(`/campaign-new/euro-football/cms/create`, body)
}

export const updateEurofootballCampaign = async (id, body) => {
	return await axios.put(`/campaign-new/euro-football/cms/update/${id}`, body)
}

export const deleteEurofootballCampaign = async (id) => {
	return await axios.delete(`/campaign-new/euro-football/cms/delete/${id}`)
}

export const onUploadEuroFootball = async (formData, options) => {
	const response = await axios.post(`/campaign-new/euro-football/cms/upload`, formData, options)
	return response
}

export const calculateScoreEuroFootball = async (id) => {
	const response = await axios.post(`/campaign-new/euro-football/cms/${id}/calculate`)
	return response
}

export const getDashboardEuroFootball = async (id, options) => {
	let url = `/campaign-new/euro-football/cms/${id}/dashboard`
	if (options) {
		Object.entries(options).forEach(([key, value], index) => {
			if (index === 0) {
				url += `?${key}=${value}`
			} else {
				url += `&${key}=${value}`
			}
		})
	}
	const response = await axios.get(url)
	return response
}

export const getExportEuroFootball = (id) => {
	const url = `${process.env.REACT_APP_API_ROOT}/campaign-new/euro-football/cms/${id}/export`
	return url
}
