import React, { useEffect, useRef, useState } from "react"
import { Alert } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import { campaignController } from "../../Services"
import { CardCampaign, CardCampaignContainer, CardImageContainer, CardImageWrapper, InputWrapper } from "./style"
import { v4 as uuidv4 } from "uuid"
import DragAndDrop from "../../Widgets/DragAndDrop/DragAndDrop"
import DateTime from "@nateradebaugh/react-datetime"

function Detail({ path }) {
	const refFile = useRef(null)

	const [data, setData] = useState([])

	const getDefaultData = () => ({
		_id: uuidv4(),
		desktopImage: null,
		mobileImage: null,
		link: null,
		title: null,
	})

	const [browseTarget, setBrowseTarget] = useState({
		name: "",
		index: -1,
	})
	const [alert, setAlert] = useState({
		isSuccess: false,
		isShow: false,
		message: "",
	})

	const fetchData = async () => {
		const res = await campaignController().getPageSettingByPath(path)
		if (res && res.status === 200) {
			const _data = res.data?.data?.data || [getDefaultData()]
			if (_data) {
				setData(_data)
			}
		}
	}
	useEffect(() => {
		fetchData()
		return () => {}
	}, [path])

	const handleChangeInput = (e, id) => {
		const tempArr = [...data]
		let index = tempArr.findIndex((f) => f._id === id)
		tempArr[index][e.target.name] = e.target.value
		setData(tempArr)
	}

	const handleClickAdd = () => {
		setData((prev) => [...prev, getDefaultData()])
	}

	const handleClickDelete = (id) => {
		const tempArr = data.filter((f) => f._id !== id)
		setData([...tempArr])
	}

	const handleClickSave = async () => {
		const payload = {
			path,
			data,
		}
		const res = await campaignController().addPageSetting(payload)
		if (res && res.status === 200) {
			setAlert({
				isShow: true,
				isSuccess: true,
				message: "บันทึกข้อมูลสำเร็จ!",
			})
			setTimeout(() => {
				setAlert((prev) => ({
					...prev,
					isShow: false,
				}))
			}, 3000)
		} else {
			setAlert({
				isShow: true,
				isSuccess: false,
				message: "ไม่สามารถบันทึกข้อมูลได้!",
			})
		}
	}

	const handleClickBrowse = (name, index) => {
		setBrowseTarget({
			name,
			index,
		})
		refFile.current.click()
	}

	const uploadFile = async (file) => {
		if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
			alert("กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น")
			return
		}
		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const res = await campaignController().uploadFile(target_file, "banner_image")
		if (res.status === 200) {
			return res.data.response
		} else {
			return null
		}
	}

	const handleChangeFile = async (event) => {
		const fileData = event.target.files[0]
		if (!fileData) return

		const fileDataUploaded = await uploadFile(fileData)

		const tempData = [...data]
		let tempDataObj = tempData[browseTarget.index]
		tempDataObj[browseTarget.name] = fileDataUploaded
		setData(tempData)
	}

	const handleDrop = async (event, name, index) => {
		event.preventDefault()
		const fileData = event.dataTransfer.files[0]
		if (!fileData) return

		const fileDataUploaded = await uploadFile(fileData)

		const tempData = [...data]
		let tempDataObj = tempData[index]
		tempDataObj[name] = fileDataUploaded
		setData(tempData)
	}

	const overrideEventDefaults = (event) => {
		event.preventDefault()
	}

	const sameEvent = {
		onDragOver: overrideEventDefaults,
		onDragEnter: overrideEventDefaults,
		onDragLeave: overrideEventDefaults,
	}
	return (
		<div
			style={{
				borderTop: "1px solid rgba(0, 0, 0, 0.12)",
			}}
		>
			{alert.isShow && (
				<Alert
					className="mt-2"
					variant={alert.isSuccess ? "success" : "danger"}
					onClose={() => setAlert((prev) => ({ ...prev, isShow: false }))}
					dismissible
				>
					<p>{alert.message}</p>
				</Alert>
			)}
			<button className="btn btn-success mt-3" onClick={handleClickSave}>
				บันทึก
			</button>
			<CardCampaignContainer>
				<DragAndDrop
					data={data}
					keyColumn={"_id"}
					onChange={(newData) => setData(newData)}
					renderData={(item, index) => (
						<CardCampaign key={item._id}>
							<InputWrapper>
								<input
									defaultValue={item.title}
									name="title"
									onChange={(e) => handleChangeInput(e, item._id)}
									type="text"
									className="form-control"
									placeholder="Title"
								/>
								<input
									defaultValue={item.link}
									name="link"
									onChange={(e) => handleChangeInput(e, item._id)}
									type="text"
									className="form-control"
									placeholder="Link"
								/>
								<DateTime
									className="datepicker"
									dateFormat={"DD/MM/YYYY"}
									timeFormat={true}
									name="startDate"
									defaultValue={item.startDate}
									onBlur={(value) => handleChangeInput({ target: { name: "startDate", value } }, item._id)}
									inputProps={{
										placeholder: "วันที่เริ่มการแสดงผล",
										readOnly: true
									}}
								/>
								<span>-</span>
								<DateTime
									className="datepicker"
									dateFormat={"DD/MM/YYYY"}
									timeFormat={true}
									name="endDate"
									defaultValue={item.endDate}
									onBlur={(value) => {
										handleChangeInput({ target: { name: "endDate", value } }, item._id)
									}}
									inputProps={{
										placeholder: "วันที่สิ้นสุดการแสดงผล",
										readOnly: true
									}}
								/>
								<button className="btn btn-danger" onClick={() => handleClickDelete(item._id)}>
									<i className="close icon text-white " />
								</button>
							</InputWrapper>
							<CardImageContainer>
								<CardImageWrapper
									{...sameEvent}
									onDrop={(e) => handleDrop(e, "desktopImage", index)}
									onClick={() => handleClickBrowse("desktopImage", index)}
								>
									{item["desktopImage"] ? (
										<img alt={item["desktopImage"]?.name} src={item["desktopImage"]?.url} />
									) : (
										<span>Please Select or Drop Desktop Image (5:1)</span>
									)}
								</CardImageWrapper>
								<CardImageWrapper {...sameEvent} onDrop={(e) => handleDrop(e, "mobileImage", index)} onClick={() => handleClickBrowse("mobileImage", index)}>
									{item["mobileImage"] ? (
										<img alt={item["mobileImage"]?.name} src={item["mobileImage"]?.url} />
									) : (
										<span>Please Select or Drop Mobile Image (4:3)</span>
									)}
								</CardImageWrapper>
							</CardImageContainer>
						</CardCampaign>
					)}
				/>
			</CardCampaignContainer>
			<button onClick={handleClickAdd} className="btn btn-outline-primary mt-3 form-control">
				<i className="fa fa-plus" />
			</button>
			<input ref={refFile} onChange={handleChangeFile} type="file" style={{ display: "none" }} />
		</div>
	)
}

export default Detail
