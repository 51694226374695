import React, { useEffect, useState } from "react"
import { Button, Form, Col } from "react-bootstrap"
import { useFormik } from "formik"
import User from "../../libs/admin"


const G2fa = () => {
    const user = User.getCookieData()
    const g2fa_stauts = User.getOnPageG2FA()
    //const [isLoading, setLoading] = useState(false)
    const [loginResult, setLoginResult] = useState(null)
    const [cooldown, setCooldown] = useState(120)
    const [number, setNumber] = useState('')
    const [error, setError] = useState(false)

    useEffect(() => {
        if(g2fa_stauts){
            window.location.href = '/'
        }

        if(user){
            sendMailG2FA(user.email)
            User.saveOnPageG2FA()
        }
        // User.saveRedirect()
    }, [])

    const sendMailG2FA = async (email) => {
       await  User.sendG2FA(email)
    }

    useEffect(() => {
      if(cooldown > 0){
        const count = setInterval(() => {
            const x = cooldown - 1
            setCooldown(x)  
           }, 1000);

           return () => clearInterval(count);
      }

      if(cooldown === 0){
        User.deleteCookie()
        window.location.href = '/'
      }
       
    }, [cooldown])

    const onSubmit = async () => {
        const result = await User.checkG2fa(user.email, number)
        const { data: { check } } = result
        if(check){
            const status = User.saveCookieG2FA()
            if(status){
                window.location.href = '/'
            }
        }else{
            setError(!check)
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg mt-10">

                        <div className="card-body p-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="form-container">
                                            <div className="form-style">
                                                <div className="form-heading">
                                                    <h2>ยืนยันรหัสทางอีเมล</h2>
                                                    <p>ระบบได้ส่งรหัสยืนยัน 6 หลักไปที่อีเมลของคุณแล้ว<br/>กรุณาตรวจสอบอีเมลและนำรหัสมากรอกในช่องด้านล่างเพื่อยืนยันตัวตน</p>
                                                </div>
                                                <div className="field">
                                                    <label className="label-form">รหัสยืนยัน</label>
                                                    <input type="text" maxLength={6} placeholder="พิมพ์รหัสยืนยัน 6 หลักของคุณที่นี่" className="form-control"  onChange={e => setNumber(e.target.value.replace(/[^0-9.]/g, ''))}  value={number} />
                                                    {error && <p className="text-danger">รหัสยืนยันไม่ถูกต้อง โปรดเช็คอีกครั้ง</p>}
                                                </div>
                                                <div className="field-button">
                                                    <Button className="button-outline"  onClick={() => { 
                                                        setNumber('')
                                                        setError(false) 
                                                    }}>เคลียร์</Button>
                                                    <Button onClick={() => onSubmit()}>เข้าสู่ระบบ</Button>
                                                </div>
                                                <p className="timeout">รหัสจะหมดอายุใน <span>{cooldown}</span> วินาที</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End New Design */}
                                </div>
                            </div>
                        </div>

                         
                    </div>
                </div>
            </div>
        </div>
    )
}
export default G2fa
