import React, { useState, useEffect, useRef, Fragment } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import "semantic-ui-css/semantic.min.css"
import { Pagination, Select } from "semantic-ui-react"
import "../../assets/css/paginationlist.css"
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"
import Spinner from "react-bootstrap/Spinner"
import { listactivities } from "../../libs/activities"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")
	const [lstActivities, setLstActivities] = useState([])
	const [createDateGte, setCreateDateGte] = useState("")
	const [createDateLt, setCreateDateLt] = useState("")
	const [birthdayGte, setBirthdayGte] = useState("")
	const [birthdayLt, setBirthdayLt] = useState("")
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(false)

	const [hasPassword, setHasPassword] = useState("")
	const [facebook, setFacebook] = useState("")
	const [google, setGoogle] = useState("")
	const [searchAll, setSearchAll] = useState("")
	const [huawei, setHuawei] = useState("")
	const [isActivate, setIsActivate] = useState("")
	const [orderBy, setOrderBy] = useState("")
	const [activity, setActivity] = useState("")
	const [register, setRegister] = useState("")
	const [advanceSearch, setAdvanceSearch] = useState("")
	const [newsLetterTrol, setNewsLetterTrol] = useState("")
	const [newsLetterTrPlus, setNewsLetterTrPlus] = useState("")
	const [socialRegister, setSocialRegister] = useState("")
	const [line, setLine] = useState("")

	const [gender, setGender] = useState("")
	const [isSearch, setIsSearch] = useState(false)
	const [loginDateGte, setLoginDateGte] = useState("")
	const [loginDateLt, setLoginDateLt] = useState("")
	const inputRef = useRef(null)
	const iconCheck = "<i class='fas fa-check-circle'></i>"
	const [sensitive, setSensitive] = useState(true)

	useEffect(() => {
		const checked = checkPermission(user.permission, ["member_view", "member_edit"], "or")
		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/list?v=` + Math.random() * 1000, { withCredentials: true })
				setTotalCount(data.totalCount)
				setallPage(data.pages)
				setData(data.data)
				setSensitive(checkPermission(user.permission, ["member_sensitive_data_view"]))
				// const {
				// 	data: { data: lst },
				// } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/dashboard/listAllActivities?v=` + Math.random() * 1000, { withCredentials: true })
				let options = [{ text: "Choose", value: "all" }]
				setLstActivities([...options, ...listactivities.map((activity) => ({ text: activity, value: activity }))])
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const carlendarCreateGte = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createDateLt)
		var compare = d1.getTime() > d2.getTime()
		if (compare) {
			setCreateDateGte(new Date())
		} else {
			setCreateDateGte(date)
		}
	}
	const carlendarCreateLt = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createDateGte)
		var compare = d1.getTime() < d2.getTime()
		console.log(compare)
		if (compare) {
			setCreateDateLt(new Date())
		} else {
			setCreateDateLt(date)
		}
	}
	const carlendarLoginGte = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(loginDateLt)
		var compare = d1.getTime() > d2.getTime()
		if (compare) {
			setLoginDateGte(new Date())
		} else {
			setLoginDateGte(date)
		}
	}
	const carlendarLoginLt = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(loginDateGte)
		var compare = d1.getTime() < d2.getTime()
		console.log(compare)
		if (compare) {
			setLoginDateLt(new Date())
		} else {
			setLoginDateLt(date)
		}
	}
	const carlendarbirthdayLt = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(birthdayGte)
		var compare = d1.getTime() < d2.getTime()
		console.log(compare)
		if (compare) {
			setBirthdayLt(new Date())
		} else {
			setBirthdayLt(date)
		}
	}
	const carlendarbirthdayGte = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(birthdayLt)
		var compare = d1.getTime() > d2.getTime()
		if (compare) {
			setBirthdayGte(new Date())
		} else {
			setBirthdayGte(date)
		}
	}

	const formSearchQuery = () => {
		let searchQuery = ""
		if (searchAll.length > 0) {
			searchQuery += `&searchAll=${searchAll}`
		}
		if (birthdayLt.length > 0 && birthdayGte.length > 0 && birthdayGte !== "Invalid date" && birthdayLt !== "Invalid date") {
			searchQuery += `&searchBirthdaygte=${birthdayGte}&searchBirthdaylt=${birthdayLt}`
		}
		if (createDateGte.length > 0 && createDateLt.length > 0 && createDateGte !== "Invalid date" && createDateLt !== "Invalid date") {
			searchQuery += `&create_dateSearchgte=${createDateGte}&create_dateSearchlt=${createDateLt}`
		}
		if (loginDateGte.length > 0 && loginDateLt.length > 0 && loginDateGte !== "Invalid date" && loginDateLt !== "Invalid date") {
			searchQuery += `&login_dateSearchgte=${loginDateGte}&login_dateSearchlt=${loginDateLt}`
		}
		if (hasPassword) {
			searchQuery += `&socailMediaOnWeb=hasPassword`
		}
		if (facebook) {
			searchQuery += `&socailMediaFacebook=facebook`
		}
		if (google) {
			searchQuery += `&socailMediaGoogle=google`
		}
		if (huawei) {
			searchQuery += `&socailMediaHuawei=huawei`
		}
		if (line) {
			searchQuery += `&socailMediaLine=line`
		}
		if (gender !== "all" && gender.length > 0) {
			searchQuery += `&gender=${gender}`
		}
		if (isActivate !== "all" && isActivate.length > 0) {
			searchQuery += `&isActivatedSearch=${isActivate}`
		}
		if (orderBy !== "all" && orderBy.length > 0) {
			searchQuery += `&orderData=${orderBy}`
		}
		if (register !== "all" && register.length > 0) {
			searchQuery += `&registerClient=${register}`
		}
		if (activity !== "all" && activity.length > 0) {
			searchQuery += `&activity=${activity}`
		}
		if (newsLetterTrPlus) {
			searchQuery += `&newsLetterTrPlus=true`
		}
		if (newsLetterTrol) {
			searchQuery += `&newsLetterTrol=true`
		}
		if (advanceSearch) {
			searchQuery += `&advanceSearch=${advanceSearch}`
		}
		if (socialRegister) {
			searchQuery += `&socialRegisterBy=${socialRegister}`
		}
		return searchQuery
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		// console.log(pageInfo.activePage, isSearch, "ssssss")
		if (isSearch !== true) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/list?page=${pageInfo.activePage}`, { withCredentials: true })
			console.log(data.pages, "1")

			setData(data.data)
		} else {
			let searchQuery = formSearchQuery()
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/search?${searchQuery}&page=${pageInfo.activePage}`, {
				withCredentials: true,
			})
			console.log(data.pages, "2")

			setData(data.data)
		}
	}

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const getChannel = (cell) => {
		if (cell === "yes") {
			return iconCheck
		} else {
			return ""
		}
	}
	const selectMember = (cell) => {
		return (
			<Link to={"edit/" + cell} className="btn btn-success btn-circle btn-sm">
				<i className="fas fa-user-edit"></i>
			</Link>
		)
	}

	const selectMemberName = (cell, row) => {
		return <Link to={"edit/" + row.user_id}>{cell}</Link>
	}

	const enumFormatter = (cell, row, enumObject) => {
		return enumObject[cell]
	}

	const clearInfo = () => {
		console.log("not working")
	}

	const searchButton = async () => {
		let searchQuery = formSearchQuery()
		setIsSearch(true)

		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/search?${searchQuery}`, { withCredentials: true })
		console.log(`${process.env.REACT_APP_API_ROOT}/admin/members/search?${searchQuery}`)
		if (data.data !== false) {
			setTotalCount(data.totalCount)
			setallPage(data.pages)
			setActivePage(data.currentPage)
			setData(data.data)
		} else {
			setData([])
		}
	}

	const showResgiter = (cell, row) => {
		if (cell) {
			return cell.name
		} else {
			return ""
		}
	}

	const actionGender = {
		male: "ชาย",
		female: "หญิง",
		unknow: "ไม่ระบุ",
	}

	const exportAllData = async (row) => {
		if (totalCount > 5000) {
			alert("ข้อมูลจำนวนเกิน 5000 ไม่สามารถ export ได้")
			return
		}
		let arrToExport = Array.from({ length: allPage }, (_, i) => i + 1)
		let searchQuery = formSearchQuery()

		setIsLoading(true)
		// const result = []
		// for (const num of arrToExport) {
		// 	let startDate = Date.now()
		// 	let { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/search?${searchQuery}&page=${num}`, { withCredentials: true })
		// 	result.push(data.data)
		// 	console.log("diff: ", (Date.now() - startDate) / 1000)
		// }

		let result = await Promise.all(
			arrToExport.map(async (num) => {
				console.log("num", num)
				let { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/search?${searchQuery}&page=${num}`, { withCredentials: true })
				return data.data
			})
		)
		result = [].concat(...result)
		setIsLoading(false)
		/** Prepare data to export */
		let arrHeader = Object.keys(result[0])
		let csvToExport = JSON.parse(JSON.stringify(arrHeader)).join(",") // Deep copy for arrHeader, otherwise shallow copy which is call by reference.
		result.forEach((element) => {
			// Create records
			csvToExport = csvToExport + "\r\n" + arrHeader.map((k) => JSON.stringify(element[k])).join(",")
		})

		/** Export process */
		let blob = new Blob([csvToExport], { type: "text/csv" })
		let link = document.createElement("a")
		link.href = window.URL.createObjectURL(blob)
		link.download = "memberlist.csv"
		link.click()
	}

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				<br />
				<div className="card-body">
					<br />

					<div className="row">
						<div className="col-8 col-md-6 offset-2 ">
							<div class="input-group md-form form-sm form-2 pl-0">
								<input
									class="form-control my-0 py-1 lime-border"
									type="text"
									placeholder="ค้นหา ชื่อ-นามสกุล,ชื่อแสดงผล,อีเมล"
									aria-label="Search"
									value={searchAll}
									onChange={(e) => setSearchAll(e.target.value)}
								/>
							</div>
						</div>

						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => searchButton()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหาข้อมูล</span>
							</button>
							&nbsp;
							<button
								class="btn btn-primary"
								type="button"
								data-toggle="collapse"
								data-target="#collapseExample"
								aria-expanded="false"
								aria-controls="collapseExample"
							>
								Advance
							</button>
						</p>

						<div class="container">
							<div class="collapse" id="collapseExample">
								<div class="card card-body">
									<div className="col-2 mt-2 mb-1">วันเกิด</div>
									<div className="col-10">
										<div className="row align-items-center">
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={birthdayGte}
													onChange={(date) => {
														carlendarbirthdayGte(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div>ถึง</div>
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={birthdayLt}
													onChange={(date) => {
														carlendarbirthdayLt(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div style={{ display: "flex", marginLeft: "1rem" }}>
												<div class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" id="email" onChange={(e) => setHasPassword(e.target.checked)} />
													<label class="form-check-label" for="email">
														email
													</label>
												</div>
												<div class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" id="facebook" onChange={(e) => setFacebook(e.target.checked)} />
													<label class="form-check-label" for="facebook">
														facebook
													</label>
												</div>
												<div class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" id="google" onChange={(e) => setGoogle(e.target.checked)} />
													<label class="form-check-label" for="google">
														google
													</label>
												</div>
												<div class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" id="line" onChange={(e) => setLine(e.target.checked)} />
													<label class="form-check-label" for="line">
														line
													</label>
												</div>
												<div class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" id="huawei" onChange={(e) => setHuawei(e.target.checked)} />
													<label class="form-check-label" for="huawei">
														huawei
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">วันที่สมัคร</div>
									<div className="col-10">
										<div className="row align-items-center">
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={createDateGte}
													onChange={(date) => {
														carlendarCreateGte(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div>ถึง</div>
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={createDateLt}
													onChange={(date) => {
														carlendarCreateLt(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>
											<div style={{ display: "flex", gap: "1rem", marginLeft: "1rem" }}>
												<div class="form-check">
													<input class="form-check-input" type="radio" name="exampleRadios" id="all" value="all" onChange={(e) => setGender(e.target.value)} />
													<label class="form-check-label" for="all">
														all
													</label>
												</div>
												<div class="form-check ">
													<input class="form-check-input" type="radio" name="exampleRadios" id="male" value="ชาย" onChange={(e) => setGender(e.target.value)} />
													<label class="form-check-label" for="male">
														male
													</label>
												</div>
												<div class="form-check ">
													<input class="form-check-input" type="radio" name="exampleRadios" id="female" value="หญิง" onChange={(e) => setGender(e.target.value)} />
													<label class="form-check-label" for="female">
														female
													</label>
												</div>
												<div class="form-check">
													<input
														class="form-check-input"
														type="radio"
														name="exampleRadios"
														id="other"
														value="ไม่ระบุ"
														onChange={(e) => setGender(e.target.value)}
													/>
													<label class="form-check-label" for="other">
														other
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">Login ล่าสุด</div>
									<div className="col-10">
										<div className="row align-items-center">
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={loginDateGte}
													onChange={(date) => {
														carlendarLoginGte(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div class="c">ถึง</div>
											<div class="col-2">
												<DateTime
													style={{ borderColor: "#ccc" }}
													dateFormat="YYYY-MM-DD"
													timeFormat={false}
													defaultValue={loginDateLt}
													onChange={(date) => {
														carlendarLoginLt(moment(date).format("YYYY-MM-DD"))
													}}
												/>
											</div>

											<div style={{ display: "flex", gap: "1rem", marginLeft: "1rem" }}>
												<div class="form-check">
													<input
														class="form-check-input"
														type="radio"
														name="exampleRadios2"
														id="allactivate"
														value="all"
														onChange={(e) => setIsActivate(e.target.value)}
													/>
													<label class="form-check-label" for="allactivate">
														all
													</label>
												</div>
												<div class="form-check">
													<input
														class="form-check-input"
														type="radio"
														name="exampleRadios2"
														id="activated"
														value={true}
														onChange={(e) => setIsActivate(e.target.value)}
													/>
													<label class="form-check-label" for="activated">
														activated
													</label>
												</div>
												<div class="form-check">
													<input
														class="form-check-input"
														type="radio"
														name="exampleRadios2"
														id="not activate"
														value={false}
														onChange={(e) => setIsActivate(e.target.value)}
													/>
													<label class="form-check-label" for="not activate">
														not activate
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">Order By</div>
									<div className="col-10">
										<div className="row">
											<div class="col-4">
												<div class="input-group">
													<select class="custom-select" id="inputGroupSelect01" onChange={(e) => setOrderBy(e.target.value)}>
														<option value="all" selected>
															Choose
														</option>
														<option value="create_time">วันที่สมัคร</option>
														<option value="last_login">login ล่าสุด</option>
													</select>
												</div>
											</div>

											{/* <div class="col-2" />
											&nbsp; &nbsp; &nbsp; */}
											<div class="col-4">
												<div class="input-group">
													<select class="custom-select" id="inputGroupSelect02" onChange={(e) => setRegister(e.target.value)}>
														<option value="all" selected disabled>
															-email register from-
														</option>
														<option value="all">all</option>
														<option value="thairath">thairath</option>
														<option value="classifieds">classifieds</option>
														<option value="thairath-plus">thairath-plus</option>
														<option value="boardgame">boardgame</option>
													</select>
												</div>
											</div>
											<div class="col-4">
												<div class="input-group">
													<select class="custom-select" id="inputGroupSelect02" onChange={(e) => setSocialRegister(e.target.value)}>
														<option value="" selected disabled>
															-social register from-
														</option>
														<option value="">all</option>
														<option value="thairath">thairath</option>
														<option value="classifieds">classifieds</option>
														<option value="thairath-plus">thairath-plus</option>
														<option value="boardgame">boardgame</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">Activity</div>
									<div className="col-10">
										<div className="row">
											<div class="col-4">
												<div class="input-group">
													<select class="custom-select" id="inputGroupSelect04" onChange={(e) => setActivity(e.target.value)}>
														{lstActivities.map((activity) => (
															<option value={activity.value}>{activity.text}</option>
														))}
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">Newsletter</div>
									<div className="col-10">
										<div className="row">
											<div class="col-12">
												<div style={{ display: "flex", gap: "1rem" }}>
													<div class="form-check form-check-inline">
														<input class="form-check-input" type="checkbox" id="newsletterTrol" onChange={(e) => setNewsLetterTrol(e.target.checked)} />
														<label class="form-check-label" for="newsletterTrol">
															thairath
														</label>
													</div>
													<div class="form-check form-check-inline">
														<input class="form-check-input" type="checkbox" id="newletterTrplus" onChange={(e) => setNewsLetterTrPlus(e.target.checked)} />
														<label class="form-check-label" for="newletterTrplus">
															thairath_plus
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-2 mt-2 mb-1">Advance Query</div>
									<div className="col-10">
										<div className="row">
											<div class="col-12">
												<input
													class="form-control my-0 py-1 lime-border"
													type="text"
													placeholder="input query"
													aria-label="Search"
													value={advanceSearch}
													onChange={(e) => setAdvanceSearch(e.target.value)}
												/>
											</div>
										</div>
									</div>
									<div className="col-12 mt-1">{`ex. { "facebook.from":"boardgame","email":"testxxxxx@gmail.com"}`}</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row" style={{ marginBottom: "8px" }}>
						<div className="col-10">
							{isLoading ? (
								<Fragment>
									<button className="btn btn-secondary" style={{ width: "120px", height: "41px" }}>
										<Spinner animation="border" role="status" variant="success">
											<span className="sr-only">Loading...</span>
										</Spinner>
									</button>
									{` waiting to export ..`}
								</Fragment>
							) : (
								<button className="btn btn-secondary" onClick={exportAllData} style={{ minWidth: "120px", height: "41px " }}>
									Export to CSV
								</button>
							)}
						</div>
						<div class="col-2">จำนวนข้อมูล {totalCount}</div>
					</div>
					<BootstrapTable data={data} className="table table-striped">
						<TableHeaderColumn row="0" rowSpan="2" dataField="user_id" width="80" isKey dataFormat={selectMember}>
							ดูข้อมูล
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="user_id" width="200">
							Member_id
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" ref={inputRef} dataField="display_name" width="200" dataFormat={selectMemberName}>
							ชื่อแสดงบนหน้าเว็บ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="name" width="200">
							ชื่อ-นามสกุล
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="email" width="200">
							email
						</TableHeaderColumn>
						{sensitive && (
							<TableHeaderColumn
								row="0"
								rowSpan="2"
								dataField="gender"
								width="150"
								filterFormatted
								dataFormat={enumFormatter}
								formatExtraData={actionGender}
							>
								gender
							</TableHeaderColumn>
						)}

						<TableHeaderColumn row="0" rowSpan="2" dataField="create_time" width="300" dataFormat={dateFormatter}>
							วันที่สมัคร
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="last_login" width="300" dataFormat={dateFormatter}>
							login ครั้งสุดท้าย
						</TableHeaderColumn>
						<TableHeaderColumn row="0" colSpan="5" csvHeader="channel" dataAlign="center">
							ช่องทางการ login
						</TableHeaderColumn>
						<TableHeaderColumn row="1" csvHeader="on web" dataField="hasPassword" width="80" dataFormat={getChannel} dataAlign="center">
							on web
						</TableHeaderColumn>
						<TableHeaderColumn row="1" csvHeader="google" dataField="google" width="80" dataFormat={getChannel} dataAlign="center">
							google
						</TableHeaderColumn>
						<TableHeaderColumn row="1" csvHeader="huawei" dataField="huawei" width="80" dataFormat={getChannel} dataAlign="center">
							huawei
						</TableHeaderColumn>
						<TableHeaderColumn row="1" csvHeader="facebook" dataField="facebook" width="80" dataFormat={getChannel} dataAlign="center">
							facebook
						</TableHeaderColumn>
						<TableHeaderColumn row="1" csvHeader="line" dataField="line" width="80" dataFormat={getChannel} dataAlign="center">
							line
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" width="150" dataField="register" dataFormat={showResgiter}>
							Register by
						</TableHeaderColumn>
					</BootstrapTable>
					{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
				</div>
			</div>
		</>
	)
}
export default List
