import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const [data, setData] = useState()
	const inputRef = useRef(null)

	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "user_management_view")

		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/user/list?v=` + Math.random() * 1000, { withCredentials: true })
				setData(data.data)
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const options = {
		sizePerPage: 25,
	}

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const selectMember = (cell) => {
		return (
			<Link to={"edit/" + cell} className="btn btn-success btn-circle btn-sm">
				<i className="fas fa-user-edit"></i>
			</Link>
		)
	}

	const enumFormatter = (cell, row, enumObject) => {
		return enumObject[cell]
	}

	const actionStatus = {
		false: "ปิดการใช้งาน",
		true: "ใช้งาน",
	}
	const actionPosition = {
		operation: "Operation",
		admin: "Admin",
		marketing: "Marketing",
		analyst: "Analyst",
	}

	const urlNew = "/user/edit/new"

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				<div className="card-body">
					<div className="row">
						{/* eslint-disable-next-line  */}
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							<Link to={urlNew} className="btn btn-success">
								เพิ่ม user
							</Link>
						</h6>
					</div>

					<div className="col-12">
						<BootstrapTable data={data} pagination={true} options={options} className="table table-striped">
							<TableHeaderColumn dataField="_id" width="50" isKey dataFormat={selectMember}>
								id
							</TableHeaderColumn>
							<TableHeaderColumn ref={inputRef} dataField="username" width="100" filter={{ type: "TextFilter", defaultValue: "", delay: 500 }}>
								username
							</TableHeaderColumn>
							<TableHeaderColumn dataField="firstname" width="200" filter={{ type: "TextFilter", delay: 500 }}>
								ชื่อ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="lastname" width="200" filter={{ type: "TextFilter", delay: 500 }}>
								นามสกุล
							</TableHeaderColumn>
							<TableHeaderColumn
								dataField="position"
								width="150"
								filterFormatted
								dataFormat={enumFormatter}
								formatExtraData={actionPosition}
								filter={{ type: "SelectFilter", options: actionPosition }}
							>
								ตำแหน่ง
							</TableHeaderColumn>
							<TableHeaderColumn
								dataField="isActive"
								width="150"
								filterFormatted
								dataFormat={enumFormatter}
								formatExtraData={actionStatus}
								filter={{ type: "SelectFilter", options: actionStatus }}
							>
								สถานะ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="last_update" width="300" dataFormat={dateFormatter} filter={{ type: "DateFilter" }}>
								อัพเดทล่าสุด
							</TableHeaderColumn>
						</BootstrapTable>
					</div>
				</div>
			</div>
		</>
	)
}
export default List
