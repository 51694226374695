import React, { useState, useEffect } from "react"
import axios from "axios"
import { StringFloatFormatter, StringTimeFormatter, StringIntFormatter, LinkMemberFormatter } from "./Formatter"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const TopMember = (startdate, enddate, web) => {
	const [topMembers, setTopMembers] = useState([])
	const nameColumnMember = "display-name"
	const emailColumnMember = "email"
	const isPlus = (web) => web === "plus"

	useEffect(() => {
		async function getMemberName(memberId) {
			if (memberId === undefined) {
				return ""
			}
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/form/${memberId}?v=` + Math.random() * 1000, {
				withCredentials: true,
			})
			const profileState = data.data
			if (profileState === false) {
				return { display_name: "N/A", email: "N/A" }
			}
			if (profileState.name) {
				const dataReturn = {
					display_name:
						profileState.firstname && profileState.lastname ? `${profileState.firstname} ${profileState.lastname}` : `${profileState.display_name}`,
					email: profileState.email,
				}
				return dataReturn
			}
		}

		async function fetchData() {
			const mainparameter = {
				startdate: startdate,
				enddate: enddate,
				web: web,
			}

			setTopMembers([]) // Use to reset topMembers because of delay from cXense API call
			let apiUrl = "https://cms-member-report-3cor0z2p.an.gateway.dev/v1/top-member?key=AIzaSyAgPNySb2caYYJMg-NS9dEb1uiEU6RUJg8"
			let reqObj = await axios.post(apiUrl, mainparameter, { headers: { "Content-Type": "application/json" } })
			let lst = reqObj.data[0].list
			if (lst.length) {
				lst = await Promise.all(
					lst.map(async (obj) => {
						const data = await getMemberName(isPlus(web) ? obj["ga:dimension1"] : obj["ga:dimension21"])
						obj[nameColumnMember] = data.display_name
						obj[emailColumnMember] = data.email
						return obj
					})
				)
				setTopMembers(lst)
			} else {
				alert("Sorry, TopMember has no data following your conditions.")
			}
		}
		fetchData()
	}, [startdate, enddate, web])

	return (
		<>
			<h4 className="font-weight-bold">Top Members</h4>
			<div style={{ textAlign: "center" }}>
				<BootstrapTable
					data={topMembers}
					className="table table-striped"
					keyField={isPlus(web) ? "ga:dimension1" : "ga:dimension21"}
					options={{ noDataText: "Please wait for data..." }}
					exportCSV
					pagination
				>
					<TableHeaderColumn dataField={nameColumnMember} width="200">
						Name
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:pageviews" width="100" dataAlign="right" dataFormat={StringIntFormatter}>
						Page View
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:avgTimeOnPage" width="140" dataAlign="right" dataFormat={StringTimeFormatter}>
						Avg Time on Page
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:bounceRate" width="140" dataAlign="right" dataFormat={StringFloatFormatter}>
						Bounce rate (%)
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:deviceCategory" width="120">
						Device
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:operatingSystem" width="120">
						OS
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:region" width="160">
						Location
					</TableHeaderColumn>
					<TableHeaderColumn dataField="email" width="160">
						Email
					</TableHeaderColumn>
					<TableHeaderColumn dataField="ga:dimension21" hidden={isPlus(web)} width="140" dataFormat={LinkMemberFormatter}></TableHeaderColumn>
					<TableHeaderColumn dataField="ga:dimension1" hidden={!isPlus(web)} width="140" dataFormat={LinkMemberFormatter}></TableHeaderColumn>
				</BootstrapTable>
			</div>
		</>
	)
}
export default TopMember
