import React from "react"
import axios from "axios"
const ModalExport = (props) => {
	const { formSearchQuery, setModalExport } = props
	const onExport = async () => {
		const searchQuery = formSearchQuery()
		window.open(`${process.env.REACT_APP_API_ROOT}/admin/mongkol/order/export?${searchQuery}`)
		setModalExport(false)
	}
	return (
		<div
			className="export-modal"
			style={{
				border: "1px solid #FF5C5C",
				width: "250px",
				height: "200px",
				position: "absolute",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)",
				backgroundColor: "#FF8A8A",
				padding: "20px",
				display: "flex",
				alignItems: "center",
				flexFlow: "row wrap",
				alignContent: "center",
				borderRadius: "6px",
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					margin: "auto",
					padding: "8px",
					color: "black",
				}}
			>
				<span>คุณต้องการ "ดาวน์โหลดไฟล์" ?</span>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					margin: "auto",
				}}
			>
				<button style={{ padding: "8px", margin: "5px" }} onClick={onExport}>
					ตกลง
				</button>
				<button
					style={{ padding: "8px", margin: "5px" }}
					onClick={() => {
						setModalExport(false)
					}}
				>
					ยกเลิก
				</button>
			</div>
			{/* <Modal isOpen={open} toggle={toggle} centered={true} wrapClassName="member--modal" className="modal-export">
				<ModalBody>
					<div>
						<svg className="close--btn" onClick={toggle} width="21.315" height="22.192" viewBox="0 0 21.315 22.192">
							<g data-name="Group 26863" transform="translate(0.361 0.346)">
								<line data-name="Line 1" x2="20.593" y2="21.5" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="1"></line>
								<line data-name="Line 2" x1="20.593" y2="21.5" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="1"></line>
							</g>
						</svg>
						<span>คุณต้องการ "ดาวน์โหลดไฟล์" ?</span>
						<button style={{ width: "100%" }} onClick={toggle}>
							ปิด
						</button>
					</div>
				</ModalBody>
			</Modal> */}
		</div>
	)
}
export default ModalExport
