import React, { useEffect, useState } from "react"
import { ContainerStyled, SectionStyled } from "../../styled"
import { FormEditor, FormInput, FormTextarea, FormUpload } from "../../widgets/Form"
import { Button, Form, Table } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import useActivityVote from "./context"
import _get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import useAlert from "../../useAlert"
import useUpload from "../../useUpload"
import DateTime from "@nateradebaugh/react-datetime"

const Prize = ({ preview }) => {
	const { onUpload } = useUpload()
	const { data, handleChange, handleChangeStep, stepNow } = useActivityVote()
	const [prizeSelected, setPrizeSelected] = useState(0)
	const handleClickNewPrize = () => {
		const temp = _get(data, "prize.items", [])
		handleChange({
			name: "prize.items",
			value: [
				...temp,
				{
					key: uuidv4(),
					title: "",
					img: {},
					url: "",
					numOfQuestion: 0,
				},
			],
		})
	}
	const handleClickDeletePrize = () => {
		// เหลือ confirm modal
		const temp = _get(data, "prize.items", [])
		handleChange({ name: "prize.items", value: temp.filter((_, index) => index !== prizeSelected) })
		setPrizeSelected(prizeSelected > 0 ? prizeSelected - 1 : 0)
		onClose()
	}
	const { onOpen, onClose, renderAlert } = useAlert({
		title: "ต้องการลบรางวัลนี้หรือไม่",
		onConfirm: handleClickDeletePrize,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})

	useEffect(() => {
		if (preview) {
			for (const ele of document.querySelectorAll("input,textarea")) {
				ele.setAttribute("disabled", "disabled")
			}
		}
		return () => {}
	}, [prizeSelected])
	return (
		<div>
			<ContainerStyled className="mb-5">
				<SectionStyled className="mb-4">
					<FormInput label={"ชื่อของ Tab"} name={`prize.tabName`} defaultValue={_get(data, "prize.tabName")} onChange={handleChange} required />
				</SectionStyled>
				<SectionStyled className="mb-4">
					<h1>การแสดงผลหน้าแรกส่วนล่าง</h1>
					<FormInput name={`prize.title`} defaultValue={_get(data, "prize.title")} onChange={handleChange} label={"ชื่อหัวข้อ"} required />
					<FormEditor
						placeholder={"รายละเอียด (ไม่ควรเกิน 4 บรรทัด)"}
						label="รายละเอียด (ไม่ควรเกิน 4 บรรทัด)"
						required
						name="prize.description"
						defaultValue={_get(data, "prize.description")}
						onChange={handleChange}
						// errorMessage="กรุณากรอก"
					/>
					<FormUpload
						label={"รูปภาพ"}
						remark="ขนาด 452x452 px (1:1)"
						name={"prize.img"}
						defaultValue={_get(data, "prize.img")}
						onUpload={onUpload(handleChange)}
						required
					/>
				</SectionStyled>
				<SectionStyled>
					<div style={{ display: "flex" }}>
						<h1 className="flex-grow-1">การแสดงผลหน้ารับคูปอง</h1>
						<Form.Check
							type="switch"
							id="custom-switch"
							defaultChecked={_get(data, "prize.couponMessage.has")}
							onChange={(e) => handleChange({ name: "prize.couponMessage.has", value: !_get(data, "prize.couponMessage.has") })}
						/>
					</div>
					{_get(data, "prize.couponMessage.has") && (
						<>
							<FormInput
								label={"ชื่อหัวข้อ"}
								name="prize.couponMessage.title"
								defaultValue={_get(data, "prize.couponMessage.title")}
								onChange={handleChange}
								required
							/>
							<FormInput
								label={"รายละเอียด (ไม่ควรเกิน 2 บรรทัด)"}
								name="prize.couponMessage.description"
								defaultValue={_get(data, "prize.couponMessage.description")}
								onChange={handleChange}
								required
							/>
						</>
					)}
				</SectionStyled>
			</ContainerStyled>
			<div
				style={{
					display: "flex",
					overflowX: "auto",
					padding: "0.5rem",
				}}
			>
				{_get(data, "prize.items", []).map((item, index) => (
					<Button
						key={item.key}
						onClick={() => setPrizeSelected(index)}
						variant={prizeSelected === index ? "dark" : "outline-dark"}
						style={{
							marginRight: "0.5rem",
							whiteSpace: "nowrap",
						}}
					>{`รางวัลที่ ${index + 1}`}</Button>
				))}
			</div>
			<Divider />
			<ContainerStyled>
				<div style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
					{_get(data, "prize.items", []).length > 1 && (
						<Button variant="outline-danger non-preview" className="mr-2" onClick={onOpen}>
							ลบรางวัลนี้
						</Button>
					)}
					<Button variant="outline-dark non-preview" onClick={handleClickNewPrize}>
						เพิ่มรางวัล
					</Button>
				</div>
				<SectionStyled className="mb-4">
					<h1>{`รางวัลที่ ${prizeSelected + 1}`}</h1>
					<FormTextarea
						label="ชื่อรางวัล (ห้ามเกิน 2 บรรทัด)"
						defaultValue={_get(data, `prize.items[${prizeSelected}].title`, "")}
						key={`${prizeSelected}-title`}
						name={`prize.items[${prizeSelected}].title`}
						onChange={handleChange}
						rows={2}
						required
					/>
					<FormUpload
						label={"รูปของรางวัล"}
						remark="ขนาด 200x112 px (16:9)"
						required
						defaultValue={_get(data, `prize.items[${prizeSelected}].img`, "")}
						key={`${prizeSelected}-img`}
						name={`prize.items[${prizeSelected}].img`}
						onUpload={onUpload(handleChange)}
					/>
					<FormInput
						label={"URL สำหรับใช้สิทธิ์"}
						defaultValue={_get(data, `prize.items[${prizeSelected}].url`, "")}
						key={`${prizeSelected}-url`}
						name={`prize.items[${prizeSelected}].url`}
						onChange={handleChange}
						required
					/>
					<FormInput
						label={"จำนวนข้อที่ต้องตอบเพื่อรับสิทธิ์"}
						defaultValue={_get(data, `prize.items[${prizeSelected}].numOfQuestion`, "")}
						name={`prize.items[${prizeSelected}].numOfQuestion`}
						key={`${prizeSelected}-numOfQuestion`}
						onChange={handleChange}
						required
					/>
				</SectionStyled>
				<SectionStyled className="mb-4">
					<h1>คูปอง</h1>
					<FormInput
						label={"รหัสคูปอง"}
						defaultValue={_get(data, `prize.items[${prizeSelected}].coupon.code`, "")}
						key={`${prizeSelected}-coupon.code`}
						name={`prize.items[${prizeSelected}].coupon.code`}
						required
						onChange={handleChange}
					/>
					<FormInput
						label={"จำนวนสิทธิ์"}
						remark="ใส่เป็นตัวเลขเท่านั้น"
						defaultValue={_get(data, `prize.items[${prizeSelected}].coupon.amount`, "")}
						key={`${prizeSelected}-coupon.amount`}
						name={`prize.items[${prizeSelected}].coupon.amount`}
						required
						onChange={handleChange}
					/>
					<FormInput
						label={"ระยะเวลาการใช้คูปอง"}
						required
						renderInput={() => (
							<div className="non-editable" style={{ display: "flex", alignItems: "center" }}>
								<DateTime
									dateFormat={"DD-MM-YYYY"}
									timeFormat={true}
									className="w-100"
									onBlur={(value) => handleChange({ name: `prize.items[${prizeSelected}].coupon.startDate`, value })}
									defaultValue={_get(data, `prize.items[${prizeSelected}].coupon.startDate`, "")}
									key={`${prizeSelected}-coupon.startDate`}
									inputProps={{ readOnly: true }}
								/>
								<span className="mx-2 font-weight-bold">-</span>
								<DateTime
									dateFormat={"DD-MM-YYYY"}
									timeFormat={true}
									className="w-100"
									onBlur={(value) => handleChange({ name: `prize.items[${prizeSelected}].coupon.endDate`, value })}
									defaultValue={_get(data, `prize.items[${prizeSelected}].coupon.endDate`, "")}
									key={`${prizeSelected}-coupon.endDate`}
									inputProps={{ readOnly: true }}
								/>
							</div>
						)}
					/>
					<FormEditor
						required
						label={`รายละเอียดและเงื่อนไขการใช้งานคูปอง`}
						name={`prize.items[${prizeSelected}].coupon.description`}
						defaultValue={_get(data, `prize.items[${prizeSelected}].coupon.description`, "")}
						key={`${prizeSelected}-coupon.description`}
						onChange={handleChange}
					/>
				</SectionStyled>
				<Button className="float-left mt-2 non-preview" variant="outline-primary" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" variant="primary" onClick={() => handleChangeStep(stepNow + 1)}>
					ถัดไป
				</Button>
			</ContainerStyled>
			{renderAlert()}
		</div>
	)
}

export default Prize
