import styled, { css } from "styled-components"

export const NavigateStyled = styled.div`
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#008435+0,005723+100 */
	padding: 1em 0;
	position: relative;
	z-index: 5;
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		list-style: none;
		justify-content: center;
		margin-top: -25px;
		li {
			max-width: 120px;
			width: 100%;
			a {
				font-size: 16px;
				color: white;
				display: grid;
				justify-items: center;
				row-gap: 10px;
				position: relative;
				/* padding:0 21px; */
				cursor: pointer;
				&:before {
					content: "";
					background: #ccc;
					height: 3px;
					width: 140px;
					position: absolute;
					top: 25px;
					z-index: -1;
				}
				strong {
					font-family: "cloudsemibold";
					width: 50px;
					height: 50px;
					background: white;
					border: 3px solid white;
					display: grid;
					font-weight: normal;
					color: #ccc;
					font-size: 28px;
					text-align: center;
					display: grid;
					align-items: center;
					border-radius: 50px;
					transition: all 0.5s;
					transform: scale(1);
					border: 1px solid #ccc;
				}
				&:hover {
					strong {
						transition: all 0.5s;
						transform: scale(1.2);
						background: #00b400;
						color: white;
					}
				}
			}
			&:first-of-type {
				a {
					&:before {
						width: 70px;
						right: 0;
					}
				}
			}
			&:last-of-type {
				a {
					&:before {
						width: 70px;
						left: 0;
					}
				}
			}
		}
		.active {
			a {
				strong {
					background: #00b400;
					color: white;
				}
			}
		}
	}
	@media (max-width: 767px) {
		ul {
			li {
				a {
					&:before {
						width: 80px;
					}
					strong {
						font-size: 25px;
					}
					span {
						display: none;
					}
				}
				&:first-of-type {
					a {
						&:before {
							width: 50px;
							right: 0;
						}
					}
				}
				&:last-of-type {
					a {
						&:before {
							width: 50px;
							left: 0;
						}
					}
				}
			}
		}
	}
	@media (max-width: 374px) {
		ul {
			li {
				a {
					&:before {
						width: 60px;
						top: 20px;
					}
					strong {
						font-size: 20px;
						width: 40px;
						height: 40px;
					}
				}
				&:first-of-type {
					a {
						&:before {
							width: 40px;
							right: 0;
						}
					}
				}
				&:last-of-type {
					a {
						&:before {
							width: 40px;
							left: 0;
						}
					}
				}
			}
		}
	}
`

export const ContainerStyled = styled.div`
	max-width: 600px;
	margin: auto;
	h1 {
		font-size: 1.5rem;
		font-weight: bold;
		color: #666;
		margin-bottom: 1.5rem;
	}
	.img-preview {
		display: none;
		max-height: 80px;
		width: auto;
	}

	${({ preview }) =>
		preview &&
		css`
			.non-preview {
				display: none;
			}
			.non-editable {
				pointer-events: none;
			}
			.img-name {
				display: none;
			}
			.img-preview {
				display: block;
			}
			.img-wrapper {
				display: flex;
				gap: 4px;
				flex-wrap: wrap;
			}
		`}
`
export const SectionStyled = styled.div`
	background-color: #f6f6f6;
	padding: 1.5rem 1rem;
	font-family: Kanit;
	h1 {
		font-family: Kanit;
		font-size: 1.5rem;
		font-weight: bold;
		color: #666;
		margin-bottom: 1.5rem;
	}
	h2 {
		font-family: Kanit;
		font-size: 1.125rem;
		font-weight: semi-bold;
		color: #666;
		margin: 0;
	}
	.form-input {
		margin-bottom: 1rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`

export const CheckboxStyled = styled.input`
	/* Double-sized Checkboxes */
	-ms-transform: scale(1.5); /* IE */
	-moz-transform: scale(1.5); /* FF */
	-webkit-transform: scale(1.5); /* Safari and Chrome */
	-o-transform: scale(1.5); /* Opera */
	transform: scale(1.5);
	margin-right: 0.75rem;
	margin-left: 0.25rem;
	cursor: pointer;
`

export const GridAutoStyled = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 1rem;
	margin-bottom: 1.5rem;
`

export const HStackStyled = styled.div`
	display: flex;
	margin-bottom: 0.75rem;
	align-items: flex-end;
	* {
		margin-right: 0.5rem;
		&:last-of-type {
			margin-right: 0;
		}
	}
`

export const SwiftButtom = styled.button`
	position: relative;
	bottom: 5px;
	padding: 5px 5px;
	font-size: 16px;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	color: #fff;
	background-color: ${(props) => (props.active ? "#4caf50" : " #f44336")};
`

export const ImageUploadContainer = styled.div`
	position: relative;
	display: inline-block;
	margin: 0 auto;
`

export const ImageFlag = styled.img`
	cursor: pointer;
	width: 100px;
	height: 100px;
	border: 2px solid #ccc;
	border-radius: 5px;
	transition: border-color 0.3s ease-in-out;

	&:hover {
		border-color: #000;
	}
`

export const ImageBox = styled.img`
	cursor: pointer;
	width: 100%;
	height: 295px;
	aspect-ratio: 16/9;
	object-fit: cover;
	border: 2px solid #ccc;
	border-radius: 5px;
	transition: border-color 0.3s ease-in-out;

	&:hover {
		border-color: #000;
	}
`

export const FileInput = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
`

export const Select = styled.select`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
`

export const GridContainer = styled.div`
	display: grid;
	// grid-template-columns: repeat(4, minmax(0, 1fr));
`

export const GameButton = styled.button`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	background: #808080;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	transition: all 0.5s;
	&:hover {
		background: #ccc;
	}
`

export const FormStyle = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
`
