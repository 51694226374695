import React, { Fragment } from 'react'
import { NavigateStyled } from "../../styled"
import useEuroFootball, { EuroFootballContextProvider } from "./context"

const EuroFootballComp = () => {
    const { stepNow, steps, handleChangeStep } = useEuroFootball()
    // if (!steps || !data) return <>Loading...</>

    return (
        <div
            style={{
                backgroundColor: "#fff",
                padding: "2rem",
            }}
        >
            <NavigateStyled className="mb-4">
                <ul>
                    {steps &&
                        steps.map((item, index) => (
                            <li 
                            key={item.key} 
                            className={stepNow >= index ? "active" : ""} 
                            onClick={() => handleChangeStep(index)}>
                                <a>
                                    <strong>{index + 1}</strong>
                                    <span>{item.label || "New tab"}</span>
                                </a>
                            </li>
                        ))}
                </ul>
            </NavigateStyled>
            <div>{steps[stepNow] && steps[stepNow].Component}</div>
        </div>
    )
}

const EuroFootballTabs = () => (
    <EuroFootballContextProvider>
        <EuroFootballComp />
    </EuroFootballContextProvider>
)

export default EuroFootballTabs