import React, { Fragment, useContext } from "react"
import { Navigation } from "../../../utils"
import { TableSetup, TableTarget, TableDetail, TableQuiz, TableScore } from "../Widgets"
import { StoreContext } from "../Context/store"
import { Button } from "react-bootstrap"
import { campaignController } from "../Services"
import { useHistory } from "react-router"

const Submit = ({ pageStep = 4, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup],
		targetSec: [target],
		detail: [detail],
		itemDelete: [itemDel],
		awardDelete: [awardDel],
		quiz: [quiz],
		score: [score],
		award: [award],
		id: id,
		rendereStep: rendereStep,
		disabled: [disabled],
		ruleDelete: [ruleDel],
	} = useContext(StoreContext)
	const history = useHistory()

	const onSubmit = async () => {
		let data = {}
		switch (campSetup.type) {
			case "Vote":
				data = {
					campSetup,
					target,
					detail,
					award,
					itemDel,
					awardDel,
					ruleDel
				}
				break
			case "Quiz":
				data = {
					campSetup,
					target,
					detail: quiz,
					award,
					itemDel,
					awardDel,
					ruleDel
				}
				break
			case "Score":
				data = {
					campSetup,
					target,
					detail: score,
					award,
					itemDel,
					awardDel,
					ruleDel
				}
			default:
				break
		}

		if (id) {
			const resposne = await campaignController().updateCampaign(data, id)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		} else {
			const resposne = await campaignController().createCampaign(data)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		}
	}

	const formatTable = (type) => {
		switch (type) {
			case "Vote":
				return <TableDetail data={detail} />
			case "Quiz":
				return <TableQuiz data={quiz} />
			case "Score":
				return <TableScore data={score} />
			default:
				break
		}
	}

	return (
		<Fragment>
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<div className="row justify-content-between">
						<h5 className="m-0 font-weight-bold text-primary">Submit</h5>
						<Button className="btn btn-success" onClick={() => onSubmit()} disabled={disabled ? "disabled" : ""}>
							Submit
						</Button>
					</div>
				</div>

				<div className="card-body">
					<div>
						<h5 className="m-0 font-weight-bold text-primary">Campaign Setup</h5>
						<br />
						<TableSetup data={campSetup} />
					</div>

					{/* Hide Target waiting */}
					{/* <div className="mt-5">
						<h5 className="m-0 font-weight-bold text-primary">Target Section</h5>
						<br />
						<TableTarget data={target} />
					</div> */}

					<div className="mt-5">
						<h5 className="m-0 font-weight-bold text-primary">Detail</h5>
						<br />
						{formatTable(campSetup.type)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Submit
