import React, { useState, useEffect } from "react"
import { SectionStyled, ContainerStyled, ImageUploadContainer, ImageBox, FileInput } from "../../styled"
import { Button, Row, Col, Form, InputGroup } from "react-bootstrap"
import _get from "lodash/get"
import useEuroFootball from "./context"
import { v4 as uuidv4 } from "uuid"
import { FormInput, FormCheckbox, FormUpload } from "../../widgets/Form"
import Editor from "../../widgets/Editor"

import ModalConfirmDelete from "./modalConfirmDelete"

const EuroFootballAward = () => {
	const { euroSetup, awardSetup, setAwardSetup, onSubmit, stepNow, handleChangeStep, onUploadImage } = useEuroFootball()
	const imageDefault = "https://img.icons8.com/?size=512&id=53386&format=png"
	const [awards, setAwardData] = useState(awardSetup)
	const [isModal, setIsModal] = useState(false)
	const [modalFunction, setModalFunction] = useState(null)

	useEffect(() => {
		setAwardData(awardSetup)
	}, [awardSetup])

	const handleChangeItem = ({ name, value }, id) => {
		const new_award = awards.award.map((item, index) => {
			if (item.id === id) {
				return {
					...item,
					[`${name}`]: value,
				}
			} else {
				return item
			}
		})

		const new_result = {
			...awards,
			award: new_award,
		}

		setAwardData(new_result)
		setAwardSetup(new_result)
	}

	const handleChangeEditor = ({ target }) => {
		const { name, value, id } = target
		const new_award = awards.award.map((item, index) => {
			if (item.id === id) {
				return {
					...item,
					[`${name}`]: value,
				}
			} else {
				return item
			}
		})

		const new_result = {
			...awards,
			award: new_award,
		}

		setAwardData(new_result)
		setAwardSetup(new_result)
	}

	const addItems = () => {
		const new_award = {
			id: uuidv4(),
			name: "",
			image: {},
			member: "",
		}

		let add_award = {
			...awards,
			award: (awards.award || []).concat(new_award),
		}

		setAwardData(add_award)
		setAwardSetup(add_award)
	}

	const deleteItems = (id) => {
		const new_result = awards.award.filter((item) => {
			if (item.id !== id) {
				return item
			}
		})

		const data = {
			...awards,
			award: new_result,
		}

		setAwardData(data)
		setAwardSetup(data)
	}

	const uploadImage = async (event, name, id) => {
		const file = event.target.files[0]
		const allowedTypes = ["image/jpeg", "image/png"]
		if (!allowedTypes.includes(file.type)) {
			alert("Only JPG and PNG files are allowed.")
			return
		}

		const img = new Image()
		img.src = URL.createObjectURL(file)
		img.onload = function () {
			const aspectRatio = this.width / this.height
			if (Math.floor(aspectRatio) !== Math.floor(16 / 9)) {
				alert("The image must have a 16:9 aspect ratio.")
				return
			}
			if (file.size > 1048576) {
				alert("The file size must not exceed 1MB.")
				return
			}

			const data = onUploadImage(file)
			data.then((response) => {
				if (response) {
					const new_award = awards.award.map((item) => {
						if (item.id === id) {
							return { ...item, [`${name}`]: response.data.items[0] }
						} else {
							return item
						}
					})
					const new_result = { ...awards, award: new_award }
					setAwardData(new_result)
					setAwardSetup(new_result)
				}
			})
		}
	}

	return (
		<>
			<ContainerStyled>
				<SectionStyled className="mb-4">
					{(awards.award || []).map((item, index) => (
						<div key={item.id} style={{ paddingBottom: "50px" }}>
							<Row>
								<Col>
									<h1>รางวัลที่ {index + 1}</h1>
								</Col>
								<Col>
									<Button
										className="float-right mt-6 non-preview"
										onClick={() => {
											setIsModal(true)
											const storeFunction = () => deleteItems(item.id)
											setModalFunction(() => storeFunction)
										}}
										variant="outline-danger"
									>
										X
									</Button>
								</Col>
							</Row>

							<Form.Group className="mb-3">
								<ImageUploadContainer>
									<ImageBox alt="awardImage" src={item.image?.url || imageDefault} roundedCircle />
									<FileInput name="image" type="file" onChange={(e) => uploadImage(e, "image", item.id)} />
								</ImageUploadContainer>
								<div className="mt-2">
									<small>(ขนาดรูป 16:9 / นามสกุล jpg, png / จำกัด 1 Mb)</small>
								</div>
							</Form.Group>

							<Form.Group className="mb-3">
								<FormInput
									name={"name"}
									label={"ชื่อรางวัล"}
									required
									onChange={(event) => handleChangeItem(event, item.id)}
									defaultValue={item.name || ""}
									// errorMessage="กรุณากรอก"
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<FormInput
									name={"amount"}
									label={"จำนวนรางวัล"}
									type={"number"}
									required
									onChange={(event) => handleChangeItem(event, item.id)}
									defaultValue={item.amount || ""}
									// errorMessage="กรุณากรอก"
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>รายชื่อผู้ได้รับรางวัล</Form.Label>

								<Editor defaultValue={item.member} onChange={(text) => handleChangeEditor({ target: { name: "member", value: text, id: item.id } })} />
							</Form.Group>
						</div>
					))}

					<div style={{ display: "flow-root", paddingTop: "20px" }}>
						<Button style={{ width: "100%" }} onClick={() => addItems()} variant="outline-secondary">
							เพิ่มรางวัล
						</Button>
					</div>
				</SectionStyled>

				<Button className="float-left mt-2 non-preview" onClick={() => handleChangeStep(stepNow - 1, awards, "award")} variant="warning">
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" onClick={() => onSubmit()} variant="success">
					เสร็จสิ้น
				</Button>
			</ContainerStyled>
			<ModalConfirmDelete
				showModal={isModal}
				closeModal={() => setIsModal(false)}
				onDelete={() => {
					setIsModal(false)
					modalFunction()
				}}
			/>
		</>
	)
}

export default EuroFootballAward
