import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import CommentCard from "./CommentCard"
import { Pagination, Dropdown, Form, Icon } from "semantic-ui-react"
import DateTime from "@nateradebaugh/react-datetime"
import CommentApi from "../../libs/comment"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

const pageOptions = [
	{
		key: "10",
		text: "10",
		value: 10,
	},
	{
		key: "15",
		text: "15",
		value: 15,
	},
	{
		key: "25",
		text: "25",
		value: 25,
	},
	{
		key: "50",
		text: "50",
		value: 50,
	},
]
const statusOptions = [
	{
		key: "all",
		text: "All",
		value: "All",
	},
	{
		key: "reported",
		text: "Reported",
		value: "Reported",
	},
	{
		key: "deleted",
		text: "Deleted",
		value: "Deleted",
	},
]
const sortOptions = [
	{
		key: "createDate",
		text: "วันที่สร้างล่าสุด",
		value: "createDate",
	},
	{
		key: "lastUpdate",
		text: "วันที่แก้ไขล่าสุด",
		value: "lastUpdate",
	},
]

export default function List(props) {
	const user = User.getCookieData()
	const [dataComments, setDataComments] = useState([])
	const [page, setPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(10)
	const [total, setTotal] = useState(0)
	const [pathOptions, setPathOptions] = useState([])
	const initDropdown = {
		status: "All",
		path: "All",
		sort: "lastUpdate",
	}
	const [formSearch, setFormSearch] = useState(initDropdown)
	const [condition, setCondition] = useState(initDropdown)
	const [disabled, setDisabled] = useState(true)
	const [view, setView] = useState(true)

	const fetchData = useCallback(async () => {
		const response = await CommentApi.getComments({
			page: page,
			pageLimit: pageLimit,
			...condition,
			sort: { [condition.sort]: -1 },
		})

		// console.log(response.data.data)
		if (response.status === 200) {
			await setDataComments(response.data.comments)
			setTotal(response.data.total)
		} else {
			console.log(response.data)
		}
	}, [page, pageLimit, condition])

	const fetchPathContentUrls = async () => {
		const response = await CommentApi.getPathContentUrls()
		if (response.status === 200) {
			let cleanData = [
				{
					key: "all",
					text: "ทั้งหมด",
					value: "All",
				},
			]
			cleanData.push(
				...response.data.map((item) => {
					return {
						key: item.path,
						text: item.title,
						value: item.path,
					}
				})
			)
			await setPathOptions(cleanData)
		} else {
			console.log(response.data)
		}
	}

	const handlePageChange = (e, pageInfo) => {
		setPage(pageInfo.activePage)
	}

	const handleSizePerPageList = (e, { value }) => {
		setPageLimit(value)
		setPage(1)
	}

	const handleChange = (value, name) => {
		setFormSearch((prev) => ({ ...prev, [name]: value }))
	}

	const handleClickSearch = () => {
		setCondition({ ...formSearch })
	}

	const handleClickClear = () => {
		setCondition(initDropdown)
		setFormSearch(initDropdown)
		setPage(1)
	}

	const handleChangeDataUser = (user) => {
		const tempArr = dataComments.map((item) => {
			return user.id === item.user.id ? { ...item, user } : item
		})
		setDataComments([...tempArr])
	}

	const permission = () => {
		setDisabled(!checkPermission(user.permission, "action_comment_edit"))
		setView(checkPermission(user.permission, "action_comment_view"))
	}

	useEffect(() => {
		fetchPathContentUrls()
		return () => {}
	}, [])

	useEffect(() => {
		const checked = checkPermission(user.permission, "comment_view")

		if (checked) {
			permission()
			fetchData()
			return () => {
				setDataComments([])
			}
		}else{
			props.history.push('/')
		}
	}, [page, pageLimit, condition])

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Comment</h6>
				</div>
				<div className="card-body">
					{
						//#region search
						<Form>
							<Form.Group>
								<Form.Input
									value={formSearch.text || ""}
									onChange={(e) => handleChange(e.target.value, "text")}
									className="ui form small"
									label="ค้นหา Comment"
									placeholder="ค้นหา Comment"
								/>
								<Form.Dropdown
									value={formSearch.status}
									onChange={(e, { value }) => handleChange(value, "status")}
									className="ui form small"
									label="สถานะ Comment"
									placeholder="สถานะ Comment"
									selection
									options={statusOptions}
								/>
								<Form.Dropdown
									value={formSearch.path}
									onChange={(e, { value }) => handleChange(value, "path")}
									className="ui form small"
									label="หมวด"
									placeholder="เลือกหมวด"
									selection
									search
									options={pathOptions}
								/>
								<Form.Field>
									<label>ช่วงเวลา</label>
									<DateTime
										defaultValue={formSearch.startDate}
										onBlur={(value) => handleChange(value, "startDate")}
										dateFormat="DD/MM/YYYY"
										timeFormat={false}
										closeOnSelect
									/>
									<span className="mx-2">ถึง</span>
									<DateTime
										defaultValue={formSearch.endDate}
										onBlur={(value) => handleChange(value, "endDate")}
										dateFormat="DD/MM/YYYY"
										timeFormat={false}
										closeOnSelect
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Dropdown
									value={formSearch.sort}
									onChange={(e, { value }) => handleChange(value, "sort")}
									className="ui form small"
									label="เรียงข้อมูลตาม"
									placeholder="เรียงข้อมูลตาม"
									selection
									options={sortOptions}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Button onClick={handleClickSearch}>
									<Icon name="search" />
									ค้นหา
								</Form.Button>
								<Form.Button onClick={handleClickClear}>
									<Icon name="redo" />
									เคลียร์
								</Form.Button>
							</Form.Group>
						</Form>
						//#endregion
					}
				</div>
				{/* <hr /> */}
				<div className="card-body">
					{dataComments && dataComments.map((item, index) => <CommentCard key={item._id} data={item} onChangeDataUser={handleChangeDataUser} disabled={disabled} view={view} />)}
				</div>
				<div className="card-footer">
					<Dropdown onChange={handleSizePerPageList} selection options={pageOptions} value={pageLimit} />
					<Pagination activePage={page} onPageChange={handlePageChange} totalPages={Math.ceil(total / pageLimit)} />
				</div>
			</div>
		</>
	)
}
