import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap"
/** Datepicker use */
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import axios from "axios"

import MemberCount from './components/MemberCount'
import MemberTraffic from './components/MemberTraffic'
import MemberTrafficTrPlus from "./components/MemberTrafficTrPlus"
//import MemberLoginChannel from './components/MemberLoginChannel'
import TopTrPlus from "../dashboard/TopTrPlus"
import TopContent from "../dashboard/TopContent"
import SpecialContent from "../dashboard/SpecialContent"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

const GroupReport = (props) => {

    const [web, setWeb] = useState("online")

    const user = User.getCookieData()
	const [startdate, setStartdate] = useState( moment().subtract(7, "days").format('YYYY-MM-DD') )
    const [enddate, setEnddate] = useState( moment().format('YYYY-MM-DD') )
    const [arrSelectedGroupby, setSelectedGroupby] = useState([])
    const [arrSourceMedium, setSourceMedium] = useState(["sourceMedium"])
    const [arrSectionTopic, setSectionTopic] = useState(["sectionTopic"])

    const arrChoicesGroupby = ['gender','agerange','device','location']
    const [dictChoices, setDictChoices] = useState({})
    // eslint-disable-next-line
    const [arrNothing, setArrNothing] = useState([]) /** Use to trick MemberTopSection because members parameter must be a state NOT the normal variable. */
    
	useEffect(() => {
        const check = checkPermission(user.permission, "report_group_edit")
		async function fetchData() {
            // Get dict choices data which will be used in MemberTopSection / TopContent / SpecialContent
            const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/report/`
            const mainparameter = { 
                source: 'thairath',
                selectedgroupby: [ ...arrChoicesGroupby.filter(choice => choice !== "location") ]
            }
            let { data: resChoices } = await axios.post(mainurl + 'memberIdByCondition', mainparameter, { withCredentials: true })
            console.log(resChoices.data)
            setDictChoices(resChoices.data)
		}
        if(!check) {
            props.history.push('/')
        }
        fetchData()
    // eslint-disable-next-line 
	}, [startdate, enddate])

    const getDatePicker = (name, defaultvalue) => {
        return (<DateTime dateFormat="YYYY-MM-DD" timeFormat={false}
            defaultValue={defaultvalue} onChange={date => { 
                date = moment(date).format('YYYY-MM-DD')
                name === "startdate" && setStartdate(date)
                name === "enddate" && setEnddate(date)
                console.log(name + " " + date) 
            }} />)
	}
	
	const handleSingleCheck = e => {
        const { name } = e.target
        let newarray = []
		if (arrSelectedGroupby.includes(name)) { // Remove if exists
            let arrTemp = arrSelectedGroupby.filter(checked_name => checked_name !== name)
            newarray = [...arrTemp]
		} else { // Add if not exists
			arrSelectedGroupby.push(name)
            newarray = [...arrSelectedGroupby]
            console.log(arrSelectedGroupby)
		}
        setSelectedGroupby(newarray)
        setSourceMedium([ ...newarray, "sourceMedium"])
        setSectionTopic([ ...newarray, "sectionTopic"])
	}

    return (
        <Container>
            <Row className="mb-4">
                <Col className="text-right">
                    <span className="mr-4">
                        <Form.Label className="mr-2 font-weight-bold">Group by</Form.Label>
                        {/* https://stackoverflow.com/questions/60456547/reactjs-manage-multiple-checkbox-inputs-with-usestate */}
                        {arrChoicesGroupby.map(attribute => (
                            <Form.Check inline type="checkbox" key={`groupby-${attribute}`} id={`groupby-${attribute}`}
                                name={attribute} checked={arrSelectedGroupby.includes(attribute)} 
                                label={attribute === "agerange" ? "Age Range" : attribute.charAt(0).toUpperCase() + attribute.slice(1)} 
                                onChange={handleSingleCheck}/>
                        ))}
					</span>
                    <span className="mr-4">
                        <Form.Label className="mr-2 font-weight-bold">Start date</Form.Label>
                        {getDatePicker("startdate", startdate)}
                    </span>
                    <span>
                        <Form.Label className="mr-2 font-weight-bold">End date</Form.Label>
                        {getDatePicker("enddate", enddate)}
                    </span>
                </Col>
			</Row>
            <Row className="mb-4">
                <Col>
                    {MemberCount(arrSelectedGroupby, startdate, enddate)}
                </Col>
            </Row>
            <Tabs activeKey={web} onSelect={k => setWeb(k)} className="mb-3">
                <Tab eventKey="online" title="Thairath Online">
                    <Row className="mb-4">
                        <Col>
                            {MemberTraffic(arrSelectedGroupby, startdate, enddate, "Traffic Statistics", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {MemberTraffic(arrSourceMedium, startdate, enddate, "Traffic by Referral", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {MemberTraffic(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {SpecialContent("member",startdate, enddate, arrSelectedGroupby, arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {TopContent(startdate, enddate, arrSelectedGroupby, arrNothing)}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="plus" title="Thairath Plus">
                    <Row className="mb-4">
                        <Col>
                            {MemberTrafficTrPlus(arrSelectedGroupby, startdate, enddate, "Traffic Statistics", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {MemberTrafficTrPlus(arrSourceMedium, startdate, enddate, "Traffic by Referral", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {MemberTrafficTrPlus(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {TopTrPlus(startdate, enddate, arrSelectedGroupby, arrNothing)}
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
export default GroupReport