import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

import User from "../../libs/admin"
import Notification from "../../libs/notification"
import Alert from "react-bootstrap/Alert"

import { CSVReader } from "react-papaparse"

import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
//import "@nateradebaugh/react-datetime/scss/styles.scss"
import * as moment from "moment"
import "moment/locale/th"

import Select from "react-select"
import makeAnimated from "react-select/animated"
import emailEx from "../../assets/img/emailEx.png"
import { checkPermission } from "../../helper/functions"

const animatedComponents = makeAnimated()

const options = [
	{ value: "local", label: "ทั่วไทย" },
	{ value: "society", label: "สังคม" },
	{ value: "crime", label: "อาชญากรรม" },
	{ value: "politic", label: "การเมือง" },
	{ value: "business", label: "เศรษฐกิจ" },
	{ value: "foreign", label: "ต่างประเทศ" },
	{ value: "video", label: "Video" },
	{ value: "podcast", label: "Podcast" },
	{ value: "lifestyle", label: "ไลฟ์สไตล์" },
	{ value: "entertain", label: "บันเทิง" },
	{ value: "sport", label: "กีฬา" },
	{ value: "horoscope", label: "เรื่องดวง" },
	{ value: "lottery", label: "หวย" },
	{ value: "novel", label: "นิยาย" },
	{ value: "women", label: "ผู้หญิง" },
	{ value: "tech", label: "ไอที" },
	{ value: "auto", label: "ยานยนต์" },
	{ value: "travel", label: "ท่องเที่ยว" },
	{ value: "gold", label: "ทองคำ" },
]

const NotificationSetup = (props) => {
	let { id } = useParams()
	const admin = User.getCookieData()
	const buttonRef = React.createRef()
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)
	const [dataResult, setDataResult] = useState(null)
	const [userId, setUserId] = useState(id)

	// state
	const [targetLv1, setStateTargetLv1] = useState()
	const [targetLv2, setStateTargetLv2] = useState()
	const [targetLv2Save, setStateTargetLv2Save] = useState({ 0: "0" })
	const [message, setStateMessage] = useState()
	const [urlLink, setStateUrlLinkText] = useState()
	const [DeliverySchedule, setStateDeliverySchedule] = useState()
	// const [repeat , setStateRepeat] = useState()
	// const [after , setStateAfter] = useState()
	const [status, setStateStatus] = useState("waiting")
	const [urlImg, setStateImg] = useState()
	const [importDefaultValue, setStateDefaultValue] = useState({ 0: "0" })
	const [fileUploadStatus, setStateFileUploadStatus] = useState(true)
	const [disabled, setDisabled] = useState(true)

	const permission = () => {
		setDisabled(!checkPermission(admin.permission, "notification_edit"))
	}

	useEffect(() => {
		const checked = checkPermission(admin.permission, "notification_view")

		if (checked) {
			permission()
			fetchData()
		} else {
			props.history.push("/")
		}
		// eslint-disable-next-line
	}, [id])

	const fetchData = async () => {
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/notificationcenter/form/${id}?v=` + Math.random() * 1000, {
			withCredentials: true,
		})
		if (Object.keys(data.data.target)[0] === "importEmail") {
			setUserId(data.data._id)
			setEditTarget(Object.keys(data.data.target)[0], data.data.target[Object.keys(data.data.target)[0]], data.data.target.filename)
			setStateMessage(data.data.message)
			setStateUrlLinkText(data.data.urlLink)
			setStateDeliverySchedule(new Date(data.data.deliverySchedule))
			// setStateRepeat(data.data.repeat)
			// setStateAfter(data.data.after)
			setStateStatus(data.data.status)
			setStateImg(data.data.urlImg)
			setStateStatus(data.data.status)
		} else {
			setUserId(data.data._id)
			setEditTarget(Object.keys(data.data.target)[0], data.data.target[Object.keys(data.data.target)[0]])
			setStateMessage(data.data.message)
			setStateUrlLinkText(data.data.urlLink)
			setStateDeliverySchedule(new Date(data.data.deliverySchedule))
			// setStateRepeat(data.data.repeat)
			// setStateAfter(data.data.after)
			setStateStatus(data.data.status)
			setStateImg(data.data.urlImg)
			setStateStatus(data.data.status)
		}
	}

	const setEditTarget = (target, targetLv2, filename) => {
		if (target === "alldata") {
			setStateTargetLv1(1)
			setStateTargetLv2Save({ alldata: targetLv2 })
			setStateTargetLv2(targetLv2)
			setTargetLv2()
		} else if (target === "gender") {
			setStateTargetLv1(2)
			setStateTargetLv2Save({ gender: targetLv2 })
			setStateTargetLv2(targetLv2)
			setTargetLv2()
		} else if (target === "age") {
			setStateTargetLv1(3)
			setStateTargetLv2Save({ age: targetLv2 })
			setStateTargetLv2(targetLv2)
			setTargetLv2()
		} else if (target === "province") {
			setStateTargetLv1(4)
			setStateTargetLv2Save({ province: targetLv2 })
			setStateTargetLv2(targetLv2)
			setTargetLv2()
		} else if (target === "registration") {
			setStateTargetLv1(5)
			setStateTargetLv2Save({ registration: targetLv2 })
			setStateTargetLv2(targetLv2)
			setTargetLv2()
		} else if (target === "interest") {
			const editValue = []

			targetLv2.map((items) => {
				options.map((items2) => {
					if (items2.value === items) {
						editValue.push(items2)
					}
				})
			})
			setStateTargetLv1(6)
			setStateTargetLv2Save({ interest: editValue })
			setStateTargetLv2(editValue)
			setTargetLv2()
		} else if (target === "importEmail") {
			setStateTargetLv1(7)
			setStateTargetLv2Save({ importEmail: targetLv2, filename: filename })
			setStateDefaultValue({ importEmail: targetLv2, filename: filename })
		}
	}

	const setTargetLv1 = () => {
		const value = parseInt(document.getElementById("STarget").value)
		setStateTargetLv1(value)
		if (value === 0) {
			setStateTargetLv2Save({ 0: "0" })
		} else if (value === 1) {
			setStateTargetLv2Save({ alldata: "1" })
		} else {
			setTargetLv2()
			setStateTargetLv2("0")
			setStateTargetLv2Save({ 0: "0" })
		}
	}

	const setTargetLv2 = () => {
		if (targetLv1 === 0 || targetLv1 === 1) {
			return <></>
		} else if (targetLv1 === 2) {
			return (
				<>
					<select className="form-control" id="STargetLevel2" value={targetLv2 || ""} onChange={() => setValueTargetLv2(targetLv1)}>
						<option value="0">Select</option>
						<option value="1">ชาย</option>
						<option value="2">หญิง</option>
						<option value="3">ไม่ระบุ</option>
					</select>
				</>
			)
		} else if (targetLv1 === 3) {
			return (
				<>
					<select className="form-control" id="STargetLevel2" value={targetLv2 || ""} onChange={() => setValueTargetLv2(targetLv1)}>
						<option value="0">Select</option>
						<option value="1">ต่ำกว่า 18</option>
						<option value="2">18-24</option>
						<option value="3">25-34</option>
						<option value="4">35-44</option>
						<option value="5">45-54</option>
						<option value="6">55-64</option>
						<option value="7">65+</option>
					</select>
				</>
			)
		} else if (targetLv1 === 4) {
			return (
				<>
					<select className="form-control" id="STargetLevel2" value={targetLv2 || ""} onChange={() => setValueTargetLv2(targetLv1)}>
						<option value="0">Select</option>
						<option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
						<option value="สมุทรปราการ">สมุทรปราการ</option>
						<option value="นนทบุรี">นนทบุรี</option>
						<option value="ปทุมธานี">ปทุมธานี</option>
						<option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
						<option value="อ่างทอง">อ่างทอง</option>
						<option value="ลพบุรี">ลพบุรี</option>
						<option value="สิงห์บุรี">สิงห์บุรี</option>
						<option value="ชัยนาท">ชัยนาท</option>
						<option value="สระบุรี">สระบุรี</option>
						<option value="ชลบุรี">ชลบุรี</option>
						<option value="ระยอง">ระยอง</option>
						<option value="จันทบุรี">จันทบุรี</option>
						<option value="ตราด">ตราด</option>
						<option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
						<option value="ปราจีนบุรี">ปราจีนบุรี</option>
						<option value="นครนายก">นครนายก</option>
						<option value="สระแก้ว">สระแก้ว</option>
						<option value="นครราชสีมา">นครราชสีมา</option>
						<option value="บุรีรัมย์">บุรีรัมย์</option>
						<option value="สุรินทร์">สุรินทร์</option>
						<option value="ศรีสะเกษ">ศรีสะเกษ</option>
						<option value="อุบลราชธานี">อุบลราชธานี</option>
						<option value="ยโสธร">ยโสธร</option>
						<option value="ชัยภูมิ">ชัยภูมิ</option>
						<option value="อำนาจเจริญ">อำนาจเจริญ</option>
						<option value="หนองบัวลำภู">หนองบัวลำภู</option>
						<option value="ขอนแก่น">ขอนแก่น</option>
						<option value="อุดรธานี">อุดรธานี</option>
						<option value="เลย">เลย</option>
						<option value="หนองคาย">หนองคาย</option>
						<option value="มหาสารคาม">มหาสารคาม</option>
						<option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
						<option value="กาฬสินธุ์">กาฬสินธุ์</option>
						<option value="สกลนคร">สกลนคร</option>
						<option value="นครพนม">นครพนม</option>
						<option value="มุกดาหาร">มุกดาหาร</option>
						<option value="เชียงใหม่">เชียงใหม่</option>
						<option value="ลำพูน">ลำพูน</option>
						<option value="ลำปาง">ลำปาง</option>
						<option value="อุตรดิตถ์">อุตรดิตถ์</option>
						<option value="แพร่">แพร่</option>
						<option value="น่าน">น่าน</option>
						<option value="พะเยา">พะเยา</option>
						<option value="เชียงราย">เชียงราย</option>
						<option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
						<option value="นครสวรรค์">นครสวรรค์</option>
						<option value="อุทัยธานี">อุทัยธานี</option>
						<option value="กำแพงเพชร">กำแพงเพชร</option>
						<option value="ตาก">ตาก</option>
						<option value="สุโขทัย">สุโขทัย</option>
						<option value="พิษณุโลก">พิษณุโลก</option>
						<option value="พิจิตร">พิจิตร</option>
						<option value="เพชรบูรณ์">เพชรบูรณ์</option>
						<option value="ราชบุรี">ราชบุรี</option>
						<option value="กาญจนบุรี">กาญจนบุรี</option>
						<option value="สุพรรณบุรี">สุพรรณบุรี</option>
						<option value="นครปฐม">นครปฐม</option>
						<option value="สมุทรสาคร">สมุทรสาคร</option>
						<option value="สมุทรสงคราม">สมุทรสงคราม</option>
						<option value="เพชรบุรี">เพชรบุรี</option>
						<option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
						<option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
						<option value="กระบี่">กระบี่</option>
						<option value="พังงา">พังงา</option>
						<option value="ภูเก็ต">ภูเก็ต</option>
						<option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
						<option value="ระนอง">ระนอง</option>
						<option value="ชุมพร">ชุมพร</option>
						<option value="สงขลา">สงขลา</option>
						<option value="สตูล">สตูล</option>
						<option value="ตรัง">ตรัง</option>
						<option value="พัทลุง">พัทลุง</option>
						<option value="ปัตตานี">ปัตตานี</option>
						<option value="ยะลา">ยะลา</option>
						<option value="นราธิวาส">นราธิวาส</option>
						<option value="บึงกาฬ">บึงกาฬ</option>
					</select>
				</>
			)
		} else if (targetLv1 === 5) {
			return (
				<>
					<select className="form-control" id="STargetLevel2" value={targetLv2 || "0"} onChange={() => setValueTargetLv2(targetLv1)}>
						<option value="0">Select</option>
						<option value="1">Facebook</option>
						<option value="2">Gmail</option>
						<option value="3">email</option>
					</select>
				</>
			)
		} else if (targetLv1 === 6) {
			return (
				<>
					<Select closeMenuOnSelect={false} components={animatedComponents} value={targetLv2} onChange={handleChange} isMulti options={options} />
				</>
			)
		}
	}

	const handleChange = (selectedOption) => {
		if (selectedOption) {
			setStateTargetLv2(selectedOption)
			const value = []
			selectedOption.map((items) => {
				value.push(items.value)
			})
			setStateTargetLv2Save({ interest: value })
		} else {
			setStateTargetLv2([])
			setStateTargetLv2Save({ 0: "0" })
		}
	}

	const setValueTargetLv2 = (selectedOption) => {
		const value = document.getElementById("STargetLevel2").value
		if (targetLv1 === 2) {
			setStateTargetLv2(value)
			setStateTargetLv2Save({ gender: value })
		} else if (targetLv1 === 3) {
			setStateTargetLv2(value)
			setStateTargetLv2Save({ age: value })
		} else if (targetLv1 === 4) {
			setStateTargetLv2(value)
			setStateTargetLv2Save({ province: value })
		} else if (targetLv1 === 5) {
			setStateTargetLv2(value)
			setStateTargetLv2Save({ registration: value })
		}
	}

	// FileUpload function

	const handleOnFileLoad = (data, file) => {
		if (file.size < 1000000) {
			const obj = []
			for (let i = 0; i < data.length; i++) {
				obj.push(data[i].data[0])
				// obj[i].push(data[i].data[0])
			}

			setStateTargetLv2Save({ importEmail: obj, filename: file.name })
		} else {
			alert("ขนาดไฟล์เกิน 1 MB กรุณาเลือกไฟล์อีกครั้ง")
			setStateFileUploadStatus(false)
		}
	}

	const handleOnError = (err, file, inputElem, reason) => {
		console.log(err)
	}

	const handleOnRemoveFile = (data) => {
		if (data) {
			if (Object.keys(data)[0] !== "0") {
				setStateTargetLv2Save({ importEmail: data.importEmail, filename: data.filename })
				setStateFileUploadStatus(true)
			} else {
				setStateTargetLv2Save({ 0: "0" })
			}
		}
	}

	// FileUpload function

	const onChangeMessage = () => {
		const messageValue = document.getElementById("TaMessage").value
		setStateMessage(messageValue)
	}

	const setUrlLinkText = () => {
		const urlValue = document.getElementById("TaUrl").value
		setStateUrlLinkText(urlValue)
	}

	const setUrlImg = () => {
		const imgValue = document.getElementById("TaImg").value
		setStateImg(imgValue)
	}

	const setDate = (date) => {
		setStateDeliverySchedule(moment(date).format("YYYY-MM-DD HH:mm:ss"))
	}

	// const setRepeat = () => {
	//   const value = parseInt(document.getElementById('SRepeat').value)
	//   setStateRepeat(value)
	// }

	// const setAfter = () => {
	//   const value = parseInt(document.getElementById('SAfter').value)
	//   setStateAfter(value)
	// }

	const setStatus = () => {
		const value = document.getElementById("SStatus").value
		setStateStatus(value)
	}

	const submitData = async () => {
		if (targetLv1 === 6) {
			if (targetLv2Save.interest.length !== 0 && message && urlLink && DeliverySchedule && fileUploadStatus) {
				const obj = {
					target: targetLv2Save,
					message: message,
					url: urlLink,
					deliverySchedule: new Date(DeliverySchedule),
					time: new Date(DeliverySchedule).toLocaleTimeString(),
					dateTimeSchedule: +new Date(DeliverySchedule),
					adminId: admin.firstname,
					status: status,
					urlImg: urlImg,
				}
				try {
					const data = await Notification.sendupdateNotificationSetup(obj, userId)
					if (data.status === 200) {
						setDataResult(data.message)
						setShow(true)
						setColor("success")
						setTimeout(() => {
							setShow(false)
						}, 3000)
					} else {
						setDataResult(data.message)
						setShow(true)
						setColor("danger")
						setTimeout(() => {
							setShow(false)
						}, 3000)
					}
				} catch (e) {
					console.log(`request failed: ${e}`)
				}
			} else {
				alert("กรุณาเลือกข้อมูลให้ถูกต้อง")
			}
		} else {
			if (!targetLv2Save[0] !== false && message && urlLink && DeliverySchedule) {
				const obj = {
					target: targetLv2Save,
					message: message,
					url: urlLink,
					deliverySchedule: new Date(DeliverySchedule),
					time: new Date(DeliverySchedule).toLocaleTimeString(),
					dateTimeSchedule: +new Date(DeliverySchedule),
					adminId: admin.firstname,
					status: status,
					urlImg: urlImg,
				}

				try {
					const data = await Notification.sendupdateNotificationSetup(obj, userId)
					if (data.status === 200) {
						setDataResult(data.message)
						setShow(true)
						setColor("success")
						setTimeout(() => {
							setShow(false)
						}, 3000)
						props.history.push("/notificationcenter/list")
					} else {
						setDataResult(data.message)
						setShow(true)
						setColor("danger")
						setTimeout(() => {
							setShow(false)
						}, 3000)
					}
				} catch (e) {
					console.log(`request failed: ${e}`)
				}
			} else {
				alert("กรุณากรอกข้อมูลให้ครบถ้วน")
			}
		}
	}
	return (
		<>
			{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
			)}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Notification Setup</h6>
				</div>

				<div className="card-body">
					{/* target */}
					<div className="row">
						<div className="col-2">Target :</div>
						<div className="col-4">
							<select className="form-control" id="STarget" value={targetLv1 || ""} onChange={() => setTargetLv1()} disabled={disabled ? 'disabled' : ''}>
								<option value="0">Select</option>
								<option value="2">เพศ</option>
								<option value="3">อายุ</option>
								<option value="4">จังหวัด</option>
								<option value="5">Registration Method</option>
								<option value="6">Interest</option>
								<option value="7">Import Email</option>
							</select>
						</div>
						<div className="col-4">{setTargetLv2()}</div>
					</div>
					{/* target */}

					<br />

					{/* import file */}
					{targetLv1 === 7 && (
						<div className="row">
							<div className="col-2" />
							<div className="col-4">
								{Object.keys(targetLv2Save)[0] !== "0" ? (
									<CSVReader
										onDrop={(data, file) => handleOnFileLoad(data, file)}
										onError={(err, file, inputElem, reason) => handleOnError(err, file, inputElem, reason)}
										addRemoveButton
										config={{ header: false, skipEmptyLines: true }}
										noDrag
										onRemoveFile={(data) => handleOnRemoveFile(importDefaultValue)}
									>
										<span>{targetLv2Save.filename}</span>
									</CSVReader>
								) : (
									<CSVReader
										onDrop={(data, file) => handleOnFileLoad(data, file)}
										onError={(err, file, inputElem, reason) => handleOnError(err, file, inputElem, reason)}
										addRemoveButton
										noDrag
										config={{ header: false, skipEmptyLines: true }}
										onRemoveFile={(data) => handleOnRemoveFile(data)}
									>
										<span>click to upload CSV . (Limit 1 MB)</span>
									</CSVReader>
								)}
							</div>
							<div className="col-6">
								<img src={emailEx} width="500" className="emailEx" alt="emailExmaple" />
							</div>
						</div>
					)}
					{/* import file */}

					<br />

					{/* message */}
					<div className="row">
						<div className="col-2">Message :</div>
						<div className="col-6">
							<textarea
								className="form-control"
								id="TaMessage"
								rows="3"
								style={{ resize: "none" }}
								maxLength="300"
								onChange={() => onChangeMessage()}
								value={message}
								disabled={disabled ? 'disabled' : ''}
							></textarea>
						</div>
						<div className="col-4" />
					</div>
					{/* message */}

					<br />

					{/* url link */}
					<div className="row">
						<div className="col-2">URL link :</div>
						<div className="col-6">
							<textarea
								className="form-control"
								id="TaUrl"
								rows="1"
								style={{ resize: "none" }}
								maxLength="200"
								value={urlLink}
								onChange={() => setUrlLinkText()}
								disabled={disabled ? 'disabled' : ''}
							></textarea>
						</div>
						<div className="col-4" />
					</div>
					{/* url link */}

					<br />

					{/* Img */}
					<div className="row">
						<div className="col-2">URL Img :</div>
						<div className="col-6">
							<textarea
								className="form-control"
								id="TaImg"
								rows="1"
								style={{ resize: "none" }}
								maxLength="300"
								value={urlImg}
								onChange={() => setUrlImg()}
								disabled={disabled ? 'disabled' : ''}
							></textarea>
						</div>
						<div className="col-4" />
					</div>
					{/* Img */}

					<br />

					{/* delivery schedule */}
					<div className="row">
						<div className="col-2">Delivery Schedule :</div>
						<div className="col-3">
							<DateTime
								style={{ borderColor: "#ccc" }}
								dateFormat="YYYY-MM-DD"
								timeFormat={true}
								defaultValue={DeliverySchedule}
								// onChange={date => {setDate(moment(date).format('YYYY-MM-DD HH:mm:ss'))}}  />
								onBlur={(date) => setDate(date)}
								inputProps={{ disabled: disabled }}
							/>
						</div>
					</div>
					{/* delivery schedule */}

					<br />

					{/*Repeat for every unacknowledged message */}
					{/* <div className="row">
						<div className="col-2">Repeat for every unacknowledged message :</div>
						<div className="col-2">
							<select className="form-control" value={repeat || "0"} onChange={() => setRepeat()} id="SRepeat">
								<option value="0">Select</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
							</select>
						</div>
						<div className="col-1">times</div>
						<div className="col-1">After</div>
						<div className="col-2">
							<select className="form-control" value={after || "0"} onChange={() => setAfter()} id="SAfter">
								<option value="0">Select</option>
								<option value="1">30 mins</option>
								<option value="2">1 Hr</option>
								<option value="3">3 Hr</option>
								<option value="4">6 Hr</option>
								<option value="5">12 Hr</option>
								<option value="6">24 Hr</option>
								<option value="7">3 days</option>
							</select>
						</div>
						<div className="col-1">Apart</div>
					</div> */}
					{/*Repeat for every unacknowledged message */}

					<br />

					{/* Status */}
					<div className="row">
						<div className="col-2">Status :</div>
						<div className="col-3">
							<select className="form-control" value={status || "waiting"} onChange={() => setStatus()} id="SStatus" disabled={disabled ? 'disabled' : ''} >
								<option value="waiting">Waiting</option>
								<option value="sending">Sending</option>
								<option value="sent">Sent</option>
							</select>
						</div>
					</div>
					{/* Status */}

					<br />

					{/* Submit */}
					<div className="row">
						<div className="col-10" />
						<div className="col-2">
							<button className="btn btn-success btn-icon-split" onClick={() => submitData()} disabled={disabled ? 'disabled' : ''}>
								<span className="icon text-white-50">
									<i className="fas fa-check"></i>
								</span>
								<span className="text">Save</span>
							</button>
						</div>
					</div>
					{/* Submit */}
				</div>
			</div>
		</>
	)
}

export default NotificationSetup
