import React, { Fragment, useContext } from "react"
import { StoreContext } from "../../Context/store"
import MemberVote from "./vote"
import MemberQuiz from "./quiz"
import MemberScore from "./score"

const CampaignSetup = ({ pageStep = 1, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup],
		loading: [loading],
	} = useContext(StoreContext)

	const renderPage = () => {
		switch (campSetup.type) {
			case "Quiz":
				return <MemberQuiz pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} />
			case "Score":
				return <MemberScore pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} />
			case "Vote":
				return <MemberVote pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} />

			default:
				break
		}
	}

	return (
		<Fragment>
			{!loading && renderPage()}
		</Fragment>
	)
}

export default CampaignSetup
