import React, { Fragment, useContext } from "react"
import { Navigation } from "../../../utils"
import { memberType, gender, location, interest, activity, topic, dataType, ages } from "../../../helper/const"
import { StoreContext } from "../Context/store"
import { DatePeriod, SelectAge, SelectInput } from "../Widgets"
import moment from "moment"

const Target = ({ pageStep = 2, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup],
		targetSec: [target, setTarget],
		rendereStep: rendereStep,
		disabled: [disabled]
	} = useContext(StoreContext)

	const onChangeData = (event) => {
		const { name, value } = event.target
		setTarget({
			...target,
			[`${name}`]: value,
		})
	}

	const onChangeSelctMulti = (event, name) => {
		setTarget({
			...target,
			[`${name}`]: event || [],
		})
	}

	const onBlurData = (event, name) => {
		switch (name) {
			case "startDate":
				setTarget({
					...target,
					startRecency: moment(event).format("YYYY-MM-DD HH:mm:ss"),
				})
				break

			case "endDate":
				setTarget({
					...target,
					endRecency: moment(event).format("YYYY-MM-DD HH:mm:ss"),
				})
				break
		}
	}

	return (
		<Fragment>
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} data={target} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Target Section</h5>
				</div>
				<div className="card-body">
					<SelectInput
						label="Membership Type"
						id="memType"
						name="memberType"
						value={target.memberType}
						data={memberType}
						onChange={onChangeData}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<SelectInput
						label="Gender"
						id="sectionGender"
						name="gender"
						value={target.gender}
						data={gender}
						onChange={(event) => onChangeSelctMulti(event, "gender")}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
						multiple
					/>
					<br />
					<SelectInput
						label="Age"
						id="sectionAge"
						name="age"
						value={target.age}
						data={ages}
						onChange={onChangeData}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
					/>
					<br />
					<SelectInput
						label="Location"
						id="sectionLocation"
						name="Province"
						value={target.province}
						data={location}
						onChange={(event) => onChangeSelctMulti(event, "province")}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
						multiple
					/>
					<br />
					<SelectInput
						label="Interest"
						id="sectionInterest"
						name="interest"
						value={target.interest}
						data={interest}
						onChange={(event) => onChangeSelctMulti(event, "interest")}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
						multiple
					/>
					{/* <br />
					<SelectInput
						label="Activity"
						id="sectionActivity"
						name="activity"
						value={target.activity}
						data={activity}
						onChange={onChangeData}
						compCol="col-4"
					/> */}
					<br />
					<SelectInput
						label="Top Section/Topic"
						id="sectionTopic"
						name="topic"
						value={target.topic}
						data={topic}
						onChange={onChangeData}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
					/>
					<br />
					<SelectInput
						label="Member Data Type"
						id="sectionDataType"
						name="dataType"
						value={target.dataType}
						data={dataType}
						onChange={onChangeData}
						compCol="col-4"
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<DatePeriod
						label="Data Recency"
						dateFormat="YYYY-MM-DD"
						startValue={moment(target.startRecency).format("YYYY-MM-DD HH:mm:ss")}
						endValue={moment(target.endRecency).format("YYYY-MM-DD HH:mm:ss")}
						onBlur={onBlurData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default Target
