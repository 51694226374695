import React, { useEffect } from "react"
import { NavigateStyled } from "../../styled"
import useVoteInArticle, { VoteInArticleContextProvider } from "./context"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"

const VoteInArticleComp = () => {
	const { handleChangeStep, stepNow, steps, data } = useVoteInArticle()
	if (!steps || !data) return <>Loading...</>
	return (
		<div
			style={{
				backgroundColor: "#fff",
				padding: "2rem",
			}}
		>
			<NavigateStyled className="mb-4">
				<ul>
					{steps &&
						steps.map((item, index) => (
							<li key={item.key} className={stepNow >= index ? "active" : ""}>
								<a onClick={() => handleChangeStep(index)}>
									<strong>{index + 1}</strong>
									<span>{item.label}</span>
								</a>
							</li>
						))}
				</ul>
			</NavigateStyled>
			<div>{steps[stepNow] && steps[stepNow].Component}</div>
		</div>
	)
}

const VoteInArticle = (props) => {
	const user = User.getCookieData()
	useEffect(() => {
		if (!checkPermission(user.permission, "vote_in_article_edit")) {
			props.history.push('/')

		}
		return () => { }
	}, [])
	return (
		<VoteInArticleContextProvider>
			<VoteInArticleComp />
		</VoteInArticleContextProvider>
	)
}
export default VoteInArticle
