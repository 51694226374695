import React, { useEffect, useState } from "react"
import { Nav } from "react-bootstrap"
import Detail from "./detail"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
// import { campaignController } from "../../Services"

const initActive = "/home"

const pages = [
	{
		label: "Home",
		path: "/home",
	},
	// {
	// 	label: "Sport",
	// 	path: "/sport",
	// },
]
function CampaignPageSetting(props) {
	const user = User.getCookieData()
	const [active, setActive] = useState(initActive)
	const handleChangePage = (eventKey, event) => {
		setActive(eventKey)
	}
	useEffect(() => {
		if(!checkPermission(user.permission,"campaign_page_setting_view")) {
			props.history.push('/')
		}
		return () => {}
	}, [])
	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3">
				<h6 className="m-0 font-weight-bold text-primary">ตั้งค่าแสดงแคมเปญตามหน้าเว็บ</h6>
			</div>
			<div className="card-body">
				<Nav fill variant="tabs" defaultActiveKey={initActive} onSelect={handleChangePage}>
					{pages.map((page) => (
						<Nav.Item key={page.path}>
							<Nav.Link eventKey={page.path}>{page.label}</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
				<Detail path={active} />
			</div>
		</div>
	)
}

export default CampaignPageSetting
