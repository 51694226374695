import React, { Fragment, useContext } from "react"
import { Navigation } from "../../../../utils"
import { StoreContext } from "../../Context/store"
import { Button, Nav, Badge } from "react-bootstrap"
import { Input, UploadInput, SelectInput, TableQuiz } from "../../Widgets"
import { v4 as uuidv4 } from "uuid"
import { campaignController } from "../../Services"
import { answerType } from "../../../../helper/const"
import { useHistory } from "react-router"

const Quiz = ({ pageStep = 3, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup],
		quiz: [quiz, setQuiz],
		targetSec: [target],
		rendereStep: rendereStep,
		tab: [tabId, setTabId],
		award: [award],
		awardDelete: [awardDel],
		itemDelete: [itemDel, setItemDel],
		disabled: [disabled],
		ruleDelete: [ruleDel],
		id: id,
	} = useContext(StoreContext)
	const history = useHistory()

	const deleteQuestion = (qid) => {
		let arrDelete = []
		const newQuiz = quiz.filter((list) => {
			if (list.qId !== qid) {
				return list
			} else {
				arrDelete.push(list.qId)
			}
		})

		setItemDel([...arrDelete])
		setQuiz([...newQuiz])
	}

	const onChangeQuestion = (event, qid) => {
		const { name, value } = event.target
		onChangeInQuestion(name, value, qid)
	}

	const onChangeInQuestion = (name, value, qid) => {
		const newData = quiz.map((list) => {
			if (list.qId === qid) {
				console.log(name, value)
				return {
					...list,
					[`${name}`]: value,
					answerCorrect: name === "answerType" ? [] : list.answerCorrect,
				}
			} else {
				return list
			}
		})
		setQuiz([...newData])
	}

	const onUpload = (event, qid, chid) => {
		const { name, files } = event.target
		let { type, size } = files[0]

		if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
			const err = {
				qid: qid,
				chid: chid,
				message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
			}
		} else {
			console.log(name)
			switch (name) {
				case "logoImage":
					onChangeInQuestion(name, files[0], qid)
					break
				case "choiceImage":
					onChangeInChoice(name, files[0], qid, chid)
					break
				default:
					break
			}
		}
	}

	const setUpload = (name, qid, chid, index) => {
		switch (name) {
			case "logoImage":
				const findLogo = quiz.find((list) => {
					return list.qId === qid && list[`${name}`]
				})

				const { logoImage } = findLogo
				if (logoImage) {
					sendToUpload(logoImage, name, qid, chid)
				}
				break

			case "choiceImage":
				const findChoiceImg = quiz.map((list) => {
					return (
						list.qId === qid &&
						list.choices.find((item) => {
							return item.parentId === qid && item.chId === chid && item
						})
					)
				})
				const { choiceImage } = findChoiceImg[index]
				if (choiceImage) {
					sendToUpload(choiceImage, name, qid, chid)
				}
				break
		}
	}

	const sendToUpload = async (file, name, qid, chid) => {
		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, campSetup.type)
		if (response && response.status === 200) {
			let data = response.data.response
			data = {
				...data,
				name: file.name,
			}
			if (chid) {
				onChangeInChoice(name, data, qid, chid)
			} else {
				onChangeInQuestion(name, data, qid)
			}
		}
	}

	const deleteChoice = (qid, chid) => {
		const newQuiz = quiz.map((list) => {
			return {
				...list,
				choices: list.choices.filter((item) => {
					if (item.parentId === qid && item.chId !== chid) {
						return item
					} else if (item.parentId !== qid && item.chId !== chid) {
						return item
					}
				}),
			}
		})

		setQuiz([...newQuiz])
	}

	const onChangeChoice = (event, qid, chid) => {
		const { name, value } = event.target

		switch (name) {
			case "choiceName":
				onChangeInChoice(name, value, qid, chid)
				break

			case "clipeUrl":
				onChangeInChoice(name, value, qid, chid)
				break

			default:
				break
		}
	}

	const onChangeInChoice = (name, value, qid, chid) => {
		const newDataName = quiz.map((list) => {
			return {
				...list,
				choices:
					list.qId === qid
						? list.choices.map((item) => {
								if (item.parentId === qid && item.chId === chid) {
									return {
										...item,
										[`${name}`]: value,
									}
								} else {
									return item
								}
						  })
						: [...list.choices],
			}
		})
		setQuiz([...newDataName])
	}

	const addQuestion = () => {
		const questionId = uuidv4()
		const newQuestion = {
			qId: questionId,
			questionName: "",
			logoImage: "",
			answerType: "single",
			answerCorrect: [],
			choices: [
				{
					chId: uuidv4(),
					parentId: questionId,
					choiceName: "คำตอบที่ 1",
					choiceImage: "",
					clipeUrl: "",
				},
			],
		}

		setQuiz([...quiz, newQuestion])
	}

	const addChoice = (parentId, index) => {
		const newChoice = {
			chId: uuidv4(),
			parentId: parentId,
			choiceName: `คำตอบที่ ${index + 1}`,
			choiceImage: "",
			clipeUrl: "",
		}

		quiz.map((list) => {
			if (list.qId === parentId) {
				return list.choices.push(newChoice)
			} else {
				return list.choices
			}
		})

		setQuiz([...quiz])
	}

	const onChangeSelect = (event, qid) => {
		const { value, name } = event.target
		const arr = quiz.map((list) => {
			if (list.qId === qid) {
				return {
					...list,
					[`${name}`]: value,
				}
			} else {
				return list
			}
		})
		setQuiz([...arr])
	}

	const onChangeSelectMulti = (event, name, qid) => {
		const arr = quiz.map((list) => {
			if (list.qId === qid) {
				return {
					...list,
					[`${name}`]: event,
				}
			} else {
				return list
			}
		})
		setQuiz([...arr])
	}

	const formatChoice = (data, qid) => {
		let x = []
		data.forEach((list) => {
			if (list.qId === qid) {
				list.choices.forEach((item, index) => {
					let arrChoices = {
						value: item.chId,
						label: item.choiceName,
					}
					x.push(arrChoices)
				})
			}
		})

		return x
	}

	const onDeleteImage = async (data, image_index) => {
		const quizIndex = quiz.findIndex((f) => f.qId === data.qId)
		let tempQuiz = [...quiz]
		tempQuiz[quizIndex].logoImage = null
		setQuiz([...tempQuiz])

		if (data?.logoImage?._id) {
			const res = await campaignController().deleteFile(data.logoImage._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	const onSubmit = async () => {
		let data = {}

		data = {
			campSetup,
			target,
			detail: quiz,
			award,
			itemDel,
			awardDel,
			ruleDel,
		}

		if (id) {
			const resposne = await campaignController().updateCampaign(data, id)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		} else {
			const resposne = await campaignController().createCampaign(data)

			if (resposne && resposne.status === 200) {
				history.push("/campaign/list")
			}
		}
	}

	return (
		<Fragment>
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Quiz</h5>
				</div>
				<div className="card-body">
					<div className="row">
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							<Button className="btn btn-success" onClick={() => addQuestion()} disabled={disabled ? "disabled" : ""}>
								Add Question
							</Button>
							<Button className="btn btn-success offset-1" onClick={() => onSubmit()} disabled={disabled ? "disabled" : ""}>
								Submit
							</Button>
						</h6>
					</div>

					<Nav variant="pills" activeKey={tabId}>
						{quiz.map((list, index) => (
							<Nav.Item key={list.qId}>
								<Nav.Link eventKey={list.qId} onSelect={(e) => setTabId(e)}>
									{` Quiz ${index + 1}`}
								</Nav.Link>
								{tabId === list.qId && quiz.length > 1 && (
									<Badge
										style={{ fontSize: "14px", position: "relative", borderRadius: "100%", bottom: "46px", left: "50px", textTransform: "capitalize" }}
										variant="danger"
										onClick={() => deleteQuestion(list.qId)}
									>
										x
									</Badge>
								)}
							</Nav.Item>
						))}
					</Nav>
					<hr />
					{quiz.map(
						(list, order) =>
							tabId === list.qId && (
								<Fragment key={list.qId}>
									<div className="row">
										<div className="col-3">
											<h6 className="m-0 font-weight-bold text-primary">
												{`Quiz No.${order + 1}`}
												<a style={{ color: "red" }}> *</a>
											</h6>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-6">
											<Input
												label="Title"
												id="qname"
												name="questionName"
												value={list.questionName}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
											/>
											<br />
											<UploadInput
												label="Image"
												id="qImage"
												name="logoImage"
												placeholder="Browse..."
												value={(list.logoImage || {}).url ? list.logoImage.name : "Browse..."}
												onChange={(event) => onUpload(event, list.qId)}
												onClick={() => setUpload("logoImage", list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
												list={list.logoImage ? list.logoImage.name : ""}
												onDelete={(index) => onDeleteImage(list, index)}
												remark="Dimension: 560x315px (16:9)"
											/>
											<br />
											{/* {console.log(`LOG: list.answerType ---> `,list.answerType)}
											<SelectInput
												label="Answer Type"
												id="type"
												name="answerType"
												value={list.answerType}
												data={answerType}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												require
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
											/>
											<br /> */}
											{list.answerType !== "writting" && (
												<SelectInput
													label="Correct Answer"
													id="correct"
													name="answerCorrect"
													value={list.answerCorrect}
													data={formatChoice(quiz, list.qId)}
													onChange={(event) =>
														list.answerType === "multiple" ? onChangeSelectMulti(event, "answerCorrect", list.qId) : onChangeSelect(event, list.qId)
													}
													require
													multiple={list.answerType === "multiple"}
													compCol="col-8"
													disabled={disabled ? "disabled" : ""}
												/>
											)}
											<br />
											<Input
												label="Score"
												id="score"
												name="score"
												value={list.score}
												onChange={(event) => onChangeQuestion(event, list.qId)}
												compCol="col-8"
												disabled={disabled ? "disabled" : ""}
											/>
											<br />
										</div>
									</div>
									<br />
									{list.choices.map((item, index) => (
										<Fragment key={item.chId}>
											<div className="row">
												<div style={{ padding: "10px" }} />
												<div className="col-3">
													<h6 className="m-0 font-weight-bold text-primary">
														{`Choice No. ${index + 1}`}
														<a style={{ color: "red" }}> *</a>
													</h6>
												</div>
												{list.choices.length > 1 && (
													<div className="col-3 row justify-content-end">
														<Button className="btn btn-danger" onClick={() => deleteChoice(list.qId, item.chId)} disabled={disabled ? "disabled" : ""}>
															Delete
														</Button>
													</div>
												)}
											</div>
											<br />
											<div className="row">
												<div style={{ padding: "10px" }} />
												<div className="col-6">
													<Input
														label="Title"
														id="cname"
														name="choiceName"
														value={item.choiceName}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId)}
														compCol="col-9"
														disabled={disabled ? "disabled" : ""}
													/>
													<br />
													{/* <UploadInput
														label="Image"
														name="choiceImage"
														id="cImage"
														placeholder="Browse..."
														value={(item.choiceImage || "").url ? "Browse..." : (item.choiceImage || "").name}
														onChange={(event) => onUpload(event, list.qId, item.chId)}
														onClick={() => setUpload("choiceImage", list.qId, item.chId, order)}
														compCol="col-9"
														disabled={disabled ? "disabled" : ""}
													/>
													<br /> */}
													{/* <Input
														label="Clip Url"
														id="cClip"
														name="clipeUrl"
														value={item.clipeUrl}
														onChange={(event) => onChangeChoice(event, list.qId, item.chId)}
														compCol="col-9"
														disabled={disabled ? "disabled" : ""}
													/>
													<br /> */}
												</div>
											</div>
										</Fragment>
									))}
									<div className="row">
										<h6 className="col-md-6 text-left">
											<Button className="btn btn-success" onClick={() => addChoice(list.qId, list.choices.length)} disabled={disabled ? "disabled" : ""}>
												Add Choice
											</Button>
										</h6>
									</div>
									<hr />
								</Fragment>
							)
					)}
					<TableQuiz data={quiz} />
				</div>
			</div>
		</Fragment>
	)
}

export default Quiz
