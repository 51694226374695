import moment from "moment"
import React, { useEffect, useState, useContext, Fragment } from "react"

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom"
import { campaignController } from "../../Services"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
import { StoreContext } from "../../Context/store"
import { Navigation } from "../../../../utils"

const Conversion_Task_Completion = ({ pageStep = 5, onChangeStep, pageId }) => {
	const admin = User.getCookieData()
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		id: id,
	} = useContext(StoreContext)

	const [datas, setDatas] = useState([
		{
			conversion: 0,
			task_completion: [],
		},
	])
	const [sensitive, setSensitive] = useState(true)

	useEffect(() => {
		permission()
		fetchData()
	}, [])

	const calPercent = (input = 0, all) => {
		if (all === 0) return 0
		return Math.round((input / all) * 100)
	}
	const fetchData = async () => {
		let arrData = []
		let conversion_data = {}
		let task_data = []
		const res_conversion = await campaignController().getConversion(id)

		if (res_conversion.status === 200) {
			const { data } = res_conversion.data
			conversion_data = data
		}

		// const res_task = await campaignController().getTaskCompletion(id)
		// if (res_task.status === 200) {
		// 	const {
		// 		data: { task_completion },
		// 	} = res_task.data
		// 	console.log(`LOG: res_task.data ---> `, res_task.data)
		// 	task_data = task_completion
		// }
		// const countPlayed = conversion_data?.reportSummary?.count || 0
		// const countGuest = conversion_data?.reportSummary?.countGuest || 0
		const newData = {
			conversion: {
				percent: calPercent(conversion_data.member, conversion_data.allMember),
				member: conversion_data.member,
				all: conversion_data.allMember,
				allPlayedCount: conversion_data?.questions?.reduce((a, b) => a + b.count, 0),
			},
			task_completion: conversion_data?.questions || [],
		}
		// console.log(`LOG: newData ---> `, newData)
		arrData.push(newData)
		setDatas(arrData)
	}

	const permission = () => {
		if (!checkPermission(admin.permission, ["member_sensitive_data_view"])) {
			setSensitive(false)
		}
	}

	const formatConversion = (cell, row, enumObject, index) => {
		return (
			<p>
				{/* {console.log(`LOG: cell ---> `, cell.conversion)} */}
				{cell.percent} % ({cell.member} members joined from {cell.all} members)
			</p>
		)
	}

	const formatTask = (cell, row, enumObject, index) => {
		return (
			<ul className="table-task ">
				{cell?.map((question, index) => (
					<li key={index}>
						<label>
							{question.questionName}
							<span>
								( ทั้งหมด {question?.count} คน {calPercent(question?.count, row.conversion.allPlayedCount)}% )
							</span>
							<span className="guest">( ไม่เป็นสมาชิก {question.countGuest} คน )</span>
						</label>
						<ul className="table-task ">
							{question.choices.map((choice) => (
								<li key={choice._id}>
									- {choice.choiceName}
									<span>
										( ทั้งหมด {choice?.count} คน {calPercent(choice?.count, question?.count)}% )
									</span>
									<span className="guest">( ไม่เป็นสมาชิก {choice?.countGuest} คน )</span>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		)
	}

	return (
		<Fragment>
			<div style={{ paddingTop: "10px" }}>
				<BootstrapTable data={datas} className="table table-striped">
					<TableHeaderColumn
						dataField="conversion"
						dataFormat={formatConversion}
						tdStyle={{
							wordWrap: "break-word",
							whiteSpace: "normal",
						}}
						isKey
					>
						Conversion
					</TableHeaderColumn>
					<TableHeaderColumn
						tdStyle={{
							wordWrap: "break-word",
							whiteSpace: "normal",
						}}
						dataField="task_completion"
						dataFormat={formatTask}
					>
						Task Completion
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		</Fragment>
	)
}

export default Conversion_Task_Completion
