import React, { useState, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import _get from "lodash/get"
import useNewsVote from "./context"
import { Divider } from "semantic-ui-react"
import { FormInput, FormTextarea } from "../../widgets/Form"
import DateTime from "@nateradebaugh/react-datetime"
import moment from "moment"
import { useEffect } from "react"
import { SectionStyled } from "../../styled"
import { changePrizeIsActive } from "./service"
const PrizeAnnounce = () => {
	const { data, handleChange, handleSave } = useNewsVote()
	const [prizeSelected, setPrizeSelected] = useState(0)
	const [isChanged, setIsChanged] = useState(false)
	const [showToggle, setShowToggle] = useState(false)
	const prize = useMemo(() => _get(data, `prize.items[${prizeSelected}]`, {}), [prizeSelected])
	const handleToggle = async (e) => {
		await changePrizeIsActive(data._id, e.target.checked)
	}
	useEffect(() => {
		const _showToggle = moment(_get(data, "setting.prizeAnnounceDate")).isBefore(moment())
		setShowToggle(_showToggle)
		return () => {}
	}, [_get(data, "setting.prizeAnnounceDate")])

	const handleSubmitChange = async () => {
		await handleSave()
	}

	const handleChangeMembers = ({ name, value }) => {
		const _value = value.split("\n")
		if (_value.length <= prize.amount) {
			handleChange({ name, value: _value })
			setIsChanged(true)
		}
	}
	return (
		<div>
			<div className="mb-4 d-flex align-items-end justify-content-between ">
				<FormInput
					label={"วันประกาศผล"}
					required
					renderInput={() => (
						<div className="d-flex">
							<DateTime
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={_get(data, "setting.prizeAnnounceDate")}
								onBlur={(value) => {
									handleChange({ name: "setting.prizeAnnounceDate", value })
									setIsChanged(true)
								}}
								inputProps={{ readOnly: true }}
							/>
							<Button
								onClick={handleSubmitChange}
								disabled={!isChanged}
								className="w-100 ml-2 mr-2"
								variant={isChanged ? "success" : "dark"}
								style={{ cursor: !isChanged && "not-allowed" }}
							>
								ยืนยันการเปลี่ยนแปลง
							</Button>
						</div>
					)}
				/>
				{showToggle && (
					<div className={`d-flex align-items-center`}>
						<div className="mr-2 font-weight-bold text-md ">{`ประกาศผลรายชื่อผู้ได้รับรางวัลบนหน้าเว็บไซต์`}</div>
						<Form.Check type="switch" id="open-switch" defaultChecked={_get(data, "prize.isActive")} onChange={handleToggle} />
					</div>
				)}
			</div>
			<div
				style={{
					display: "flex",
					overflowX: "auto",
					padding: "0.5rem",
				}}
			>
				{_get(data, "prize.items", []).map((item, index) => (
					<Button
						key={item.key}
						onClick={() => setPrizeSelected(index)}
						variant={prizeSelected === index ? "dark" : "outline-dark"}
						style={{
							marginRight: "0.5rem",
							whiteSpace: "nowrap",
						}}
					>{`รางวัลที่ ${index + 1}`}</Button>
				))}
			</div>
			<Divider />
			<div className="row">
				<SectionStyled key={`prize-data-${prizeSelected}`} className="col-12 col-xl-6">
					<div className="row">
						<div className="col-12 col-xl-12 d-flex mb-4">
							<div
								style={{
									borderRadius: "0.5rem",
									width: "200px",
									height: "112px",
									minWidth: "200px",
									minHeight: "112px",
									backgroundImage: `url(${prize?.img?.url})`,
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center center",
								}}
							/>
							<div className="px-4">
								<h5 className="font-weight-bold">{prize?.title}</h5>
								<h6>
									จำนวน <span className="text-primary">{prize?.amount}</span> รางวัล
								</h6>
							</div>
						</div>

						<div className="col-12 col-xl-12">
							<FormTextarea
								label="รายชื่อผู้ได้รับรางวัล"
								required
								remark={"หนึ่งบรรทัดต่อหนึ่งคน"}
								rows={prize?.amount}
								placeholder="ชื่อ นามสกุล"
								name={`prize.items[${prizeSelected}].members`}
								onChange={handleChangeMembers}
								value={(prize?.members || []).join("\n")}
							/>
						</div>
					</div>
				</SectionStyled>
			</div>
		</div>
	)
}

export default PrizeAnnounce
