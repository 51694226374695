import moment from "moment"
import React, { useEffect, useState, useContext, Fragment } from "react"

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom"
import { campaignController } from "../../Services"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
import { StoreContext } from "../../Context/store"
import { Navigation } from "../../../../utils"
import Conversion_Task_Completion from "./conversionTask"
import { Pagination } from "semantic-ui-react"

const MemberVote = ({ pageStep = 5, onChangeStep, pageId }) => {
	const admin = User.getCookieData()
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		id: id,
	} = useContext(StoreContext)

	const [datas, setDatas] = useState()
	const [sensitive, setSensitive] = useState(true)

	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const limit = 20
	const handlePageChange = (e, data) => {
		setPage(data.activePage)
		fetchData(data.activePage)
	}

	useEffect(() => {
		permission()
		fetchData(1)
	}, [])

	const permission = () => {
		if (!checkPermission(admin.permission, ["member_sensitive_data_view"])) {
			setSensitive(false)
		}
	}

	const fetchData = async (_page) => {
		const response = await campaignController().getMemberPlayed(id, _page, limit)
		const {
			data: { data },
			status,
		} = response
		if (status === 200) {
			setDatas(data.data)
			setTotal(data.total)
		}
	}

	const formatIndex = (cell, row, enumObject, index) => {
		return <p>{(page - 1) * limit + index + 1}</p>
	}

	const formatTime = (cell, row, enumObject, index) => {
		return moment(cell).format("DD/MM/YYYY H:mm")
	}

	const updatePoint = async () => {
		const { data } = await campaignController().updatePoints(id)
		if (data.status === 200) {
			alert(`อัพเดทเรียบร้อย มี member ทั้งหมด ${data.data.members} คน`)
		} else {
			console.log(`LOG: error ---> `, data.message)
		}
	}

	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Member</h5>
				</div>

				<div className="card-body">
					{/* <button className="btn btn-info mb-1" onClick={updatePoint}>
						อัพเดทคะแนน
					</button> */}
					<Conversion_Task_Completion />
					<a className="btn btn-success mb-2" target="__blank" href={`${process.env.REACT_APP_API_ROOT}/admin/campaign/export-csv/${id}`}>
						ExportCSV
					</a>
					<BootstrapTable data={datas} className="table table-striped">
						<TableHeaderColumn dataField="userId" width="50" dataAlign="center" dataFormat={formatIndex} isKey>
							ลำดับ
						</TableHeaderColumn>
						<TableHeaderColumn dataField="userId" width="200">
							user_id
						</TableHeaderColumn>
						<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.display_name} width="200">
							ชื่อ-นามสกุล
						</TableHeaderColumn>
						{sensitive && (
							<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.gender} width="120">
								เพศ
							</TableHeaderColumn>
						)}
						{sensitive && (
							<TableHeaderColumn dataField="user" dataFormat={(cell) => cell.phone} width="120">
								เบอร์โทร
							</TableHeaderColumn>
						)}
						{/* <TableHeaderColumn dataField="team" width="120" dataFormat={formatMatch}>
							ทีม
						</TableHeaderColumn> */}
						{/* <TableHeaderColumn dataField="team" width="120" dataFormat={formatScore}>
							คำตอบ
						</TableHeaderColumn>
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatCorrect}>
							ตอบถูก
						</TableHeaderColumn>
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatPoints}>คะแนน</TableHeaderColumn> */}
						<TableHeaderColumn dataField="created_time" width="150" dataFormat={formatTime}>
							วันที่เล่น
						</TableHeaderColumn>
					</BootstrapTable>
					<Pagination activePage={page} onPageChange={handlePageChange} totalPages={Math.ceil(total / limit)} />
				</div>
			</div>
		</Fragment>
	)
}

export default MemberVote
