import DateTime from "@nateradebaugh/react-datetime"
import React, { useEffect, useState } from "react"
import { FormInput, FormCheckbox, FormUpload } from "../../widgets/Form"
import { SectionStyled, ContainerStyled } from "../../styled"
import { Button, Form } from "react-bootstrap"
import { isNil } from "lodash"
import Editor from "../../widgets/Editor"

import useEuroFootball from "./context"

const EuroFootballSetup = () => {
	const { euroSetup, setEuroSetup, stepNow, handleChangeStep } = useEuroFootball()
	const [dataSetup, setDataSetup] = useState(euroSetup)

	useEffect(() => {
		setDataSetup(euroSetup)
	}, [euroSetup])

	const handleChangeData = ({ name, value }) => {
		const newData = {
			...dataSetup,
			[`${name}`]: value,
		}
		console.log("newData => ", newData)
		setDataSetup(newData)
		setEuroSetup(newData)
	}

	const handleChangeEditor = ({ target }) => {
		const { name, value } = target
		const new_result = {
			...dataSetup,
			[`${name}`]: value,
		}
		console.log(new_result)
		setDataSetup(new_result)
		setEuroSetup(new_result)
	}

	return (
		<ContainerStyled>
			<SectionStyled className="mb-4">
				<h1>ตั้งค่าแคมเปญ</h1>
				<div className="row ml-0 mb-2">
					<Form.Check
						label="Active"
						type="switch"
						id="isActive"
						defaultChecked={dataSetup.isActive}
						onBlur={(value) => handleChangeData({ name: "isActive", value: value.target.checked })}
					/>
				</div>
				<FormInput
					name={"name"}
					label={"ชื่อแคมเปญ"}
					required
					onChange={handleChangeData}
					defaultValue={dataSetup.name || ""}
					// errorMessage="กรุณากรอก"
				/>

				<FormInput
					label={"ชื่อ URL ของแคมเปญ"}
					name="slagName"
					required
					defaultValue={dataSetup.slagName || ""}
					onChange={handleChangeData}
					renderInput={(inputProps) => (
						<div style={{ display: "flex" }}>
							<input
								style={{ width: "70%" }}
								className={`form-control mr-2 font-weight-bold`}
								disabled
								value={`${process.env.REACT_APP_LINK_CAMPAIGN}/fanpanchamp`}
							/>
							<input style={{ width: "30%" }} {...inputProps} />
						</div>
					)}
				/>

				<FormInput
					label={"ระยะเวลากิจกรรม"}
					required
					renderInput={() => (
						<div className="non-editable" style={{ display: "flex", alignItems: "center" }}>
							<DateTime
								name="startDate"
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={dataSetup.startDate || ""}
								onBlur={(value) => handleChangeData({ name: "startDate", value })}
							/>
							<span className="mx-2 font-weight-bold">-</span>
							<DateTime
								name="endDate"
								dateFormat={"DD-MM-YYYY"}
								timeFormat={true}
								className="w-100"
								defaultValue={dataSetup.endDate || ""}
								onBlur={(value) => handleChangeData({ name: "endDate", value })}
							/>
						</div>
					)}
				/>
				<label className="label font-weight-bold">
					เงื่อนไขและกติกา
					<span className="ml-1">*</span>
				</label>
				{!isNil(dataSetup.rule) && (
					<Editor defaultValue={dataSetup.rule} onChange={(text) => handleChangeEditor({ target: { name: "rule", value: text } })} />
				)}
				{isNil(dataSetup.rule) && <Editor defaultValue={""} onChange={(text) => handleChangeEditor({ target: { name: "rule", value: text } })} />}
			</SectionStyled>
			<Button className="float-right mt-2 non-preview" onClick={() => handleChangeStep(stepNow + 1, dataSetup, "setup")} variant="primary">
				ถัดไป
			</Button>
		</ContainerStyled>
	)
}

export default EuroFootballSetup
