import { isEmpty } from "lodash"
import React from "react"
import { Upload } from "../../../utils"

export const UploadInput = ({
	label,
	placeholder,
	id,
	name,
	key,
	value,
	onChange,
	onClick,
	require,
	list = [],
	labelCol = "col-3 row-label",
	compCol = "col-4",
	spaceCol = "col-0",
	disabled,
	muti,
	onDelete,
	remark,
	validate
}) => {
	return (
		<div className="row">
			<div className={spaceCol} />
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={spaceCol} />
			<div className={compCol}>
				{/* <input type="file" id={id} name={name} key={key} onChange={onChange}  /> */}
				<Upload  id={id} name={name} label={placeholder} key={key} value={value} onChange={onChange} onClick={onClick} disabled={disabled} validate={validate} />
				<div className="pl-1 my-1" style={{ color: "orange", fontSize: "0.85rem" }}>
					{remark}
				</div>
				{validate && <div style={{ color: "red" }}>{validate}</div>}
				{muti ? (
					<ul style={{ display: "contents" }}>
						{(list || []).map((list, index) => (
							<li key={index} className="list-item">
								<p className="text-ellipsis nowrap my-auto ">{list.name}</p>
								{onDelete && <i onClick={() => onDelete(index)} class="close link icon red " />}
							</li>
						))}
					</ul>
				) : (
					!isEmpty(list) && (
						<div className="list-item">
							<p className="text-ellipsis nowrap my-auto">{list}</p>
							{onDelete && <i class="close link icon red" onClick={() => onDelete()} />}
						</div>
					)
				)}
			</div>
		</div>
	)
}
