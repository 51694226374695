import React from "react"
import { Switch, Route, HashRouter } from "react-router-dom"
import Routing from "./route/routing"

import History from "./history"
import Sidebar from "./components/layouts/Sidebar"
import Topbar from "./components/layouts/Topbar"
import Footer from "./components/layouts/Footer"

import Login from "./components/login/View"
import G2fa from "./components/g2fa/View"

import "./assets/css/main.min.css"
import "./assets/css/main.css"
import "./assets/css/form.css"
import "./assets/css/table-style.css"
import "./assets/css/table-compare.css"

import User from "./libs/admin"

export default function App() {
	const user = User.getCookieData()
	const g2fa = User.getCookieG2FA()
	return (
		<>
			<HashRouter history={History}>
			{(!user || !g2fa) ? (
				<Switch>
					{/* <Login /> */}
					<Route exact path="/" component={Login} />
					<Route exact path="/g2fa" component={G2fa} />
				</Switch>
			) : (
				<div id="wrapper">
					<Sidebar />
					<div id="content-wrapper" component="d-flex flex-column">
						<div id="content">
							<Topbar />
							<div className="container-fluid">
								<Switch>
									<Routing />
								</Switch>
							</div>
						</div>
						<Footer />
					</div>
				</div>
			)}
			</HashRouter>
		</>
	)

	// <HashRouter history={History}>
	// 	<div id="wrapper">
	// 		<Sidebar />
	// 		<div id="content-wrapper" component="d-flex flex-column">
	// 			<div id="content">
	// 				<Topbar />
	// 				<div className="container-fluid">
	// 					<Switch>

	// 						<Route exact path="/home" component={Home} />
	// 						<Route exact path="/dashboard" component={Dashboard} />
	// 						<Route exact path="/report/group" component={GroupReport} />
	// 						<Route exact path="/report/persons" component={PersonsReport} />
	// 						<Route exact path="/report/journey" component={Journey} />
	// 						<Route exact path="/member/list" component={MemberList} />
	// 						<Route exact path="/member/edit/:id" component={MemberForm} />
	// 						<Route exact path="/content/list/waiting" component={ListWaiting} />
	// 						<Route exact path="/content/list/approve" component={ListApprove} />

	// 						<Route exact path="/profile/password" component={ChangePassword} />
	// 						{user.position === "admin" ? (
	// 							<>
	// 								<Route exact path="/user/list" component={UserList} />
	// 								<Route exact path="/user/edit/:id" component={UserForm} />
	// 								<Route exact path="/notificationcenter/list" component={NotificationList} />
	// 								<Route exact path="/notificationcenter/edit/:id" component={NotificationSetup} />

	// 							</>
	// 						) : (
	// 							""
	// 						)}
	// 					</Switch>
	// 				</div>
	// 			</div>
	// 			<Footer />
	// 		</div>
	// 	</div>
	// </HashRouter>
}
