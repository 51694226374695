import React, { Fragment, useContext, useState } from "react"
import { StoreContext } from "../Context/store"
import { Navigation } from "../../../utils"
import { stepVote, stepQuiz, type, rule, taskRule, positon } from "../../../helper/const"
import { Input, Textarea, SelectInput, SelectScore, DatePeriod, UploadInput, InputSlagName } from "../Widgets"
import moment from "moment"
import { campaignController } from "../Services"
import { Button, Nav } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import Editor from "../Widgets/Editor"

const Award = ({ pageStep = 1, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		award: [award, setAward],
		awardDelete: [awardDel, setAwardDel],
	} = useContext(StoreContext)
	const [myImage, setImage] = useState([])

	const data = {
		adsAward: campSetup.adsAward,
		noteAward: campSetup.noteAward,
		award: award,
	}

	const onUpload = (event, aid) => {
		const { files } = event.target
		let { type, size } = files[0]
		if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
			const err = {
				message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
			}
		} else {
			let arrPrizeImg = []
			arrPrizeImg.push({ file: files[0], prizeId: aid })
			setImage([...myImage, ...arrPrizeImg])
		}
	}

	const setUpload = async (name, aid) => {
		const uploadImg = myImage.find((list) => {
			return list.prizeId === aid
		})
		const fileImg = uploadImg?.file

		const formData = new FormData()
		formData.append("File", fileImg, fileImg.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, "award_image")

		if (response && response.status === 200) {
			let data = response.data.response
			const result = award.map((list) => {
				if (list.awardId === aid) {
					return {
						...list,
						imgSrc: {
							...data,
							name: fileImg.name,
						},
					}
				} else {
					return list
				}
			})

			setAward(result)

			const newMyImage = myImage.map((list) => {
				if (list.prizeId === aid) {
					return {}
				}
				return list
			})

			setImage(newMyImage)
		}
	}

	const addAwards = () => {
		const newAward = {
			awardId: uuidv4(),
			title: "",
			imgSrc: "",
			indexAward: award.length + 1,
			calculate: 1,
			members: [
				{
					memId: uuidv4(),
					name: "",
				},
			],
		}
		setAward([...award, newAward])
	}

	const onChangeValue = (event, aid, type) => {
		const { name, value } = event.target
		let arr = []
		if (name === "calculate") {
			// ไว้สำหรับเก็บข้อมูล member ของ textarea ที่เคยกรอกไว้แล้ว
			const oldMembers = award.find((item) => item.awardId === aid)?.members
			if (oldMembers) {
				arr = [...oldMembers]
			}
			let len = arr.length
			if (value - len > 0) {
				// ถ้าเกิดว่า value ที่กรอกเพิ่มเข้ามามันมากกว่าของเดิม ให้เพิ่มเข้าไปตามจำนวนความต่าง
				for (let i = 0; i < value - len; i++) {
					arr.push({ memId: uuidv4(), name: "" })
				}
			}

			if (isEmpty(value)) {
				// ถ้าเกิดว่า value เป็น null หรือ "" จะให้เพิ่มใหม่ให้หมด
				for (let i = 0; i < value; i++) {
					arr.push({ memId: uuidv4(), name: "" })
				}
			} else {
				// ถ้าเกิดว่า value ที่เข้ามามันน้อยกว่าของเดิมให้ลบไปตามจำนวนความต่าง
				arr = [...arr.slice(0, value)]
			}
		}
		const result = award.map((list) => {
			if (list.awardId === aid) {
				let cvt
				if (type === "number") {
					if (!isEmpty(value)) {
						cvt = parseInt(value ? value.replace(/[^0-9.]/g, 0) : 0)
					} else {
						cvt = ""
					}
				}
				return {
					...list,
					[`${name}`]: type === "number" ? cvt : value,
					members: name === "calculate" ? arr : list.members,
				}
			} else {
				return list
			}
		})

		setAward(result)
	}

	const deleteChoice = (aid) => {
		const arrDel = []
		const delAward = award.filter((list) => {
			if (list.awardId !== aid) {
				return list
			} else {
				arrDel.push(aid)
			}
		})

		setAwardDel([...arrDel])
		setAward([...delAward])
	}

	const onDeleteImage = async (_data) => {
		const data = { ..._data }
		let tempAward = [...award]
		const award_index = tempAward.findIndex((f) => f._id === data._id)
		tempAward[award_index].imgSrc = null
		setAward(tempAward)
		if (data && data.imgSrc && data.imgSrc._id) {
			const res = await campaignController().deleteFile(data.imgSrc._id)
			if (res.status != 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} data={data} schema="camp-award" />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Award</h5>
				</div>

				<div className="card-body">
					<div className="row">
						<h6 className="col-10">
							{/* <Textarea
								label="หน้าแรก"
								id="ads"
								name="campAdsAward"
								value={campSetup.adsAward}
								onChange={(event) => setCampSetup({ ...campSetup, adsAward: event.target.value })}
								disabled={disabled ? "disabled" : ""}
								require
							/> */}
							<Editor
								require
								label={
									<>
										ข้อความหน้าแรก
										<br />
										(ส่วนล่าง)
										<br />
										ไม่ควรเกิน 2 บรรทัด
									</>
								}
								defaultValue={campSetup.adsAward}
								onChange={(text) => setCampSetup({ ...campSetup, adsAward: text })}
								placeholderText="ข้อความหน้าแรก (ส่วนล่าง)"
								labelCol="col-2"
								editorCol="col-8"
								validate={error["adsAward"]}
							/>
							<br />
							<Editor
								label={
									<>
										เงื่อนไขรับของรางวัล
										<br />
										(แสดงใต้รายการของรางวัล)
									</>
								}
								require
								defaultValue={campSetup.noteAward}
								placeholderText="เงื่อนไขรับของรางวัล (แสดงใต้รายการของรางวัล)"
								onChange={(text) => setCampSetup({ ...campSetup, noteAward: text })}
								labelCol="col-2"
								editorCol="col-8"
								validate={error["noteAward"]}
							/>
							<br />
							{/* <Textarea
								label="หมายเหตุ"
								id="desc"
								name="campNote"
								value={campSetup.noteAward}
								onChange={(event) => setCampSetup({ ...campSetup, noteAward: event.target.value })}
								disabled={disabled ? "disabled" : ""}
								require
							/>
							<br /> */}
						</h6>
						<h6 className="col-2 text-right">
							<Button className="btn btn-success" onClick={() => addAwards()} disabled={disabled ? "disabled" : ""}>
								เพิ่มรางวัล
							</Button>
						</h6>
					</div>

					<hr />

					{award.map((list, order) => (
						<Fragment key={list.awardId}>
							<div className="row">
								<div className="col-3">
									<h6 className="m-0 font-weight-bold text-primary">
										{`รางวัลที่ ${list.indexAward}`}
										<a style={{ color: "red" }}> *</a>
									</h6>
								</div>
							</div>

							{award.length > 1 && (
								<div className="col-9 row justify-content-end">
									<Button className="btn btn-danger" onClick={() => deleteChoice(list.awardId)} disabled={disabled ? "disabled" : ""}>
										Delete
									</Button>
								</div>
							)}
							<br />
							<div className="row">
								<div className="col-6">
									<Input
										label="ชื่อรางวัล"
										id="awardTitle"
										name="title"
										value={list?.title}
										onChange={(event) => onChangeValue(event, list?.awardId)}
										compCol="col-8"
										disabled={disabled ? "disabled" : ""}
										rows={2}
										validate={error[`award[${order}].title`]}
										require
									/>
									<br />
									<UploadInput
										label="รูปประกอบ"
										id="awardImage"
										name="imgSrc"
										placeholder="Browse..."
										value={
											myImage.find((item) => {
												return item.prizeId === list.awardId
											})?.file?.name
										}
										onChange={(event) => onUpload(event, list?.awardId)}
										onClick={() => setUpload("logoImage", list?.awardId)}
										compCol="col-8"
										disabled={disabled ? "disabled" : ""}
										list={list.imgSrc ? list.imgSrc.name : ""}
										onDelete={() => onDeleteImage(list)}
										remark="Dimension: 200x112px (16:9)"
										validate={error[`award[${order}].imgSrc`]}
										require
									/>
									<br />
									<Input
										label="จำนวน"
										id="awardCal"
										type="number"
										name="calculate"
										// value={list?.calculate}
										defaultValue={list?.calculate}
										onBlur={(event) => onChangeValue(event, list?.awardId, "number")}
										compCol="col-8"
										disabled={disabled ? "disabled" : ""}
									/>
									<br />
									<hr />
								</div>
							</div>
						</Fragment>
					))}
				</div>
			</div>
		</Fragment>
	)
}

export default Award
