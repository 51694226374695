import React, { useContext } from "react"
import Quiz from "./details/quiz"
import Detail from "./details/vote"
import Score from "./details/score"
import { StoreContext } from "../Context/store"

const Question = ({ pageStep = 3, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup],
	} = useContext(StoreContext)

	switch (campSetup.type) {
		case "Vote":
			return <Detail pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} error={error} />
		case "Quiz":
			return <Quiz pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} error={error} />
		case "Score":
			return <Score pageStep={pageStep} onChangeStep={onChangeStep} pageId={pageId} />
		default:
			break
	}
}

export default Question
