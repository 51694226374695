import React, { useContext, Fragment } from "react"

import Container from "reactstrap/lib/Container"

import { SC_RecruitNavigate } from "./styled"
import { StoreContext } from "../../components/Campaign/Context/store"

export const Navigation = ({ PageStep, dataStep = [], onChangeStep, type = "Vote", data, schema }) => {
	const {
		campSetup: [campSetup],
	} = useContext(StoreContext)
	let tempIndex = 1
	const checkCondition = (list) => {
		// console.log(`LOG: list ---> `,list)
		// console.log(`LOG: campSetup.position ---> `,campSetup.position)
		if (typeof list.position === "undefined") return true
		else if (campSetup.position == list.position) return true
		else return false
	}
	return (
		<Fragment>
			<SC_RecruitNavigate>
				<Container>
					<ul>
						{dataStep.length > 0 &&
							dataStep.map(
								(list, index) =>
									checkCondition(list) && (
										<li key={list.id} className={PageStep >= list.id ? "active" : ""}>
											<a onClick={() => onChangeStep(list.id, data, schema)}>
												<strong>{tempIndex++}</strong>
												<span>{list.name}</span>
											</a>
										</li>
									)
							)}
					</ul>
				</Container>
			</SC_RecruitNavigate>
		</Fragment>
	)
}
