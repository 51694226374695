import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap"
/** Datepicker use */
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import axios from "axios"

import { NumberFormatter, LinkMemberFormatter } from '../dashboard/Formatter'
import Autocomplete from '../member/Autocomplete'
import MemberActivities from './components/MemberActivities'
import MemberTraffic from './components/MemberTraffic'
import MemberTrafficTrPlus from "./components/MemberTrafficTrPlus"
import TopTrPlus from '../dashboard/TopTrPlus'
import TopContent from '../dashboard/TopContent'
import SpecialContent from '../dashboard/SpecialContent'
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

export const fieldUserId = "user_id"
export const fieldDisplayName = "display_name"

const PersonsReport = (props) => {
    const [web, setWeb] = useState("online")
    const user = User.getCookieData()

	const [startdate, setStartdate] = useState( moment().subtract(7, "days").format('YYYY-MM-DD') )
    const [enddate, setEnddate] = useState( moment().format('YYYY-MM-DD') )
    const [tags, setTags] = useState([])
    const [members, setMembers] = useState([])

    // eslint-disable-next-line
    const [arrSectionTopic, setSectionTopic] = useState(["sectionTopic"])
    // eslint-disable-next-line
    const [arrNothing, setArrNothing] = useState([])

    const fieldTableKey = "ts"
    const fieldVisitingdays = "visitingdays"
    const fieldLoginChannel = "loginchannel"

    useEffect(() => {
        const check = checkPermission(user.permission, "report_persons_edit")
        async function fetchData() {
            if(tags.length > 0){
                const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/report/`
                const mainparameter = { startdate, enddate, members: tags.map(obj => obj[fieldUserId]) }
        
                //let { data: resV } = await axios.post(mainurl + 'personsVisitingdaysCount', mainparameter,  { withCredentials: true })
                setMembers(tags.map( obj => {
                    //let i = resV.data.findIndex(v => v[fieldUserId] === obj[fieldUserId])
                    obj[fieldVisitingdays] = 0//resV.data[i][fieldVisitingdays]
                    
                    let loginchannel = ["facebook","google","huawei"].reduce((acc, _, i, channels) => {
                        if(obj[channels[i]] === "yes"){
                            channels.splice(i+1) // Splicing channels array to stop reduce function.
                            return channels[i]
                        }
                        return acc
                    }, "email")
                    obj[fieldLoginChannel] = loginchannel
                    return obj
                }))
            }
        }
        if(!check) {
            props.history.push('/')
        }
        fetchData()
    }, [startdate, enddate, tags])
    
    const getDatePicker = (name, defaultvalue) => {
        return (<DateTime dateFormat="YYYY-MM-DD" timeFormat={false}
            defaultValue={defaultvalue} onChange={date => { 
                date = moment(date).format('YYYY-MM-DD')
                name === "startdate" && setStartdate(date)
                name === "enddate" && setEnddate(date)
            }} />)
    }

    const saveMembers = membersSearchResult => {
        // Filter Out Deleted Members
        let memberIfSomeRemoved = tags.filter(obj => membersSearchResult.findIndex(element => element[fieldUserId] === obj[fieldUserId]) > -1)
        // Filter Only New Members
        let memberNew = membersSearchResult.filter(obj => tags.findIndex(element => element[fieldUserId] === obj[fieldUserId]) === -1)
        // Merge original members with New Members so that only updated data will be updated.
        let memberToUpdate = [ ...memberIfSomeRemoved, ...memberNew.map(obj => ({
            ...obj,
            [fieldVisitingdays]: 0,
            [fieldLoginChannel]: "...",
            [fieldTableKey]: new Date().getTime() // Use as key in the table
        }))]
        setTags(memberToUpdate)
        setMembers(memberToUpdate)
    }
    
    return (
        <Container>
            <Row className="mb-4">
                <Col xs sm={3}></Col>
                <Col className="text-right" xs sm={6}>
                    <span className="mr-4">
                        <Form.Label className="mr-2 font-weight-bold">Start date</Form.Label>
                        {getDatePicker("startdate", startdate)}
                    </span>
                    <span>
                        <Form.Label className="mr-2 font-weight-bold">End date</Form.Label>
                        {getDatePicker("enddate", enddate)}
                    </span>
                </Col>
                <Col xs sm={3}></Col>
            </Row>
            <Row className="mb-4">
                <Col xs sm={3}></Col>
                <Col className="" xs sm={6}>
                    <Form.Label className="mr-2 font-weight-bold">Selected members</Form.Label>
                    <div className="text-center">
                        <Autocomplete parentCallback={saveMembers} ></Autocomplete>
                    </div>
                </Col>
                <Col xs sm={3}></Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <h4 className="font-weight-bold">Visiting days detail</h4>
                    <div style={{textAlign: "center"}}>
                        <BootstrapTable data={members} className="table table-striped" keyField={fieldTableKey} 
                            options={{noDataText : "No data"}} exportCSV pagination>
                            <TableHeaderColumn dataField="display_name">
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField={fieldVisitingdays} width="140" dataAlign="right" dataFormat={NumberFormatter}>
                                Visiting days
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField={fieldLoginChannel} width="120" dataAlign="right">
                                Login Channel
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField={fieldUserId} width="140" dataFormat={LinkMemberFormatter}>
                                
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    {MemberActivities(members,startdate,enddate)}
                </Col>
            </Row>
            <Tabs activeKey={web} onSelect={k => setWeb(k)} className="mb-3">
                <Tab eventKey="online" title="Thairath Online">
                    <Row className="mb-4">
                        <Col>
                            {MemberTraffic(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", members)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {SpecialContent("member",startdate, enddate, arrNothing, members)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {TopContent(startdate,enddate,arrNothing,members)}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="plus" title="Thairath Plus">
                    <Row className="mb-4">
                        <Col>
                            {MemberTrafficTrPlus(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", members)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {TopTrPlus(startdate, enddate, arrNothing, members)}
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
export default PersonsReport