import moment from "moment"
import React, { useEffect, useState, useContext, Fragment } from "react"

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom"
import { campaignController } from "../../Services"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
import { StoreContext } from "../../Context/store"
import { Navigation } from "../../../../utils"

const MemberJoin = ({ pageStep = 5, onChangeStep, pageId }) => {
	const admin = User.getCookieData()
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		id: id,
	} = useContext(StoreContext)

	const [datas, setDatas] = useState()
	const [sensitive, setSensitive] = useState(true)

	useEffect(() => {
		permission()
		fetchData()
	}, [])

	const permission = () => {
		if (!checkPermission(admin.permission, ["member_sensitive_data_view"])) {
			setSensitive(false)
		}
	}

	const fetchData = async () => {
		const response = await campaignController().getMemberPlayed(id)
		const {
			data: { data },
			status,
		} = response
		if (status === 200) {
			setDatas(data)
		}

		console.log(response)
	}

	const formatIndex = (cell, row, enumObject, index) => {
		return <p>{index + 1}</p>
	}

	const formatTime = (cell, row, enumObject, index) => {
		return moment(cell).format("DD/MM/YYYY H:mm")
	}

	const formatMatch = (cell, row, enumObject, index) => {
		return `${cell.teamName1} vs ${cell.teamName2}`
	}

	const formatScore = (cell, row, enumObject, index) => {
		return `${cell.score1} - ${cell.score2}`
	}

	const formatCorrect = (cell, row, enumObject, index) => {
		// if (cell.correctScore1 && cell.correctScore2) {
		// 	return `${cell.correctScore1} - ${cell.correctScore2}`
		// } else {
		// 	return ""
		// }
		return cell.points > 0 ? 'ได้แต้ม' : '-'
	}

	const formatPoints = (cell, row, enumObject, index) => {
		// if (cell.correctScore1 && cell.correctScore2) {
		// 	return `${cell.correctScore1} - ${cell.correctScore2}`
		// } else {
		// 	return ""
		// }
		return cell.points
	}


	const updatePoint = async () => {
		const { data } = await campaignController().updatePoints(id)
		if (data.status === 200) {
			alert(`อัพเดทเรียบร้อย มี member ทั้งหมด ${data.data.members} คน`)
		} else {
			console.log(`LOG: error ---> `, data.message)
		}
	}

	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Member</h5>
				</div>

				<div className="card-body">
					<button className="btn btn-info mb-1" onClick={updatePoint}>
						อัพเดทคะแนน
					</button>
					<BootstrapTable data={datas} className="table table-striped" exportCSV>
						<TableHeaderColumn dataField="userId" width="50" dataAlign="center" dataFormat={formatIndex} isKey>
							ลำดับ
						</TableHeaderColumn>
						<TableHeaderColumn dataField="userId" width="200">
							user_id
						</TableHeaderColumn>
						<TableHeaderColumn dataField="name" width="200">
							ชื่อ-นามสกุล
						</TableHeaderColumn>
						{sensitive && (
							<TableHeaderColumn dataField="gender" width="120">
								เพศ
							</TableHeaderColumn>
						)}
						{sensitive && (
							<TableHeaderColumn dataField="phone" width="120">
								เบอร์โทร
							</TableHeaderColumn>
						)}
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatMatch}>
							ทีม
						</TableHeaderColumn>
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatScore}>
							คำตอบ
						</TableHeaderColumn>
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatCorrect}>
							ตอบถูก
						</TableHeaderColumn>
						<TableHeaderColumn dataField="team" width="120" dataFormat={formatPoints}>คะแนน</TableHeaderColumn>
						<TableHeaderColumn dataField="created_time" width="150" dataFormat={formatTime}>
							วันที่เริ่ม
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</div>
		</Fragment>
	)
}

export default MemberJoin
