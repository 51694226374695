import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import { FormInput, FormRadio, FormTextarea, FormUpload } from "../../widgets/Form"
import _get from "lodash/get"
import useActivityVote from "./context"
import useAlert from "../../useAlert"
import useUpload from "../../useUpload"
const Choice = ({ questionIndex, index }) => {
	const { data, handleChange } = useActivityVote()
	const { onUpload } = useUpload()
	const handleClickDeleteChoice = () => {
		const temp = [..._get(data, "questions")]
		temp[questionIndex].choices = temp[questionIndex].choices.filter((_, _index) => _index !== index)
		handleChange({ name: "questions", value: temp })
		onClose()
	}
	const { onOpen, onClose, renderAlert } = useAlert({
		title: "ต้องการลบคำตอบนี้หรือไม่",
		onConfirm: handleClickDeleteChoice,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})
	return (
		<div>
			<div style={{ display: "flex" }}>
				<h1 className="flex-grow-1">{`คำตอบที่ ${index + 1}`}</h1>
				<Button
					style={{
						height: "fit-content",
					}}
					variant="outline-danger"
					onClick={onOpen}
					className="non-preview"
				>
					ลบคำตอบ
				</Button>
			</div>
			<FormTextarea
				label="ชื่อหัวข้อ (ห้ามเกิน 2 บรรทัด)"
				name={`questions[${questionIndex}].choices[${index}].title`}
				rows={2}
				required
				defaultValue={_get(data, `questions[${questionIndex}].choices[${index}].title`, "")}
				onChange={handleChange}
			/>
			<FormUpload
				label={"รูปภาพ"}
				remark="ขนาด 600x338 px (16:9)"
				required
				name={`questions[${questionIndex}].choices[${index}].media`}
				defaultValue={_get(data, `questions[${questionIndex}].choices[${index}].media`)}
				onUpload={onUpload(({ name, value }) => {
					handleChange({ name, value: { ...value, type: "image" } })
				})}
			/>

			<Divider />
			{renderAlert()}
		</div>
	)
}

export default Choice
