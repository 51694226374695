import _set from "lodash/set"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { getEurofootballById, onUploadEuroFootball, addEurofootballCampaign, updateEurofootballCampaign } from "../service"
import { useHistory, useParams } from "react-router-dom"

import EuroFootballSetup from "../setup"
import EuroFootballPlaygame from "../playgame"
import EuroFootballAward from "../award"

export const EuroFootballContext = React.createContext({})

const useEuroFootball = () => React.useContext(EuroFootballContext)

export const EuroFootballContextProvider = ({ children }) => {
	const history = useHistory()
	const params = useParams()
	const [stepNow, setStepNow] = useState(0)
	const [steps, setSteps] = useState([])
	const [euroSetup, setEuroSetup] = useState({})
	const [playSetup, setPlaySetup] = useState([])
	const [awardSetup, setAwardSetup] = useState({})

	const [currentSelectGame, setCurrentSelectGame] = useState(0)

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		const { id } = params

		if (id) {
			const {
				data: { data },
			} = await getEurofootballById(id)

			if (data) {
				const { campSetup, score, award } = data
				campSetup.euroId = campSetup.euroId || uuidv4()
				setEuroSetup(campSetup)
				setPlaySetup(score)
				setAwardSetup(award)
			}
		}
	}

	const handleChangeStep = (step, data, page) => {
		switch (page) {
			case "setup":
				setEuroSetup(data)
				setStepNow(step)
				break

			case "play":
				setPlaySetup(data)
				setStepNow(step)
				break

			case "award":
				setAwardSetup(data)
				setStepNow(step)
				break

			default:
				setStepNow(step)
				break
		}
	}

	const onUploadImage = async (file) => {
		// const { files } = event.target
		// const { name, type, size } = files[0]
		// const formData = new FormData()
		// formData.append("File", files[0], name)
		// const target_file = formData
		// const data = await onUploadEuroFootball(target_file, {
		//     params: {
		//         type: 'Score',
		//         upload: 'campaign'
		//     }
		// })

		// return data
		if (file) {
			const formData = new FormData()
			formData.append("image_files", file)
			formData.append("ref_id", "eurofootball")
			try {
				const options = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
				const data = await onUploadEuroFootball(formData, options)
				return data.data.data
			} catch (e) {
				console.log("onUploadImage error => ", e)
			}
		}
	}

	const onSubmit = async () => {
		let data = {}

		const new_euroSetup = {
			...euroSetup,
			link: `${process.env.REACT_APP_LINK_CAMPAIGN}/fanpanchamp/${euroSetup.slagName}`,
		}

		if (!new_euroSetup["isActive"]) {
			new_euroSetup["isActive"] = false
		}

		data = {
			campSetup: new_euroSetup,
			detail: playSetup,
			award: awardSetup,
			type: "Score",
		}

		if (euroSetup._id) {
			const { status } = await updateEurofootballCampaign(euroSetup._id, data)
			if (status === 200) {
				history.push("/campaign-new/euro-football")
			}
		} else {
			const { status } = await addEurofootballCampaign(data)
			if (status === 200) {
				history.push("/campaign-new/euro-football")
			}
		}
	}

	useEffect(() => {
		const leftTabs = [
			{
				key: uuidv4(),
				label: "ตั้งค่าแคมเปญ",
				Component: <EuroFootballSetup />,
			},
			{
				key: uuidv4(),
				label: "การเล่น",
				Component: <EuroFootballPlaygame />,
			},
			{
				key: uuidv4(),
				label: "รางวัล",
				Component: <EuroFootballAward />,
			},
		]

		setSteps([...leftTabs])
	}, [])

	const store = {
		euroSetup,
		playSetup,
		awardSetup,
		stepNow,
		steps,
		handleChangeStep,
		onUploadImage,
		onSubmit,
		setPlaySetup,
		setEuroSetup,
		setAwardSetup,
		currentSelectGame,
		setCurrentSelectGame,
	}

	return <EuroFootballContext.Provider value={store}>{children}</EuroFootballContext.Provider>
}

export default useEuroFootball
