import React, { useState, useEffect, Fragment } from "react"
import axios from "axios"
import { NumberFormatter } from '../../dashboard/Formatter'
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { fieldUserId, fieldDisplayName } from '../PersonsReport'

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const MemberActivities = (members, startdate, enddate) => {
    const [data, setData] = useState([])
    
	useEffect(() => {
        async function fetchData() {
            if(members.length > 0){
                const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/dashboard/memberActivity`
                const mainparameter = `type=&startdate=` + startdate + `&enddate=` + enddate
                    + members.map(obj => obj[fieldUserId]).reduce((acc, id) => acc + '&arrMemberId[]=' + id, '')

                let reqObjData = await axios.get(mainurl + `?` + mainparameter  + `&v=` + Math.random() * 1000,  { withCredentials: true })
                setData(reqObjData.data.data)
            }
        }
        fetchData()
    }, [members, startdate, enddate])

    const getDisplayName = id => {
        let arr = members.filter(obj => obj[fieldUserId] === id)
        if(arr.length > 0){
            return arr[0][fieldDisplayName]
        }
        return ""
    }
    
    return (
        <Fragment>
            <h4 className="font-weight-bold">Member Activities</h4>
            <div style={{textAlign: "center"}}>
                <BootstrapTable data={data} className="table table-striped" keyField="ts" 
                    options={{noDataText : "No data"}} exportCSV pagination>
                    <TableHeaderColumn dataField="memberId" width="150" dataFormat={getDisplayName}>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="activity" width="150">
                        Activity
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="count" width="100" dataAlign="right" dataFormat={NumberFormatter}>
                        Count
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Fragment>
    )

}
export default MemberActivities