import React, { Fragment, useContext, useState } from "react"
import { StoreContext } from "../Context/store"
import { Navigation } from "../../../utils"
import { stepVote, stepQuiz, type, rule, taskRule, positon } from "../../../helper/const"
import { Input, Textarea, SelectInput, SelectScore, DatePeriod, UploadInput, InputSlagName } from "../Widgets"
import moment from "moment"
import { campaignController } from "../Services"
import { Button, Nav } from "react-bootstrap"
import DateTime from "@nateradebaugh/react-datetime"
import { isEmpty } from "lodash"

const Announce = ({ pageStep = 1, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		award: [award, setAward],
	} = useContext(StoreContext)
	const [errors, setErrors] = useState({})
	const onChangeData = (event, memId, aid) => {
		const { value } = event.target
		const result = award.map((list) => {
			if (list.awardId === aid) {
				return {
					...list,
					members: list.members.map((item) => {
						if (item.memId === memId) {
							return {
								...item,
								name: value,
							}
						} else {
							return item
						}
					}),
				}
			} else {
				return list
			}
		})

		setAward(result)
	}

	const onBlurData = (event) => {
		setCampSetup({
			...campSetup,
			prize_start_date: moment(event).format("YYYY-MM-DD HH:mm:ss"),
		})
	}
	const handleChangeTextarea = (value, awardIndex) => {
		let tempAwardArr = [...award]
		const membersArr = value.split("\n").filter((text) => !isEmpty(text))
		let tempMembers = tempAwardArr[awardIndex].members
		for (let i = 0; i < tempMembers.length; i++) {
			tempMembers[i] = { ...tempMembers[i], name: membersArr[i] }
		}
		setErrors({
			[tempAwardArr[awardIndex]._id]: membersArr.length > tempMembers.length,
		})
		setAward(tempAwardArr)
	}
	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Award</h5>
				</div>
				<div className="flex p-3">
					<div className="row ">
						<div className={"col-auto row-label"}>
							วันที่ประกาศของรางวัล :
						</div>
						<div className={"col-8"}>
							<DateTime
								style={{ borderColor: "#ccc", marginLeft: "10px" }}
								dateFormat={"YYYY-MM-DD"}
								timeFormat={true}
								defaultValue={moment(campSetup.prize_start_date).format("YYYY-MM-DD HH:mm:ss")}
								onBlur={(event) => onBlurData(event)}
							/>
						</div>
					</div>
				</div>
				<div className="card-body">
					{award.map((list, index) => (
						<Fragment key={list.awardId}>
							<div className="row">
								<div className="col-3">
									<h6 className="m-0 font-weight-bold text-primary">
										{`รางวัลที่ ${list.indexAward}`}
										<a style={{ color: "red" }}> *</a>
									</h6>
								</div>
							</div>
							<br />
							<div className="row">
								<div className="col-6">
									<Input
										label="ชื่อรางวัล"
										id="awardTitle"
										name="title"
										value={list.title}
										// onChange={(event) => onChangeQuestion(event, list.qId)}
										compCol="col-8"
										disabled={disabled ? "disabled" : ""}
										rows={2}
									/>
									<br />
									<div>
										<Textarea
											label="รายชื่อผู้รับรางวัล (หนึ่งบรรทัดต่อหนึ่งชื่อ)"
											rows={list.members.length > 25 ? 25 : list.members.length}
											onChange={(e) => handleChangeTextarea(e.target.value, index)}
											compCol="col-8"
											errorMessage={errors[list._id] && <>ใส่ได้สูงสุด: {list.members.length} คน</>}
											defaultValue={list?.members.map((m) => m.name).join("\n")}
											//ไม่ได้ดักจริง มีแค่ message
										/>
									</div>
								</div>
								{list.imgSrc?.url && (
									<div className="col-6">
										<img src={list.imgSrc?.url} height={200} alt="award" />
									</div>
								)}
							</div>
							<hr />
						</Fragment>
					))}
				</div>
			</div>
		</Fragment>
	)
}

export default Announce
