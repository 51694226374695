import React from "react"

export const Radio = ({
	label,
	id,
	name,
	value,
	onChange,
	labelCol = "col-3 row-label",
	compCol = "col-4",
	require,
	spaceCol = "col-0",
	disabled,
}) => {
	return (
		<div className="row">
			<div className={spaceCol} />
			<div className={labelCol}>
				{label}
				<p style={{ color: "red" }}>{require && `*`}</p>:
			</div>
			<div className={spaceCol} />
			<div className="row">
				<div className={compCol}>
					<input type="radio" id={id} name={name} value={true} checked={value} onChange={onChange} disabled={disabled} />  <label for={value}  style={{ padding: '10px' }}>เปิด</label>
					<input type="radio" id={id} name={name} value={false}  checked={!value} onChange={onChange} disabled={disabled}  />  <label for={!value} style={{ padding: '10px' }} >ปิด</label>
				</div>
			</div>
		</div>
	)
}
