import React, { Fragment, useState } from "react"
import { Alert, Button, Badge } from "react-bootstrap"
import CommentApi from "../../libs/comment"
import moment from "moment"
export default function ReportDetail({ data, disabled, view }) {
	const [report, setReport] = useState(data)
	const [hide, setHide] = useState(data.verify)
	// call service && credential
	const handleClickVerify = async () => {
		const res = await CommentApi.verifyReport({ id: data._id })
		if (res.status === 200) {
			setReport(res.data)
		} else {
			console.log("verify error: ", res)
		}
	}
	return (
		<div>
			{hide && (
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault()
						setHide(!hide)
					}}
				>
					ตรวจรายงานความเห็นไม่เหมาะสมแล้ว คลิกเพื่อดู
				</a>
			)}
			{!hide && (
				<Alert variant={report.verify ? "success" : "danger"}>
					{report.reports && <Alert.Heading>{report.reports.join(", ")}</Alert.Heading>}
					{report.reports[0] === "ai9"&&report.ai9.score>80 ? (
						<Fragment>
							<Badge pill variant="secondary">
							result : {report.ai9.result}
							</Badge>{" "}
							<Badge pill variant="secondary">
							score : {report.ai9.score}
							</Badge>{" "}
						</Fragment>
					) : (
						<p style={{ textIndent: 16 }}>{report.description}</p>
					)}

					{!report.verify && (
						<>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
								{view && <Button onClick={handleClickVerify} variant={disabled ? "secondary" : "success"} disabled={disabled ? 'disabled' : ''}>
									ตรวจสอบ
								</Button>}
								<span>{moment(report.createDate).format("DD/MM/YYYY HH:mm:ss")}</span>
							</div>
						</>
					)}
				</Alert>
			)}
		</div>
	)
}
