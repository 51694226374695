import React, { useState, useEffect } from "react"
import ReportDetail from "./ReportDetail"
import CommentApi from "../../libs/comment"
export default function ReportPanel({ data, disabled, view }) {
	const [dataReports, setDataReports] = useState([])
	const fetchData = async () => {
		const res = await CommentApi.getReportByCommentId(data._id)
		if (res.status === 200) {
			await setDataReports(res.data.reports)
		} else {
			console.log("ReportPanel err", res)
		}
	}

	useEffect(() => {
		fetchData()
		return () => {}
	}, [])
	return <>{dataReports && dataReports.map((item) => <ReportDetail key={item._id} data={item} disabled={disabled} view={view} />)}</>
}
