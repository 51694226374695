import React, { useState, useEffect } from "react"
import { NavigateStyled } from "../../styled"
import useActivityVote, { ActivityVoteContextProvider } from "./context"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"

const ActivityVoteComp = () => {
	const { handleChangeStep, stepNow, steps, data } = useActivityVote()
	if (!steps || !data) return <>Loading...</>
	return (
		<div
			style={{
				backgroundColor: "#fff",
				padding: "2rem",
			}}
		>
			<NavigateStyled className="mb-4">
				<ul>
					{steps &&
						steps.map((item, index) => (
							<li key={item.key} className={stepNow >= index ? "active" : ""}>
								<a onClick={() => handleChangeStep(index)}>
									<strong>{index + 1}</strong>
									<span>{item.label || "New tab"}</span>
								</a>
							</li>
						))}
				</ul>
			</NavigateStyled>
			<div>{steps[stepNow] && steps[stepNow].Component}</div>
		</div>
	)
}

const ActivityVote = (props) => {
	const user = User.getCookieData()
	useEffect(() => {
		if(!checkPermission(user.permission,"activity_vote_edit")) {
			props.history.push('/')
		
		}
		return () => {}
	}, [])
	return (
		<ActivityVoteContextProvider>
			<ActivityVoteComp />
		</ActivityVoteContextProvider>
	)
}

export default ActivityVote
