import Axios from "axios"

export default {
	/**
	 * axios wrappers
	 */
	_errorMsg: "",
	_post: async function (path, body) {
		return this._axios(path, "post", body)
	},
	_get: async function (path) {
		return this._axios(path)
	},
	_delete: async function (path, body) {
		return this._axios(path, "delete", body)
	},
	_axios: async function (path, method, body) {
		const options = {
			method,
			data: body,
			url: `${process.env.REACT_APP_API_ROOT}/${path}`,
			withCredentials: true,
		}
		const { data } = await Axios(options)
		if (data.status === 200) {
			return data
		} else {
			console.log(`User lib error ${path}: ${data.message}`)
			this._errorMsg = data.message
			return data
		}
	},

	/**
	 * API functions
	 */

	

	// update notification
	updateNotificationSetup: async function (dataForm , id) {
        return this._post("admin/notificationcenter/addnotification/" + id , dataForm)
	},

	// update user info
	sendupdateNotificationSetup: async function (dataForm, id) {
		const data = await this.updateNotificationSetup(dataForm, id)
		return data
	},

	
}
