import moment from "moment"
import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { Dimmer, Icon, Loader, Pagination, Select } from "semantic-ui-react"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import styled from "styled-components"
const SelectStyled = styled(Select)`
	min-width: 20px !important;
`
const CustomTable = ({ data = [], columns = [], page, limit, total, sort = {}, isLoading, onSort, onChangePage, onChangeLimit }) => {
	const [sortField, setSortField] = useState(sort)

	const formatData = (data, format) => {
		switch (format) {
			case "date":
				return moment(data).format("DD/MM/YYYY")
			case "datetime":
				return moment(data).format("DD/MM/YYYY HH:mm")
			default:
				return data
		}
	}

	const renderColumn = (item, col, index) => {
		if (col.render) return col.render(_get(item, col.field), item, index)
		else if (col.type) return formatData(_get(item, col.field), col.type)
		else return _get(item, col.field)
	}

	const handleSort = (col) => {
		const { field, sort } = col
		let _sortField = {}
		if (!sort) return

		if (sortField[field] !== undefined) {
			const newSort = sortField[field] === 1 ? -1 : 1
			_sortField = { [field]: newSort }
		} else {
			_sortField = { [field]: -1 }
		}
		setSortField(_sortField)
		onSort && onSort(_sortField)
	}
	const handlePageChange = (e, data) => {
		onChangePage && onChangePage(data.activePage)
	}

	const handleChangeLimit = (e, data) => {
		onChangeLimit && onChangeLimit(data.value)
	}
	return (
		<div className="position-relative">
			<div
				style={{
					whiteSpace: "nowrap",
					overflowX: "auto",
					width: "100%",
					maxWidth: "100%",
				}}
			>
				<Table
					variant={"hover"}
					style={{
						border: "1px solid #e6e6e6",
					}}
				>
					<thead>
						<tr>
							{columns.map((col, index) => (
								<th
									key={index}
									onClick={() => handleSort(col)}
									style={{
										cursor: col.sort ? "pointer" : "not-allowed",
									}}
									align={col.align}
								>
									<span>{col.label}</span>
									{col.sort && sortField[col.field] !== undefined && (
										<Icon name={`caret ${sortField[col.field] === 1 ? "up" : "down"}`} className="ml-1" color="green" />
									)}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative", width: "100%" }}>
						{_isEmpty(data) ? (
							<tr>
								<td colSpan={columns.length} align="center" className="py-4">
									<div className="d-flex align-items-center justify-content-center flex-column">
										<Icon name="cancel" size="huge" />
										<span style={{ fontSize: "1.25rem", marginTop: "0.5rem" }}>ไม่พบแคมเปญที่คุณกำลังค้นหา</span>
									</div>
								</td>
							</tr>
						) : (
							data.map((item, index) => (
								<tr key={item._id || index}>
									{columns.map((col, index2) => (
										<td key={`${col.field}_${index2}`} align={col.align}>
											{renderColumn(item, col, index)}
										</td>
									))}
								</tr>
							))
						)}
					</tbody>
				</Table>
			</div>
			<div className="mt-2">
				<span>
					<span>การแสดงผล</span>
					<span className="mx-2">
						<SelectStyled onChange={handleChangeLimit} defaultValue={limit} options={limits} />
					</span>
					<span>รายการ</span>
				</span>
				<Pagination activePage={page} onPageChange={handlePageChange} totalPages={Math.ceil(total / limit)} />
			</div>
			{isLoading && (
				<Dimmer active inverted>
					<Loader inverted content="Loading" />
				</Dimmer>
			)}
		</div>
	)
}

const limits = [
	{ key: "10", value: 10, text: "10" },
	{ key: "20", value: 20, text: "20" },
	{ key: "50", value: 50, text: "50" },
	{ key: "100", value: 100, text: "100" },
]
export default CustomTable
