import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { Divider } from "semantic-ui-react"
import { ContainerStyled, SectionStyled } from "../../styled"
import { FormInput, FormRadio, FormTextarea, FormUpload } from "../../widgets/Form"
import Choice from "./Choice"
import useNewsVote from "./context"
import _get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import useAlert from "../../useAlert"
import useUpload from "../../useUpload"

const Question = ({ preview }) => {
	const { onUpload } = useUpload()
	const { data, handleChange, handleChangeStep, stepNow } = useNewsVote()
	const [questionSelected, setQuestionSelected] = useState(0)
	const handleClickNewQuestion = () => {
		handleChange({
			name: "questions",
			value: [
				..._get(data, "questions"),
				{
					choices: [
						{
							key: uuidv4(),
							title: "",
							media: {
								type: "image",
								name: "",
								url: "",
							},
						},
					],
				},
			],
		})
	}
	const handleClickNewChoice = () => {
		const temp = [..._get(data, "questions")]
		temp[questionSelected].choices = [
			...temp[questionSelected].choices,
			{
				key: uuidv4(),
				title: "",
				media: {
					type: "image",
					name: "",
					url: "",
				},
			},
		]
		handleChange({ name: "questions", value: temp })
	}
	const handleClickDeleteQuestion = () => {
		// เหลือ confirm modal
		handleChange({ name: "questions", value: _get(data, "questions").filter((item, index) => index !== questionSelected) })
		setQuestionSelected(questionSelected > 0 ? questionSelected - 1 : 0)
		onClose()
	}
	const { onOpen, onClose, renderAlert } = useAlert({
		title: "ต้องการลบคำถามนี้หรือไม่",
		onConfirm: handleClickDeleteQuestion,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})
	useEffect(() => {
		if (preview) {
			for (const ele of document.querySelectorAll("input,textarea")) {
				ele.setAttribute("disabled", "disabled")
			}
		}
		return () => {}
	}, [questionSelected])

	return (
		<div>
			<div
				style={{
					display: "flex",
					overflowX: "auto",
					padding: "0.5rem",
				}}
			>
				{_get(data, "questions", []).map((question, index) => (
					<Button
						key={question.key}
						onClick={() => setQuestionSelected(index)}
						variant={questionSelected === index ? "dark" : "outline-dark"}
						style={{
							marginRight: "0.5rem",
							whiteSpace: "nowrap",
						}}
					>{`คำถามที่ ${index + 1}`}</Button>
				))}
			</div>
			<Divider />
			<ContainerStyled>
				<div style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
					{_get(data, "questions", []).length > 1 && (
						<Button variant="outline-danger non-preview" className="mr-2" onClick={onOpen}>
							ลบคำถาม
						</Button>
					)}
					<Button variant="outline-dark non-preview" onClick={handleClickNewQuestion}>
						เพิ่มคำถาม
					</Button>
				</div>

				<SectionStyled className="mb-4">
					<h1>{`คำถามที่ ${questionSelected + 1}`}</h1>
					<FormTextarea
						label="ชื่อหัวข้อ (ห้ามเกิน 2 บรรทัด)"
						name={`questions[${questionSelected}].title`}
						defaultValue={_get(data, `questions[${questionSelected}].title`, "")}
						key={`${questionSelected}-title`}
						rows={2}
						required
						placeholder="(ตัวอย่าง) ไก่กับไข่อะไรเกิดก่อนกัน"
						onChange={handleChange}
					/>
					<FormRadio
						label={"ประเภทของ Media"}
						name={`questions[${questionSelected}].media.type`}
						defaultValue={_get(data, `questions[${questionSelected}].media.type`, "image")}
						required
						onChange={handleChange}
						items={[
							{
								label: "รูปภาพ",
								value: "image",
							},
							{
								label: "วิดีโอ",
								value: "video",
							},
						]}
					/>
					{_get(data, `questions[${questionSelected}].media.type`, "image") === "image" ? (
						<FormUpload
							label={"รูปหัวข้อโหวต"}
							remark="ขนาด 600x338 px (16:9)"
							required
							name={`questions[${questionSelected}].media`}
							defaultValue={_get(data, `questions[${questionSelected}].media`)}
							key={`${questionSelected}-media`}
							onUpload={onUpload(({ name, value }) => {
								handleChange({ name, value: { ...value, type: "image" } })
							})}
						/>
					) : (
						<FormInput
							name={`questions[${questionSelected}].media.url`}
							defaultValue={_get(data, `questions[${questionSelected}].media.url`)}
							key={`${questionSelected}-url`}
							label={"URL คลิปวิดีโอ"}
							required
							onChange={handleChange}
						/>
					)}
				</SectionStyled>

				<SectionStyled className="mb-4">
					{_get(data, `questions[${questionSelected}].choices`, []).map((choice, index) => (
						<Choice key={choice.key} questionIndex={questionSelected} index={index} />
					))}
					<Button variant="outline-dark w-100 non-preview" onClick={handleClickNewChoice}>
						เพิ่มคำตอบ
					</Button>
				</SectionStyled>

				<Button className="float-left mt-2 non-preview" variant="outline-primary" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" variant="primary" onClick={() => handleChangeStep(stepNow + 1)}>
					ถัดไป
				</Button>
			</ContainerStyled>
			{renderAlert()}
		</div>
	)
}

export default Question
