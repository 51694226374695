export const listactivities = [
	"activate",
	"change_password",
	"forget_pass",
	"login",
	"logout",
	"newsletter",
	"register",
	"remove_favorite",
	"remove_follow",
	"resend_activate",
	"save_favorite",
	"set_campaign",
	"set_follow",
	"set_interest",
	"set_tutorial",
	"update_info",
]
