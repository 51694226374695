import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import { Pagination } from "semantic-ui-react"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const [data, setData] = useState("")
	const [allPage, setallPage] = useState("")
	const [activePage, setActivePage] = useState(1)
	const inputRef = useRef(null)

	const restrucObj = (data_format, pagedata = 1) => {
		// eslint-disable-next-line
		const newArr = []

		console.log(data_format)

		data_format.data.data.map((items, key) => {
			const newObj = {
				_id: items._id,
				count: key + (1 + 25 * (pagedata - 1)),
				target2: Object.keys(items.target)[0],
				message: items.message,
				delivery_schedule:
					new Date(items.deliverySchedule).getDate() +
					"/" +
					(new Date(items.deliverySchedule).getMonth() + 1) +
					"/" +
					new Date(items.deliverySchedule).getFullYear() +
					" " +
					("0" + new Date(items.deliverySchedule).getHours()).slice(-2) +
					":" +
					("0" + new Date(items.deliverySchedule).getMinutes()).slice(-2),
				create_date:
					new Date(items.last_update).getDate() +
					"/" +
					(new Date(items.last_update).getMonth() + 1) +
					"/" +
					new Date(items.last_update).getFullYear() +
					" " +
					("0" + new Date(items.last_update).getHours()).slice(-2) +
					":" +
					("0" + new Date(items.last_update).getMinutes()).slice(-2),
				create_by: items.create_by,
				status: items.status,
				send_amount: items.count || "",
				read_amount: items.read || "",
			}

			newArr.push(newObj)
		})
		setData(newArr)
	}

	const selectMember = (cell) => {
		return (
			<Link to={"edit/" + cell} className="btn btn-success btn-circle btn-sm">
				<i className="fas fa-edit"></i>
			</Link>
		)
	}

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_ROOT}/admin/notificationcenter/list?v=` + Math.random() * 1000 + `&page=${pageInfo.activePage}`,
			{ withCredentials: true }
		)

		restrucObj(data, pageInfo.activePage)
	}

	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "notification_view")

		if (checked) {
			async function getTableDate() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/notificationcenter/list?v=` + Math.random() * 1000, {
					withCredentials: true,
				})

				console.log(`${process.env.REACT_APP_API_ROOT}/admin/notificationcenter/list?v=` + Math.random() * 1000)

				console.log("data", data)
				setallPage(data.data.pages)
				restrucObj(data)
			}
			getTableDate()
		} else {
			props.history.push('/')
		}
	}, [])

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">จัดการ Notification Center</h6>
				</div>

				<div className="card-body">
					{/* <div className="row">
                        <div className="col-1">
                            ค้นหาจาก
                        </div>
                        <div className="col-2">
                            <select className="form-control" id="Ssearch" onChange={() => setFilterSearch()}>
                            <option value="0">Select</option>
                            <option value="1">Notification ID</option>
                            <option value="2">วันที่ส่ง</option>
                        </select> 
                        </div>
                        <div className="col-4">
                            <textarea className="form-control" id="TaSearch" rows="1" style={{resize: "none"}} maxLength="100" onChange={() => onChangeSearchMessage()}></textarea>
                        </div>
                        <div className="col-2">
                            <button onClick={() => search()}  className="btn btn-primary btn-icon-split">
                                <span className="text">ค้นหา</span>
                            </button>
                        </div>
                    </div> */}

					<br />
					{data !== undefined && (
						<div className="row">
							<div className="col-12">
								<Link to="/notificationcenter/edit/new" className="custom-rigth btn btn-success btn-sm">
									<span className="text">Notification Setup+</span>
								</Link>
								<BootstrapTable data={data} className="table table-striped" exportCSV>
									<TableHeaderColumn dataField="_id" width="150" headerAlign="left" dataAlign="center" isKey dataFormat={selectMember} />
									<TableHeaderColumn ref={inputRef} dataAlign="center" dataField="count" width="100">
										ลำดับ
									</TableHeaderColumn>
									<TableHeaderColumn ref={inputRef} dataField="target2" width="100">
										Type
									</TableHeaderColumn>
									<TableHeaderColumn dataField="message" width="200">
										Message
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="delivery_schedule"
										width="300"
										// dataFormat={dateFormatter}
									>
										วันที่ส่งออก
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="create_date"
										width="200"
										// dataFormat={dateFormatter}
									>
										วันที่สร้าง
									</TableHeaderColumn>
									<TableHeaderColumn dataField="create_by" width="200">
										Create By
									</TableHeaderColumn>
									<TableHeaderColumn dataField="status" width="200">
										Status
									</TableHeaderColumn>
									<TableHeaderColumn dataField="send_amount" width="200">
										จำนวนที่ส่งแล้ว
									</TableHeaderColumn>
									<TableHeaderColumn dataField="read_amount" width="200">
										จำนวนผู้ที่เปิดอ่าน
									</TableHeaderColumn>
								</BootstrapTable>
								{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default List
