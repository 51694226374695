import axios from "axios"
const Axios = axios.create({
	withCredentials: true,
})
export const campaignController = () => {
	const url = `${process.env.REACT_APP_API_ROOT}`
	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	}
	return {
		createCampaign: (body) => {
			return Axios.post(`${url}/admin/campaign/`, body)
		},
		getAllCampaign: () => {
			return Axios.get(`${url}/admin/campaign/`)
		},
		getCampaignById: (id) => {
			return Axios.get(`${url}/admin/campaign/${id}`)
		},
		updateCampaign: (body, id) => {
			return Axios.put(`${url}/admin/campaign/${id}`, body)
		},
		getMemberPlayed: (id, page, limit) => {
			return Axios.get(`${url}/admin/campaign/played/${id}`, {
				params: {
					page,
					limit,
				},
			})
		},
		updatePoints: (id) => {
			return Axios.get(`${url}/admin/campaign/score/update/${id}`)
		},
		getConversion: (id) => {
			return Axios.get(`${url}/admin/campaign/${id}/conversion`)
		},
		getTaskCompletion: (id) => {
			return Axios.get(`${url}/admin/campaign/${id}/task-completion`)
		},
		calResultVote: (id) => {
			return Axios.get(`${url}/admin/campaign/${id}/calculate-vote`)
		},
		uploadFile: (body, type) => {
			return axios.post(`${url}/upload?type=${type}&upload=campaign`, body, config)
		},
		deleteFile: (id, type) => {
			return axios.delete(`${url}/upload/${id}`, config)
		},
		getPageSettingByPath: (path) => {
			return Axios.get(`${url}/admin/campaign/page-setting/${encodeURIComponent(path)}`)
		},
		addPageSetting: (body) => {
			return Axios.post(`${url}/admin/campaign/page-setting`, body)
		},
	}
}
