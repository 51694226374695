import DateTime from "@nateradebaugh/react-datetime"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom"
import { Icon, Select } from "semantic-ui-react"
import { HStackStyled } from "../../styled"
import CustomTable from "../../widgets/CustomTable"
import { FormInput, FormSelect } from "../../widgets/Form"
import { getActivityVotes } from "./service"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
import StatusCampaignListComp from "../../widgets/StatusCampaignList"

const ActivityVoteList = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [formSearch, setFormSearch] = useState({})
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [sort, setSort] = useState({ _id: -1 })
	const [isLoading, setIsLoading] = useState(true)
	const fetch = async () => {
		setIsLoading(true)
		const response = await getActivityVotes({
			condition: formSearch,
			page,
			limit,
			sort,
		})
		if (response.status === 200 && response.data.status === 200) {
			setData(response.data.data.campaigns)
			setTotal(response.data.data.total)
			setIsLoading(false)
		}
	}

	const handleChange = ({ name, value }) => {
		setFormSearch((prev) => ({ ...prev, [name]: value }))
	}
	useEffect(() => {
		if (!checkPermission(user.permission, "activity_vote_view")) {
			props.history.push("/")
		}
		fetch()
		return () => {}
	}, [page, limit, sort])
	return (
		<div>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายการแคมเปญ Activity Vote</h6>
				</div>
				<div className="card-body ">
					<form
						onSubmit={(e) => {
							e.preventDefault()
							fetch()
						}}
					>
						<HStackStyled>
							<FormInput name={"setting.name"} label={"ชื่อแคมเปญ"} onChange={handleChange} />
							<FormSelect
								name={"isActive"}
								label={"สถานะ"}
								placeholder="ทั้งหมด"
								options={[
									{ key: "active", value: true, text: "Active" },
									{ key: "inactive", value: false, text: "Inactive" },
								]}
								// defaultValue={"all"}
								onChange={handleChange}
							/>
							<FormInput
								label={"วันที่เริ่มต้น"}
								required
								name="setting.startDate"
								renderInput={(props) => (
									<div className="non-editable d-flex align-items-center">
										<DateTime
											dateFormat={"DD-MM-YYYY"}
											timeFormat={true}
											className="w-100"
											defaultValue={formSearch["setting.startDate"]}
											onBlur={(value) => handleChange({ name: props.name, value })}
											inputProps={{ readOnly: true }}
										/>
									</div>
								)}
							/>
							<FormInput
								label={"วันสิ้นสุด"}
								required
								name="setting.endDate"
								renderInput={(props) => (
									<div className="non-editable d-flex align-items-center">
										<DateTime
											dateFormat={"DD-MM-YYYY"}
											timeFormat={true}
											defaultValue={formSearch["setting.endDate"]}
											className="w-100"
											onBlur={(value) => handleChange({ name: props.name, value })}
											inputProps={{ readOnly: true }}
										/>
									</div>
								)}
							/>
							<div>
								<Button variant="primary" type="submit">
									ค้นหา
								</Button>
							</div>
						</HStackStyled>
					</form>
					<div className="d-flex justify-content-between mb-2">
						<div>
							จำนวน
							<span className="mx-2 font-weight-bold" style={{ color: "black" }}>
								{total}
							</span>
							รายการ
						</div>
						<div>
							<Link to="/campaign-new/activity-vote/manage/" className="custom-rigth btn btn-success btn-sm">
								<span className="text">สร้างแคมเปญ</span>
							</Link>
							<a
								href={`${process.env.REACT_APP_API_ROOT}/campaign-new/activity-vote/cms/list/export-csv`}
								className="btn btn-success btn-sm ml-2"
								target="__blank"
							>
								Export to CSV
							</a>
						</div>
					</div>
					<CustomTable
						data={data}
						total={total}
						page={page}
						limit={limit}
						sort={{ _id: -1 }}
						onSort={setSort}
						onChangePage={setPage}
						onChangeLimit={setLimit}
						columns={columns}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	)
}

const columns = [
	{ label: "ลำดับ", field: "_id", sort: true, render: (col, row, index) => index + 1 },
	{
		label: "ชื่อแคมเปญ",
		sort: true,
		field: "setting.name",
		render: (col, row) => <Link to={`/campaign-new/activity-vote/manage/${row._id}`}>{col}</Link>,
	},
	{
		label: "สิทธิ์ผู้เล่น",
		field: "setting.allows",
		render: (col, row) => {
			const isMem = col.find((f) => f === "membership")
			const isGuest = col.find((f) => f === "guest")
			if (isMem && isGuest) {
				return "ทุกคน"
			} else if (isMem) {
				return "สมาชิก"
			} else if (isGuest) {
				return "ผู้เยี่ยมชม"
			}
		},
	},
	{
		label: "ไม่เป็นสมาชิก",
		field: "countGuest",
		align: "right",
		render: (col, row) => {
			return Number(col).toLocaleString("en-US", {
				maximumFractionDigits: 0,
			})
		},
	},
	{
		label: "เป็นสมาชิก",
		field: "countMembership",
		align: "right",
		render: (col, row) => {
			return Number(col).toLocaleString("en-US", {
				maximumFractionDigits: 0,
			})
		},
	},
	{
		label: "ผู้เล่นทั้งหมด",
		field: "countAll",
		align: "right",
		render: (col, row) => {
			return Number(col).toLocaleString("en-US", {
				maximumFractionDigits: 0,
			})
		},
	},
	{
		label: "สถานะ",
		field: "isActive",
		sort: true,
		render: (col, row) => <StatusCampaignListComp data={row} />,
	},
	{ label: "วันที่เริ่ม", sort: true, field: "setting.startDate", type: "datetime" },
	{ label: "วันที่สิ้นสุด", sort: true, field: "setting.endDate", type: "datetime" },
	{
		label: "ลิ้งก์",
		field: "setting.url",
		render: (col, row) => (
			<a href={col} target="_blank">
				{col}
			</a>
		),
	},
]
export default ActivityVoteList
