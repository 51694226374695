import React, { useState } from "react"
import { ContainerStyled, SectionStyled } from "../../styled"
import { FormEditor, FormInput, FormRadio, FormTextarea } from "../../widgets/Form"
import { Button } from "react-bootstrap"
import useActivityVote from "./context"
import _get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import useAlert from "../../useAlert"
const TabNew = ({ index }) => {
	const { data, handleChange, handleChangeStep, stepNow, handleNewTab } = useActivityVote()
	const handleClickNewSection = () => {
		handleChange({
			name: `tabs[${index}].sections`,
			value: [
				..._get(data, `tabs[${index}].sections`, []),
				{
					key: uuidv4(),
					title: "",
					description: "",
				},
			],
		})
	}
	const handleClickDeleteTab = () => {
		handleChange({
			name: "tabs",
			value: _get(data, "tabs").filter((item, _index) => _index !== index),
		})
		onCloseTab()
	}
	const handleClickDeleteSection = (index) => {
		handleChange({ name: `tabs[${index}].sections`, value: _get(data, `tabs[${index}].sections`).filter((item, _index) => _index !== index) })
		onCloseSection()
	}
	const { onOpen: onOpenTab, onClose: onCloseTab, renderAlert: renderAlertTab } = useAlert({
		title: "ต้องการลบ Tab นี้หรือไม่",
		onConfirm: handleClickDeleteTab,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})
	const { onOpen: onOpenSection, onClose: onCloseSection, renderAlert: renderAlertSection } = useAlert({
		title: "ต้องการลบ Section นี้หรือไม่",
		onConfirm: handleClickDeleteSection,
		btnSubmitText: "ลบ",
		btnSubmitColor: "danger",
	})

	return (
		<div key={_get(data, `tabs[${index}].key`)}>
			<ContainerStyled className="mb-5">
				<div style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
					<Button variant="outline-danger non-preview non-preview" className="mr-2" onClick={onOpenTab}>
						ลบ Tab นี้
					</Button>
					<Button variant="outline-dark non-preview non-preview" onClick={handleNewTab}>
						เพิ่ม Tab
					</Button>
				</div>
				<SectionStyled className="mb-4">
					<FormInput
						label={"ชื่อของ Tab"}
						name={`tabs[${index}].tabName`}
						defaultValue={_get(data, `tabs[${index}].tabName`)}
						onChange={handleChange}
						required
					/>
				</SectionStyled>
				<SectionStyled className="mb-4">
					<h1>การแสดงผลของ Tab นี้</h1>
					<h2>เลือก Template ที่ต้องการใช้งาน</h2>
					<FormRadio
						name={`tabs[${index}].template`}
						defaultValue={_get(data, `tabs[${index}].template`)}
						items={[
							{
								label: "Section ทั่วไป (มีแค่ให้ใส่หัวข้อและรายละเอียด)",
								value: "default",
							},
						]}
					/>
				</SectionStyled>
				<SectionStyled className="mb-4">
					<h1>การแสดงผลหน้าแรกส่วนบน</h1>
					<FormInput
						name={`tabs[${index}].title`}
						defaultValue={_get(data, `tabs[${index}].title`)}
						onChange={handleChange}
						label={"ชื่อหัวข้อ"}
						required
					/>
					<FormTextarea
						name={`tabs[${index}].description`}
						defaultValue={_get(data, `tabs[${index}].description`)}
						onChange={handleChange}
						label={"รายละเอียด (ไม่ควรเกิน 2 บรรทัด)"}
						required
					/>
				</SectionStyled>
				<h1>การแสดงผลของ Tab นี้</h1>
				{_get(data, `tabs[${index}].sections`, []).map((section, sectionIndex) => (
					<SectionStyled key={section.key} className="mb-2">
						<div
							style={{
								display: "flex",
								alignItems: "start",
							}}
						>
							<h1 className="flex-grow-1">Section {sectionIndex + 1}</h1>
							{_get(data, `tabs[${index}].sections`, []).length > 1 && (
								<Button variant="outline-danger " onClick={() => onOpenSection(sectionIndex)}>
									ลบ Section นี้
								</Button>
							)}
						</div>
						<FormInput
							defaultValue={_get(data, `tabs[${index}].sections[${sectionIndex}].title`, "")}
							key={`${index}-title`}
							name={`tabs[${index}].sections[${sectionIndex}].title`}
							onChange={handleChange}
							label={"ชื่อหัวข้อ"}
							required
						/>
						<FormEditor
							defaultValue={_get(data, `tabs[${index}].sections[${sectionIndex}].description`, "")}
							key={`${index}-description`}
							name={`tabs[${index}].sections[${sectionIndex}].description`}
							onChange={handleChange}
							label={"รายละเอียด"}
							required
						/>
					</SectionStyled>
				))}
				<Button variant="outline-dark w-100 mb-4 non-preview" onClick={handleClickNewSection}>
					เพิ่ม Section
				</Button>
				<Button className="float-left mt-2 non-preview" variant="outline-primary" onClick={() => handleChangeStep(stepNow - 1)}>
					ย้อนกลับ
				</Button>
				<Button className="float-right mt-2 non-preview" variant="primary" onClick={() => handleChangeStep(stepNow + 1)}>
					ถัดไป
				</Button>
			</ContainerStyled>
			{renderAlertTab()}
			{renderAlertSection()}
		</div>
	)
}

export default TabNew
