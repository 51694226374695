import React from "react"

export const Selectd = ({ id, name, value, onChange, data, title, disabled, validate }) => {
	return (
		<div>
			<select
				id={id}
				name={name}
				className={"form-control" + (validate ? " is-invalid" : "")}
				value={value}
				onChange={(e) => onChange(e)}
				disabled={disabled}
			>
				<option value={undefined} hidden>
					{title}
				</option>
				{data.map((list, index) => (
					<option key={index} value={list.value}>
						{list.label}
					</option>
				))}
			</select>
			{validate && <div style={{ color: "red" }}>{validate}</div>}
		</div>
	)
}

export const SelectNumber = ({ id, name, value, onChange, data, type, start, disabled }) => {
	const makeData = (data) => {
		let arrData = []

		switch (type) {
			case "age":
				for (let i = 0; i < data.length; i++) {
					arrData.push(i)
				}

				let agePlus = start ? `${data.length}` : `${data.length}+`
				return arrData.concat([`${agePlus}`, "Not define"])

			default:
				for (let i = 0; i <= data.length; i++) {
					arrData.push(i)
				}
				return arrData
		}
	}
	return (
		<select id={id} name={name} className="form-control" value={value} onChange={(e) => onChange(e)} disabled={disabled}>
			{makeData(data).map((list, index) => (
				<option key={index} value={list}>
					{list}
				</option>
			))}
		</select>
	)
}
