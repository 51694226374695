import styled from 'styled-components'

export const SC_RecruitNavigate = styled.div`
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#008435+0,005723+100 */
    padding: 1em 0;
    position: relative;
    z-index: 5;
    ul{
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        justify-content: center;
        margin-top: -25px;
        li{
            max-width: 110px;
            width: 100%;
            a{
                font-size: 16px;
                color: white;
                display: grid;
                justify-items: center;
                row-gap: 10px;
                position: relative;
                /* padding:0 21px; */
                cursor: pointer;
                &:before{
                    content:"";
                    background: white;
                    height: 3px;
                    width: 140px;
                    position: absolute;
                    top: 25px;
                }
                strong{
                    font-family: "cloudsemibold";
                    width: 50px;
                    height: 50px;
                    background: white;
                    border: 3px solid white;
                    display: grid;
                    font-weight: normal;
                    color: #00B400;
                    font-size: 28px;
                    text-align: center;
                    display: grid;
                    align-items: center;
                    border-radius: 50px;
                    transition: all 0.5s;
                    transform: scale(1);
                    border: 1px solid #00B400;
                }
                &:hover{
                    strong{
                        transition: all 0.5s;
                        transform: scale(1.2);
                        background: #00B400;
                        color: white;
                    }
                }
            }
            &:first-of-type{
                a{
                    &:before{
                        width: 70px;
                        right: 0;
                    }
                }
            }
            &:last-of-type{
                a{
                    &:before{
                        width: 70px;
                        left: 0;
                    }
                }
            }
        }
        .active{
            a{
                strong{
                    background: #00B400;
                    color: white;
                }
            }
        }
    }
    @media (max-width: 767px){
        ul{
            li{
                a{
                    &:before{
                        width: 80px;
                    }
                    strong{
                        font-size: 25px;
                    }
                    span{
                        display: none;
                    }
                }
                &:first-of-type{
                    a{
                        &:before{
                            width: 50px;
                            right: 0;
                        }
                    }
                }
                &:last-of-type{
                    a{
                        &:before{
                            width: 50px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 374px){
        ul{
            li{
                a{
                    &:before{
                        width: 60px;
                        top: 20px;
                    }
                    strong{
                        font-size: 20px;
                        width: 40px;
                        height: 40px;
                    }
                }
                &:first-of-type{
                    a{
                        &:before{
                            width: 40px;
                            right: 0;
                        }
                    }
                }
                &:last-of-type{
                    a{
                        &:before{
                            width: 40px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
`