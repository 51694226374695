import React, { useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import reorder from "./reorder"

const DragAndDrop = ({ data, keyColumn, renderData, onChange }) => {
	const onDragEnd = (result) => {
		if (!result.destination) {
			return
		}

		const items = reorder(data, result.source.index, result.destination.index)
		if (onChange) {
			onChange(items)
		}
	}
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "0.5rem",
						}}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{data.map((item, index) => (
							<Draggable key={`${item[keyColumn]}`} draggableId={`${item[keyColumn]}`} index={index}>
								{(provided, snapshot) => (
									<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
										{renderData && renderData(item, index)}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default DragAndDrop
