import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"

const useAlert = ({
	title,
	body,
	size = "sm",
	btnSubmitText = "ยืนยัน",
	btnSubmitColor = "primary",
	btnCancelText = "ย้อนกลับ",
	btnCancelColor = "secondary",
	defaultOpen,
	onConfirm,
}) => {
	const [open, setOpen] = useState(defaultOpen)
	const [args, setArgs] = useState([])
	const onClose = () => setOpen(false)
	const onOpen = (..._args) => {
		setOpen(true)
		setArgs([..._args])
	}
	return {
		onOpen,
		onClose,
		renderAlert: () => (
			<Modal size={size} show={open} centered onHide={onClose}>
				<Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
				{body && <Modal.Body>{body}</Modal.Body>}
				<Modal.Footer>
					<Button variant={btnCancelColor} onClick={onClose}>
						{btnCancelText}
					</Button>
					<Button
						variant={btnSubmitColor}
						onClick={() => {
							onConfirm(...args)
						}}
					>
						{btnSubmitText}
					</Button>
				</Modal.Footer>
			</Modal>
		),
	}
}

export default useAlert
