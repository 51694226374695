import React from 'react'
import * as moment from "moment";
import "moment/locale/th";
import { Link } from 'react-router-dom';

export const LinkFormatter = (title, row) => (
    <div>
        <a href={row.item} target='_blank' rel="noopener noreferrer">{title ? title : row.item}</a>
    </div>
)

export const GALinkFormatter = (title, row) => {
    let url = "https://www.thairath.co.th/content/" + row['ga:dimension9']
    return (
    <div>
        <a href={url} target="_blank" rel="noopener noreferrer">
            {title ? title : url}</a>
    </div>
)}

export const GATRPlusLinkFormatter = (title, row) => {
    let url = "https://plus.thairath.co.th" + row['ga:pagePath']
    title = ((row['ga:pagePath'].indexOf("webPush") > -1) ? "(Insider) " : "") + title
    return (
    <div>
        <a href={url} target="_blank" rel="noopener noreferrer">
            {title ? title : url}</a>
    </div>
)}


export const LinkMemberFormatter = memberId => memberId !== undefined ? (
    <div>
        <Link to={"/member/edit/" + memberId + "/"} target="_blank" rel="noopener noreferrer">
            ดูประวัติ
        </Link>
    </div>
) : ""

const isString = variable => typeof variable == 'string' 

export const NumberFormatter = number => isString(number) ? number : number.toLocaleString(undefined, {maximumFractionDigits:2})

export const StringIntFormatter = stringint => parseInt(stringint).toLocaleString(undefined, {maximumFractionDigits:2})

export const StringFloatFormatter = stringfloat => parseFloat(stringfloat).toLocaleString(undefined, {maximumFractionDigits:2})

export const TimeFormatter = seconds => isString(seconds) ? seconds : moment().startOf('day').seconds(seconds).format('m:ss')

export const StringTimeFormatter = stringtime => moment().startOf('day').seconds(parseInt(stringtime)).format('m:ss')