import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
const ModalConfirm = (props) => {
	const { colseModal, onDelete, showModal } = props
	const [confirmKey, setConfirmKey] = useState("")
	const onSubmit = async () => {
		if (confirmKey === "delete") {
			onDelete()
		} else {
			alert("กรอกข้อความยืนยันผิดพลาด")
			setConfirmKey("")
		}
	}

	return (
		<Modal size={"sm"} show={showModal} centered onHide={colseModal}>
			<Modal.Header closeButton>
				<Modal.Title>ลบข้อมูล</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				กรอก 'delete' เพื่อยืนยันการลย
				<input
					class="form-control my-0 py-1 lime-border"
					type="text"
					placeholder="input key.."
					aria-label="Search"
					value={confirmKey}
					onChange={(e) => setConfirmKey(e.target.value)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button style={{ padding: "8px", margin: "5px" }} onClick={onSubmit}>
					ตกลง
				</button>
				<button
					style={{ padding: "8px", margin: "5px" }}
					onClick={() => {
						colseModal()
					}}
				>
					ยกเลิก
				</button>
			</Modal.Footer>
		</Modal>
	)
}
export default ModalConfirm
