/**
 * Reference:
 * 1. Autocomplete as TagsInput: https://github.com/olahol/react-tagsinput#how-do-i-add-auto-suggestion
 * 2. autocompleteRenderInput part: https://codepen.io/moroshko/pen/EPZpev
 */
import React, { useState } from 'react'
import axios from "axios"

import Autosuggest from 'react-autosuggest'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

const members = async inputValue => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/search?searchAll=${inputValue}`, { withCredentials: true })
  if (data.data !== false) {
    return data.data
  }
  return []
}

const Autocomplete = ({parentCallback}) => {
    const [tags, setTags] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const handleChange = tags => setTags(tags)

    const attributeDisplayname = "display_name"
    const attributeUserId = "user_id"

    const autocompleteRenderInput = ({addTag, ...props}) => {
        const handleOnChange = (e, {newValue, method}) => (method === 'enter') ? e.preventDefault() : props.onChange(e)
      
        const onSuggestionsClearRequested = () => setSuggestions([])

        const onSuggestionsFetchRequested = async ({ value }) => {
          let inputValue = (value && value.trim().toLowerCase()) || ''
          let suggestionsArray = await members(inputValue)
          /*
            let inputLength = inputValue.length
            let suggestionsFiltered = suggestionsArray.filter((state) => {
            return state[attributeDisplayname].toLowerCase().slice(0, inputLength) === inputValue
          })*/
          setSuggestions(suggestionsArray)
        };

        const onSuggestionSelected = (e, {suggestion}) => {
          addTag(suggestion)
          // Send data back to the parent hook after adding some member
          parentCallback([ ...tags, suggestion ])
        }

        const renderSuggestion = suggestion => <span style={{'cursor':'pointer'}}>{suggestion[attributeDisplayname]}</span>
  
        return (
          <Autosuggest
            ref={props.ref}
            inputProps={{...props, onChange: handleOnChange, placeholder: "Type name"}}
            suggestions={suggestions}
            shouldRenderSuggestions={(value) => value && value.trim().length > 0}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            getSuggestionValue={(suggestion) => suggestion[attributeUserId]}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
          />
        )
    }

    const renderTag = (props) => {
      let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
      return (
        <span key={key} {...other}>
          {getTagDisplayValue(tag[attributeDisplayname])}
          {!disabled &&
            // eslint-disable-next-line 
            <a className={classNameRemove} onClick={(e) => {
              onRemove(key)
              // Send data back to the parent hook after removing some member
              parentCallback(tags.filter((_,i) => i !== key ))
            }} />
          }
        </span>
      )
    }

    return <TagsInput renderInput={autocompleteRenderInput} renderTag={renderTag} 
          value={tags} onChange={handleChange} />

}

export default Autocomplete