import cookie from "react-cookies"
import Axios from "axios"
import moment from "moment"

const CMS_MEMBER_COOKIE = "cms-member"
const CMS_MEMBER_COOKIE_TOKEN = "cms-member-token"

export default {
	/**
	 * axios wrappers
	 */
	_errorMsg: "",
	_post: async function (path, body) {
		return this._axios(path, "post", body)
	},
	_get: async function (path) {
		return this._axios(path)
	},
	_delete: async function (path, body) {
		return this._axios(path, "delete", body)
	},
	_axios: async function (path, method, body) {
		const options = {
			method,
			data: body,
			url: `${process.env.REACT_APP_API_ROOT}/${path}`,
			withCredentials: true,
		}
		const { data } = await Axios(options)
		if (data.status === 200) {
			return data
		} else {
			console.log(`User lib error ${path}: ${data.message}`)
			this._errorMsg = data.message
			return data
		}
	},

	/**
	 * API functions
	 */

	// login
	login: async function (username, password, remember) {
		const { data } = await this._post("admin/login", { username, password, remember })
		if (data.time) {
			this.saveLockLogin(data.time)
			return { status: false, data }
		}

		if (data && data._id) {
			this.saveCookie(data)
			return { status: true }
		} else {
			return { status: false }
		}
	},
	// logout
	logout: async function () {
		this._post("/admin/logout", {})
		this.deleteCookie()
		return true
	},
	/**
	 * Cookie functions
	 */

	// delete cookie
	deleteCookie: function () {
		cookie.remove(CMS_MEMBER_COOKIE, { path: "/" })
		cookie.remove(CMS_MEMBER_COOKIE_TOKEN, { path: "/" })

		return true
	},

	saveCookieG2FA: function () {
		const options = {
			path: "/",
		}
		// const expired = moment().subtract(30, "days").format('DD/MM/YYYY HH:mm:ss')
		const expired = new Date()
		expired.setDate(expired.getDate() + 30)
		options.expires = expired

		cookie.save("verified", "true", options)
		return true
	},

	getOnPageG2FA: function () {
		return cookie.load("g2fa") || false
	},

	saveOnPageG2FA: function () {
		const options = {
			path: "/",
		}

		cookie.save("g2fa", "true", options)
		return true
	},

	deleteOnPageG2FA: function () {
		cookie.remove("g2fa", { path: "/" })
	},

	// save cookie
	saveCookie: function (data) {
		if (data) {
			const options = { path: "/" }
			if (data.expires) {
				options.expires = new Date(data.expires)
			}
			cookie.save(CMS_MEMBER_COOKIE, data, options)
		}
	},

	saveLockLogin: function (time) {
		const options = { path: "/" }
		cookie.save("locked", time, options)
	},

	getLocked: function () {
		return cookie.load("locked") || false
	},

	deleteLocked: function () {
		return cookie.remove("locked", { path: "/" })
	},

	// get cookie
	getCookieData: function () {
		return cookie.load(CMS_MEMBER_COOKIE) || false
	},

	getCookieG2FA: function () {
		return cookie.load("verified") || false
	},

	deleteCookieG2FA: function () {
		return cookie.remove("verified", { path: "/" })
	},

	// restore redirect page for after login success
	getRedirect: function () {
		const redirect = cookie.load("redirect_uri")
		if (redirect) {
			cookie.remove("redirect_uri", { path: "/" })
			const _redirect = redirect

			// protect redirect loop
			if (_redirect.indexOf("/login") !== -1 || _redirect.indexOf("/check-login") !== -1) {
				return "/"
			} else {
				return _redirect
			}
		} else {
			return "/"
		}
	},
	// save lastest page when user visit before they go to login page
	// or save current page when user click login popup
	saveRedirect: function () {
		const redirect_cookie = cookie.load("redirect_uri")
		if (redirect_cookie && redirect_cookie !== "Lw==") {
			return
		}

		const urlParams = new URLSearchParams(window.location.search)
		let redirect = "/"

		const ignoreRedirectPath = ["/admin/login"]

		// regular redirect url
		if (urlParams.get("redirect")) {
			redirect = urlParams.get("redirect")

			// base64 encode redirect url
		} else if (urlParams.get("redirect_encode")) {
			redirect = urlParams
		} else if (ignoreRedirectPath.includes(window.location.pathname)) {
			redirect = "/"
		} else {
			redirect = window.location.pathname
		}

		cookie.save("redirect_uri", redirect, {
			maxAge: 60 * 10,
			path: "/",
		})
	},

	// update member info
	update: async function (dataForm, id) {
		return this._post("admin/members/update/" + id, dataForm)
	},

	// update member info
	updateMembers: async function (dataForm, id) {
		const data = await this.update(dataForm, id)
		return data
	},

	// update user info
	updateUser: async function (dataForm, id) {
		console.log("update", dataForm)
		return this._post("admin/user/update/" + id, dataForm)
	},

	updatePermission: async function (obj, id) {
		return this._post("admin/permission/update/" + id, obj)
	},

	sendupdatePermission: async function (obj, id) {
		const data = await this.updatePermission(obj, id)
		return data
	},

	// update user info
	sendupdateUser: async function (dataForm, id) {
		const data = await this.updateUser(dataForm, id)
		return data
	},

	// update password
	updatePassword: async function (dataForm, id) {
		return this._post("admin/user/update_password/" + id, dataForm)
	},

	// update password
	sendupadtePassword: async function (dataForm, id) {
		const data = await this.updatePassword(dataForm, id)
		return data
	},

	// reset password
	resetPassword: async function (id) {
		return this._post("admin/user/reset_password/" + id)
	},

	// reset password
	sendresetPassword: async function (id) {
		const data = await this.resetPassword(id)
		return data
	},

	// send email forget password
	forgetPassword: async function (dataForm) {
		console.log(dataForm)
		return this._post("members/forget-password", dataForm)
	},

	// send email forget password
	sendforgetPassword: async function (dataForm) {
		const data = await this.forgetPassword(dataForm)
		return data
	},

	updateStatusBlockUser: async function (dataForm) {
		const data = await this._post("admin/user/updateStatusBlock", dataForm)
		return data
	},

	sendSaleUser: async function (dataForm) {
		const data = await this._post("admin/sale/addSaleUser", dataForm)
		return data
	},

	updateSaleAdsActive: async function (dataForm) {
		const data = await this._post("admin/sale/updateActive", dataForm)
		return data
	},

	addAdsSetting: async function (dataForm) {
		const data = await this._post("admin/ads-setting/add", dataForm)
		return data
	},

	updateAdsSetting: async function (dataForm) {
		const data = await this._post("admin/ads-setting/update", dataForm)
		return data
	},

	uploadGenerateScript: async function (dataForm) {
		console.log(dataForm)
		const data = await this._post("admin/generate-script/upload", dataForm)
		return data
	},

	sendupdatePackage: async function (dataForm, id) {
		return this._post("admin/subscription/package/update/" + id, dataForm)
	},

	sendupdatePackageActive: async function (dataForm, id) {
		return this._post("admin/subscription/package/updateActive", dataForm)
	},

	sendG2FA: async function (email) {
		return this._post("admin/g2fa", { email: email })
	},

	checkG2fa: async function (email, number) {
		return this._post("admin/check_g2fa", { email: email, number: number })
	},
}
