import React from "react"
import { Modal } from "react-bootstrap"
const ModalConfirmDelete = (props) => {
	const { closeModal, onDelete, showModal } = props
	const onSubmit = () => {
		onDelete()
	}

	return (
		<Modal size={"sm"} show={showModal} centered onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>ต้องการลบข้อมูล?</Modal.Title>
			</Modal.Header>
			<Modal.Footer>
				<button className="btn btn-danger" style={{ padding: "8px", margin: "5px" }} onClick={onSubmit}>
					ตกลง
				</button>
				<button
					className="btn btn-secondary"
					style={{ padding: "8px", margin: "5px" }}
					onClick={() => {
						closeModal()
					}}
				>
					ยกเลิก
				</button>
			</Modal.Footer>
		</Modal>
	)
}
export default ModalConfirmDelete
