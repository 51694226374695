import React, { useState } from "react"
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/css/froala_style.css"
import "froala-editor/js/plugins.pkgd.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/image_manager.min.js"
import "froala-editor/js/plugins/paragraph_style.min.js"
import FroalaEditorComponent from "react-froala-wysiwyg"

export default function Editor({
	label,
	require,
	placeholderText = "รายละเอียด",
	onChange,
	defaultValue,
	action,
	editorCol = "col-6",
	labelCol = "col-3",
	validate
}) {
	const [text, setText] = useState(defaultValue)
	const handleModelChange = (model) => {
		setText(model)
		if (onChange) {
			onChange(model)
		}
		// if (text !== model) {
		// 	let keys = []
		// 	let values = []
		// 	keys.push(`widgets[${index}].data[0].description`)
		// 	values.push(model)
		// 	if (newImage) {
		// 		keys.push(`images`)
		// 		values.push([...content.images, newImage])
		// 		setNewImage(undefined)
		// 	}
		// 	handleChange(keys, values)
		// }
		// }
	}

	return (
		<>
			<div className="row">
				<div className={`row-label flex-nowrap ${labelCol}`}>
					{label}
					{label && (
						<>
							<p style={{ color: "red" }}>{require && `*`}</p>:
						</>
					)}
				</div>
				<div className={editorCol}>
					<FroalaEditorComponent
						tag="textarea"
						onModelChange={handleModelChange}
						model={text}
						config={{
							key: process.env.REACT_APP_FROALA_KEY,
							pastePlain: true,
							placeholderText: placeholderText,
							charCounterCount: false,
							attribution: false,
							linkAlwaysBlank: true,
							heightMin: 180,
							heightMax: 200,
							useClasses: true,
							toolbarSticky: false,
							pluginsEnabled: [
								"align",
								"codeBeautifier",
								"codeView",
								"colors",
								"fontSize",
								"fullscreen",
								"inlineStyle",
								"link",
								"lists",
								"paragraphFormat",
								"paragraphStyle",
								"table",
								"url",
								"insertHR",
							],

							toolbarButtons: {
								moreText: {
									buttonsVisible: 4,
									buttons: [
										"bold",
										"italic",
										"underline",
										"fontSize",
										// more
										"textColor",
										"backgroundColor",
										"strikeThrough",
										"subscript",
										"superscript",
										// 'fontFamily',
										"inlineClass",
										"inlineStyle",
										"clearFormatting",
									],
								},
								moreParagraph: {
									buttonsVisible: 4,
									buttons: [
										"paragraphStyle",
										"paragraphFormat",
										"formatUL",
										"insertTable",
										"alignLeft",
										"alignCenter",
										"alignRight",
										"formatOL",
										"formatOLSimple",
										"alignJustify",
										"lineHeight",
										"outdent",
										"indent",
										// 'quote',
										"insertHR",
									],
								},
								moreRich: {
									buttonsVisible: 4,
									buttons: ["insertLink"],
								},
								moreMisc: {
									buttonsVisible: 4,
									buttons: [
										"fullscreen",
										"clearFormatting",
										"undo",
										"redo",
										"html",
										// more
										// 'print',
										// 'getPDF',
										"spellChecker",
										// 'selectAll',
										"help",
									],
									align: "right",
								},
							},
							htmlRemoveTags: [""],
						}}
					/>
					{validate && <div style={{ color: "red" }}>{validate}</div>}
				</div>
				{action && (
					<div className="col-3  row-label" style={{ alignItems: "center" }}>
						{action()}
					</div>
				)}
			</div>
		</>
	)
}
