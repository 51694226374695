import React, { useEffect, useState } from "react"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { ages } from "../../../../helper/const"
import moment from "moment"
import "moment/locale/th"

export const TableSetup = ({ data }) => {
	const {
		type,
		name,
		description,
		detail,
		startDate,
		endDate,
		rules,
		taskRule,
		joinScore,
		answerCorrect,
		backgroundImage,
		bannerImage,
		shareImage,
		prizeImage,
		sponsorImage,
		position,
		limitRight,
		link,
		slagName,
		timeout,
		adsAward,
		noteAward,
	} = data

	const [arrData, setArrData] = useState([])

	useEffect(() => {
		let tableHead = []
		switch (type) {
			case "Vote":
				tableHead = [
					{
						header: "Type",
						value: type,
					},
					{
						header: "Name",
						value: name,
						require: true,
					},
					{
						header: "Term Condition",
						value: description,
						require: true,
						html: description ? true : false,
					},
					{
						header: "Rules",
						value: formatManyText(rules),
						require: true,
					},
					{
						header: "Start Time",
						value: moment(startDate).format("D MMMM YYYY HH:MM"),
						require: true,
					},
					{
						header: "End Time",
						value: moment(endDate).format("D MMMM YYYY HH:MM"),
						require: true,
					},
					{
						header: "Banner Image",
						value: formatImage(bannerImage),
						require: true,
					},
					{
						header: "Background Image",
						value: formatImage(backgroundImage),
						require: true,
					},
					{
						header: "Share Image",
						value: formatImage(shareImage),
						require: true,
					},
					{
						header: "Prize Image",
						value: formatImage(prizeImage),
						require: true,
					},
					{
						header: "Sponsor Image",
						value: formatManyImages(sponsorImage),
						require: false,
					},
					{
						header: "Position",
						value: position ? "หน้าแคมเปญ" : "ใต้บทความ",
						require: true,
					},
					{
						header: "Slagname",
						value: slagName,
						require: true,
					},
					{
						header: "Link",
						value: link,
						require: true,
					},
					{
						header: "คำโปรยของรางวัล",
						value: adsAward,
						require: true,
						html: adsAward ? true : false,
					},
					{
						header: "หมายเหตุ",
						value: noteAward,
						require: true,
						html: noteAward ? true : false,
					},
				]
				break

			case "Quiz":
				tableHead = [
					{
						header: "Type",
						value: type,
					},
					{
						header: "Name",
						value: name,
						require: true,
					},
					{
						header: "Term Condition",
						value: description,
						require: true,
						html: description ? true : false,
					},
					{
						header: "Rules",
						value: formatManyText(rules),
						require: true,
					},
					{
						header: "Timeout / Qusetion",
						value: `${timeout} seconds`,
						require: false,
					},
					{
						header: "Start Time",
						value: moment(startDate).format("D MMMM YYYY HH:MM"),
						require: true,
					},
					{
						header: "End Time",
						value: moment(endDate).format("D MMMM YYYY HH:MM"),
						require: true,
					},
					{
						header: "Banner Image",
						value: formatImage(bannerImage),
						require: true,
					},
					{
						header: "Background Image",
						value: formatImage(backgroundImage),
						require: true,
					},
					{
						header: "Share Image",
						value: formatImage(shareImage),
						require: true,
					},
					{
						header: "Prize Image",
						value: formatImage(prizeImage),
						require: true,
					},
					{
						header: "Sponsor Image",
						value: formatManyImages(sponsorImage),
						require: false,
					},
					{
						header: "Position",
						value: position ? "หน้าแคมเปญ" : "ใต้บทความ",
						require: true,
					},
					{
						header: "Slagname",
						value: slagName,
						require: true,
					},
					{
						header: "Link",
						value: link,
						require: true,
					},
					{
						header: "คำโปรยของรางวัล",
						value: adsAward,
						require: true,
						html: true,
					},
					{
						header: "หมายเหตุ",
						value: noteAward,
						html: true,
					},
				]
				break
			default:
				break
		}
		setArrData(tableHead)
	}, [])

	const formatManyText = (cell) => {
		return cell.length
	}

	const formatImage = (cell) => {
		return cell ? <img style={{ height: 300, width: 300 }} src={(cell || "").url} alt={cell && "bg"} /> : ""
	}

	const formatManyImages = (cell) => {
		return cell.map((list) => <img style={{ height: 150, width: 150 }} src={(list || "").url} alt={list && "bg"} />)
	}

	return (
		<table className="table-compare">
			<tbody>
				{arrData.map((list, index) => (
					<tr key={index}>
						<th className="headcol">
							<h5>
								<strong>{list.header}</strong>
							</h5>
						</th>
						<td
							className={`tr-${list.require && !list.value ? "require-" : ""}text`}
							style={{
								width: "100%",
							}}
						>
							{list?.html ? <div dangerouslySetInnerHTML={{ __html: list.value }}></div> : list.require && !list.value ? "require*" : list.value}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export const TableTarget = ({ data }) => {
	const { memberType, gender, age, province, interest, activity, topic, dataType, startRecency, endRecency } = data
	const [arrData, setArrData] = useState([])

	useEffect(() => {
		setArrData([
			{
				header: "Membership Type",
				value: memberType,
				require: true,
			},
			{
				header: "Gender",
				value: formatMultiSelect(gender),
			},
			{
				header: "Age",
				value: formatAge(age),
			},
			{
				header: "Location",
				value: formatMultiSelect(province),
			},
			{
				header: "Interest",
				value: formatMultiSelect(interest),
			},
			{
				header: "Activity",
				value: activity,
			},
			{
				header: "Top Section/Topic",
				value: topic,
			},
			{
				header: "Member Data Type",
				value: dataType,
				require: true,
			},
			{
				header: "Start Date Recency",
				value: startRecency,
				require: true,
			},
			{
				header: "End Date Recency",
				value: endRecency,
				require: true,
			},
		])
	}, [])

	const formatMultiSelect = (cell = []) => {
		return <ul>{cell && cell.map((list, index) => <li key={index}>{` ${index + 1}. ${list.value}`}</li>)}</ul>
	}

	const formatAge = (cell) => {
		const result = ages.find((list) => {
			return list.value === parseInt(cell)
		})
		return `${result?.label || 0} ปี`
	}

	return (
		<table className="table-compare">
			<tbody>
				{arrData.map((list, index) => (
					<tr key={index}>
						<th className="headcol">
							<h5>
								<strong>{list.header}</strong>
							</h5>
						</th>
						<td className={`tr-${list.require && !list.value ? "require-" : ""}text`}>{list.require && !list.value ? "require*" : list.value}</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export const TableDetail = ({ data }) => {
	const formatData = (cell, row, enumObject, index) => {
		return index + 1
	}

	const formatChoice = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, index) => (
					<li key={index} style={{ display: !list.choiceName && "none" }}>
						{`${index + 1}. `}
						{list.choiceName}
					</li>
				))}
			</ul>
		)
	}

	const formatImage = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, index) => (
					<li key={index} style={{ display: !list.choiceName && "none", padding: "5px", boxSizing: "border-box" }}>
						{`${index + 1}. `}
						{!(list.choiceImage || "").url ? "" : <img src={(list.choiceImage || "").url} style={{ width: "100%", maxHeight: 300 }} alt="image_choice" />}
					</li>
				))}
			</ul>
		)
	}

	const formatUrl = (cell, row, enumObject, index) => {
		return <div dangerouslySetInnerHTML={{ __html: row.clip || "" }} />
	}

	const formatLogo = (cell, row, enumObject, index) => {
		if (cell) {
			return <img src={cell.url ? cell.url : ""} alt="logo" style={{ width: "100%", maxHeight: 300 }} />
		}
	}
	return (
		<BootstrapTable data={data} className="table table-striped">
			<TableHeaderColumn dataField="qId" headerAlign="center" dataAlign="center" width="30" dataFormat={formatData} isKey>
				Question No.
			</TableHeaderColumn>
			<TableHeaderColumn dataField="questionName" headerAlign="center" width="80">
				Question
			</TableHeaderColumn>
			<TableHeaderColumn dataField="choices" headerAlign="center" width="80" dataFormat={formatChoice}>
				choice
			</TableHeaderColumn>
			<TableHeaderColumn dataField="choices" headerAlign="center" width="100" dataFormat={formatImage}>
				Image
			</TableHeaderColumn>
			<TableHeaderColumn dataField="choices" headerAlign="center" width="80" dataFormat={formatUrl}>
				Video Url
			</TableHeaderColumn>
			<TableHeaderColumn dataField="questionImage" headerAlign="center" dataAlign="center" width="100" dataFormat={formatLogo}>
				Question Image
			</TableHeaderColumn>
		</BootstrapTable>
	)
}

export const TableQuiz = ({ data }) => {
	const formatData = (cell, row, enumObject, index) => {
		return index + 1
	}

	const formatChoice = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, index) => (
					<li key={index} style={{ display: !list.choiceName && "none" }}>
						{`${index + 1}. `}
						{list.choiceName}
					</li>
				))}
			</ul>
		)
	}

	const formatLogo = (cell, row, enumObject, index) => {
		return (cell || "").url && <img src={cell.url} alt="logo" style={{ width: "100%", maxHeight: 300 }} />
	}

	const formatCorrect = (cell, row, enumObject, index) => {
		if (Array.isArray(cell)) {
			if (cell.length > 1) {
				return (
					<ul>
						{cell.map((list, index) => (
							<li key={index}>
								{`${index + 1}. `}
								{list.label}
							</li>
						))}
					</ul>
				)
			} else {
				const newData = row.choices.find((list) => {
					return list.chId === cell[0]
				})
				return newData?.choiceName || ""
			}
		} else {
			const newData = row.choices.find((list) => {
				return list.chId === cell
			})
			return newData?.choiceName || ""
		}
	}

	const formatImage = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, index) => (
					<li key={index} style={{ display: !list.choiceName && "none", padding: "5px" }}>
						{`${index + 1}. `}
						{!(list.choiceImage || "").url ? "" : <img src={(list.choiceImage || "").url} style={{ width: "100%", maxHeight: 300 }} alt="image_choice" />}
					</li>
				))}
			</ul>
		)
	}

	const formatUrl = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, index) => (
					<li key={index} style={{ display: !list.choiceName && "none" }}>
						{`${index + 1}. `}
						{list.clipeUrl}
					</li>
				))}
			</ul>
		)
	}

	return (
		<BootstrapTable data={data} className="table table-striped">
			<TableHeaderColumn dataField="qId" headerAlign="center" dataAlign="center" width="30" dataFormat={formatData} isKey>
				Question No.
			</TableHeaderColumn>
			<TableHeaderColumn dataField="questionName" headerAlign="center" width="80">
				Question
			</TableHeaderColumn>
			<TableHeaderColumn dataField="answerType" headerAlign="center" width="80">
				answer type
			</TableHeaderColumn>
			<TableHeaderColumn dataField="choices" headerAlign="center" width="80" dataFormat={formatChoice}>
				choice
			</TableHeaderColumn>
			<TableHeaderColumn dataField="answerCorrect" headerAlign="center" width="80" dataFormat={formatCorrect}>
				correct answer
			</TableHeaderColumn>
			{/* <TableHeaderColumn dataField="choices" headerAlign="center" width="100" dataFormat={formatImage}>
				Image
			</TableHeaderColumn> */}
			{/* <TableHeaderColumn dataField="choices" headerAlign="center" width="80" dataFormat={formatUrl}>
				Video
			</TableHeaderColumn> */}
			<TableHeaderColumn dataField="logoImage" headerAlign="center" dataAlign="center" width="100" dataFormat={formatLogo}>
				Question Image
			</TableHeaderColumn>
		</BootstrapTable>
	)
}

export const TableScore = ({ data }) => {
	const formatData = (cell, row, enumObject, index) => {
		return index + 1
	}

	const formatLogo = (cell, row, enumObject, index) => {
		return cell.url && <img src={cell.url} alt="logo" style={{ width: "100%", maxHeight: 300 }} />
	}

	const formatTeams = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order}>
						{`${order + 1}. `}
						{list.teamName1}
					</li>
				))}
			</ul>
		)
	}

	const formatTeams2 = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order}>
						{`${order + 1}. `}
						{list.teamName2}
					</li>
				))}
			</ul>
		)
	}

	const formatFlag = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order} className="mb-2">
						{!(list.flagImage1 || "").url ? "" : <img src={(list.flagImage1 || "").url} style={{ width: "100%", maxHeight: 300 }} alt="flag_logo" />}
					</li>
				))}
			</ul>
		)
	}

	const formatFlag2 = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order} className="mb-2">
						{!(list.flagImage2 || "").url ? "" : <img src={(list.flagImage2 || "").url} style={{ width: "100%", maxHeight: 300 }} alt="flag_logo" />}
					</li>
				))}
			</ul>
		)
	}

	const formatScore = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order}>{list.correctScore1}</li>
				))}
			</ul>
		)
	}

	const formatScore2 = (cell, row, enumObject, index) => {
		return (
			<ul>
				{cell.map((list, order) => (
					<li key={order}>{list.correctScore2}</li>
				))}
			</ul>
		)
	}

	return (
		<BootstrapTable data={data} className="table table-striped">
			<TableHeaderColumn dataField="qId" headerAlign="center" dataAlign="center" width="30" dataFormat={formatData} isKey>
				Question No.
			</TableHeaderColumn>
			<TableHeaderColumn dataField="questionName" headerAlign="center" width="80">
				Question
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" dataAlign="center" width="80" dataFormat={formatFlag}>
				Flag 1
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" width="80" dataFormat={formatTeams}>
				Team 1
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" dataAlign="center" width="80" dataFormat={formatScore}>
				score (Team 1)
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" dataAlign="center" width="80" dataFormat={formatScore2}>
				score (Team 2)
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" width="80" dataFormat={formatTeams2}>
				Team 2
			</TableHeaderColumn>
			<TableHeaderColumn dataField="teams" headerAlign="center" width="80" dataAlign="center" dataFormat={formatFlag2}>
				Flag 2
			</TableHeaderColumn>
			<TableHeaderColumn dataField="logoImage" headerAlign="center" dataAlign="center" width="100" dataFormat={formatLogo}>
				Question Logo
			</TableHeaderColumn>
		</BootstrapTable>
	)
}
