export const memberType = [
	{
		value: "Membership",
		label: "Membership",
	},
	{
		value: "Subscription",
		label: "Subscription",
	},
]

export const gender = [
	{
		value: "male",
		label: "ชาย",
	},
	{
		value: "female",
		label: "หญิง",
	},
]

export const location = [
	{
		value: "กรุงเทพมหานคร",
		label: "กรุงเทพมหานคร",
	},
	{
		value: "สมุทรปราการ",
		label: "สมุทรปราการ",
	},
	{
		value: "นนทบุรี",
		label: "นนทบุรี",
	},
	{
		value: "ปทุมธานี",
		label: "ปทุมธานี",
	},
	{
		value: "พระนครศรีอยุธยา",
		label: "พระนครศรีอยุธยา",
	},
	{
		value: "อ่างทอง",
		label: "อ่างทอง",
	},
	{
		value: "ลพบุรี",
		label: "ลพบุรี",
	},
	{
		value: "สิงห์บุรี",
		label: "สิงห์บุรี",
	},
	{
		value: "ชัยนาท",
		label: "ชัยนาท",
	},
	{
		value: "สระบุรี",
		label: "สระบุรี",
	},
	{
		value: "ชลบุรี",
		label: "ชลบุรี",
	},
	{
		value: "ระยอง",
		label: "ระยอง",
	},
	{
		value: "จันทบุรี",
		label: "จันทบุรี",
	},
	{
		value: "ตราด",
		label: "ตราด",
	},
	{
		value: "ฉะเชิงเทรา",
		label: "ฉะเชิงเทรา",
	},
	{
		value: "ปราจีนบุรี",
		label: "ปราจีนบุรี",
	},
	{
		value: "นครนายก",
		label: "นครนายก",
	},
	{
		value: "สระแก้ว",
		label: "สระแก้ว",
	},
	{
		value: "นครราชสีมา",
		label: "นครราชสีมา",
	},
	{
		value: "บุรีรัมย์",
		label: "บุรีรัมย์",
	},
	{
		value: "สุรินทร์",
		label: "สุรินทร์",
	},
	{
		value: "ศรีสะเกษ",
		label: "ศรีสะเกษ",
	},
	{
		value: "อุบลราชธานี",
		label: "อุบลราชธานี",
	},
	{
		value: "ยโสธร",
		label: "ยโสธร",
	},
	{
		value: "ชัยภูมิ",
		label: "ชัยภูมิ",
	},
	{
		value: "อำนาจเจริญ",
		label: "อำนาจเจริญ",
	},
	{
		value: "หนองบัวลำภู",
		label: "หนองบัวลำภู",
	},
	{
		value: "ขอนแก่น",
		label: "ขอนแก่น",
	},
	{
		value: "อุดรธานี",
		label: "อุดรธานี",
	},
	{
		value: "อำนาจเจริญ",
		label: "อำนาจเจริญ",
	},
	{
		value: "เลย",
		label: "เลย",
	},
	{
		value: "หนองคาย",
		label: "หนองคาย",
	},
	{
		value: "มหาสารคาม",
		label: "มหาสารคาม",
	},
	{
		value: "ร้อยเอ็ด",
		label: "ร้อยเอ็ด",
	},
	{
		value: "กาฬสินธุ์",
		label: "กาฬสินธุ์",
	},
	{
		value: "สกลนคร",
		label: "สกลนคร",
	},
	{
		value: "นครพนม",
		label: "นครพนม",
	},
	{
		value: "มุกดาหาร",
		label: "มุกดาหาร",
	},
	{
		value: "เชียงใหม่",
		label: "เชียงใหม่",
	},
	{
		value: "ลำพูน",
		label: "ลำพูน",
	},
	{
		value: "ลำปาง",
		label: "ลำปาง",
	},
	{
		value: "อุตรดิตถ์",
		label: "อุตรดิตถ์",
	},
	{
		value: "แพร่",
		label: "แพร่",
	},
	{
		value: "น่าน",
		label: "น่าน",
	},
	{
		value: "พะเยา",
		label: "พะเยา",
	},
	{
		value: "เชียงราย",
		label: "เชียงราย",
	},
	{
		value: "แม่ฮ่องสอน",
		label: "แม่ฮ่องสอน",
	},
	{
		value: "นครสวรรค์",
		label: "นครสวรรค์",
	},
	{
		value: "อุทัยธานี",
		label: "อุทัยธานี",
	},
	{
		value: "กำแพงเพชร",
		label: "กำแพงเพชร",
	},
	{
		value: "ตาก",
		label: "ตาก",
	},
	{
		value: "สุโขทัย",
		label: "สุโขทัย",
	},
	{
		value: "พิษณุโลก",
		label: "พิษณุโลก",
	},
	{
		value: "พิจิตร",
		label: "พิจิตร",
	},
	{
		value: "เพชรบูรณ์",
		label: "เพชรบูรณ์",
	},
	{
		value: "ราชบุรี",
		label: "ราชบุรี",
	},
	{
		value: "กาญจนบุรี",
		label: "กาญจนบุรี",
	},
	{
		value: "สุพรรณบุรี",
		label: "สุพรรณบุรี",
	},
	{
		value: "นครปฐม",
		label: "นครปฐม",
	},
	{
		value: "สมุทรสาคร",
		label: "สมุทรสาคร",
	},
	{
		value: "สมุทรสงคราม",
		label: "สมุทรสงคราม",
	},
	{
		value: "เพชรบุรี",
		label: "เพชรบุรี",
	},
	{
		value: "ประจวบคีรีขันธ์",
		label: "ประจวบคีรีขันธ์",
	},
	{
		value: "นครศรีธรรมราช",
		label: "นครศรีธรรมราช",
	},
	{
		value: "กระบี่",
		label: "กระบี่",
	},
	{
		value: "พังงา",
		label: "พังงา",
	},
	{
		value: "เพชรบุรี",
		label: "เพชรบุรี",
	},
	{
		value: "ภูเก็ต",
		label: "ภูเก็ต",
	},
	{
		value: "สุราษฎร์ธานี",
		label: "สุราษฎร์ธานี",
	},
	{
		value: "ระนอง",
		label: "ระนอง",
	},
	{
		value: "ชุมพร",
		label: "ชุมพร",
	},
	{
		value: "สงขลา",
		label: "สงขลา",
	},
	{
		value: "สตูล",
		label: "สตูล",
	},
	{
		value: "ตรัง",
		label: "ตรัง",
	},
	{
		value: "พัทลุง",
		label: "พัทลุง",
	},
	{
		value: "ปัตตานี",
		label: "ปัตตานี",
	},
	{
		value: "ยะลา",
		label: "ยะลา",
	},
	{
		value: "นราธิวาส",
		label: "นราธิวาส",
	},
	{
		value: "บึงกาฬ",
		label: "บึงกาฬ",
	},
]

export const interest = [
	{
		value: "local",
		label: "ทั่วไทย",
	},
	{
		value: "politic",
		label: "การเมือง",
	},
	{
		value: "travel",
		label: "ท่องเที่ยว",
	},
	{
		value: "auto",
		label: "ยานยนต์",
	},
	{
		value: "novel",
		label: "นิยาย",
	},
	{
		value: "women",
		label: "ผู้หญิง",
	},
	{
		value: "lottery",
		label: "หวย",
	},
	{
		value: "tech",
		label: "ไอที",
	},
	{
		value: "entertain",
		label: "บันเทิง",
	},
	{
		value: "podcast",
		label: "Podcast",
	},
	{
		value: "gold",
		label: "ทองคำ",
	},
	{
		value: "video",
		label: "Video",
	},
	{
		value: "sport",
		label: "กีฬา",
	},
	{
		value: "crime",
		label: "อาชญากรรม",
	},
	{
		value: "society",
		label: "สังคม",
	},
	{
		value: "business",
		label: "เศรษฐกิจ",
	},
	{
		value: "foreign",
		label: "ต่างประเทศ",
	},
	{
		value: "lifestyle",
		label: "ไลฟ์สไตล์",
	},
	{
		value: "horoscope",
		label: "เรื่องดวง",
	},
]

export const activity = [
	{
		value: "Login",
		label: "Login",
	},
	{
		value: "Register",
		label: "Register",
	},
	{
		value: "Forget_pass",
		label: "Forget_pass",
	},
	{
		value: "Change_password",
		label: "Change_password",
	},
	{
		value: "Activate",
		label: "Activate",
	},
	{
		value: "Update_info",
		label: "Update_info",
	},
]

export const topic = [
	{
		value: "Travel",
		label: "Travel",
	},
	{
		value: "Auto",
		label: "Auto",
	},
	{
		value: "Novel",
		label: "Novel",
	},
	{
		value: "Women",
		label: "Women",
	},
	{
		value: "Lottery",
		label: "Lottery",
	},
	{
		value: "Tech",
		label: "Tech",
	},
	{
		value: "Entertain",
		label: "Entertain",
	},
	{
		value: "Podcast",
		label: "Podcast",
	},
	{
		value: "Gold",
		label: "Gold",
	},
	{
		value: "Video",
		label: "Video",
	},
	{
		value: "Sport",
		label: "Sport",
	},
	{
		value: "Crime",
		label: "Crime",
	},
	{
		value: "Society",
		label: "Society",
	},
	{
		value: "Business",
		label: "Business",
	},
	{
		value: "Foreign",
		label: "Foreign",
	},
]

export const dataType = [
	{
		value: "Total",
		label: "Total",
	},
	{
		value: "Active",
		label: "Active",
	},
	{
		value: "New",
		label: "New",
	},
]

export const type = [
	{
		label: "Vote",
		value: "Vote",
	},
	{
		label: "Quiz",
		value: "Quiz",
	},
	{
		label: "Score",
		value: "Score",
	},
]

export const rule = [
	{
		label: "No limit (ไม่จำกัดสิทธิ์การเล่น)",
		value: "nolimit",
	},
	{
		label: "1 member/1 right (1 คนต่อ 1 สิทธิ์)",
		value: "oneToOne",
	},
	{
		label: "Limit right (จำกัดครั้งในการเล่น)",
		value: "limit",
	},
]

export const taskRule = [
	{
		label: "Force (ต้องโหวตครบทุกข้อ)",
		value: "force",
	},
	{
		label: "Unforce (ไม่ต้องโหวตครบทุกข้อ) ",
		value: "unforce",
	},
]

export const positon = [
	{
		value: 0,
		label: "ใต้บทความ",
	},
	{
		value: 1,
		label: "หน้าแคมเปญ",
	},
]

export const publish = [
	{
		value: 0,
		label: "ปิด",
	},
	{
		value: 1,
		label: "เปิด",
	},
]

export const answerType = [
	{
		value: "single",
		label: "single",
	},
	{
		value: "multiple",
		label: "multiple",
	},
	{
		value: "writting",
		label: "writting",
	},
]

export const ages = [
	{
		value: 1,
		label: "ต่ำกว่า 18",
	},
	{
		value: 2,
		label: "18-24",
	},
	{
		value: 3,
		label: "25-34",
	},
	{
		value: 4,
		label: "35-44",
	},
	{
		value: 5,
		label: "45-54",
	},
	{
		value: 6,
		label: "55-64",
	},
	{
		value: 7,
		label: "65+",
	},
]

export const showDetail = [
	{
		value: 0,
		label: "image",
	},
	{
		value: 1,
		label: "video",
	},
]

export const premiumType = [
	{
		value: "",
		label: "Guest",
	},
	{
		value: "membership",
		label: "Membership",
	},
]

// export const displayPosition = [
// 	{
// 		value: "last_article",
// 		label: "ด้านล่างบทความ",
// 	},
// 	{
// 		value: "in_article",
// 		label: "แทรกในบทความ",
// 	},
// ]
