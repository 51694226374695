import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import { checkPermission } from "../../../helper/functions"
import User from "../../../libs/admin"
import Alert from "react-bootstrap/Alert"
import { Pagination } from "semantic-ui-react"


var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const [data, setData] = useState()
	const inputRef = useRef(null)

	
	const [editSubscriptionMember , setStateEditSubscriptionMember] = useState(true)
	const [packageName , setPackageName] = useState([])
	const [payment , setPayment] = useState([])
	const [trial , setFreeTrial] = useState([])

	const [packageSearch , setPackageSearch] = useState("")
	const [paymentSearch , setPaymentSearch] = useState("")
	const [trialSearch , setTrialSearch] = useState("")
	
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")

	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "subscription_member_view")

		const checkedEdit = checkPermission(user.permission, "subscription_member_edit")
		setStateEditSubscriptionMember(checkedEdit)



		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/member/list?v=` + Math.random() * 1000, { withCredentials: true })
				setallPage(data.pages)
				restrucObj(data)

				const package_detail =  await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/package/list?v=` + Math.random() * 1000, { withCredentials: true })
				generatePackageToSelect(package_detail.data.data)
			}
			fetchData()
		}else{
			props.history.push('/')
		}
	}, [])

	const restrucObj = (data_format, pagedata = 1) => {
		const newArr = []
		data_format.data.map((items, key) => {
			const newObj = {
				_id: items._id,
				count: key + (1 + 25 * (pagedata - 1)),
				customer : items.customer,
				plan_subscription : items.plan_subscription,
				trial : items.trial,
			}

			newArr.push(newObj)
		})
		setData(newArr)
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_ROOT}/admin/subscription/member/list?v=` + Math.random() * 1000 + `&page=${pageInfo.activePage}`,
			{ withCredentials: true }
		)
		restrucObj(data, pageInfo.activePage)
	}

	const generatePackageToSelect = package_detail => {
		const package_name = []
		const package_payment = ["creditcard","mobilebanking","promptpay","truemoney"]
		const package_trial = ["กำลังทดลอง" , "ทดลองแล้ว"]

		package_detail.map((items) => {
			package_name.push(items.name)
		})

		setPackageName(package_name)
		setPayment(package_payment)
		setFreeTrial(package_trial)
	}

	const formatName = (cell, row, enumObject, index) => {
		return <Link to={"/member/edit/" + cell.member_id} >{cell.name}</Link>
	}

	const formatPackage = (cell, row, enumObject, index) => {
		return cell.package_name
	}

	const formatTrial = (cell, row, enumObject, index) => {
		if(cell.status){
			return "กำลังทดลอง"
		}else{
			return "ทดลองแล้ว"
		}
	}

	const formatStartDate = (cell, row, enumObject, index) => {
		return  <Moment format="DD/MM/YYYY H:mm">{cell.start_date}</Moment>
	}

	const formatEndDate = (cell, row, enumObject, index) => {
		return  <Moment format="DD/MM/YYYY H:mm">{cell.end_date}</Moment>
	}
	
	const formatPayment = (cell, row, enumObject, index) => {
		return cell.payment_method
	}

	const formatRecurring = (cell, row, enumObject, index) => {
		if(cell.active){
			return "ดำเนินการ"
		}else{
			return  "ยกเลิก"
		}
		
	}

	const onSearch = async () => {

		
		let searchQuery = ""
		if (packageSearch.length > 0) {
			searchQuery += `&package=${packageSearch}`
		}
		if (paymentSearch.length > 0){
			searchQuery += `&payment=${paymentSearch}`
		}
		if (trialSearch.length > 0){
			searchQuery += `&freetrial=${trialSearch}`
		}
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/member/search?${searchQuery}`, { withCredentials: true })
		
		if (data.data !== false) {
			setallPage(data.pages)
			setActivePage(data.currentPage)
			setData(data.data)
		} else {
			setData([])
		}

	}

	
	
	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				
				<div className="card-body">
					<br/>

					<div className="row">
						<div className="col-4" />
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={packageSearch || "Package Name"} onChange={e => setPackageSearch(e.target.value)}>
									<option value="">Package Name ...</option>
								{packageName && packageName.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={paymentSearch || "Payment"} onChange={e => setPaymentSearch(e.target.value)}>
									<option value="">Payment ...</option>
								{payment && payment.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<div className="col-2">
							<div class="input-group md-form form-sm form-2 pl-0">
							<select id="package-name" className="form-control" value={trialSearch || "Free Trial"} onChange={e => setTrialSearch(e.target.value)}>
									<option value="">Free Trial ...</option>
								{trial && trial.map((items) => (
									<option value={items}>{items}</option>
								))}
							</select>
							</div>
						</div>
						<p>
							<button className="btn btn-success btn-icon-split" onClick={() => onSearch()}>
								<span className="icon text-white-50">
									<i className="fas fa-search"></i>
								</span>
								<span className="text">ค้นหาข้อมูล</span>
							</button>
						</p>
					</div>

					
					
					<br/>
					{data !== undefined &&  <div className="col-12">
							<BootstrapTable data={data} className="table table-striped"> 
							<TableHeaderColumn ref={inputRef} dataAlign="center" dataField="count" width="50">
								ลำดับ
							</TableHeaderColumn>
							<TableHeaderColumn ref={inputRef} dataField="customer" width="150" dataFormat={formatName} isKey>
								ชื่อ-นามสกุล
							</TableHeaderColumn>
							<TableHeaderColumn dataField="plan_subscription" width="100" dataFormat={formatPackage}>
								แพ็คเกจ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="trial" width="100" dataFormat={formatTrial}>
								ทดลอง
							</TableHeaderColumn>
							<TableHeaderColumn dataField="plan_subscription" width="130" dataFormat={formatStartDate}>
								วันที่เริ่ม
							</TableHeaderColumn>
							<TableHeaderColumn dataField="plan_subscription" width="130"  dataFormat={formatEndDate}>
								วันหมดอายุ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="plan_subscription" width="150" dataFormat={formatPayment} >
								ประเภทการชำระเงิน
							</TableHeaderColumn>
							<TableHeaderColumn dataField="plan_subscription" width="100" dataFormat={formatRecurring} dataAlign="center">
								recurring
							</TableHeaderColumn>
						</BootstrapTable>
						{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
					</div>}
					
				</div>
			</div>
		</>
	)
}
export default List
