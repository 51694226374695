import React, { Fragment, useContext } from "react"
import { StoreContext } from "../../Context/store"
import { Navigation } from "../../../../utils"
import { type, positon } from "../../../../helper/const"
import { Input, Textarea, SelectInput, SelectScore, UploadInput, InputSlagName, Radio } from "../../Widgets"
import { campaignController } from "../../Services"
import Editor from "../../Widgets/Editor"
import { v4 as uuidv4 } from "uuid"

const ScoreSetup = ({ pageStep = 1, onChangeStep, pageId }) => {
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		ruleDelete: [ruleDel, setRuleDel],
	} = useContext(StoreContext)

	const onChangeData = (event) => {
		const { name, value, id } = event.target
		switch (name) {
			case "campType":
				setCampSetup({
					...campSetup,
					type: value,
				})
				break

			case "campRule":
				setCampSetup({
					...campSetup,
					rule: value,
					limitRight: value !== "limit" ? "" : campSetup.limitRight,
				})
				break

			case "campLimit":
				setCampSetup({
					...campSetup,
					limitRight: value,
				})
				break

			case "campTaskRule":
				setCampSetup({
					...campSetup,
					taskRule: value,
				})
				break

			case "campJoinScore":
				setCampSetup({
					...campSetup,
					joinScore: parseInt(value),
				})
				break

			case "campAnswerCorrect":
				setCampSetup({
					...campSetup,
					answerCorrect: parseInt(value),
				})
				break

			case "campName":
				setCampSetup({
					...campSetup,
					name: value,
				})
				break

			case "campDescription":
				setCampSetup({
					...campSetup,
					description: value,
				})
				break

			case "campDetail":
				setCampSetup({
					...campSetup,
					detail: value,
				})
				break
			case "campPosition":
				setCampSetup({
					...campSetup,
					position: value,
				})
				break
			case "campLink":
				setCampSetup({
					...campSetup,
					slagName: value,
					link: parseInt(campSetup.position) === 1 ? `${process.env.REACT_APP_LINK_CAMPAIGN}/${value}` : `${process.env.REACT_APP_CONTENT_PAGE}/${value}`,
				})
				break
			case "require_member_name":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						name: value === "true" ? true : false,
					},
				})
				break
			case "require_member_phone":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						phone: value === "true" ? true : false,
					},
				})
				break
			case "require_member_address":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						address: value === "true" ? true : false,
					},
				})
				break
			case "require_member_birthday":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						birthday: value === "true" ? true : false,
					},
				})
				break
		}
	}

	const onUpload = (event) => {
		const { files } = event.target
		let { type, size } = files[0]
		if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
			const err = {
				message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
			}
		} else {
			setCampSetup({
				...campSetup,
				backgroundImage: files[0],
			})
		}
	}

	const setUpload = async () => {
		const file = campSetup.backgroundImage
		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, "logo")

		if (response && response.status === 200) {
			let data = response.data.response
			data = {
				...data,
				name: file.name,
			}
			setCampSetup({
				...campSetup,
				backgroundImage: data,
			})
		}
	}

	const onDeleteImage = async (image_index) => {
		let tempCamp = { ...campSetup }
		if (tempCamp?.backgroundImage?._id) {
			const res = await campaignController().deleteFile(tempCamp?.backgroundImage._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
		tempCamp.backgroundImage = null
		setCampSetup(tempCamp)
	}
	const onAddRule = () => {
		let arr = []
		arr.push({ _id: uuidv4(), text: "" })
		setCampSetup({ ...campSetup, rules: [...campSetup.rules, ...arr] })
	}

	const onDeleteRule = (id) => {
		const { rules } = campSetup
		console.log(rules)
		const arrDel = []
		const delRules = rules.filter((list) => {
			if (list._id !== id) {
				return list
			} else {
				arrDel.push(id)
			}
		})

		setRuleDel([...arrDel])
		setCampSetup({ ...campSetup, rules: [...delRules] })
	}
	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Campaign Setup</h5>
				</div>

				<div className="card-body">
					<SelectInput
						label="Campaign Type"
						id="type"
						name="campType"
						value={campSetup.type}
						data={type}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<Input
						label="Campaign Name"
						id="name"
						name="campName"
						value={campSetup.name}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />

					<Editor
						label="Term Condition"
						require
						defaultValue={campSetup.description}
						onChange={(text) => onChangeData({ target: { name: "campDescription", id: "desc", value: text } })}
					/>
					<br />
					<Editor
						label="Rules"
						require
						defaultValue={campSetup.detail}
						onChange={(text) => onChangeData({ target: { name: "campDetail", id: "detail", value: text } })}
					/>
					<br />
					<SelectScore
						label="Join Score"
						id="joinScore"
						name="campJoinScore"
						value={campSetup.joinScore}
						data={Array(10)}
						onChange={onChangeData}
						unit="score"
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<SelectScore
						label="Answer Correct"
						id="answerCorrect"
						name="campAnswerCorrect"
						value={campSetup.answerCorrect}
						data={Array(10)}
						onChange={onChangeData}
						unit="score"
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<UploadInput
						label="Background Image"
						id="backgroundImg"
						name="backgroundImage"
						placeholder="Browse..."
						value={(campSetup.backgroundImage || {}).url ? campSetup.backgroundImage.name : "Browse..."}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("backgroundImage")}
						list={(campSetup.backgroundImage || {}).url ? campSetup.backgroundImage.name : ""}
						disabled={disabled ? "disabled" : ""}
						onDelete={onDeleteImage}
						remark="Dimension: 1440x789px (16:9)"
					/>
					<br />
					<SelectInput
						label="Campaign Position"
						id="position"
						name="campPosition"
						value={campSetup.position}
						data={positon}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					{campSetup.position >= 0 && (
						<InputSlagName
							label={parseInt(campSetup.position) === 1 ? "Link Campaignpage" : "Link Contentpage"}
							id="link"
							name="campLink"
							maxLength="200"
							value={campSetup.slagName}
							onChange={onChangeData}
							disabled={disabled ? "disabled" : ""}
							domain={parseInt(campSetup.position) === 1 ? `${process.env.REACT_APP_LINK_CAMPAIGN}/` : `${process.env.REACT_APP_CONTENT_PAGE}/`}
							require
						/>
					)}
					<br />
					<div className="row">
						<div className="col-3">
							<h5 className="m-0 font-weight-bold text-primary">แก้ไขกล่องส่งที่อยู่</h5>
						</div>
					</div>
					<br />
					<Radio
						label="require name"
						id="rName"
						name="require_member_name"
						value={campSetup.require_member?.name || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
					<Radio
						label="require phone"
						id="rPhone"
						name="require_member_phone"
						value={campSetup.require_member?.phone || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
					<Radio
						label="require address"
						id="rAddress"
						name="require_member_address"
						value={campSetup.require_member?.address || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
				</div>
			</div>
		</Fragment>
	)
}

export default ScoreSetup
