import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Link } from "react-router-dom"
import { checkPermission } from "../../../helper/functions"
import User from "../../../libs/admin"
import Alert from "react-bootstrap/Alert"
import { Pagination , Checkbox} from "semantic-ui-react"


var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
	const [data, setData] = useState()
	const inputRef = useRef(null)

	const [dataResult, setDataResult] = useState(null)
	const [show, setShow] = useState(false)
	const [color, setColor] = useState(null)
	
	const [addPackageBtn , setStateAddPackageBtn] = useState(true)

	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")


	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "subscription_package_view")

		const checkedEdit = checkPermission(user.permission, "subscription_package_edit")
		setStateAddPackageBtn(checkedEdit)

		if (checked) {
			async function fetchData() {
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/package/list?v=` + Math.random() * 1000, { withCredentials: true })
				restrucObj(data)
				setallPage(data.pages)
			}
			fetchData()
		}else{
			props.history.push('/')
		}
	}, [])

	const restrucObj = (data_format, pagedata = 1) => {
		const newArr = []
		data_format.data.map((items, key) => {
			const newObj = {
				_id: items._id,
				count: key + (1 + 25 * (pagedata - 1)),
				name : items.name,
				day : items.day,
				type : items.type,
				price : items.price,
				freeTrial : items.freeTrial,
				last_update : items.last_update,
				active : items.active
			}

			newArr.push(newObj)
		})
		setData(newArr)
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_ROOT}/admin/subscription/package/list?v=` + Math.random() * 1000 + `&page=${pageInfo.activePage}`,
			{ withCredentials: true }
		)
		restrucObj(data, pageInfo.activePage)
	}

	

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	const formatView = (cell, row, enumObject, index) => {
		return <Link to={`edit/${cell}`}>{cell}</Link>
	}

	
	const activeFormatter = (cell, row, enumObject, index) => {
		return <Checkbox  defaultChecked={cell} id={row._id} onChange={(e) => setActive(e.target.checked , row._id)}/>
	}

	const setActive = async (data , id) => {

		const obj = {
			packageId : id,
			package_active : data
		}
		
		
		try{

			const data = await User.sendupdatePackageActive(obj)
				if (data.status === 200) {
					setDataResult(data.message)
					setShow(true)
					setColor("success")
					setTimeout(() => {
						setShow(false)
					}, 3000)
					
				} else {
					setDataResult(data.message)
					setShow(true)
					setColor("danger")
					setTimeout(() => {
						setShow(false)
					}, 3000)
				}

		}catch(e){
			console.log(e)
		}
	}


	const urlNew = "/subscription/package/edit/new"

	return (
		<>
		{show === true ? (
				<Alert variant={color} onClose={() => setShow(false)} dismissible>
					<p>{dataResult}</p>
				</Alert>
			) : (
				""
		)}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อแพคเกจ</h6>
				</div>
				
				<div className="card-body">
					{addPackageBtn ? <div className="row">
						{/* eslint-disable-next-line  */}
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							<Link to={urlNew} className="btn btn-success">
								เพิ่ม Package
							</Link>
						</h6>
					</div> : ""}
					

					{data !== undefined && <div className="col-12">
						<BootstrapTable data={data}   className="table table-striped">
							<TableHeaderColumn ref={inputRef} dataAlign="center" dataField="count" width="50">
								ลำดับ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="_id" width="200" dataFormat={formatView} isKey >
								id
							</TableHeaderColumn>
							<TableHeaderColumn ref={inputRef} dataField="name" width="100" >
								ชื่อแพ็กเกจ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="day" width="100" >
								ระยะเวลา
							</TableHeaderColumn>
							<TableHeaderColumn dataField="type" width="100" >
								ประเภท
							</TableHeaderColumn>
							<TableHeaderColumn dataField="price" width="100">
								ราคา
							</TableHeaderColumn>
							<TableHeaderColumn
								dataField="freeTrial"
								width="130"
								filterFormatted
							>
								จำนวนวันทดลอง(วัน)
							</TableHeaderColumn>
							<TableHeaderColumn dataField="last_update" width="150" dataFormat={dateFormatter} >
								อัพเดทล่าสุด
							</TableHeaderColumn>
							<TableHeaderColumn dataField="active" width="100" dataFormat={activeFormatter} dataAlign="center">
								ใช้งาน
							</TableHeaderColumn>
						</BootstrapTable>
						{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
					</div>}
				</div>
			</div>
		</>
	)
}
export default List
