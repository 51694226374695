import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Row, Col } from "react-bootstrap"
import { Button, Nav } from "react-bootstrap"
import { Pagination } from "semantic-ui-react"
var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const Activity = () => {
	let { id } = useParams()

    // console.log(id)
	const [data, setData] = useState()
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/member/log/${id}?v=` + Math.random() * 1000,  { withCredentials: true })
			if(data.data.length > 0){ /** Prevent error from data.data is not exists (due to permission) */
				console.log(data.data)
				restrucObj(data)
				setallPage(data.pages)
			}
		}
		fetchData()
	}, [id])

	const restrucObj = (data_format, pagedata = 1) => {
		const newArr = []
		data_format.data.map((items, key) => {
			const newObj = {
				_id : items._id,
				count: key + (1 + 25 * (pagedata - 1)),
				package_name : items.package_name,
				start_date : items.start_date,
				end_date : items.end_date,
				payment_method : items.payment_method,
				recurring : items.recurring,
				status : items.status,
				invoice_number : items.invoice_number
			}

			newArr.push(newObj)
		})
		setData(newArr)
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_ROOT}/admin/subscription/member/log/${id}?v=` + Math.random() * 1000 + `&page=${pageInfo.activePage}`,
			{ withCredentials: true }
		)
		restrucObj(data, pageInfo.activePage)
	}

	const formatDate = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
    }

   	const formatRecurring = (cell) => {
		if(cell){
			return "ทำงาน"
		}else{
			return  "ยกเลิก"
		}
		
	}

    const formatActive = (cell) => {
        if(cell){
			return "ใช้งาน"
		}else{
			return  "หมดอายุ"
		}
    }

	const formatPlan = (cell) => {
		 return `${cell} PLAN`
    }

    const formatGenPdf = (cell, row,) => {
		if(row.package_name !== "free trial"){
			return <Button className="btn btn-primary"  onClick={() => getPdf(row._id)}>PDF</Button>
		}
		 
    }
    
	const getPdf = async (transaction_id) => {
		const searchTransactionById = `&member_id=${id}&transaction_id=${transaction_id}`
		
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/getTrasactionLogById?${searchTransactionById}`,  { withCredentials: true })
		const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/admin/subscription/transaction/generatePdfTrasacrionLogById`, {data , transaction_id} , { withCredentials: true })
		
		// if(response){
		// const arrayBuffer = new Uint8Array(response.data.data.data).buffer;
		// const blob = new Blob([arrayBuffer], {type: 'application/pdf'})
		// const blobURL = URL.createObjectURL(blob);
		
		// //แบบเปิด Page ใหม่
		// window.open(blobURL)
		

		// // แบบกดแล้วโหลดเลย
		// // const link = document.createElement('a')
		// // link.href = window.URL.createObjectURL(blob)
		// // link.download = `invoice_subscription_${transaction_id}.pdf`
		// // link.click()
		// }else{	
		// 	alert('ไม่สามารถดาวน์โหลดไฟล์ได้')
		// }
	}


	return (
		<>
		
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">Transaction Subscription</h6>
				</div>
				
				<div className="card-body">
					<div className="row">
						{/* eslint-disable-next-line  */}
						<h6 className="col-6" />
						<h6 className="col-md-6 text-right">
							{/* <Button className="btn btn-success">
								Export
							</Button> */}
						</h6>
					</div> 
					
					<br/>
					{data !== undefined && <div className="col-12">
					<BootstrapTable data={data}  className="table table-striped">
					<TableHeaderColumn dataAlign="center" dataField="count" width="30">
						ลำดับ
					</TableHeaderColumn>
					<TableHeaderColumn dataField="invoice_number" width="100" dataAlign="center"   isKey>
                        เลขใบกำกับภาษี
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="package_name" width="50" dataAlign="center" dataFormat={formatPlan}>
                        ชื่อ Package
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="start_date" width="80" dataAlign="center" dataFormat={formatDate} >
                        วันที่เริ่ม
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="end_date" width="80" dataAlign="center" dataFormat={formatDate} >
                        วันที่สิ้นสุด
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="payment_method" width="60"  >
						ประเภทการชำระเงิน
					</TableHeaderColumn>
					<TableHeaderColumn dataField="recurring" width="50" dataFormat={formatRecurring} >
						recurring
					</TableHeaderColumn>
                    <TableHeaderColumn dataField="status" width="50" dataFormat={formatActive} >
						สถานะ
					</TableHeaderColumn>
                    <TableHeaderColumn dataField="plan_subscription" width="50" dataAlign="center"  dataFormat={formatGenPdf} >
						action
					</TableHeaderColumn>
                </BootstrapTable> 
						{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
					</div>}
				</div>
			</div>
		</>
	)
}
export default Activity
