import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Form, Tabs, Tab, Button } from "react-bootstrap"
/** Datepicker use */
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css";
import * as moment from "moment";
import "moment/locale/th";
import TopTrPlus from './dashboard/TopTrPlus'
import SpecialContent from "./dashboard/SpecialContent"
import TopContent from './dashboard/TopContent'
import TopMember from './dashboard/TopMember'
import MemberTraffic from './report/components/MemberTraffic'
import MemberTrafficTrPlus from './report/components/MemberTrafficTrPlus'
import { checkPermission } from "../helper/functions"
import User from "../libs/admin"

const Dashboard2 = (props) => {
	const [startdate, setStartdate] = useState( moment().subtract(6, "days").format('YYYY-MM-DD') )
	const [enddate, setEnddate] = useState( moment().format('YYYY-MM-DD') )
    const [startdateText, setStartdateText] = useState( moment().subtract(6, "days").format('YYYY-MM-DD') )
    const [enddateText, setEnddateText] = useState( moment().format('YYYY-MM-DD') )
    
    const [isButtonSubmitted, setIsButtonSubmitted] = useState(false)

    // eslint-disable-next-line
    const [arrSectionTopic, setArrSectionTopic] = useState(["sectionTopic"])
    // eslint-disable-next-line
    const [arrNothing, setArrNothing] = useState([]) /** Use to trick TopContent & SpecialContent because members parameter must be a state NOT the normal variable. */
    
    async function fetchData() {
        
    }

    const runData = () => {
        fetchData().then(() => {
            setIsButtonSubmitted(false)
        }).then(() => {
        })
    }

	useEffect(() => {
        const user = User.getCookieData()
        const checked = checkPermission(user.permission, "dashboard_view")
        
        if(checked){
            runData()
        }else{
            props.history.push('/')
        }
	}, [])

    useEffect(() => {
        if (isButtonSubmitted) {
            runData()
        }
    }, [isButtonSubmitted])

    const getDatePicker = (name, defaultvalue) => {
        return (<DateTime dateFormat="YYYY-MM-DD" timeFormat={false}
            defaultValue={defaultvalue} onChange={date => { 
                date = moment(date).format('YYYY-MM-DD')
                name === "startdate" && setStartdateText(date)
                name === "enddate" && setEnddateText(date)
                console.log(name + " " + date) 
            }} />)
    }

    const submitForm = () => {
        setStartdate(startdateText)
        setEnddate(enddateText)
        setIsButtonSubmitted(true)
    }

    return (
        <Container>
            <Row className="mb-4">
                <Col className="text-right">
                    <span className="mr-4">
                        <Form.Label className="mr-2 font-weight-bold">Start date</Form.Label>
                        {getDatePicker("startdate", startdateText)}
                    </span>
                    <span>
                        <Form.Label className="mr-2 font-weight-bold">End date</Form.Label>
                        {getDatePicker("enddate", enddateText)}
                    </span>
                    <Button className="mr-4" onClick={submitForm}>Submit</Button>
                </Col>
            </Row>
            <Tabs defaultActiveKey="online" id="tabs-product" className="mb-3">
                <Tab eventKey="online" title="Thairath Online">
                    <Row className="mb-4">
                        <Col>
                            {MemberTraffic(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                        {SpecialContent("nonmember", startdate, enddate, arrNothing, arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                        {TopContent(startdate, enddate, arrNothing, arrNothing)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {TopMember(startdate, enddate, "online")}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="plus" title="Thairath Plus">
                    <Row className="mb-4">
                        <Col>
                        {MemberTrafficTrPlus(arrSectionTopic, startdate, enddate, "Member's Top Section/Topic", arrNothing)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                        {TopTrPlus(startdate, enddate, arrNothing, arrNothing)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {TopMember(startdate, enddate, "plus")}
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
export default Dashboard2