import React, { useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios"
import ReactApexChart from "react-apexcharts"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import "./MemberStat.css"

const MemberStat = ({ startdate, enddate }) => {
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState(null)

	const fetchByType = async () => {
		setIsLoading(true)
		const res = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/dashboard/member-stat`, {
			withCredentials: true,
			params: {
				startdate,
				enddate,
			},
		})
		setData(res.data.data)
		setIsLoading(false)
	}

	const series = useMemo(() => {
		if (_isEmpty(data)) {
			return {
				total: [],
				new: [],
				active: [],
			}
		}
		return {
			total: [
				{
					name: "Total Member",
					type: "area",
					data: data.total.map((d) => d.total),
					summary: data.total[data.total.length - 1]?.total,
				},
			],
			new: [
				{
					name: "New Member",
					type: "column",
					data: data.new.map((d) => d.total),
					summary: data.new.reduce((a, b) => a + b.total, 0),
				},
			],
			active: [
				{
					name: "Active Member",
					type: "column",
					data: data.active.map((d) => d.total),
					summary: data.active.reduce((a, b) => a + b.total, 0),
				},
			],
		}
	}, [data])

	const getOptions = (type, { color, formatter }) => {
		return {
			chart: {
				group: "member-stat",
			},
			title: {
				text: _get(series, [type, 0, "name"], ""),
			},
			stroke: {
				width: 3,
				curve: "smooth",
			},
			labels: _get(data, [type], []).map((d) => d.date),
			xaxis: {
				type: "datetime",
			},
			colors: [color],
			yaxis: {
				forceNiceScale: true,
			},
			tooltip: {
				y: {
					formatter: formatter
						? (val, opts) => {
								const prevVal = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex - 1]
								const diff = val - prevVal
								let text = ""
								let color = "#777"
								if (diff > 0) {
									color = "green"
									text = `(+${diff})`
								}
								if (diff < 0) {
									color = "red"
									text = `(${diff})`
								}
								return `<span>${val} <span style="color: ${color}">${text}</span></span>`
						  }
						: undefined,
				},
			},
			noData: {
				text: isLoading ? "Loading..." : "No Data present in the graph!",
				align: "center",
				verticalAlign: "middle",
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#777",
					fontSize: "14px",
				},
			},
			series: series[type],
		}
	}

	const options = useMemo(() => {
		return {
			total: getOptions("total", {
				color: "#008FFB",
				formatter: true,
			}),
			new: getOptions("new", {
				color: "#00E396",
			}),
			active: getOptions("active", {
				color: "#FEB019",
			}),
		}
	}, [series, isLoading])

	useEffect(() => {
		fetchByType()
		return () => {}
	}, [startdate, enddate])

	return (
		<>
			<div className="card-member-stat">
				{["total", "new", "active"].map((type) => {
					const item = _get(series, `${type}.0`, {})
					return (
						<div className="card-member-stat--item" key={type}>
							<ReactApexChart options={options[type]} series={series[type]} height={240} />
							<div className="card-member-stat--summary">
								Total <span>{item?.summary?.toLocaleString()}</span>
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}

export default MemberStat
