import React, { useEffect, useState } from "react"
import { Button, Form, Col } from "react-bootstrap"
import { useFormik } from "formik"
import User from "../../libs/admin"


const Login = () => {
	
	//const [isLoading, setLoading] = useState(false)
	const [loginResult, setLoginResult] = useState(null)
	const [locked, setLocked] = useState(false)
	
	useEffect(() => {
		User.saveRedirect()
		User.deleteCookie()
		User.deleteOnPageG2FA()
	}, [])

	useEffect(() => {
		  const count = setInterval(() => {
			 	setLocked(false)
				User.deleteLocked()
				setLoginResult('')
			 }, 300000);
  
			 return () => clearInterval(count);
		 
	  }, [locked])

	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
			remember: false,
		},
		onSubmit: async (values) => {
				if(!locked){
					try {
						const response = await User.login(values.username, values.password, values.remember)
						const { status, data } = response
						const hasVerify = await User.getCookieG2FA()
						if(!status && data){
							setLoginResult('ล็อคอินผิดเกินกำหนดกรุณารอ 5 นาที')
							setLocked(true)
						}
						
						if (status) {
							// window.location.reload()
							if(hasVerify){
								window.location.reload()
							}else{
								window.location.href = '/#/g2fa'
							}
							
						} else {
							setLoginResult(User._errorMsg)
						}
					} catch (e) {
						setLoginResult(User._errorMsg)
						console.log(`request failed: ${e}`)
					}
				}
				
		},
	})
	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-10 col-lg-12 col-md-9">
					<div className="card o-hidden border-0 shadow-lg mt-10">
						<div className="card-body p-0">
							<div className="row">
								<div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
								<div className="col-lg-6">
									<div className="p-5">
										<div className="text-center">
											<h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
										</div>

										<Form onSubmit={formik.handleSubmit}>
											<p className="text-center">
												<label className="text-danger">{loginResult}</label>
											</p>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Control
														placeholder="username"
														id="username"
														name="username"
														onChange={formik.handleChange}
														value={formik.values.username}
														className="form-control form-control-user"
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Control
														type="password"
														placeholder="password"
														id="password"
														name="password"
														onChange={formik.handleChange}
														value={formik.values.password}
														className="form-control form-control-user"
													/>
												</Form.Group>
											</Form.Row>
											<Button variant="primary" type="submit" className="btn btn-primary btn-user btn-block">
												Login
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Login
