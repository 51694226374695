import _axios from "axios"
const axios = _axios.create({
	baseURL: process.env.REACT_APP_API_ROOT,
	withCredentials: true,
	headers: {
		"Content-Type": "multipart/form-data",
	},
})
const useUpload = () => {
	return {
		onUpload: (callback) => async ({ name, value, oldValue = [] }) => {
			if (Array.isArray(value)) {
				const uploaded = []
				for (const file of value) {
					const formData = new FormData()
					formData.append("File", file, file.name)
					const response = await axios.post(`/upload`, formData, {
						params: {
							type: "campaign_new",
							upload: "campaign",
						},
					})
					if (response.status === 200) uploaded.push(response.data.response)
				}
				callback({ name, value: oldValue.concat(uploaded) })
				return
			} else {
				const formData = new FormData()
				formData.append("File", value, value.name)
				const response = await axios.post(`/upload`, formData, {
					params: {
						type: "campaign_new",
						upload: "campaign",
					},
				})
				if (response.status === 200) {
					callback({ name, value: response.data.response })
				}
				return
			}
		},
	}
}

export default useUpload
