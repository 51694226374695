import moment from "moment"
import React, { useEffect, useState } from "react"

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom"
import { campaignController } from "./Services"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"

const ListCampaign = (props) => {
	const user = User.getCookieData()
	const [campaign, setCampaign] = useState()

	useEffect(() => {
		const checked = checkPermission(user.permission, "campaign_view")

		if (checked) {
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const fetchData = async () => {
		const response = await campaignController().getAllCampaign()
		if (response && response.status === 200) {
			const {
				data: { data },
			} = response

			setCampaign(data)
		}
	}

	const formatTime = (cell, row, enumObject, index) => {
		return moment(cell).format("DD/MM/YYYY H:mm")
	}

	const formatView = (cell, row, enumObject, index) => {
		return <Link to={`edit/${cell}`}>{cell}</Link>
	}

	const formatIndex = (cell, row, enumObject, index) => {
		return <p>{index + 1}</p>
	}

	const formatLink = (cell, row, enumObject, index) => {
		return (
			<a href={cell} target="popup">
				{cell}
			</a>
		)
	}

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายการแคมเปญ</h6>
				</div>
				<div className="card-body row">
					<div className="col-12">
						<Link to="/campaign/form" className="custom-rigth btn btn-success btn-sm">
							<span className="text">เพิ่มแคมเปญ</span>
						</Link>
						<BootstrapTable data={campaign} className="table table-striped" exportCSV>
							<TableHeaderColumn dataField="_id" width="50" dataAlign="center" dataFormat={formatIndex} isKey>
								ลำดับ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="_id" width="200" dataFormat={formatView}>
								Campaign_id
							</TableHeaderColumn>
							<TableHeaderColumn dataField="name" width="200">
								ชื่อแคมเปญ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="type" width="120">
								ประเภทแคมเปญ
							</TableHeaderColumn>
							<TableHeaderColumn dataField="joinScore" width="120">
								จำนวนสิทธิการเล่น
							</TableHeaderColumn>
							<TableHeaderColumn dataField="questionNum" width="120">
								จำนวนคำถาม
							</TableHeaderColumn>
							<TableHeaderColumn dataField="total_players" width="120">
								จำนวนผู้เล่น
							</TableHeaderColumn>
							<TableHeaderColumn dataField="link" width="350" dataFormat={formatLink}>
								ลิ้งก์
							</TableHeaderColumn>
							<TableHeaderColumn dataField="startDate" width="150" dataFormat={formatTime}>
								วันที่เริ่ม
							</TableHeaderColumn>
							<TableHeaderColumn dataField="endDate" width="150" dataFormat={formatTime}>
								วันที่สิ้นสุด
							</TableHeaderColumn>
						</BootstrapTable>
					</div>
				</div>
			</div>
		</>
	)
}

export default ListCampaign
