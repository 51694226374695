import * as Yup from "yup"
import { showDetail } from "../../../helper/const"

const camapignSetupSchema = Yup.object().shape({
	name: Yup.string().required("กรุณากรอกข้อมูล"),
	desc_meta_tag: Yup.string().required("กรุณากรอกข้อมูล"),
	description: Yup.string().required("กรุณากรอกข้อมูล"),
	rules: Yup.array().of(
		Yup.object().shape({
			text: Yup.string().required("กรุณากรอกข้อมูล"),
		})
	),
	detail: Yup.string().required("กรุณากรอกข้อมูล"),
	bannerImage: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
	backgroundImage: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
	shareImage: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
	prizeImage: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
	// noteAward: Yup.string().required("กรุณากรอกข้อมูล"),
	position: Yup.string().required("กรุณาเลือก"),
	slagName: Yup.string().required("กรุณากรอกข้อมูล"),
	premiumType: Yup.string().default(""),
})

const camapignSetupUnderArticleSchema = Yup.object().shape({
	name: Yup.string().required("กรุณากรอกข้อมูล"),
	position: Yup.string().required("กรุณาเลือก"),
	// displayPosition: Yup.string().required("กรุณาเลือก"),
	slagName: Yup.string().required("กรุณากรอกข้อมูล"),
	premiumType: Yup.string().default(""),
})

const camapignDetailSchema = Yup.array().of(
	Yup.object().shape({
		questionName: Yup.string().required("กรุณากรอกข้อมูล"),
		show: Yup.number(),
		clip: Yup.string().when("show", {
			is: showDetail.find((f) => f.label === "video")?.value,
			then: Yup.string().required("กรุณากรอกข้อมูล"),
		}),
		questionImage: Yup.object().when("show", {
			is: showDetail.find((f) => f.label === "image")?.value,
			then: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
		}),
		choices: Yup.array().of(
			Yup.object().shape({
				choiceName: Yup.string().required("กรุณากรอกข้อมูล"),
				choiceImage: Yup.object().nullable().required("กรุณาอัพโหลดข้อมูล"),
			})
		),
	})
)

const camapignAwardSchema = Yup.object().shape({
	adsAward: Yup.string().required("กรุณากรอกข้อมูล"),
	noteAward: Yup.string().required("กรุณากรอกข้อมูล"),
	award: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().required("กรุณากรอกข้อมูล"),
			imgSrc: Yup.object().nullable().required("กรุณากรอกข้อมูล"),
		})
	),
})

const camapignAnnounceSchema = Yup.object().shape({})

export const validateForm = async (data, schemas) => {
	try {
		// console.log(`LOG: schemas ---> `, schemas)
		if (schemas === "camp-setup") {
			if (String(data.position) === "0") {
				// ใต้บทความ
				await camapignSetupUnderArticleSchema.validate(data, { abortEarly: false })
			} else {
				await camapignSetupSchema.validate(data, { abortEarly: false })
			}
		}

		if (schemas === "camp-detail") {
			await camapignDetailSchema.validate(data, { abortEarly: false })
		}

		if (schemas === "camp-award") {
			await camapignAwardSchema.validate(data, { abortEarly: false })
		}

		if (schemas === "camp-announce") {
			await camapignAnnounceSchema.validate(data, { abortEarly: false })
		}

		return false
	} catch (e) {
		const err = {}
		e.inner.forEach((obj) => {
			err[obj.path] = obj.message
		})
		console.log(`LOG: data ---> `, data)
		console.log(`LOG:  ---> `, err)
		return err
	}
}
