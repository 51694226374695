import Axios from "axios"

export default {
	/**
	 * axios wrappers
	 */
	_errorMsg: "",
	_post: async function (path, body) {
		return this._axios(path, "post", body)
	},
	_put: async function (path, body) {
		return this._axios(path, "put", body)
	},
	_get: async function (path, params) {
		return this._axios(path, "get", {}, params)
	},
	_delete: async function (path, body) {
		return this._axios(path, "delete", body)
	},
	_axios: async function (path, method, body, params) {
		const options = {
			method,
			data: body,
			url: `${process.env.REACT_APP_API_ROOT}/${path}`,
			withCredentials: true,
			params,
		}
		const { data } = await Axios(options)
		if (data.status === 200) {
			return data
		} else {
			console.log(`User lib error ${path}: ${data.message}`)
			this._errorMsg = data.message
			return data
		}
	},
	/**
	 * API functions
	 */
	getComments: async function (params) {
		const data = await this._get("comment/cms/", params)
		return data
	},
	getPathContentUrls: async function () {
		const data = await this._get(`comment/cms/path-content`)
		return data
	},
	getReportByCommentId: async function (commentId) {
		const data = await this._get("comment/cms/report/commentId/" + commentId)
		return data
	},
	deleteComment: async function (body) {
		const data = await this._delete("comment/cms/", body)
		return data
	},
	verifyReport: async function (body) {
		const data = await this._put("comment/cms/report", body)
		return data
	},
	deleteReport: async function (body) {
		const data = await this._delete("comment/cms/report", body)
		return data
	},
}
