import React, { useEffect } from "react"
import { NavigateStyled } from "../../styled"
import useNewsVote, { NewsVoteContextProvider } from "./context"
import { checkPermission } from "../../../../helper/functions"
import User from "../../../../libs/admin"
const NewsVoteComp = () => {
	const { handleChangeStep, stepNow, steps, data } = useNewsVote()
	console.log('steps => ', steps)
	if (!steps || !data) return <>Loading...</>
	return (
		<div
			style={{
				backgroundColor: "#fff",
				padding: "2rem",
			}}
		>
			<NavigateStyled className="mb-4">
				<ul>
					{steps &&
						steps.map((item, index) => (
							<li key={item.key} className={stepNow >= index ? "active" : ""}>
								<a onClick={() => handleChangeStep(index)}>
									<strong>{index + 1}</strong>
									<span>{item.label || "New tab"}</span>
								</a>
							</li>
						))}
				</ul>
			</NavigateStyled>
			<div>{steps[stepNow] && steps[stepNow].Component}</div>
		</div>
	)
}

const NewsVote = (props) => {
	const user = User.getCookieData()
	useEffect(() => {
		if (!checkPermission(user.permission, "news_vote_edit")) {
			props.history.push('/')

		}
		return () => { }
	}, [])
	return (
	<NewsVoteContextProvider>
		<NewsVoteComp />
	</NewsVoteContextProvider>
	)
}

export default NewsVote
