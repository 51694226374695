import _set from "lodash/set"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import Question from "../Question"
import Setting from "../Setting"
import { v4 as uuidv4 } from "uuid"
import Submit from "../Submit"
import { editVoteInArticle, getVoteInArticleById, saveVoteInArticle } from "../service"
import { useHistory, useParams } from "react-router-dom"
import Report from "../Report"
export const VoteInArticleContext = React.createContext({})
const useVoteInArticle = () => React.useContext(VoteInArticleContext)
export const VoteInArticleContextProvider = ({ children }) => {
	const params = useParams()
	const history = useHistory()
	const [stepNow, setStepNow] = useState(0)
	const [data, setData] = useState()
	const [steps, setSteps] = useState([
		{
			key: uuidv4(),
			label: "ตั้งค่า campaign",
			Component: <Setting />,
		},
		{
			key: uuidv4(),
			label: "ชุดคำถาม",
			Component: <Question />,
		},
		{
			key: uuidv4(),
			label: "ยืนยัน",
			Component: <Submit />,
		},
		{
			key: uuidv4(),
			label: "รายงาน",
			Component: <Report />,
		},
	])
	const handleChangeStep = (step) => {
		setStepNow(step)
	}
	const fetch = async () => {
		if (params.id) {
			const response = await getVoteInArticleById(params.id)
			if (response.status === 200 && response.data.status === 200) {
				setData(response.data.data)
			}
		} else {
			setData(getDefaultData())
		}
	}
	useEffect(() => {
		fetch()
	}, [])

	const handleChange = (payload) => {
		if (Array.isArray(payload)) {
			let temp = { ...data }
			for (const { name, value } of payload) {
				temp = _set(temp, name, value)
				console.log(`LOG: ${name} `, _get(temp, name))
			}
			setData(temp)
		} else {
			const { name, value } = payload
			const temp = _set({ ...data }, name, value)
			console.log(`LOG: ${name} `, _get(temp, name))
			setData(temp)
		}
	}

	const handleSave = async () => {
		if (params.id) {
			const response = await editVoteInArticle(params.id, data)
			if (response.status === 200 && response.data.status === 200) {
				history.push("/campaign-new/vote-in-article")
			} else {
				console.log(`LOG: edit data ---> `, data)
				console.log(`LOG: edit err ---> `, response.data)
			}
		} else {
			const response = await saveVoteInArticle(data)
			if (response.status === 200 && response.data.status === 200) {
				history.push("/campaign-new/vote-in-article")
			} else {
				console.log(`LOG: save data ---> `, data)
				console.log(`LOG: save err ---> `, response.data)
			}
		}
	}
	const store = {
		stepNow,
		steps,
		data,
		handleChange,
		handleSave,
		handleChangeStep,
	}
	return <VoteInArticleContext.Provider value={store}>{children}</VoteInArticleContext.Provider>
}

const getDefaultData = () => {
	// media schema
	// {
	// 	name: "",
	// 	url: "",
	// }

	const defaultData = {
		setting: {
			name: "",
			startDate: "",
			endDate: "",
			allows: [],
			shareImg: {},
		},
		questions: [
			{
				key: uuidv4(),
				title: "",
				media: {
					type: "image",
					name: "",
					url: "",
				},
				choices: [
					{
						key: uuidv4(),
						title: "",
						media: {
							type: "image",
							name: "",
							url: "",
						},
					},
				],
			},
		],
		isActive: true,
	}
	return defaultData
}

export default useVoteInArticle
