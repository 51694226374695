import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Form, Tabs, Tab, Button } from "react-bootstrap"
/** Datepicker use */
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import ActivatedMember from "./dashboard/ActivatedMember"
import ChartBox from "./dashboard/ChartBox"
import HitLimit from "./dashboard/HitLimit"
import TopFollow from "./dashboard/TopFollow"
import { checkPermission } from "../helper/functions"
import User from "../libs/admin"
import MemberStat from "./dashboard/MemberStat"

const Dashboard = (props) => {
	const [startdate, setStartdate] = useState(moment().subtract(6, "days").format("YYYY-MM-DD"))
	const [enddate, setEnddate] = useState(moment().format("YYYY-MM-DD"))
	const [startdateText, setStartdateText] = useState(moment().subtract(6, "days").format("YYYY-MM-DD"))
	const [enddateText, setEnddateText] = useState(moment().format("YYYY-MM-DD"))

	const [isButtonSubmitted, setIsButtonSubmitted] = useState(false)
	const [flag1, setFlag1] = useState(0)
	const [flag2, setFlag2] = useState(0)

	const [memberTotal, setMemberTotal] = useState({})
	const [memberActive, setMemberActive] = useState({})
	const [memberNew, setMemberNew] = useState({})

	async function fetchData() {
		const mainurl = `${process.env.REACT_APP_API_ROOT}/admin/dashboard/`
		const mainparameter = `startdate=` + startdate + `&enddate=` + enddate

		let reqObjMemberTotal = await axios.get(mainurl + `memberTotal?` + mainparameter + `&v=` + Math.random() * 1000, { withCredentials: true })
		setMemberTotal(reqObjMemberTotal.data.data)
		let reqObjMemberActive = await axios.get(mainurl + `memberActive?` + mainparameter + `&v=` + Math.random() * 1000, { withCredentials: true })
		setMemberActive(reqObjMemberActive.data.data)
		let reqObjMemberNew = await axios.get(mainurl + `memberNew?` + mainparameter + `&v=` + Math.random() * 1000, { withCredentials: true })
		setMemberNew(reqObjMemberNew.data.data)

		console.log("fetch data complete")
	}

	const runData = () => {
		fetchData()
			.then(() => {
				setIsButtonSubmitted(false)
				setFlag1(1)
				setFlag2(1)
			})
			.then(() => {
				setFlag1(0)
				setFlag2(0)
			})
	}

	useEffect(() => {
		const user = User.getCookieData()
		const checked = checkPermission(user.permission, "dashboard_view")

		if (checked) {
			// runData()
		} else {
			props.history.push("/")
		}
	}, [])

	// useEffect(() => {
	// 	if (isButtonSubmitted) {
	// 		runData()
	// 	}
	// }, [isButtonSubmitted])

	const getDatePicker = (name, defaultvalue) => {
		return (
			<DateTime
				dateFormat="YYYY-MM-DD"
				timeFormat={false}
				defaultValue={defaultvalue}
				onChange={(date) => {
					date = moment(date).format("YYYY-MM-DD")
					name === "startdate" && setStartdateText(date)
					name === "enddate" && setEnddateText(date)
					console.log(name + " " + date)
				}}
			/>
		)
	}

	const getMetricBox = (header, value, change) => {
		return (
			<Col xs sm={4}>
				<div className="rounded bg-white" style={{ border: "0.25rem solid", margin: "0.25rem", padding: "0.5rem" }}>
					<h4 className="font-weight-bold">{header}</h4>
					<h5 className="text-center">{value ? value.toLocaleString() : ""}</h5>
					<div className="text-center">{change}%</div>
				</div>
			</Col>
		)
	}

	const downloadMonthlyStat = async () => {
		let { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/dashboard/downloadMonthlyStat`, { withCredentials: true })
		let result = data.data

		/** Prepare data to export */
		let arrHeader = ["Month and Year", "Total Members", "Active Members", "New Members", "Active Members / Day", "New Members / Day"]
		let csvToExport = JSON.parse(JSON.stringify(arrHeader)).join(",") // Deep copy for arrHeader, otherwise shallow copy which is call by reference.
		let keys = result.total.map((obj) => obj._id)
		keys.forEach((monthandyear, i) => {
			// Create records
			let activemembers = result.active[i].count
			let newmembers = result.new[i].count

			/** Calculate member count per days */
			let objDate = new Date(monthandyear)
			objDate.setMonth(objDate.getMonth() + 1) // Increment month because we want days count by setDate(0)
			objDate.setDate(0) // If we set date to 0, we will get days count after setting month + 1
			let daycount = objDate.getDate()
			/** Only the last records will count days until now */
			if (i + 1 == keys.length) {
				daycount = new Date().getDate()
			}

			let activeperday = activemembers / daycount > 1 ? parseInt(activemembers / daycount) : activemembers
			let newperday = newmembers / daycount > 1 ? parseInt(newmembers / daycount) : newmembers

			csvToExport = csvToExport + "\r\n" + [monthandyear, result.total[i].count, activemembers, newmembers, activeperday, newperday].join(",")
		})

		/** Export process */
		let blob = new Blob([csvToExport], { type: "text/csv" })
		let link = document.createElement("a")
		link.href = window.URL.createObjectURL(blob)
		link.download = "memberstat.csv"
		link.click()
	}

	const submitForm = () => {
		if (moment(enddateText).diff(moment(startdateText), "months") > 1 || moment(enddateText).diff(moment(startdateText), "days") > 31) {
			alert("Maximum date range is 1 month")
			return
		}
		setStartdate(startdateText)
		setEnddate(enddateText)
		setIsButtonSubmitted(true)
	}

	return (
		<Container>
			<Row className="mb-4">
				<Col className="text-right">
					{/* <Button className="mr-4" onClick={downloadMonthlyStat}>
						Download Monthly Stat
					</Button> */}
					<span className="mr-4">
						<Form.Label className="mr-2 font-weight-bold">Start date</Form.Label>
						{getDatePicker("startdate", startdateText)}
					</span>
					<span>
						<Form.Label className="mr-2 font-weight-bold">End date</Form.Label>
						{getDatePicker("enddate", enddateText)}
					</span>
					<Button className="mr-4" onClick={submitForm}>
						Submit
					</Button>
				</Col>
			</Row>
			<Row className="mb-4">
				{/* {getMetricBox("Total Members", memberTotal.total, memberTotal.percentchange)}
				{getMetricBox("Active Members", memberActive.total, memberActive.percentchange)}
				{getMetricBox("New Members", memberNew.total, memberNew.percentchange)} */}
				<MemberStat startdate={startdate} enddate={enddate} />
			</Row>
			{/* <Row className="mb-4">
				<Col xs sm={3}>
					{ChartBox("bar", "gender", "Gender", startdate, enddate, 300, 300, flag1)}
				</Col>
				<Col xs sm={3}>
					{ChartBox("bar", "age", "Age range", startdate, enddate, 300, 300, flag1)}
				</Col>
				<Col xs sm={3}>
					{ActivatedMember(startdate, enddate, flag1)}
				</Col>
				<Col xs sm={3}>
					{ChartBox("bar", "loginChannels", "Login channels", startdate, enddate, 300, 300, flag1)}
				</Col>
			</Row>
			<Row className="mb-4">
				<Col xs sm={6}>
					<h4 className="font-weight-bold">Top activities</h4>
					<Tabs defaultActiveKey="activity" id="tabs-member-activity">
						<Tab eventKey="activity" title="Activity Count">
							{ChartBox("bar", "memberActivity", "", startdate, enddate, 500, 300, flag1)}
						</Tab>
						<Tab eventKey="member" title="Member Count">
							{ChartBox("bar", "memberActivityMemberCount", "", startdate, enddate, 500, 300, flag1)}
						</Tab>
					</Tabs>
				</Col>
				<Col xs sm={6}>
					{ChartBox("bar", "memberInterest", "Top interest", startdate, enddate, 500, 300, flag1)}
				</Col>
			</Row>
			<Row className="mb-4">
				<Col>{TopFollow(startdate, enddate, flag1)}</Col>
			</Row>
			<Row className="mb-4">
				<Col>{HitLimit(startdate, enddate, flag2)}</Col>
			</Row> */}
		</Container>
	)
}
export default Dashboard
