import React from "react"
import { Button, Header, Image, Modal } from "semantic-ui-react"
import "semantic-ui-css/semantic.min.css"
import { Card } from "react-bootstrap"
import moment from "moment"
export default function BlockUserModal({ data, onBlock, disabled, view }) {
	const [open, setOpen] = React.useState(false)
	const handleSubmit = (status) => {
		onBlock(status)
		setOpen(false)
	}
	return (
		<>
			<Modal
				onClose={() => {
					setOpen(false)
				}}
				onOpen={() => setOpen(true)}
				open={open}
				trigger={
					view && <Card.Link
						href="#"
						onClick={(e) => {
							e.preventDefault()
						}}
						className={ disabled ? 'text-link-disabled' : ''}
					>
						Block User
					</Card.Link>
				}
				style={{
					height: "auto",
					top: "auto",
					left: "auto",
				}}
			>
				<Modal.Header>Block User</Modal.Header>
				<Modal.Content image>
					<Image size="small" src={`${process.env.REACT_APP_API_ROOT}/members/profile/${data.user.id}`} wrapped />
					<Modal.Description>
						<Header>{data.user.name}</Header>
						<p>{data.text}</p>
						<p>
							<strong>{moment(data.createDate).format("DD/MM/YYYY HH:mm:ss")}</strong> ( edit: {moment(data.lastUpdate).format("DD/MM/YYYY HH:mm:ss")} )
						</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button content="Cancel" labelPosition="left" icon="cancel" onClick={() => setOpen(false)} />
					{!!data.user.isBlock ? (
						<Button content="Unblock" labelPosition="left" icon="lock open" onClick={() => handleSubmit(false)} color="blue" />
					) : (
						<Button content="Block" labelPosition="left" icon="lock" onClick={() => handleSubmit(true)} color="orange" />
					)}
				</Modal.Actions>
			</Modal>
		</>
	)
}
