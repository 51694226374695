import _set from "lodash/set"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import Prize from "../Prize"
import Question from "../Question"
import Rule from "../Rule"
import Setting from "../Setting"
import TabNew from "../TabNew"
import { v4 as uuidv4 } from "uuid"
import Submit from "../Submit"
import { editNewsVote, getNewsVoteById, saveNewsVote } from "../service"
import { useHistory, useParams } from "react-router-dom"
import Report from "../Report"
import PrizeAnnounce from "../PrizeAnnounce"
export const NewsVoteContext = React.createContext({})
const useNewsVote = () => React.useContext(NewsVoteContext)
export const NewsVoteContextProvider = ({ children }) => {
	const params = useParams()
	const history = useHistory()
	const [stepNow, setStepNow] = useState(0)
	const [data, setData] = useState()
	const [steps, setSteps] = useState()
	const [leftTabLen, setLeftTabLen] = useState()
	const [loading, setLoading] = useState(true)
	const handleChangeStep = (step) => {
		setStepNow(step)
	}
	const fetch = async () => {
		if (params.id) {
			const response = await getNewsVoteById(params.id)
			if (response.status === 200 && response.data.status === 200) {
				setData(response.data.data)
			}
			setLoading(false)
		} else {
			setData(getDefaultData())
		}
	}
	useEffect(() => {
		fetch()
	}, [])
	// useEffect rerender tab
	useEffect(() => {
		if (!data) return
		const leftTabs = [
			{
				key: uuidv4(),
				label: "ตั้งค่า campaign",
				Component: <Setting />,
			},
			{
				key: uuidv4(),
				label: "ชุดคำถาม",
				Component: <Question />,
			},
			{
				key: uuidv4(),
				label: data.prize.tabName,
				Component: <Prize />,
			},
			{
				key: uuidv4(),
				label: data.rule.tabName,
				Component: <Rule />,
			},
		]
		const customTabs =
			data?.tabs?.map((tab, index) => ({
				label: tab.tabName,
				Component: <TabNew key={tab.key} index={index} />,
			})) || []
		const rightTabs = [
			{
				key: uuidv4(),
				label: "ยืนยัน",
				Component: <Submit />,
			},
			{
				key: uuidv4(),
				label: "รายงาน",
				Component: <Report />,
			},
			{
				key: uuidv4(),
				label: "ประกาศรางวัล",
				Component: <PrizeAnnounce />,
			},
		]
		setLeftTabLen(leftTabs.length)
		setSteps([...leftTabs, ...customTabs, ...rightTabs])
		return () => {}
	}, [data?.prize?.tabName, data?.rule?.tabName, JSON.stringify(data?.tabs)])

	const handleNewTab = () => {
		const newTab = {
			key: uuidv4(),
			template: "default",
			tabName: "",
			sections: [
				{
					key: uuidv4(),
					title: "",
					description: "",
				},
			],
		}
		const insertIndex = stepNow + 1 - leftTabLen
		setData((prev) => {
			const newData = { ...prev }
			newData.tabs = [...prev.tabs.slice(0, insertIndex), newTab, ...prev.tabs.slice(insertIndex)]
			return newData
		})
		// handleChangeStep(stepNow + 1)
	}
	const handleChange = (payload) => {
		if (Array.isArray(payload)) {
			let temp = { ...data }
			for (const { name, value } of payload) {
				temp = _set(temp, name, value)
				console.log(`LOG: ${name} `, _get(temp, name))
			}
			setData(temp)
		} else {
			const { name, value } = payload
			const temp = _set({ ...data }, name, value)
			console.log(`LOG: ${name} `, _get(temp, name))
			setData(temp)
		}
	}

	const handleSave = async () => {
		if (params.id) {
			const response = await editNewsVote(params.id, data)
			if (response.status === 200 && response.data.status === 200) {
				history.push("/campaign-new/news-vote")
			} else {
				console.log(`LOG: edit data ---> `, data)
				console.log(`LOG: edit err ---> `, response.data)
			}
		} else {
			const response = await saveNewsVote(data)
			if (response.status === 200 && response.data.status === 200) {
				history.push("/campaign-new/news-vote")
			} else {
				console.log(`LOG: save data ---> `, data)
				console.log(`LOG: save err ---> `, response.data)
			}
		}
	}
	const store = {
		stepNow,
		steps,
		data,
		handleChange,
		handleSave,
		handleChangeStep,
		handleNewTab,
	}

	return <NewsVoteContext.Provider value={store}>{children}</NewsVoteContext.Provider>
}

const getDefaultData = () => {
	// media schema
	// {
	// 	name: "",
	// 	url: "",
	// }

	const defaultData = {
		setting: {
			name: "",
			url: "",
			slugName: "",
			description: "",
			startDate: "",
			endDate: "",
			prizeAnnounceDate: "",
			allows: [],
			termCondition: {
				has: true,
				description: "",
			},
			recommendWidgetId: "",
			bannerImg: {},
			shareImg: {},
			bgImg: {},
			logoImgs: [],
			logo2Imgs: [],
		},
		questions: [
			{
				key: uuidv4(),
				title: "",
				media: {
					type: "image",
					name: "",
					url: "",
				},
				choices: [
					{
						key: uuidv4(),
						title: "",
						media: {
							type: "image",
							name: "",
							url: "",
						},
					},
				],
			},
		],
		prize: {
			tabName: "ของรางวัล",
			title: "",
			summary: "",
			description: "",
			img: {},
			isActive: true,
			items: [
				{
					key: uuidv4(),
					title: "",
					img: {},
					amount: 0,
					members: [],
				},
			],
		},
		rule: {
			tabName: "กติกา",
			title: "",
			description: "",
			sections: [
				{
					key: uuidv4(),
					title: "",
					description: "",
				},
			],
		},
		tabs: [
			// {
			// key: uuidv4(),
			// 	template: "",
			// 	tabName: "ทดสอบ",
			// 	sections: [],
			// },
		],
		isActive: true,
	}
	return defaultData
}

export default useNewsVote
