import React, { Fragment, useContext, useState } from "react"
import { StoreContext } from "../../Context/store"
import { Navigation } from "../../../../utils"
import { type, positon, publish } from "../../../../helper/const"
import { Input, Textarea, SelectInput, SelectScore, UploadInput, InputSlagName, DatePeriod, Radio } from "../../Widgets"
import moment from "moment"
import { campaignController } from "../../Services"
import { v4 as uuidv4 } from "uuid"
import Editor from "../../Widgets/Editor"

const QuizSetup = ({ pageStep = 1, onChangeStep, pageId, error }) => {
	const {
		campSetup: [campSetup, setCampSetup],
		rendereStep: rendereStep,
		disabled: [disabled],
		ruleDelete: [ruleDel, setRuleDel],
	} = useContext(StoreContext)
	const [bgImg, setBgImg] = useState({})
	const [bannerImg, setBannerImg] = useState({})
	const [sponserImg, setSponserImg] = useState([])
	const [shareImg, setShareImg] = useState({})
	const [prizeImg, setPrizeImg] = useState({})

	const onChangeData = (event) => {
		const { name, value, id } = event.target
		switch (name) {
			case "campDescMeta":
				setCampSetup({
					...campSetup,
					desc_meta_tag: value,
				})
				break
			case "campTimeout":
				const valueTimeout = value.replace(/[^0-9]/g, "")
				setCampSetup({
					...campSetup,
					timeout: valueTimeout,
				})
				break
			case "campPublish":
				setCampSetup({
					...campSetup,
					publish: parseInt(value),
				})
				break
			case "campType":
				setCampSetup({
					...campSetup,
					type: value,
				})
				break

			case "campTaskRule":
				setCampSetup({
					...campSetup,
					taskRule: value,
				})
				break

			case "campJoinScore":
				setCampSetup({
					...campSetup,
					joinScore: parseInt(value),
				})
				break

			case "campAnswerCorrect":
				setCampSetup({
					...campSetup,
					answerCorrect: parseInt(value),
				})
				break

			case "campName":
				setCampSetup({
					...campSetup,
					name: value,
				})
				break

			case "campDescription":
				setCampSetup({
					...campSetup,
					description: value,
				})
				break

			case "campDetail":
				const arr = campSetup.rules.map((list) => {
					return list._id === id
						? {
								...list,
								text: value,
						  }
						: {
								...list,
						  }
				})
				setCampSetup({
					...campSetup,
					rules: arr,
				})
				break
			case "campPosition":
				setCampSetup({
					...campSetup,
					position: value,
				})
				break
			case "campLink":
				setCampSetup({
					...campSetup,
					slagName: value,
					link: parseInt(campSetup.position) === 1 ? `${process.env.REACT_APP_LINK_CAMPAIGN}/${value}` : `${process.env.REACT_APP_CONTENT_PAGE}/${value}`,
				})
				break
			case "require_member_name":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						name: value === "true" ? true : false,
					},
				})
				break
			case "require_member_phone":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						phone: value === "true" ? true : false,
					},
				})
				break
			case "require_member_address":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						address: value === "true" ? true : false,
					},
				})
				break
			case "require_member_birthday":
				setCampSetup({
					...campSetup,
					require_member: {
						...campSetup.require_member,
						birthday: value === "true" ? true : false,
					},
				})
				break
		}
	}

	const onUpload = (event) => {
		const { files, name } = event.target
		if (files.length > 0) {
			let { type, size } = files[0]
			if (!["image/jpg", "image/jpeg", "image/png"].includes(type)) {
				const err = {
					message: "กรุุณาแนบไฟล์ jpg, jpeg และ png เท่านั้น",
				}
			} else {
				switch (name) {
					case "sponsorImage":
						setSponserImg(files[0])
						break
					case "bannerImage":
						setBannerImg(files[0])
						break
					case "backgroundImage":
						setBgImg(files[0])
						break
					case "shareImage":
						setShareImg(files[0])
						break
					case "prizeImage":
						setPrizeImg(files[0])
						break
					default:
						break
				}
			}
		}
	}

	const setUpload = async (type) => {
		let file = {}
		switch (type) {
			case "sponsorImage":
				file = sponserImg
				break
			case "bannerImage":
				file = bannerImg
				break
			case "backgroundImage":
				file = bgImg
				break
			case "shareImage":
				file = shareImg
				break
			case "prizeImage":
				file = prizeImg
				break
			default:
				break
		}

		const formData = new FormData()
		formData.append("File", file, file.name)
		const target_file = formData
		const response = await campaignController().uploadFile(target_file, type)

		if (response && response.status === 200) {
			let data = response.data.response
			data = {
				...data,
				name: file.name,
			}

			let arr = []

			switch (type) {
				case "sponsorImage":
					arr.push(data)
					setCampSetup({
						...campSetup,
						sponsorImage: [...campSetup.sponsorImage, ...arr],
					})
					setSponserImg("")
					break
				case "bannerImage":
					setCampSetup({
						...campSetup,
						[`${type}`]: data,
					})
					setBannerImg("")
					break
				case "backgroundImage":
					setCampSetup({
						...campSetup,
						[`${type}`]: data,
					})
					setBgImg("")
					break
				case "shareImage":
					setCampSetup({
						...campSetup,
						[`${type}`]: data,
					})
					setShareImg("")
					break
				case "prizeImage":
					setCampSetup({
						...campSetup,
						[`${type}`]: data,
					})
					setPrizeImg("")
					break
				default:
					break
			}
		}
	}

	const onAddRule = () => {
		let arr = []
		arr.push({ _id: uuidv4(), text: "" })
		setCampSetup({ ...campSetup, rules: [...campSetup.rules, ...arr] })
	}

	const onBlurData = (event, name, qid) => {
		switch (name) {
			case "startDate":
				setCampSetup({
					...campSetup,
					startDate: moment(event),
				})
				break

			case "endDate":
				setCampSetup({
					...campSetup,
					endDate: moment(event),
				})
				break
		}
	}

	const onDeleteRule = (id) => {
		const { rules } = campSetup
		console.log(rules)
		const arrDel = []
		const delRules = rules.filter((list) => {
			if (list._id !== id) {
				return list
			} else {
				arrDel.push(id)
			}
		})

		setRuleDel([...arrDel])
		setCampSetup({ ...campSetup, rules: [...delRules] })
	}

	const onDeleteImage = async (image_index) => {
		let tempCamp = { ...campSetup }
		let tempSponsorImageObj = tempCamp.sponsorImage[image_index]
		let tempSponsorImageArr = tempCamp.sponsorImage.filter((item) => item._id !== tempSponsorImageObj._id)
		setCampSetup({ ...tempCamp, sponsorImage: tempSponsorImageArr })
		if (tempSponsorImageObj?._id) {
			const res = await campaignController().deleteFile(tempSponsorImageObj._id)
			if (res.status !== 200) {
				console.log(`LOG: delete image error ---> `, res)
			}
		}
	}

	return (
		<Fragment>
			<br />
			<Navigation PageStep={pageStep} dataStep={rendereStep(pageId, campSetup.type)} onChangeStep={onChangeStep} />
			{/* data={campSetup} schema='camp-setup' */}
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h5 className="m-0 font-weight-bold text-primary">Campaign Setup</h5>
				</div>

				<div className="card-body">
					<SelectInput
						label="Campaign Type"
						id="type"
						name="campType"
						value={campSetup.type}
						data={type}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<Input
						label="Campaign Name"
						id="name"
						name="campName"
						value={campSetup.name}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<Textarea
						label={
							<>
								Description
								<br />
								(ไม่แสดงที่หน้าแคมเปญ
								<br />
								มีผลกับ Search engine)
							</>
						}
						require
						value={campSetup.desc_meta_tag}
						onChange={(text) => onChangeData({ target: { name: "campDescMeta", id: "desc_meta", value: text } })}
					/>
					<br />
					<Editor
						label={
							<>
								Term Condition
								<br />
								(แสดงครั้งแรกให้ผู้เล่นกดยอมรับ)
							</>
						}
						require
						defaultValue={campSetup.description}
						onChange={(text) => onChangeData({ target: { name: "campDescription", id: "desc", value: text } })}
					/>
					<br />
					{campSetup?.rules &&
						campSetup.rules.map((item, index) => (
							<div key={item._id} className="my-3">
								<Editor
									defaultValue={item.text}
									label={index === 0 ? "Rules" : ""}
									require
									action={() => <i className="close link icon red" onClick={() => onDeleteRule(item._id)} style={{ fontSize: "2rem" }} />}
									onChange={(text) => onChangeData({ target: { name: "campDetail", id: item._id, value: text } })}
								/>
							</div>
						))}
					<div className="row ">
						<div className="col-3"></div>
						<div className="col-6 text-center ">
							<button className="btn btn-success " onClick={onAddRule}>
								<i className="fas fa-plus mr-1" /> Add Rule
							</button>
						</div>
					</div>
					<br />
					<Input
						label="เวลาต่อข้อ (วินาที)"
						id="timeout"
						name="campTimeout"
						value={campSetup.timeout}
						data={Array(60)}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					<DatePeriod
						id="campTimeEvent"
						label="ระยะเวลากิจกรรม"
						dateFormat="YYYY-MM-DD"
						startValue={moment(campSetup.startDate).format("YYYY-MM-DD HH:mm:ss")}
						endValue={moment(campSetup.endDate).format("YYYY-MM-DD HH:mm:ss")}
						onBlur={onBlurData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					{/* <SelectInput
						label="เปิด/ปิด กิจกรรม"
						id="publish"
						name="campPublish"
						value={campSetup.publish}
						data={publish}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br /> */}
					{/* <SelectScore
						label="Answer Correct"
						id="answerCorrect"
						name="campAnswerCorrect"
						value={campSetup.answerCorrect}
						data={Array(10)}
						onChange={onChangeData}
						unit="score"
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br /> */}
					<UploadInput
						label="Banner Image"
						id="bannerImage"
						name="bannerImage"
						placeholder="Browse..."
						value={(bannerImg || "").url ? "Browse..." : bannerImg.name}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("bannerImage")}
						list={(campSetup.bannerImage || "").url ? campSetup.bannerImage.name : ""}
						disabled={disabled ? "disabled" : ""}
						remark="Dimension: 1128x856px"
					/>
					<br />
					<UploadInput
						label="Background Image"
						id="backgroundImg"
						name="backgroundImage"
						placeholder="Browse..."
						value={(bgImg || "").url ? "Browse..." : bgImg.name}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("backgroundImage")}
						list={(campSetup.backgroundImage || "").url ? campSetup.backgroundImage.name : ""}
						disabled={disabled ? "disabled" : ""}
						remark="Dimension: 1440x789px(16:9)"
					/>
					<br />
					<UploadInput
						label="Share Image"
						id="shareImg"
						name="shareImage"
						placeholder="Browse..."
						value={(shareImg || "").url ? "Browse..." : shareImg.name}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("shareImage")}
						list={(campSetup.shareImage || "").url ? campSetup.shareImage.name : ""}
						disabled={disabled ? "disabled" : ""}
						remark="Dimension: 1200x675px(16:9)"
					/>
					<br />
					<UploadInput
						label="Prize Image"
						id="prizeImg"
						name="prizeImage"
						placeholder="Browse..."
						value={(prizeImg || "").url ? "Browse..." : prizeImg.name}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("prizeImage")}
						list={(campSetup.prizeImage || "").url ? campSetup.prizeImage.name : ""}
						disabled={disabled ? "disabled" : ""}
						remark="Dimension: 452x452px(1:1)"
						require
						// validate={error["prizeImage"]}
					/>
					<br />
					<UploadInput
						label="Sponser Image"
						id="sponsorImage"
						name="sponsorImage"
						placeholder="Browse..."
						value={(sponserImg || "").url ? "Browse..." : sponserImg.name}
						onChange={(event) => onUpload(event)}
						onClick={() => setUpload("sponsorImage")}
						list={Array.isArray(campSetup.sponsorImage || []) ? campSetup.sponsorImage : ""}
						disabled={disabled ? "disabled" : ""}
						muti
						onDelete={(index) => onDeleteImage(index)}
						remark="ความสูงไม่เกิน 120px"
					/>
					<br />
					<SelectInput
						label="Campaign Position"
						id="position"
						name="campPosition"
						value={campSetup.position}
						data={positon}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
					/>
					<br />
					{campSetup.position >= 0 && (
						<InputSlagName
							label={parseInt(campSetup.position) === 1 ? "Link Campaignpage" : "Link Contentpage"}
							id="link"
							name="campLink"
							maxLength="200"
							value={campSetup.slagName}
							onChange={onChangeData}
							disabled={disabled ? "disabled" : ""}
							domain={parseInt(campSetup.position) === 1 ? `${process.env.REACT_APP_LINK_CAMPAIGN}/` : `${process.env.REACT_APP_CONTENT_PAGE}/`}
							require
						/>
					)}
					<br />
					<div className="row">
						<div className="col-3">
							<h5 className="m-0 font-weight-bold text-primary">แก้ไขกล่องส่งที่อยู่</h5>
						</div>
					</div>
					<br />
					<Radio
						label="require name"
						id="rName"
						name="require_member_name"
						value={campSetup.require_member?.name || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
					<Radio
						label="require phone"
						id="rPhone"
						name="require_member_phone"
						value={campSetup.require_member?.phone || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
					<Radio
						label="require address"
						id="rAddress"
						name="require_member_address"
						value={campSetup.require_member?.address || false}
						onChange={onChangeData}
						disabled={disabled ? "disabled" : ""}
						require
						compCol="col-12"
					/>
					<br />
				</div>
				{/* <button onClick={() => console.log(campSetup)}>Submit</button> */}
			</div>
		</Fragment>
	)
}

export default QuizSetup
