import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import User from "../../libs/admin"
import { checkPermission } from "../../helper/functions"

export default function Sidebar() {
	const user = User.getCookieData()
	// console.log("user ==> ", user)
	return (
		<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
			{/* Sidebar - Brand */}
			<Link to="/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center">
				<div className="sidebar-brand-icon rotate-n-15">
					<i className="fas fa-laugh-wink" />
				</div>
				<div className="sidebar-brand-text mx-3">
					CMS Member <sup>1</sup>
				</div>
			</Link>
			{/* Divider */}
			{checkPermission(user.permission, "dashboard_view") && (
				<Fragment>
					<hr className="sidebar-divider my-0" />
					<li className="nav-item">
						<Link to="/dashboard" className="nav-link">
							<i className="fas fa-fw fa-tachometer-alt" />
							<span>Dashboard 1</span>
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/dashboard2" className="nav-link">
							<i className="fas fa-fw fa-tachometer-alt" />
							<span>Dashboard 2</span>
						</Link>
					</li>
				</Fragment>
			)}
			{/* Content */}
			{/* {checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "finance_edit"], "or") && (
				<>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="/home"
							data-toggle="collapse"
							data-target="#collapseContent"
							aria-expanded="true"
							aria-controls="collapseContent"
						>
							<i className="fas fa-book"></i>
							<span>Content</span>
						</Link>
						<div id="collapseContent" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								<Link to="/content/list/waiting" className="collapse-item">
									รอการอนุมัติ
								</Link>
								<Link to="/content/list/approve" className="collapse-item">
									ได้รับการอนุมัติแล้ว
								</Link>
								<div className="collapse-divider"></div>
							</div>
						</div>
					</li>
				</>
			)} */}
			{checkPermission(user.permission, ["report_group_view", "report_persons_view", "report_journey_view"], "or") && (
				<Fragment>
					<hr className="sidebar-divider my-0" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="#"
							data-toggle="collapse"
							data-target="#collapseReport"
							aria-expanded="true"
							aria-controls="collapseReport"
						>
							<i className="fas fa-fw fa-book" />
							<span>Report</span>
						</Link>
						<div id="collapseReport" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								{checkPermission(user.permission, "report_group_view") && (
									<Link to="/report/group" className="collapse-item">
										Group
									</Link>
								)}
								{checkPermission(user.permission, "report_persons_view") && (
									<Link to="/report/persons" className="collapse-item">
										Persons
									</Link>
								)}
								{checkPermission(user.permission, "report_journey_view") && (
									<Link to="/report/journey" className="collapse-item">
										Journey
									</Link>
								)}
							</div>
						</div>
					</li>
					<hr className="sidebar-divider d-none d-md-block" />
				</Fragment>
			)}

			{checkPermission(user.permission, ["member_view", "member_manage"], "or") && (
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="/home"
							data-toggle="collapse"
							data-target="#collapseMember"
							aria-expanded="true"
							aria-controls="collapseMember"
						>
							<i className="fas fa-user-astronaut"></i>
							<span>Member</span>
						</Link>

						<div id="collapseMember" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								{checkPermission(user.permission, "member_view") && (
									<Link to="/member/list" className="collapse-item">
										รายชื่อทั้งหมด
									</Link>
								)}
								{checkPermission(user.permission, "member_manage_view") && (
									<Link to="/member/dashboard" className="collapse-item">
										Member Manage (Dev.)
									</Link>
								)}
							</div>
						</div>
					</li>
				</Fragment>
			)}

			{checkPermission(user.permission, "user_ads_management_view") && (
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="/home"
							data-toggle="collapse"
							data-target="#collapseSale"
							aria-expanded="true"
							aria-controls="collapseSale"
						>
							<i className="fas fa-book"></i>
							<span>Sale</span>
						</Link>
						<div id="collapseSale" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								<Link to="/sale/ads" className="collapse-item">
									User หน้า Ads
								</Link>
								<Link to="/sale/ads-setting" className="collapse-item">
									Ads Setting
								</Link>
								<Link to="/sale/generate-script" className="collapse-item">
									Generate Script
								</Link>
								<div className="collapse-divider"></div>
							</div>
						</div>
					</li>
				</Fragment>
			)}

			{checkPermission(user.permission, "user_management_view") && (
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />

					<>
						<li className="nav-item">
							<Link to="/user/list" className="nav-link">
								<i className="fas fa-users-cog" />
								<span>User management</span>
							</Link>
						</li>
						<hr className="sidebar-divider d-none d-md-block" />
					</>
				</Fragment>
			)}

			{/* {user.position === "admin" ? (
				<>
					<li className="nav-item">
						<Link to="/user/list" className="nav-link">
							<i className="fas fa-users-cog" />
							<span>User management</span>
						</Link>
					</li>
					<hr className="sidebar-divider d-none d-md-block" />
				</>
			) : (
				""
			)} */}

			{checkPermission(user.permission, "notification_view") && (
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link to="/notificationcenter/list" className="nav-link">
							<i className="fas fa-fw fa fa-bolt" />
							<span>Notification Setup</span>
						</Link>
					</li>
				</Fragment>
			)}

			{checkPermission(user.permission, "comment_view") && (
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="/comment/list"
							data-toggle="collapse"
							data-target="#collapseComment"
							aria-expanded="true"
							aria-controls="collapseComment"
						>
							<i className="fas fa-comments"></i>
							<span>Comment Management</span>
						</Link>
						<div id="collapseComment" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								<Link to="/comment/list" className="collapse-item">
									Comment
								</Link>
								{/* <div className="collapse-divider"></div>
						<Link to="/comment/report" className="collapse-item">
							จัดการเมนู Spam(report)
						</Link> */}
							</div>
						</div>
					</li>
				</Fragment>
			)}

			{checkPermission(
				user.permission,
				["activity_vote_view", "vote_in_article_view", "news_vote_view", "campaign_page_setting_view", "euro_football_view"],
				"or"
			) && (
				// <Fragment>
				// 	<hr className="sidebar-divider d-none d-md-block" />
				// 	<li className="nav-item">
				// 		<Link className="nav-link collapsed" to="/campaign/list" data-target="#collapseVote" aria-expanded="true" aria-controls="collapseVote">
				// 			<i className="fas fa-tasks"></i>
				// 			<span>Campaign Management</span>
				// 		</Link>
				// 	</li>
				// </Fragment>
				<Fragment>
					<hr className="sidebar-divider d-none d-md-block" />
					<li className="nav-item">
						<Link
							className="nav-link collapsed"
							to="/campaign/list"
							data-toggle="collapse"
							data-target="#collapseCampaign"
							aria-expanded="true"
							aria-controls="collapseCampaign"
						>
							<i className="fas fa-tasks"></i>
							<span>Campaign Management</span>
						</Link>
						<div id="collapseCampaign" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								{checkPermission(user.permission, "activity_vote_view") && (
									<Link to="/campaign-new/activity-vote" className="collapse-item">
										Activity Vote
									</Link>
								)}
								{checkPermission(user.permission, "vote_in_article_view") && (
									<Link to="/campaign-new/vote-in-article" className="collapse-item">
										Vote In Article
									</Link>
								)}
								{checkPermission(user.permission, "news_vote_view") && (
									<Link to="/campaign-new/news-vote" className="collapse-item">
										News Vote
									</Link>
								)}
								{/* <Link to="/campaign/list" className="collapse-item">
									Campaign Management
								</Link> */}
								{checkPermission(user.permission, "campaign_page_setting_view") && (
									<Link to="/campaign/page-setting" className="collapse-item">
										Campaign Page Setting
									</Link>
								)}
								{checkPermission(user.permission, "euro_football_view") && (
									<Link to="/campaign-new/euro-football" className="collapse-item">
										Football Campaign
									</Link>
								)}
							</div>
						</div>
					</li>
				</Fragment>
			)}
			{checkPermission(user.permission, ["subscription_member_view", "subscription_package_view", "subscription_transaction_view"], "or") && (
				<>
					<Fragment>
						<hr className="sidebar-divider d-none d-md-block" />
						<li className="nav-item">
							<Link
								className="nav-link collapsed"
								to="/subscription/package/list"
								data-toggle="collapse"
								data-target="#collapseSubscription"
								aria-expanded="true"
								aria-controls="collapseSubscription"
							>
								<i className="fas fa-comments"></i>
								<span>Subscription</span>
							</Link>
							<div id="collapseSubscription" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
								<div className="bg-white py-2 collapse-inner rounded">
									{checkPermission(user.permission, "subscription_member_view") && (
										<Link to="/subscription/member/list" className="collapse-item">
											Members
										</Link>
									)}
									{checkPermission(user.permission, "subscription_package_view") && (
										<Link to="/subscription/package/list" className="collapse-item">
											Package
										</Link>
									)}
									{checkPermission(user.permission, "subscription_transaction_view") && (
										<Link to="/subscription/transaction/list" className="collapse-item">
											Transaction
										</Link>
									)}
								</div>
							</div>
						</li>
					</Fragment>
				</>
			)}

			<Fragment>
				{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "finance_edit"], "or") && (
					<Fragment>
						<hr className="sidebar-divider d-none d-md-block" />
						<li className="nav-item">
							<Link
								className="nav-link collapsed"
								data-toggle="collapse"
								data-target="#collapseMongkolWall"
								aria-expanded="true"
								aria-controls="collapseMongkolWall"
							>
								<i className="fas fa-star-and-crescent"></i>
								<span>Mongkol Wall</span>
							</Link>
							<div id="collapseMongkolWall" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
								<div className="bg-white py-2 collapse-inner rounded">
									<Link to="/mongkol-wall/dashboard" className="collapse-item">
										Dashboard
									</Link>
								</div>
							</div>
						</li>
					</Fragment>
				)}
			</Fragment>

			{/* <Fragment>
				{checkPermission(user.permission, ["member_sensitive_data_edit"], "or") && (
					<Fragment>
						<hr className="sidebar-divider d-none d-md-block" />
						<li className="nav-item">
							<Link className="nav-link collapsed" data-toggle="collapse" data-target="#collapseMemberManage" aria-expanded="true" aria-controls="collapseMemberManage">
								<i class="fas fa-solid fa-user"></i>
								<span>Member manage</span>
							</Link>
							<div id="collapseMemberManage" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
								<div className="bg-white py-2 collapse-inner rounded">
									<Link to="/member/dashboard" className="collapse-item">
										Dashboard
									</Link>
								</div>
							</div>
						</li>
					</Fragment>
				)}
			</Fragment> */}

			{/* Sidebar Toggler (Sidebar) */}
			<div className="text-center d-none d-md-inline">
				<button className="rounded-circle border-0" id="sidebarToggle" />
			</div>
		</ul>
	)
}
