import React from "react"
import _get from "lodash/get"
import moment from "moment"

const StatusCampaignList = ({ data }) => {
	const startDate = _get(data, "setting.startDate", new Date())
	const endDate = _get(data, "setting.endDate", new Date())

	if (!data.isActive) {
		return <span style={{ color: "red" }}>Inactive</span>
	} else if (moment().isBefore(startDate)) {
		return <span style={{ color: "#bea25f" }}>Waiting</span>
	} else if (moment().isAfter(endDate)) {
		return <span style={{ color: "orange" }}>Expired</span>
	} else {
		return <span style={{ color: "green" }}>Active</span>
	}
}

export default React.memo(StatusCampaignList)
