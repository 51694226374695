import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import { Row, Col } from "react-bootstrap"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const Activity = () => {
	let { id } = useParams()
	const [data, setData] = useState()
	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/members/log/${id}?v=` + Math.random() * 1000, { withCredentials: true })
			setData(data.data)
		}
		fetchData()
	}, [id])

	const actionType = {
		save_favorite: "save_favorite",
		remove_favorite: "remove_favorite",
		set_interest: "set_interest",
		update_info: "update_info",
		login: "login",
		logout: "logout",
		newsletter: "newsletter",
	}

	const options = {
		sizePerPage: 25,
	}

	const enumFormatter = (cell, row, enumObject) => {
		return enumObject[cell]
	}

	const dateFormatter = (cell) => {
		if (!cell) {
			return ""
		}
		return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
	}

	return (
		<>
			<Row>
				<Col xs={12}>
					<BootstrapTable data={data} pagination={true} options={options}>
						<TableHeaderColumn
							dataField="type"
							filterFormatted
							dataFormat={enumFormatter}
							formatExtraData={actionType}
							filter={{ type: "SelectFilter", options: actionType }}
						>
							ประเภทกิจกรรม
						</TableHeaderColumn>
						<TableHeaderColumn dataField="activity" isKey filter={{ type: "TextFilter", delay: 1000 }}>
							รายละเอียดกิจกรรม
						</TableHeaderColumn>
						<TableHeaderColumn dataField="create_date" dataFormat={dateFormatter} dataSort={true}>
							วันที่ทำกิจกรรม
						</TableHeaderColumn>
						<TableHeaderColumn dataField="admin" dataSort={true}>
							อัพเดทโดย
						</TableHeaderColumn>
					</BootstrapTable>
				</Col>
			</Row>
		</>
	)
}
export default Activity
